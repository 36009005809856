import { ItemAddPropertySkeleton } from 'components/skeleton/molecules/Item-add-property-skeleton/Item-add-property-skeleton';
import './Edit-plan-skeleton.scss';
import { Divider } from 'components/atoms/Divider/Divider';

export const EditPlanSkeleton = () => {
  return (
    <div className="edit__plan__skeleton__container">
      <div className="edit__plan__skeleton__title skeleton"></div>
      <div className="edit__plan__skeleton__sub__title skeleton"></div>
      <Divider type="bar" />
      <div className="edit__plan__skeleton__count__title skeleton"></div>
      <div className="edit__plan__skeleton__count__sub__title skeleton"></div>
      <ItemAddPropertySkeleton></ItemAddPropertySkeleton>
      <div className="edit__plan__information__divider__container">
        <Divider type="line" margin="notMargin" />
      </div>
      <div className="edit__plan__skeleton__prices__container">
        <div className="amount__container">
          <div className="amount__title skeleton"></div>
          <div className="amount__title skeleton"></div>
        </div>
        <div className="amount__price skeleton"></div>
      </div>
    </div>
  );
};
