import { IRequestAddPropertiy } from 'pages/Create-account/pages/Create-properties/models/Create-properties.interface';
import { IEditPropertyRequest } from 'pages/Edit-Property/models/Edit-Property.interface';
import cms from './cms';

/**
 * Function to get all products
 * @returns
 */
export async function getProducts() {
  const response = await cms.get('subscription-products');
  return response.data;
}

/**
 * Function to get a product by id
 * @param id number
 * @returns object
 */
export async function getProductById(id: number) {
  const response = await cms.get(`subscription-products/${id}`);
  return response.data;
}

/**
 * Function to get a product by id
 * @param uid string
 * @returns object
 */
export async function createProductProperties(
  uid: string,
  data: IRequestAddPropertiy | FormData
) {
  const response = await cms.post(`product-properties/${uid}`, data);
  return response.data;
}

/**
 * Function to get a product by id
 * @param uid string
 * @returns object
 */
export async function updateProductProperties(
  uid: string,
  data: IEditPropertyRequest
) {
  const response = await cms.put(`product-properties/${uid}`, data);
  return response.data;
}
