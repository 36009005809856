import { PopupExistProcess } from 'components/molecules/Popup-exist-porcess/Popup-exist-porcess';
import { HeaderWithSteps } from 'components/organisms/Header-with-steps/Header-with-steps';
import { IDetailStep } from 'models/info.steps.interface';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { BuyPlanRouter } from './routes/buy-plan.routes';

export const BuyPlan = () => {
  const navigate = useNavigate();
  const steps: IDetailStep[] = [
    {
      description: 'Comprar Plan',
      state: 'active',
      link: '/settings/payments',
      permissionToNavigate: true,
    },
    {
      description: 'Crear Propiedades',
      state: 'disabled',
      link: 'create-properties',
      permissionToNavigate: false,
    },
  ];
  const [step, setStep] = useState<IDetailStep[]>(steps);
  const [popUp, setPopUp] = useState(false);
  const [typePopUp, setTypePopUp] = useState<'create' | 'other'>('create');
  const showInfoPopUp = () => {
    setPopUp((status) => !status);
  };
  const handleStep = (indexStep: number, typePopUp: 'create' | 'other') => {
    const indexItem = indexStep - 1;
    step.forEach((element: IDetailStep, index: number) => {
      if (index === indexItem) {
        element.state = 'active';
        element.permissionToNavigate = true;
      } else if (indexItem > index) {
        element.state = 'completed';
        element.permissionToNavigate = false;
      } else {
        element.state = 'disabled';
        element.permissionToNavigate = false;
      }
    });
    setStep([...step]);
    setTypePopUp(typePopUp);
  };
  const exitProcess = () => {
    navigate('/settings');
  };

  const getText = () => {
    if (typePopUp === 'create') {
      return {
        message: `Si es así, recuerde que deberá iniciar nuevamente desde el principio.`,
      };
    } else {
      return {
        message:
          'Estás a punto de salir sin completar el formulario, recuerda que hasta no tener toda la información, no podrás solicitar el servicio de asistencia.',
      };
    }
  };
  return (
    <>
      <HeaderWithSteps
        navbarTemplate="light"
        steps={step}
        onClick={showInfoPopUp}
      />
      <section className="create__account__container">
        <BuyPlanRouter
          handleStep={(index: number, typePopUp: 'create' | 'other') =>
            handleStep(index, typePopUp)
          }
        ></BuyPlanRouter>
      </section>
      {popUp && (
        <PopupExistProcess
          title="¿Deseas salir?"
          message={getText().message}
          labelButton="Continuar"
          labelButtonCancel="Salir"
          type="warning"
          closePopUp={showInfoPopUp}
          exitProcess={exitProcess}
        />
      )}
    </>
  );
};
