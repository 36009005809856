import axios from 'axios';
import { config } from 'config/constants';

interface IRequestRuedaz {
  plate: string;
  documentNumber: number;
}

/**
 * Function to add a subscription to a user
 * @param data IRequestRuedaz
 * @returns
 */
export const getUserRuedaz = async (data: IRequestRuedaz) => {
  const response = await axios.post(config.ruedazUrl, {
    ...data,
  });
  const { isRuedaz, isActive } = response.data.data;

  return isRuedaz && isActive ? true : false;
};
