import { Alert } from 'components/atoms/Alert/Alert';
import { IDetailStep } from 'models/info.steps.interface';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { resetEditAndDeleteProperty } from 'store/actions/app.types';
import { HeaderWithSteps } from '../Header-with-steps/Header-with-steps';
interface IPropsHeaderWithAlert {
  steps: IDetailStep[];
  title?: string;
  message?: string;
  showAlert?: boolean;
  onClick?: () => void;
}
export const HeaderWithAlert = (props: IPropsHeaderWithAlert) => {
  const dispatch = useDispatch();
  const contentEl = useRef<HTMLDivElement>(null);
  const [showAlert, setShowAlert] = useState<boolean>(
    props.showAlert as boolean
  );
  const [alertHeight, setAlertHeight] = useState<undefined | number>(undefined);
  useEffect(() => {
    setAlertHeight(contentEl?.current?.clientHeight);
    dispatch(resetEditAndDeleteProperty());
    setTimeout(() => {
      setShowAlert(false);
    }, 16000);
  }, [showAlert]);

  useEffect(() => {
    if (props.showAlert) {
      setShowAlert(true);
    }
  }, [props.showAlert]);

  return (
    <>
      {showAlert && (
        <div
          ref={contentEl}
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 2,
          }}
        >
          <Alert
            type="successful"
            onClick={() => {
              setShowAlert(false);
            }}
            title={props.title || ''}
            message={props.message || ''}
          />
        </div>
      )}
      <div
        style={{
          position: 'sticky',
          top: alertHeight ? alertHeight : '0',
          zIndex: 1,
        }}
      >
        <HeaderWithSteps
          navbarTemplate="light"
          steps={props.steps}
          notProgress={true}
        />
      </div>
    </>
  );
};
