import { IPropsIcon } from 'assets/icons/icons.interface';
import { useEffect, useState } from 'react';

export const useColorHook = (propsColor: IPropsIcon['color']) => {
  const [color, setColor] = useState('');
  useEffect(() => {
    switch (propsColor) {
      case 'primary':
        setColor('#f15b2b');
        break;
      case 'secondary':
        setColor('#001D3D');
        break;
      case 'tertiary':
        setColor('#fff');
        break;
      default:
        setColor('#f15b2b');
        break;
    }
  }, [propsColor]);
  return color;
};
