import { useColorHook } from 'hooks/Color.hook';
import { IPropsIcon } from './icons.interface';

export const PinConnect = (props: IPropsIcon) => {
  const color = useColorHook(props.color);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      className={props.className}
      viewBox="0 0 48 48"
      fill="none"
    >
      <g clipPath="url(#clip0_7816_13281)">
        <path
          d="M23.9994 0C10.7449 0 0 10.7449 0 23.9994C0 37.2539 10.7449 47.9988 23.9994 47.9988C37.2539 47.9988 47.9988 37.2539 47.9988 23.9994C47.9988 10.7449 37.2551 0 23.9994 0ZM33.8335 37.7971L26.7985 30.0184C29.0767 29.1274 30.5134 27.1957 30.5134 24.3719C30.5134 21.0035 27.838 18.4271 24.0229 18.4271C20.2078 18.4271 17.4841 21.0035 17.4841 24.3719C17.4841 27.2452 18.8218 28.9294 21.3487 30.1186L14.2642 37.8961C9.70667 34.8742 7.17977 30.1186 7.17977 24.3224C7.17977 14.8112 13.768 7.2305 24.0229 7.2305C33.633 7.2305 40.8165 14.81 40.8165 24.3224C40.8165 30.2176 38.2896 34.9237 33.831 37.7971H33.8335Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_7816_13281">
          <rect width="48" height="48" fill="transparent" />
        </clipPath>
      </defs>
    </svg>
  );
};
