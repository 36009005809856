import { Images } from 'assets/images/images.index';
import './Item-status.scss';
interface IPropsItemStatus {
  status:
    | 'successful'
    | 'pending'
    | 'error'
    | 'cancelled'
    | 'Activo'
    | 'Cancelado'
    | 'timedOut'
    | 'Finalizado';
  message?: string;
  big?: boolean;
  hidenIcon?: boolean;
}
export const ItemStatus = (props: IPropsItemStatus) => {
  const setIcon = () => {
    return {
      successful: Images.chipCheck,
      pending: Images.chipWarning,
      error: Images.chipError,
      cancelled: Images.chipWarning,
      Activo: Images.chipCheck,
      Cancelado: Images.chipWarning,
      Finalizado: Images.chipWarning,
      timedOut: Images.chipWarning,
    }[props.status];
  };
  return (
    <div
      className={`item__status ${
        props.status && props.status.toLocaleLowerCase()
      } ${props.big ? 'big' : ''}`}
    >
      {!props.hidenIcon && (
        <img
          className="item__status__icon"
          src={setIcon()}
          alt={`icono estado ${props.status}`}
        />
      )}
      <p className="item__status__text">{props.message}</p>
    </div>
  );
};
