import './Divider.scss';
import * as CSS from 'csstype';
export interface IPropsDivider {
  type?: 'line' | 'dashed' | 'bar';
  styleInLine?: CSS.Properties<string | number>;
  classWrapper?: string;
  margin?: 'small' | 'notMargin';
}

export const Divider = (props: IPropsDivider) => {
  return (
    <div
      className={`container__divider ${
        props.classWrapper ? props.classWrapper : ''
      }`}
    >
      <hr
        className={`${props.type ? props.type : ''} ${
          props.margin === 'small' ? 'margin--small' : ''
        } ${props.margin === 'notMargin' ? 'margin--not' : ''}`}
      />
    </div>
  );
};
