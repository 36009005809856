import { Images } from 'assets/images/images.index';
import './Button-index-property.scss';

export interface IPropsLabel {
  index: string;
  isDisabled?: boolean;
  isCompleted?: boolean;
  onClick?: () => void;
}

export const ButtonIndexProperty = (props: IPropsLabel) => {
  return (
    <button
      type="button"
      className={`button__index__property ${
        props.isDisabled && !props.isCompleted ? 'disabled' : ''
      }  ${props.isCompleted ? 'completed' : ''}`}
      onClick={props.onClick}
    >
      {props.isCompleted ? (
        <img className="button__index__completed" src={Images.check} />
      ) : (
        props.index
      )}
    </button>
  );
};
