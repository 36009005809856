import { NewPassword } from "pages/sing-in/New-password/New-password";
import { useRoutes } from "react-router-dom";
import { Navigate } from "react-router-dom";

export const SingInRouter = () => {
  const routes = useRoutes([
    {
      path: "/email-verification",
      element: (
        <div>Email address verification</div>
      ),
    },
    {
      path: "/recover-email",
      element: (
        <div>Recover email address</div>
      ),
    },
    {
      path: "/new-password",
      element: (
        <NewPassword/>
      ),
    },
    {
      path: "/*",
      element: (
        <Navigate to="/login" />
      ),
    },
  ]);
  return routes;
};
