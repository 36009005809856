import { IProduct } from 'pages/Checkout/pages/Payment-information/models/Payment-information.interface';
import { useRef, useState } from 'react';
import {
  IAtributesProperties,
  ICartCheckoutData,
} from '../models/Activate-benefits.interface';
import { FormikValues } from 'formik';
import { IAtribute } from '../../Create-properties/models/Create-properties.interface';

export const UseActivateBenefitsCreateProperties = () => {
  const [showPopUp, setShowPopUp] = useState<boolean>(false);
  const [initialValuesProperties] = useState<{
    [key: string]: string;
  }>({});
  const [products, setProducts] = useState<IProduct[]>([]);
  const [formsData, setFormsData] = useState<
    ICartCheckoutData['order']['dataOrderCompleted']['products']
  >([]);
  const formAutoRef =
    useRef<{
      getValues: () => FormikValues;
    }>(null);
  const formMotorcycleRef =
    useRef<{
      getValues: () => FormikValues;
    }>(null);
  const formHomeRef =
    useRef<{
      getValues: () => FormikValues;
    }>(null);

  const getProductType = (dataProducts: IProduct[]) => {
    setProducts(dataProducts);
    return dataProducts;
  };
  const onChangeQuantityForPlan = (
    callFunction: () => ICartCheckoutData,
    dataProducts?: IProduct[]
  ) => {
    const dataCartCheckout = callFunction();
    const planIds = dataCartCheckout.order.dataOrderCompleted.products;
    const containsProduct = (dataProducts ? dataProducts : products).filter(
      (product) => planIds.some((item) => item.product_id === product.id)
    );
    containsProduct.forEach((element, index) => {
      if (planIds[index].product_id === element.id) {
        planIds[index].attributes = element.attributes.productos.data[0]
          .attributes.properties.data as IAtributesProperties[];
        planIds[index].typeProduct =
          element.attributes.productos.data[0].attributes.product_type_id.data.attributes.name;
      }
    });
    const dataForm = planIds.filter((p) => p.quantity > 0);
    setFormsData([...dataForm]);
  };

  const validateProperties = async () => {
    let valuesForm: FormikValues = {};
    const formAutoValues = formAutoRef.current?.getValues() as FormikValues;
    const formMotorcycleValues =
      formMotorcycleRef.current?.getValues() as FormikValues;
    const formHomeValues = formHomeRef.current?.getValues() as FormikValues;
    valuesForm = {
      ...formAutoValues,
      ...formMotorcycleValues,
      ...formHomeValues,
    };

    if (
      (formAutoValues && Object.keys(formAutoValues).length <= 0) ||
      (formMotorcycleValues && Object.keys(formMotorcycleValues).length <= 0) ||
      (formHomeValues && Object.keys(formHomeValues).length <= 0)
    ) {
      setShowPopUp(true);
    } else {
      const dataRequest = mappingDataRequest(valuesForm);
      return { data: [...dataRequest] };
    }
  };

  const mappingDataRequest = (data: any) => {
    const dataProperties: {
      atributos: IAtribute[];
    }[] = [];
    formsData.forEach((typeProperty) => {
      Array(typeProperty.quantity)
        .fill(1)
        .forEach((_, indexQuantity: number) => {
          const atributos = typeProperty.attributes
            ?.map((property) => {
              const valor =
                data[
                  `${typeProperty.typeProduct}__${property.attributes.name}__${indexQuantity}__${property.id}`
                ];
              if (valor !== undefined) {
                return {
                  valor,
                  status: true,
                  propiedad: property.id,
                  user_product: 0,
                  product_type_id: typeProperty.product_type_id,
                };
              }
              return null;
            })
            .filter((atributo) => atributo !== null) as IAtribute[];
          if (atributos.length) {
            dataProperties.push({
              atributos,
            });
          }
        });
    });
    return dataProperties;
  };

  return {
    products,
    getProductType,
    onChangeQuantityForPlan,
    formsData,
    formAutoRef,
    formMotorcycleRef,
    formHomeRef,
    initialValuesProperties,
    showPopUp,
    validateProperties,
  };
};
