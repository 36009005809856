import './Tooltip.scss';

export interface IPropsTooltip {
  label: string;
}

export const Tooltip = (props: IPropsTooltip) => {
  return (
    <div className="tooltip__container">
      <svg
        className="tooltip__icon"
        width="33"
        height="32"
        viewBox="0 0 33 32"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.0455 9.45455C19.4305 5.83959 13.5695 5.83959 9.95455 9.45455C6.33959 13.0695 6.33959 18.9305 9.95455 22.5455C13.5695 26.1604 19.4305 26.1604 23.0455 22.5455C26.6604 18.9305 26.6604 13.0695 23.0455 9.45455ZM8.5 8C12.9183 3.58172 20.0817 3.58172 24.5 8C28.9183 12.4183 28.9183 19.5817 24.5 24C20.0817 28.4183 12.9183 28.4183 8.5 24C4.08172 19.5817 4.08172 12.4183 8.5 8Z"
          fill="#F15B2B"
        />
        <path
          d="M17.4504 10.8276C17.7307 11.1079 17.8882 11.4881 17.8882 11.8844C17.8882 12.2808 17.7307 12.661 17.4504 12.9413C17.1702 13.2215 16.79 13.379 16.3936 13.379C15.9972 13.379 15.6171 13.2215 15.3368 12.9413C15.0565 12.661 14.8991 12.2808 14.8991 11.8844C14.8991 11.4881 15.0565 11.1079 15.3368 10.8276C15.6171 10.5473 15.9972 10.3899 16.3936 10.3899C16.79 10.3899 17.1702 10.5473 17.4504 10.8276Z"
          fill="#F15B2B"
        />
        <path
          d="M17.39 15.6209L17.39 21.35C17.39 21.487 17.2779 21.5991 17.1409 21.5991L15.6463 21.5991C15.5093 21.5991 15.3972 21.487 15.3972 21.35L15.3972 15.6209C15.3972 15.4839 15.5093 15.3718 15.6463 15.3718L17.1409 15.3718C17.2779 15.3718 17.39 15.4839 17.39 15.6209Z"
          fill="#F15B2B"
        />
      </svg>
      <span className="tooltip__text">{props.label}</span>
    </div>
  );
};
