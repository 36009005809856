export const typeProperty = {
  '1': 'auto',
  '2': 'hogar',
  '3': 'mascota',
  '4': 'moto',
};

export const monthsConstants = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

export const statusPayments = [
  { value: 1, description: 'Pagado' },
  { value: 2, description: 'Cancelado' },
  { value: 3, description: 'Pendiente' },
];
