import { Images } from 'assets/images/images.index';
import { TypeProperty } from 'models/app.interface';
import { IAppReducer } from 'models/reducer.interface';
import { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import './Item-add-property.scss';
import { formatPrice } from 'utils/payments.utils';

export interface IPropsItemAddProperty {
  template: 'primary' | 'secondary';
  onClick: (items: number, type?: number) => void;
  type: TypeProperty;
  maxItems: number;
  isDisabled?: boolean;
  isDisabledButtonLess?: boolean;
  isDisabledButtonPlus?: boolean;
  count?: number;
  priceForUnit?: number;
  branch: string;
  price?: number;
  value?: string;
  id: string | number;
}

export const ItemAddProperty = (props: IPropsItemAddProperty) => {
  const [items, setItems] = useState(props.count || 0);
  const { createAccountReducer } = useSelector(
    (state: IAppReducer) => state,
    shallowEqual
  );
  useEffect(() => {
    if (typeof props.count === 'number') {
      setItems(props.count);
    }
  }, [props.count]);

  const handleClick = (event: number) => {
    if (!props.isDisabled) {
      if (
        (items <= 0 && event === -1) ||
        (items >= props.maxItems && event === 1)
      ) {
        return;
      }
      const value = items + event;
      setItems(value);
      props.onClick(value, event);
    }
  };

  useEffect(() => {
    if (createAccountReducer.short_link_properties.properties.length > 0) {
      if (props.type == 'auto') {
        const quantity =
          createAccountReducer.short_link_properties.properties.autos.length;
        setItems(quantity);
        props.onClick(quantity);
      }
    }
  }, [props.count]);

  return (
    <div className="item__add__property__container">
      <div className="item__add__property__container__icon">
        <img
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          src={(Images as any)[props.type.toLowerCase()]}
          alt="Tipo de propiedad"
        />
        <div className="item__add__property__container__icon__detail">
          <div className="item_type">
            <span>{props.type}</span>
            {props.priceForUnit && (
              <span className="price__unit">{`$${formatPrice(
                props.priceForUnit,
                props.branch
              )} /unidad`}</span>
            )}
          </div>
          {props.price ? (
            <div className="item_price">{`$${formatPrice(
              props.price,
              props.branch
            )} TOTAL`}</div>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className="item__add__property__container__info">
        <button
          data-testid={`item__property__less__${props.id}`}
          onClick={() => !props.isDisabledButtonLess && handleClick(-1)}
          className={`less ${
            props.isDisabled || props.isDisabledButtonLess ? 'disabled' : ''
          }`}
          type="button"
        ></button>
        <div className={`${props.template === 'secondary' ? 'border' : ''}`}>
          <h4>{items}</h4>
        </div>
        <button
          data-testid={`item__property__pluss__${props.id}`}
          onClick={() => !props.isDisabledButtonPlus && handleClick(1)}
          className={`plus ${
            props.isDisabled || props.isDisabledButtonPlus ? 'disabled' : ''
          }`}
          type="button"
        ></button>
      </div>
    </div>
  );
};
