import { AxiosRequestConfig } from 'axios';
import cms from 'shared/services/cms';

interface RetryConfig extends AxiosRequestConfig {
  retry: number;
  retryDelay: number;
}

interface IRequestSendLog {
  nameError: string;
  error: {
    message: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    type: string;
  };
  payload: {
    scope: string;
    action: string;
    url: string;
    method?: string;
    user: string;
    agentId?: number | null;
    funelId?: string | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
}
/**
 * Function to send logs to the backend
 * @returns
 */
export async function sendLog(data: IRequestSendLog, attempts?: number) {
  const globalConfig: RetryConfig = {
    retry: attempts ?? 0,
    retryDelay: 1000,
  };
  const response = await cms.post(
    'register-log',
    {
      projectName: 'Meraki-Frontend',
      ...data,
    },
    globalConfig
  );
  return;
}
