import './Tap.scss';

export interface IPropsTap {
  label: string;
  className?: string;
  onClick: (id: string | number, label: string) => void;
  id: string | number;
  icon?: string;
}

export const Tap = (props: IPropsTap) => {
  return (
    <div
      className={`tap__container ${props.className ? props.className : ''}`}
      onClick={() => props.onClick(props.id, props.label)}
    >
      {props.icon && <img className="tap__icon" src={props.icon} alt="Icono" />}
      {props.label}
    </div>
  );
};
