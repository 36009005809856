import { ItemAddPropertySkeleton } from 'components/skeleton/molecules/Item-add-property-skeleton/Item-add-property-skeleton';
import './Add-property-skeleton.scss';

export const AddPropertySkeleton = () => {
  return <div className="add__property__skeleton__container">
    <div className="add__property__skeleton__icon skeleton"></div>
    <div className='add__property__skeleton__select skeleton'></div>
    <div className='add__property__skeleton__label skeleton'></div>
    <ItemAddPropertySkeleton></ItemAddPropertySkeleton>
  </div>;
};
