import { ReactNode } from 'react';
import './Tag-alert.scss';
import { Images } from 'assets/images/images.index';

interface IPropsTagAlert {
  children: ReactNode;
  type: 'error' | 'success' | 'warning';
  size?: 'medium';
  typeStyle: 'rounded' | 'square';
}
export const TagAlert = (props: IPropsTagAlert) => {
  const getIconCard = () => {
    return {
      error: Images.error,
      success: Images.check,
      warning: Images.warning,
    }[props.type];
  };
  return (
    <div
      className={`tag__alert__container ${props.type} ${
        props.size ? 'tag__alert__container--' + props.size : ''
      } ${props.typeStyle}`}
    >
      <img
        className="tag__alert__icon"
        src={getIconCard()}
        alt={`icono label ${props.type}`}
      />
      <span>{props.children}</span>
    </div>
  );
};
