import { Button } from 'components/atoms/Button/Button';
import './Activate-benefits.scss';
import {
  IAtributesProperties,
  ICartCheckoutData,
} from './models/Activate-benefits.interface';

import { DetailAccordion } from 'components/atoms/Detail-accordion/Detail-accordion';

import { PopupExistProcess } from 'components/molecules/Popup-exist-porcess/Popup-exist-porcess';
import { CartCheckout } from 'components/templates/Cart-checkout/Cart-checkout';
import { TitleWhitImage } from 'components/atoms/Title-whit-image/Title-whit-image';
import { Images } from 'assets/images/images.index';
import { CartCheckoutSkeleton } from 'components/skeleton/organisms/Cart-checkout-skeleton/Cart-checkout-skeleton';
import { ItemList } from 'components/atoms/Item-list/Item-list';
import { UseActivateBenefitsCreateProperties } from './hooks/activate-benefits-create-properties.hooks';
import { FormVehicles } from '../Create-properties/components/Form-vehicles/Form-vehicles';
import { TypeProperties } from 'pages/Settings/models/Properties.interface';
import { FormHomes } from '../Create-properties/components/Form-homes/Form-homes';
import { useActivateBenefits } from './hooks/Activate-benefits.hook';

export const ActivateBenefits = () => {
  const {
    getProductType,
    onChangeQuantityForPlan,
    formsData,
    formAutoRef,
    formMotorcycleRef,
    formHomeRef,
    initialValuesProperties,
    showPopUp,
    validateProperties,
  } = UseActivateBenefitsCreateProperties();
  const {
    formPaymentSubscription,
    exitProcess,
    showInfoPopUp,
    errorActivation,
    products,
    branch,
    orderId,
    cartCheckoutRef,
    user,
    checkoutReducer,
    phoneBranch,
    popUp,
  } = useActivateBenefits(
    validateProperties,
    getProductType,
    onChangeQuantityForPlan
  );

  return (
    <>
      <form
        className="active__plan__container container__generic"
        onSubmit={formPaymentSubscription.handleSubmit}
        noValidate
      >
        {errorActivation && (
          <DetailAccordion tilte="¡Listo! Cuenta creada" type="error">
            Ocurrió un error al activar tu beneficio
          </DetailAccordion>
        )}
        <div className="active__plan__content">
          <TitleWhitImage
            title="Activa tu <br/>descuento"
            image={Images.hogarTitle}
            styleInLineImage={{ width: '6.25rem', height: '6.25rem' }}
          ></TitleWhitImage>
          {products ? (
            <CartCheckout
              title="Cantidad de propiedades"
              titleAccordion="Ver beneficios"
              titleTermsAndConditions="Ver términos y condiciones"
              branch={branch}
              planItemsQuantity={[]}
              allProducts={products}
              orderId={orderId}
              ref={cartCheckoutRef}
              templete="default"
              agentId={user.agentId ? user.agentId.toString() : ''}
              companyName={checkoutReducer.company || ''}
              isCuponHidden={true}
              periodicityIsHidden={true}
              summaryIsHidden={true}
              onlyCount={true}
              onCartCheckoutChanged={() =>
                onChangeQuantityForPlan(
                  cartCheckoutRef.current
                    ?.saveOrderInformation as () => ICartCheckoutData
                )
              }
            ></CartCheckout>
          ) : (
            <CartCheckoutSkeleton />
          )}
        </div>
        <div className="active__plan__properties">
          <div className="active__plan__properties__title">
            <h3>
              información <br />
              de propiedades
            </h3>
            <p>
              Completa la información de las propiedades a cubrir para empezar a
              utilizar tu descuento.
            </p>
          </div>
          <div>
            {formsData.map((typeProperty) => (
              <>
                {typeProperty.category === TypeProperties.AUTO && (
                  <FormVehicles
                    productType={typeProperty.category}
                    branch={branch}
                    properties={
                      typeProperty.attributes as IAtributesProperties[]
                    }
                    quantity={typeProperty.quantity}
                    typeProduct={typeProperty.typeProduct as string}
                    ref={formAutoRef}
                    values={initialValuesProperties}
                  ></FormVehicles>
                )}
                {[TypeProperties.MOTOCICLETA, TypeProperties.MOTORA].includes(
                  typeProperty.category as TypeProperties
                ) && (
                  <FormVehicles
                    productType={
                      typeProperty.category as
                        | TypeProperties.MOTORA
                        | TypeProperties.MOTOCICLETA
                        | TypeProperties.VEHICULOS
                        | TypeProperties.AUTO
                    }
                    branch={branch}
                    properties={
                      typeProperty.attributes as IAtributesProperties[]
                    }
                    quantity={typeProperty.quantity}
                    typeProduct={typeProperty.typeProduct as string}
                    values={initialValuesProperties}
                    ref={formMotorcycleRef}
                  ></FormVehicles>
                )}
                {typeProperty.category === TypeProperties.HOGAR && (
                  <FormHomes
                    branch={branch}
                    properties={
                      typeProperty.attributes as IAtributesProperties[]
                    }
                    quantity={typeProperty.quantity}
                    typeProduct={typeProperty.typeProduct as string}
                    values={initialValuesProperties}
                    ref={formHomeRef}
                  ></FormHomes>
                )}
              </>
            ))}
          </div>
          <Button
            color="primary"
            size="medium"
            template="primary"
            typeStyle="rounded"
            className="success"
            type="button"
            label="Finalizar"
            onClick={formPaymentSubscription.handleSubmit}
            isDisabled={formPaymentSubscription.isSubmitting}
            isLoading={formPaymentSubscription.isSubmitting}
            testid="btn__buy__plan"
          ></Button>
          <ItemList>
            Verifica que la información que ingresaste sea la correcta, esta
            solo se podrá modificar llamando al{' '}
            <a
              style={{ textDecoration: 'none' }}
              target="_blank"
              rel="noreferrer"
              href={`https://wa.me/${phoneBranch}`}
            >
              {phoneBranch}
            </a>
          </ItemList>
        </div>
      </form>
      <></>
      {popUp.showModal ||
        (showPopUp && (
          <PopupExistProcess
            title={popUp.title}
            message={popUp.message}
            labelButton={popUp.labelButton}
            labelButtonCancel="Salir"
            type="warning"
            closePopUp={showInfoPopUp}
            exitProcess={exitProcess}
          />
        ))}
    </>
  );
};
