import { useEffect, useLayoutEffect, useState, useRef } from 'react';

import { useFormik } from 'formik';
import { getCountry } from 'utils/branch.utils';
import { useDispatch, useSelector } from 'react-redux';
import { getPlanById } from 'shared/services/plans';
import { useNavigate } from 'react-router-dom';
import { IAppReducer } from 'models/reducer.interface';
import { resetCreateAccountInformation } from 'pages/Create-account/store/actions/create-account.types';

import * as Analytics from 'helpers/segment.helper';
import { createOrderId } from 'pages/Checkout/services/checkout.service';
import { IProduct } from 'pages/Checkout/pages/Payment-information/models/Payment-information.interface';
import { getContactInfo } from 'utils/connect-info.utils';
import { addSubscriptionFreemium } from 'shared/services/users';
import {
  ICartCheckoutData,
  IFormErrorPaymentSubscription,
  IFormPaymentSubscription,
} from '../models/Activate-benefits.interface';
import {
  IAtribute,
  IRequestAddPropertiy,
} from '../../Create-properties/models/Create-properties.interface';
export const useActivateBenefits = (
  validateProperties: () => Promise<
    | {
        data: {
          atributos: IAtribute[];
        }[];
      }
    | undefined
  >,
  getProductType: (dataProducts: IProduct[]) => IProduct[],
  onChangeQuantityForPlan: (
    callFunction: () => ICartCheckoutData,
    dataProducts?: IProduct[] | undefined
  ) => void
) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cartCheckoutRef =
    useRef<{
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      saveOrderInformation: () => any;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      sendOrderCompleted: (order: any) => any;
    }>(null);
  const {
    checkoutReducer,
    authReducer,
    branchReducer: { branch, country_id },
  } = useSelector((state: IAppReducer) => state);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [products, setProducts] = useState<any>(null);
  const [orderId, setOrderId] = useState<string>('');
  const [errorActivation, setErrorActivation] = useState<boolean>(false);
  const [popUp, setPopUp] = useState<{
    showModal: boolean;
    title: string;
    message: string;
    labelButton: string;
  }>({
    showModal: false,
    title: '¡Alto!',
    message:
      '<strong>Está por dejar el proceso incompleto.</strong> Recuerda que al al dejarlo incompleto deberá iniciarlo nuevamente cuando regreses.',
    labelButton: 'Continuar',
  });
  const [phoneBranch, setPhoneBranch] = useState<string>('');
  const {
    authReducer: user,
    createAccountReducer,
    branchReducer,
  } = useSelector((state: IAppReducer) => state);

  useLayoutEffect(() => {
    const phone = getContactInfo(branchReducer.branch).phone;
    setPhoneBranch(phone);
  }, [branchReducer.branch]);

  useEffect(() => {
    (async () => {
      const dataCheckoutStepVviewed = {
        step: 3,
        order_id: checkoutReducer.order?.id as string,
      };
      Analytics.checkoutStepVviewed(dataCheckoutStepVviewed);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const planIdRequest = createAccountReducer.plan_id;

      const responsePlans: IProduct[] = await getPlanById(
        planIdRequest,
        branch
      );
      const responsePlansRoad = responsePlans.filter(
        (plan) =>
          plan.attributes.alias_name.toLocaleLowerCase().indexOf('carretera') >=
          0
      );
      const responsePlansOther = responsePlans.filter(
        (plan) =>
          plan.attributes.alias_name.toLocaleLowerCase().indexOf('carretera') <
          0
      );
      const responsePlanInformation = [
        ...responsePlansRoad,
        ...responsePlansOther,
      ];
      setProducts(responsePlanInformation);
      const dataProducts = getProductType(responsePlanInformation);
      onChangeQuantityForPlan(
        cartCheckoutRef.current
          ?.saveOrderInformation as () => ICartCheckoutData,
        dataProducts
      );
      const ordenId = await createOrderId({
        email: '',
        phone: '',
        country: Number(getCountry(branch)),
        plans: planIdRequest,
        raw_data: '',
      });
      setOrderId(ordenId);
    })();
  }, [branch]);

  const formPaymentSubscriptionInitialValues: IFormPaymentSubscription = {
    uuid: authReducer.uid,
    plan_id: '',
    card_token: '',
    coupon: '',
    country_id: country_id as number,
    agentId: '',
    orderId: '',
    card_name: '',
    cardNumber: '',
    cardExpiry: '',
    cardCvc: '',
    cardId: '',
  };

  const formPaymentSubscriptionValidate = (
    values: IFormPaymentSubscription
  ) => {
    const errors: IFormErrorPaymentSubscription = {};

    if (!values.uuid) {
      errors.uuid = 'Este campo es requerido';
    }
    return errors;
  };

  const formPaymentSubscription = useFormik({
    initialValues: formPaymentSubscriptionInitialValues,
    validate: formPaymentSubscriptionValidate,
    onSubmit: async (values: IFormPaymentSubscription) => {
      try {
        const dataCartCheckout =
          cartCheckoutRef.current?.saveOrderInformation();
        setErrorActivation(false);
        const dataRequestsubscriptionAdded = {
          orderId,
          uuid: values.uuid,
          plan_id: dataCartCheckout.order?.dataProducts.plan_id as string,
          coupon:
            dataCartCheckout.order?.dataOrderCompleted.coupon?.coupon_code,
          card_name: values.card_name ?? '',
          card_token: '',
          card_id: '',
          country_id: country_id as number,
          agentId: user.agentId,
          products: [...(dataCartCheckout.order?.dataProducts.products as [])],
          subTotalEmail: dataCartCheckout.resumen.subTotal,
          totalEmail: checkoutReducer.trialPeriod
            ? 0
            : dataCartCheckout.resumen.price,
          discountForPeriodicity: checkoutReducer.trialPeriod
            ? (dataCartCheckout.resumen.price as number)
            : (dataCartCheckout.resumen.discount as number) || 0,
          discountForCoupon:
            (dataCartCheckout.resumen.discountCoupon as number) || 0,
        };
        const dataProperties = await validateProperties();
        await addSubscriptionFreemium(
          dataRequestsubscriptionAdded,
          dataProperties as IRequestAddPropertiy
        );
        cartCheckoutRef.current?.sendOrderCompleted({
          ...dataCartCheckout,
          order_id: orderId,
        });
        navigate('/create-account/confirmation-activated');
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        setErrorActivation(true);
        window.scrollTo({ behavior: 'smooth', top: 0 });
      } finally {
        formPaymentSubscription.setSubmitting(false);
      }
    },
  });

  const showInfoPopUp = () => {
    popUp.showModal = !popUp.showModal;
    setPopUp({ ...popUp });
  };

  const exitProcess = () => {
    dispatch(resetCreateAccountInformation());
    showInfoPopUp();
    navigate('/settings/payments');
  };

  return {
    formPaymentSubscription,
    exitProcess,
    showInfoPopUp,
    errorActivation,
    products,
    branch,
    orderId,
    cartCheckoutRef,
    user,
    checkoutReducer,
    phoneBranch,
    popUp
  };
};
