import { Animations } from 'assets/animations/animations.index';
import './Loader.scss';

export const Loader = () => {
  return (
    <div className="container__loading__screen__auth">
      <img className="" src={Animations.loader} alt="loading" />
    </div>
  );
};
