import { useRef, useState } from 'react';
import { IUploadProperties } from './models/upload.interface';
import { UploadSvg } from 'assets/icons/upload';
import './Upload.scss';
import { ButtonDelete } from '../Button-delete/Button-delete';
import { Images } from 'assets/images/images.index';

export const Upload = (props: IUploadProperties) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [nameFile, setNameFile] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFileChange = (e: any) => {
    const file = e.target.files[0];
    if (!file) return;
    props.setFileURL(e.target.files);
    setNameFile(e.target.files[0].name);
  };
  const onResetFile = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    props.setFileURL('');
    setNameFile('');
  };
  return (
    <div
      className={`upload__container ${
        props.errorLabel ? 'error' : ''
      } container__input ${props.WrapperClassName}`}
    >
      <label
        htmlFor={`input-upload-${props.indexItem}`}
        className={`input__generic  upload__label ${
          props.errorLabel ? 'error' : ''
        } `}
      >
        <span
          className={`upload__label__placeholder ${
            nameFile ? 'disabled' : ''
          } ${props.errorLabel ? 'error' : ''}`}
        >
          Carnet de vacunación
          {props.isRequired && (
            <img
              className="input__image__required"
              src={Images.inputRequired}
              alt="Campo requerido"
            />
          )}
        </span>
        <span className="upload__label__texts">
          <span className={`upload__label__name__file`}>
            {nameFile ? nameFile : ''}
          </span>
        </span>
        <span className="upload__icon">
          <UploadSvg color="secondary"></UploadSvg>
        </span>
        <input
          ref={inputRef}
          type="file"
          id={`input-upload-${props.indexItem}`}
          onChange={onFileChange}
        />
      </label>
      {nameFile && (
        <div className="upload__icon__close">
          <ButtonDelete
            isDisabled={false}
            error={undefined}
            onClick={onResetFile}
          />
        </div>
      )}
      {props.errorLabel ? (
        <div className="input__error">{props.errorLabel}</div>
      ) : null}
    </div>
  );
};
