import { Images } from 'assets/images/images.index';
import { Button } from 'components/atoms/Button/Button';
import { CadrInfo } from 'components/atoms/Car-info/Card-info';
import { HeaderWithSteps } from 'components/organisms/Header-with-steps/Header-with-steps';
import { PopupEmailSend } from 'components/templates/Popup-email-send/Popup-email-send';
import { PopupErrorRecoverPassword } from 'components/templates/Popup-error-recover-password/Popup-error-recover-password';
import { defaultFirebaseAuth } from 'config/firebase-config';
import { sendPasswordResetEmail } from 'firebase/auth';
import { TypeState } from 'models/app.interface';
import { IDetailStep } from 'models/info.steps.interface';
import { IAppReducer } from 'models/reducer.interface';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { maskEmail } from 'utils/mask.utils';
import './Reset-password.scss';

export const ResetPassword = () => {
  const navigate = useNavigate();
  const [isEmailSent, setIsEmailSent] =
    useState<undefined | boolean>(undefined);
  const [popUp, setPopUp] = useState<{
    isVisible: boolean;
    type: TypeState;
  }>({ isVisible: false, type: 'successful' });
  const { email } = useSelector((state: IAppReducer) => state.authReducer);
  const steps: IDetailStep[] = [
    {
      description: 'Cambio contraseña ',
      state: 'active',
      link: '/reset-password',
      permissionToNavigate: false,
    },
  ];
  const sendResetEmail = async () => {
    try {
      setIsEmailSent(false);
      await sendPasswordResetEmail(defaultFirebaseAuth, email);
      popUp.type = 'successful';
    } catch (error) {
      popUp.type = 'error';
    } finally {
      setIsEmailSent(true);
    }
    setPopUp({ ...popUp, isVisible: true });
  };
  const showInfoPopUp = () => {
    setPopUp({ ...popUp, isVisible: !popUp.isVisible });
  };
  return (
    <>
      <HeaderWithSteps
        notProgress={true}
        steps={steps}
        onClick={() => {
          navigate('/settings/profile');
        }}
      />
      <div className="reset__password__container container__generic">
        <p className="reset__password__message">
          Le enviaremos por correo electrónico a{' '}
          <span className="bold">{maskEmail(email, 1)}</span> un enlace para
          restablecer su contraseña
        </p>
        <Button
          color="primary"
          size="medium"
          template="primary"
          typeStyle="rounded"
          className="reset__password__button"
          type="submit"
          onClick={sendResetEmail}
          isDisabled={isEmailSent === false}
          isLoading={isEmailSent === false}
        >
          enviar <img src={Images.email} alt="" />
        </Button>
        <CadrInfo
          message="Si tiene algún problema...servicio al cliente "
          styleInLine={{ width: '100%' }}
        ></CadrInfo>
      </div>
      {popUp.isVisible &&
        (popUp.type === 'successful' ? (
          <PopupEmailSend email={email} closePopUp={showInfoPopUp} />
        ) : (
          <PopupErrorRecoverPassword email={email} closePopUp={showInfoPopUp} />
        ))}
    </>
  );
};
