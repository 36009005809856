import { Images } from 'assets/images/images.index';
import { Navbar } from 'components/atoms/Navbar/Navbar';
import './Header.scss';
import { PinConnect } from 'assets/icons/pin-connect';
import { Link } from 'react-router-dom';
interface IPropsHeader {
  notPadding?: boolean;
  children?: React.ReactNode;
  navbarTemplate?: 'light';
}

export const Header = (props: IPropsHeader) => {
  return (
    <Navbar notPaddingBottom={props.notPadding} template={props.navbarTemplate}>
      <div className="header__content container__generic">
        {props.children}
        {!props.navbarTemplate ? (
          <img
            className="header__logo"
            src={Images.connetLogoWhite}
            alt="logo connect blanco"
          />
        ) : (
          <Link to={'/settings'}>
            <PinConnect
              color="primary"
              className="header__logo pin"
            ></PinConnect>
          </Link>
        )}
      </div>
    </Navbar>
  );
};
