import { AuthGuard } from 'guards/auth.guard';
import { useRoutes } from 'react-router-dom';
import { Paymentinformation } from '../pages/Payment-information/Payment-information';
import { ContactInformation } from '../pages/Contact-information/Contact-information';
import { CreateProperties } from 'pages/Create-account/pages/Create-properties/Create-properties';
import { CheckoutGuard } from '../guards/Checkout.guard';
import { PurchaseOrder } from '../pages/Purchase-order/Purchase-order';
import { CheckoutRoot } from '../pages/Checkout-root/Checkout-root';

export const CheckoutRouter = (pros: {
  handleStep: (index: number, typePopUp: 'create' | 'other') => void;
}) => {
  const element = useRoutes([
    {
      children: [
        {
          path: '',
          element: (
            <AuthGuard privateRoute={false}>
              <CheckoutRoot></CheckoutRoot>
            </AuthGuard>
          ),
        },
        {
          path: 'orden',
          element: (
            <AuthGuard privateRoute={false}>
              <PurchaseOrder
                handleStep={(index: number) => pros.handleStep(index, 'other')}
                link="/checkout/contact-information"
              />
            </AuthGuard>
          ),
        },
        {
          path: 'contact-information',
          element: (
            <AuthGuard privateRoute={false}>
              <ContactInformation
                handleStep={(index: number) => pros.handleStep(index, 'create')}
              />
            </AuthGuard>
          ),
        },
        {
          path: 'payment-information',
          element: (
            <AuthGuard privateRoute={false}>
              <Paymentinformation
                handleStep={(index: number) => pros.handleStep(index, 'other')}
                link="/checkout/create-properties"
              />
            </AuthGuard>
          ),
        },
        {
          path: 'create-properties',
          element: (
            <CheckoutGuard>
              <CreateProperties
                link="/confirmation"
                redirect="/confirmation"
              />
            </CheckoutGuard>
          ),
        },
        {
          path: '/*',
          element: (
            <AuthGuard privateRoute={false}>
              <PurchaseOrder
                handleStep={(index: number) => pros.handleStep(index, 'other')}
                link="/checkout/contact-information"
              />
            </AuthGuard>
          ),
        },
      ],
    },
  ]);

  return element;
};
