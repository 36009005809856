import { CreateProperties } from 'pages/Create-account/pages/Create-properties/Create-properties';
import { Paymentinformation } from 'pages/Create-account/pages/Payment-information/Payment-information';
import { useRoutes } from 'react-router-dom';

export const BuyPlanRouter = (props: {
  handleStep: (index: number, typePopUp: 'create' | 'other') => void;
}) => {
  const element = useRoutes([
    {
      children: [
        {
          path: 'create-properties',
          element: (
            <CreateProperties
              handleStep={(index: number) => props.handleStep(index, 'other')}
              link="/settings/payments"
              redirect="/settings"
            />
          ),
        },
        {
          path: '/*',
          element: (
            <Paymentinformation
              handleStep={(index: number) => props.handleStep(index, 'other')}
              link="/buy-plan/create-properties"
            />
          ),
        },
      ],
    },
  ]);

  return element;
};
