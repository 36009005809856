import { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { IInputPaymentMethodState } from 'shared/models/input-payment-method.interface';
import {
  IInputChangeEvent,
  IInputFocusEvent,
  InputFElementPaymentMethod,
} from '../models/payment-method-dlocal.interface';
import {
  ContainersElements,
  InputsState,
  dlocalInstanceField,
  getDlocalSmartFieldsKey,
} from '../constants/payment-method-dlocal.constants';

export interface IPropsPaymentMethodDlocal {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: any;
  inputNameLabel: string;
  inputNameControl: string;
  handleChangeNameInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlurNameInput: (event: React.FocusEvent<HTMLInputElement>) => void;
  touchedNameInput: boolean | undefined;
  errorNameInput: string | undefined;
  valueNameInput: string | undefined;
  handleChangeDlocalInput: (event: IInputFocusEvent) => void;
  country: 'co' | 'cr';
  checkBoxNameControl?: string;
  checkBoxValue?: boolean | undefined;
  handleChangeCheck?: (event: boolean | undefined) => void;
}

export const usePaymentMethodDlocal = (
  props: IPropsPaymentMethodDlocal,
  ref: any
) => {
  const dLocalRef = useRef(null);

  useImperativeHandle(ref, () => {
    return { getTokenCard: () => getToken() };
  });

  const dlocalInstance = dlocal(getDlocalSmartFieldsKey(props.country));
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [panFieldReact, setPanFieldReact] = useState<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [cvvFieldReact, setCvvFieldReact] = useState<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [expirationFieldReact, setExpirationFieldReact] = useState<any>(null);
  const [stateInputDlocal, setStateInputDlocal] =
    useState<IInputPaymentMethodState>({ ...InputsState });
  const fields: {
    nameElement: ContainersElements;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    element?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    elementReact: any;
    alias: InputFElementPaymentMethod;
  }[] = [
    {
      nameElement: ContainersElements.cardNumber,
      alias: 'cardNumber',
      elementReact: panFieldReact,
    },
    {
      nameElement: ContainersElements.cardExpiration,
      alias: 'cardExpiry',
      elementReact: expirationFieldReact,
    },
    {
      nameElement: ContainersElements.carCVV,
      alias: 'cardCvc',
      elementReact: cvvFieldReact,
    },
  ];
  useEffect(() => {
    const createFieldsDlocal = (
      type: 'pan' | 'expiration' | 'cvv',
      data?: object
    ) => {
      return fieldsDlocal.create(type, {
        ...dlocalInstanceField,
        ...data,
      });
    };
    const fieldsDlocal = dlocalInstance.fields({
      fonts: [
        {
          cssSrc: 'https://rsms.me/inter/inter-ui.css',
        },
      ],
      locale: 'es',
      country: props.country.toUpperCase(),
    });
    const panField = createFieldsDlocal('pan', { hideIcon: false });
    const expirationField = createFieldsDlocal('expiration');
    const cvvField = createFieldsDlocal('cvv');
    panField.mount(document.getElementById(ContainersElements.cardNumber));
    expirationField.mount(
      document.getElementById(ContainersElements.cardExpiration)
    );
    cvvField.mount(document.getElementById(ContainersElements.carCVV));
    fields[0].element = panField;
    fields[1].element = expirationField;
    fields[2].element = cvvField;
    setPanFieldReact(panField);
    setExpirationFieldReact(expirationField);
    setCvvFieldReact(cvvField);
  }, [props.country]);
  useEffect(() => {
    fields.forEach((field) => {
      field.element.on('focus', (e: any) => {
        document
          .getElementById(`label__${field.alias}`)
          ?.classList.add('input__label--value');
      });
      field.element.on('blur', (event: IInputFocusEvent) => {
        if ((stateInputDlocal as any)[field.alias].isEmpty) {
          (stateInputDlocal as any)[field.alias].errorMessage =
            'Campo requerido';
        } else {
          (stateInputDlocal as any)[field.alias].isEmpty = event.empty;
          (stateInputDlocal as any)[field.alias].isValid = !event.error
            ? true
            : false;
          (stateInputDlocal as any)[field.alias].errorMessage = event.error
            ? event.error.message
            : undefined;
        }
        setStateInputDlocal({ ...stateInputDlocal });
      });
      let smartField = {
        isValid: false,
        isEmpty: true,
        errorMessage: '',
      };
      field.element.on('change', async (event: IInputChangeEvent) => {
        smartField = (stateInputDlocal as any)[field.alias];
        smartField.isValid = !event.error ? true : false;
        smartField.isEmpty = event.empty;
        smartField.errorMessage = event.error?.message;
        setStateInputDlocal({
          ...stateInputDlocal,
        });
        props.handleChangeDlocalInput({
          ...event,
          element: field.alias,
          complete: smartField.isValid && !smartField.isEmpty ? true : false,
        });
      });
    });
  }, []);

  const getToken = async () => {
    const name = document.getElementById(
      'payment-method-name-dlocal'
    ) as HTMLInputElement;
    if (
      !stateInputDlocal.cardNumber.isValid ||
      !stateInputDlocal.cardExpiry.isValid ||
      !stateInputDlocal.cardCvc.isValid ||
      !name.value
    ) {
      return;
    }
    try {
      const token = await dlocalInstance.createToken(panFieldReact, {
        name: name.value,
      });
      return token.token;
    } catch (error) {
      return null;
    }
  };

  const focusInput = (type: InputFElementPaymentMethod) => {
    fields.find((field) => field.alias === type)?.elementReact.focus();
  };
  const clearInput = (type: InputFElementPaymentMethod) => {
    fields.find((field) => field.alias === type)?.elementReact.clear();
  };

  return { dLocalRef, fields, focusInput, stateInputDlocal, clearInput };
};
