import './Item-card-skeleton.scss';

export const ItemCardSkeleton = () => {
  return (
    <div className="item__card__skeleton__container">
      <div className="item__card__skeleton__header">
        <div className="title skeleton"></div>
        <div className="button skeleton"></div>
      </div>
      <div className="item__card__skeleton__chip">
        <div className="chip skeleton"></div>
        <div className="chip skeleton"></div>
      </div>
      <div className="item__card__skeleton__tag skeleton"></div>
    </div>
  );
};
