import { IdataCheckoutAction } from '../state/checkout.state';

export const enum TypeActionsCheckout {
  saveInformation = '[CHECKOUT] set information',
  resetInformation = '[CHECKOUT] remove information',
}

export type checkoutAction = {
  type: TypeActionsCheckout;
  data?: IdataCheckoutAction;
};

export const setCheckoutInformation = (data: IdataCheckoutAction) => ({
  type: TypeActionsCheckout.saveInformation,
  data,
});

export const resetCheckoutInformation = () => ({
  type: TypeActionsCheckout.resetInformation,
});
