import { Loader } from 'components/atoms/Loader/Loader';
import { PeriodicityTypesOptions } from 'pages/Checkout/models/Payment-subscription.interface';
import { IProduct } from 'pages/Checkout/pages/Payment-information/models/Payment-information.interface';
import { createOrderId } from 'pages/Checkout/services/checkout.service';
import { setCheckoutInformation } from 'pages/Checkout/store/actions/checkout.types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCouponByCode } from 'shared/services/coupons';
import { getPlanById } from 'shared/services/plans';
import { getInfoPreSale } from 'shared/services/user';
import { setBranch } from 'store/actions/branch.types';
import * as utils from 'utils/branch.utils';
import { calculatePrices } from 'utils/pricing.utils';

export const ShortLink = () => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const params = new URLSearchParams(search);
  const planId = params.get('planId') as string;
  const shortLink = params.get('ps') as string;
  const couponString = params.get('coupon');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [, setShortLinkData] = useState<any>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const data = await getPresaleInfo();
      setShortLinkData(data);
    })();
  }, [setShortLinkData]);

  const getPresaleInfo = async () => {
    try {
      let couponInfo = undefined;
      const response = await getInfoPreSale(shortLink);
      const countryId = utils.getBranchIndicatorCountry(response.branch);
      dispatch(setBranch(countryId));
      const responsePlanInformation = await getPlanById(planId, countryId);
      if (couponString || response.cupon) {
        const responseCoupon = await getCouponByCode(
          (couponString || response.cupon).toLocaleUpperCase()
        );
        couponInfo = responseCoupon
          ? responseCoupon.data.attributes
          : undefined;
      }
      const quantityForPlan = Object.values(response.properties)
        .map((item: []) => item.length)
        .filter((item: number) => item > 0);
      const productsFiltered = calculatePrices(
        responsePlanInformation[0].attributes.periocidad.data.attributes
          .name as PeriodicityTypesOptions,
        quantityForPlan,
        couponInfo,
        responsePlanInformation as unknown as IProduct[]
      );
      const ordenId = await createOrderId({
        email: '',
        phone: '',
        country: Number(utils.getCountry(countryId)),
        plans: planId,
        raw_data: '',
      });
      const dataOrderCompleted = {
        total: productsFiltered.productsPeriodicityAnual.prices.total,
        discount: productsFiltered.productsPeriodicityAnual.prices.discount,
        coupon: couponInfo,
        currency: utils.getCurrencyForCountry(
          Number(utils.getCountry(countryId)) as number
        ),
        products: productsFiltered.productsPeriodicityAnual.data.map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (plan: any, indexPlan: number) => {
            if (quantityForPlan[indexPlan]) {
              return {
                product_id: plan.id,
                category:
                  plan.attributes.productos.data[0].attributes
                    .alias_product_type ||
                  plan.attributes.productos.data[0].attributes.product_type_id
                    .data.attributes.name,
                name: plan.attributes.alias_title,
                alias_name: plan.attributes.alias_name,
                price: plan.attributes.productos.data[0].attributes.price,
                quantity: quantityForPlan[indexPlan],
              };
            }
          },
          []
        ),
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const dataRequest: any[] = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let products: any[] = [];
      productsFiltered.productsPeriodicityAnual.data.forEach(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (product: any, index: number) => {
          dataRequest[index] = {
            stripe_price_id:
              product.attributes.productos.data[0].attributes.stripe_price_id,
            quantity: quantityForPlan[index],
            product_id: product.attributes.productos.data[0].id,
            is_additional: false,
            plan_id: product.id,
          };
        }
      );
      products = dataRequest.reduce((acc, val) => acc.concat(val), []);
      products = dataRequest.filter((product) => product.quantity > 0);
      dispatch(
        setCheckoutInformation({
          agentId: response.agentId,
          agentName: response.agentName,
          plan_id: planId,
          name: response.name,
          lastname: response.lastname,
          funelId: response._id,
          phone: response.phone,
          email: response.email,
          documentNumber: response.document,
          zipCode: response.zipCode,
          acceptTerms: true,
          indicator: utils.getPhoneIndicator(countryId),
          short_link: shortLink,
          short_link_properties: {
            properties: response.properties,
            agentId: response.agentId,
          },
          order: {
            id: ordenId as string,
            dataOrderCompleted,
            dataProducts: {
              products,
              plan_id: planId,
              planItemsQuantity: quantityForPlan,
              periodicity:
                responsePlanInformation[0].attributes.periocidad.data.attributes
                  .name,
            },
          },
          products: responsePlanInformation,
        })
      );
      navigate('/checkout/payment-information');
    } catch (error) {
      console.log('error', error);
    }
  };
  return <Loader></Loader>;
};
