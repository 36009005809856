import { Divider } from 'components/atoms/Divider/Divider';
import { IOptionSelect, Select } from 'components/atoms/Select/Select';
import { HistoryCard } from 'components/molecules/History-card/History-card';
import { statusPayments } from 'constants/app.constants';
import { useEffect, useState } from 'react';
import { DatePicker } from '../Date-picker/Date-picker';
import { HistoryItemEmpty } from '../History-item-empty/History-item-empty';
import './Services-history.scss';
export interface IPropsServicesHistory {
  data: {
    name: string;
    number: string;
    description: string;
    total: number;
    date: string;
    typeService: string;
    status: 'Activo' | 'Cancelado' | 'Finalizado';
    message: string;
    couponName: string;
    couponAmount: string;
    planName: string;
    hasDiscount: boolean;
  }[];
  infoIsHidden?: boolean;
  branch: string;
  agentId?: string | number;
  userType: string | undefined;
}

export const ServicesHistory = (props: IPropsServicesHistory) => {
  const [filters, setFilters] = useState<{
    days: { start: Date | null; end: Date | null };
    status: number | string;
  }>({ days: { start: null, end: null }, status: 0 });
  const [data, setData] = useState(props.data);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let newData: any = [...data];

  useEffect(() => {
    if (filters.days.start && filters.status) {
      newData = props.data.filter((p) => {
        return (
          new Date(p.date).getTime() >=
            (filters.days.start as Date).getTime() &&
          new Date(p.date).getTime() <= (filters.days.end as Date).getTime() &&
          p.message ===
            (
              statusPayments.find(
                (p) => p.value === filters.status
              ) as IOptionSelect
            ).description
        );
      });
    } else if (filters.days.start) {
      newData = props.data.filter(
        (p) =>
          new Date(p.date).getTime() >=
            (filters.days.start as Date).getTime() &&
          new Date(p.date).getTime() <= (filters.days.end as Date).getTime()
      );
    } else if (filters.status) {
      newData = props.data.filter(
        (p) =>
          p.message ===
          (
            statusPayments.find(
              (p) => p.value === filters.status
            ) as IOptionSelect
          ).description
      );
    }
    setData([...newData]);
  }, [filters]);
  const filterHistory = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: number | any,
    typeFilter: 'month' | 'status'
  ) => {
    if (typeFilter === 'month') {
      setFilters({ ...filters, days: { start: value.start, end: value.end } });
    } else {
      setFilters({ ...filters, status: value });
    }
  };

  return data && data.length > 0 ? (
    <section className="services__history__container">
      {!props.infoIsHidden && (
        <>
          <h6>Historial </h6>
          <p className="services__history__description">
            Encuentra aquí el historial de servicios <br /> anteriores, facturas
            y demás.
          </p>
          <Divider margin="small" />
        </>
      )}
      <div
        className={`services__history__filters ${
          data.length > 0 ? '' : 'small'
        }`}
      >
        <Select
          id="services-history-status"
          placeholder="Status"
          value={''}
          name="services-history-status"
          onClick={(e) => filterHistory(e, 'status')}
          options={statusPayments}
          WrapperClassName="filter__status"
          onBlur={() => {
            return;
          }}
        />
        <DatePicker
          onApplyFilters={(data) => filterHistory(data, 'month')}
        ></DatePicker>
      </div>
      {data.map((item, index: number) => (
        <HistoryCard
          data={item}
          status={item.status}
          message={item.status}
          key={index}
          agentId={props.agentId}
        />
      ))}
    </section>
  ) : (
    <HistoryItemEmpty branch={props.branch} userType={props.userType} />
  );
};
