import { IAuthFromGeneric } from "components/templates/Auth-from-generic/models/Auth-from-generic.interface";

export const createPasswordListOfInformation: IAuthFromGeneric['listOfInformation'] =
  [
    {
      title: 'Requisitos de la contraseña:',
      items: [
        'Utilice al menos 8 caracteres.',
        'Requiere números y letras mayúsculas.',
      ],
    },
    {
      title: 'Consejos de seguridad:',
      items: [
        'No utilice la misma contraseña que ha utilizado con nosotros anteriormente.',
        'No utilice palabras del diccionario, su nombre, dirección de correo electrónico, número de teléfono móvil u otra información personal que pueda obtenerse fácilmente.',
        'No utilice la misma contraseña para varias cuentas en línea.',
      ],
    },
  ];
