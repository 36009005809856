import { Card, Divider, HeaderWithAlert } from 'components';
import { UserInfo } from './components/User-info/User-info';
import { useProfile } from './hooks/useProfile';
import './Profile.scss';

export const Profile = () => {
  const {
    steps,
    navigate,
    authReducer,
    branch,
    goToTermsAndConditions,
    goToResetPassword,
    handleSignOut,
  } = useProfile();
  return (
    <>
      <HeaderWithAlert
        title={''}
        message={``}
        steps={steps}
        showAlert={undefined}
        onClick={() => {
          navigate('/settings');
        }}
      ></HeaderWithAlert>
      <section className="profile__container">
        <UserInfo
          name={authReducer.name}
          lastname={authReducer.lastname}
          email={authReducer.email}
          phone={authReducer.phone}
          photoURL={authReducer.photoURL}
          branch={branch}
        ></UserInfo>
        <Divider margin="notMargin" classWrapper="profile__divider"></Divider>
        <div className="profile__container__options">
          <div onClick={goToTermsAndConditions}>
            <Card WrapperClassName="profile__option">
              Términos y Condiciones
            </Card>
          </div>
          <div onClick={goToResetPassword}>
            <Card WrapperClassName="profile__option">Cambio de contraseña</Card>
          </div>
          <div onClick={handleSignOut}>
            <Card WrapperClassName="profile__option">Cerrar sesión</Card>
          </div>
        </div>
      </section>
    </>
  );
};
