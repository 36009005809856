import { Input } from 'components/atoms/Input/Input';
import { AuthFromGeneric } from '../Auth-from-generic/Auth-from-generic';
import { IAuthFromGeneric } from '../Auth-from-generic/models/Auth-from-generic.interface';
import { createPasswordListOfInformation } from 'shared/constants/password.constants';
import { useCreatePassword } from 'pages/sing-in/New-password/hooks/New-password.hokks';
import './Create-password.scss';
export interface IPropsCreatePassword {
  handleChangePassword: (password: string) => Promise<void>;
  headerInformation: IAuthFromGeneric['headerInformation'];
}
export const CreatePassword = ({
  handleChangePassword,
  headerInformation
}: IPropsCreatePassword) => {
  const { formNewPassword } = useCreatePassword({
    handleChangePassword,
    
  });
  return (
    <AuthFromGeneric
      labelPrimaryAction="Guardar"
      headerInformation={headerInformation}
      isLoading={formNewPassword.isSubmitting}
      handleClick={formNewPassword.handleSubmit}
      listOfInformation={createPasswordListOfInformation}
    >
      <Input
        label="Contraseña"
        id="new-password"
        type="password"
        name="password"
        onChange={formNewPassword.handleChange}
        onBlur={formNewPassword.handleBlur}
        value={formNewPassword.values.password}
        placeholder=""
        isTouched={formNewPassword.touched.password}
        errorInput={formNewPassword.errors.password}
        errorLabel={formNewPassword.errors.password}
        isRequired={true}
        form={formNewPassword}
      />
      <Input
        label="Confirmar Contraseña"
        id="new-confirm-password"
        type="password"
        name="conFirmPassword"
        onChange={formNewPassword.handleChange}
        onBlur={formNewPassword.handleBlur}
        value={formNewPassword.values.conFirmPassword}
        placeholder=""
        isTouched={formNewPassword.touched.conFirmPassword}
        errorInput={formNewPassword.errors.conFirmPassword}
        errorLabel={formNewPassword.errors.conFirmPassword}
        isRequired={true}
        form={formNewPassword}
      />
    </AuthFromGeneric>
  );
};
