import { useEffect } from 'react';
import { getCountry, getDefaultPlansByBranch } from 'utils/branch.utils';
import { useDispatch, useSelector } from 'react-redux';
import { getPlanById } from 'shared/services/plans';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { IAppReducer } from 'models/reducer.interface';
import { setCheckoutInformation } from 'pages/Checkout/store/actions/checkout.types';
import { setBranch } from 'store/actions/branch.types';
import { createOrderId } from 'pages/Checkout/services/checkout.service';
import { IProduct } from '../Payment-information/models/Payment-information.interface';
import { getCouponByCode } from 'shared/services/coupons';
import * as utils from 'utils/branch.utils';
import { Loader } from 'components';
import { calculatePrices } from 'utils/pricing.utils';
import { PeriodicityTypesOptions } from 'pages/Checkout/models/Payment-subscription.interface';
import { ICouponInformation } from 'components/organisms/Form-cupon/models/Form-cupon.interface';
import * as Analytics from 'helpers/segment.helper';
import { extractUtm } from 'pages/Checkout/shared/checkout.utils';

export const CheckoutRoot = () => {
  const navigate = useNavigate();
  const {
    checkoutReducer,
    branchReducer: { country_id },
  } = useSelector((state: IAppReducer) => state);
  const dispatch = useDispatch();
  const { search } = useLocation();
  let responseCoupon = { data: { attributes: {} } };
  useEffect(() => {
    (async () => {
      await getInformation();
    })();
  }, []);

  const getInformation = async () => {
    try {
      let couponInfo = undefined;
      const params = new URLSearchParams(search);
      const country = params.get('country') as string;
      const planId = params.get('planId') as string;
      const quantity = params.get('quantity');
      const couponString = params.get('coupon');
      const flow = params.get('flow');
      const company = params.get('company');
      const agentId = params.get('agent');
      const referrerId = params.get('referrerId');
      const utms = extractUtm(search);

      let ordenId = checkoutReducer.order?.id ?? '';
      let IsFreeTrial = false;
      dispatch(setBranch(country));
      let planIdRequest = getDefaultPlansByBranch(country) as string;
      if (planId && planId !== checkoutReducer.plan_id?.toString()) {
        planIdRequest = planId;
      } else if (checkoutReducer.plan_id) {
        planIdRequest = checkoutReducer.plan_id;
      }
      const responsePlans: IProduct[] = await getPlanById(
        planIdRequest,
        country
      );
      const responsePlansRoad = responsePlans.filter(
        (plan) =>
          plan.attributes.alias_name.toLocaleLowerCase().indexOf('carretera') >=
          0
      );
      const responsePlansOther = responsePlans.filter(
        (plan) =>
          plan.attributes.alias_name.toLocaleLowerCase().indexOf('carretera') <
          0
      );
      const responsePlanInformation = [
        ...responsePlansRoad,
        ...responsePlansOther,
      ];
      if (couponString) {
        responseCoupon = await getCouponByCode(
          (couponString as string).toLocaleUpperCase()
        );
        couponInfo = responseCoupon
          ? responseCoupon.data.attributes
          : undefined;
      }
      let quantityForPlan: number[] = [];
      responsePlanInformation.forEach((product) => {
        if (product.attributes.productos.data[0].attributes.has_trial) {
          IsFreeTrial = true;
        }
      });
      if (IsFreeTrial) {
        quantityForPlan = responsePlanInformation.map((product) => {
          return product.attributes.productos.data[0].attributes.max_count;
        });
      }

      if (quantity && !IsFreeTrial) {
        const quantityArray = [...quantity.split(',')];
        quantityForPlan = quantityArray.map(Number);
      }
      const productsFiltered = calculatePrices(
        responsePlanInformation[0].attributes.periocidad.data.attributes
          .name as PeriodicityTypesOptions,
        quantityForPlan,
        couponInfo as ICouponInformation,
        responsePlanInformation as unknown as IProduct[]
      );
      if (!quantity && !IsFreeTrial) {
        quantityForPlan = productsFiltered.itemsQuantity;
      }
      if (!checkoutReducer.order?.id) {
        ordenId = await createOrderId({
          email: '',
          phone: '',
          country: Number(getCountry(country)),
          plans: planIdRequest,
          raw_data: '',
        });
      }
      const dataOrderCompleted = {
        total: productsFiltered.productsPeriodicityAnual.prices.total,
        discount: productsFiltered.productsPeriodicityAnual.prices.discount,
        coupon: couponInfo,
        currency: utils.getCurrencyForCountry(
          Number(getCountry(country)) as number
        ),
        products: productsFiltered.productsPeriodicityAnual.data.map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (plan: any, indexPlan: number) => {
            if (quantityForPlan[indexPlan]) {
              return {
                product_id: plan.id,
                category:
                  plan.attributes.productos.data[0].attributes
                    .alias_product_type ||
                  plan.attributes.productos.data[0].attributes.product_type_id
                    .data.attributes.name,
                name: plan.attributes.alias_title,
                alias_name: plan.attributes.alias_name,
                price: plan.attributes.productos.data[0].attributes.price,
                quantity: quantityForPlan[indexPlan],
                trialPeriod: plan.attributes.productos.data[0].attributes
                  .has_trial
                  ? plan.attributes.productos.data[0].attributes.trial_period
                  : 0,
              };
            }
          },
          []
        ),
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const dataRequest: any[] = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let products: any[] = [];
      productsFiltered.productsPeriodicityAnual.data.forEach(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (product: any, index: number) => {
          dataRequest[index] = {
            stripe_price_id:
              product.attributes.productos.data[0].attributes.stripe_price_id,
            quantity: quantityForPlan[index],
            product_id: product.attributes.productos.data[0].id,
            is_additional: false,
            plan_id: product.id,
            trialPeriod: product.attributes.productos.data[0].attributes
              .has_trial
              ? product.attributes.productos.data[0].attributes.trial_period
              : 0,
          };
        }
      );
      products = dataRequest.reduce((acc, val) => acc.concat(val), []);
      products = dataRequest.filter((product) => product.quantity > 0);
      dispatch(
        setCheckoutInformation({
          company,
          agentId: agentId as string,
          referrerId: referrerId as string,
          plan_id: planId,
          acceptTerms: true,
          indicator: utils.getPhoneIndicator(country),
          utms,
          order: {
            id: ordenId as string,
            dataOrderCompleted,
            dataProducts: {
              products,
              plan_id: planId,
              planItemsQuantity: quantityForPlan,
              periodicity:
                responsePlanInformation[0].attributes.periocidad.data.attributes
                  .name,
            },
          },
          isCountryDisabled: country ? true : false,
          products: responsePlanInformation,
          require_validation:
            responsePlanInformation[0].attributes.require_validation,
          is_addon: responsePlanInformation[0].attributes.is_addon,
          terms_and_conditions: responsePlanInformation[0].attributes.company.data.attributes.terms_and_conditions,
        })
      );
      const dataCheckoutStarted = {
        currency: utils.getCurrencyForCountry(country_id as number),
        order_id: (checkoutReducer.order?.id as string) || ordenId,
        tax: !quantity ? 1 : 2,
        products: responsePlanInformation.map(
          (plan: any, indexPlan: number) => {
            return {
              product_id: plan.id,
              category:
                plan.attributes.productos.data[0].attributes.product_type_id
                  .data.attributes.name,
              name: plan.attributes.alias_title,
              alias_name: plan.attributes.alias_name,
              price: plan.attributes.productos.data[0].attributes.price,
              adicionalPrice:
                plan.attributes.productos.data[0].attributes.additional_price,
              quantity: quantityForPlan[indexPlan],
              brand: plan?.attributes?.company?.data?.attributes?.param,
            };
          },
          []
        ),
      };
      Analytics.checkoutStarted(dataCheckoutStarted);
      if (flow && +flow === 2) {
        navigate('/checkout/contact-information');
      } else {
        navigate('/checkout/orden');
      }
    } catch (error) {
      console.log(error);
    }
  };
  return <Loader />;
};