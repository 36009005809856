import { Images } from 'assets/images/images.index';
import { Input } from 'components/atoms/Input/Input';
import { useFormik } from 'formik';
import { useState } from 'react';
import { formatPrice } from 'utils/payments.utils';
import './Summary-prices.scss';

export interface IPropsSummaryPrices {
  typeProperty: string;
  values: {
    current: number;
    refund: number;
    newPrice: number;
    priceByUnit: number;
  };
  branch: string;
  type: 'increase' | 'refund';
  valuesIsHidden: boolean;
  updateValue: (value: string) => void;
}

export const SummaryInformationPrices = ({
  values,
  branch,
  type,
  valuesIsHidden,
  updateValue,
}: IPropsSummaryPrices) => {
  const [isCustomAmount, setIsCustomAmount] = useState<boolean>(false);
  const [isEditedAmount, setIsEditedAmount] = useState<boolean>(false);

  const steps = Array(12)
    .fill(1)
    .map((_, index) => index + 1);
  const formCustomAmountInitialValues = {
    amount: '',
  };
  const formCustomAmountValidate = (values: { amount: string | undefined }) => {
    const errors: any = {};
    if (!values.amount) {
      errors.amount = 'Este campo es requerido';
    }
    return errors;
  };
  const formCustomAmount = useFormik({
    initialValues: formCustomAmountInitialValues,
    validate: formCustomAmountValidate,
    onSubmit: async (values) => {
      formCustomAmount.setSubmitting(false);
    },
  });
  const handleShowInput = () => {
    setIsCustomAmount((isCustomAmount) => !isCustomAmount);
  };
  const isEditing = (e: any) => {
    setIsEditedAmount(true);
    formCustomAmount.handleBlur(e);
    handleShowInput();
    updateValue(e.target.value);
  };

  return (
    <>
      <div className="summary__prices__container">
        <div className="summary__prices__content">
          <div className="summary__prices__label">
            Monto actual <br />
          </div>
          <div className="summary__prices__value">
            $ {formatPrice(values.current, branch)}
          </div>
        </div>
        {valuesIsHidden && (
          <>
            {isCustomAmount ? (
              <Input
                label="Nuevo valor"
                id="new-amount"
                type="decimal"
                name="amount"
                onChange={formCustomAmount.handleChange}
                onBlur={(e) => isEditing(e)}
                value={formCustomAmount.values.amount}
                isTouched={formCustomAmount.touched.amount}
                errorInput={formCustomAmount.errors.amount}
                errorLabel={formCustomAmount.errors.amount}
                isRequired={true}
                form={formCustomAmount}
                prefix={'$'}
              />
            ) : (
              <div className="summary__prices__content">
                <div
                  className={`summary__prices__label bold ${
                    type === 'increase' ? 'plus' : 'less'
                  } `}
                >
                  {type === 'increase'
                    ? 'Cobro adicional'
                    : 'Devolución/Reembolso'}
                </div>
                <div
                  role="button"
                  onClick={handleShowInput}
                  className={`summary__prices__value bold bold--successful ${
                    type === 'increase' ? 'plus' : 'less'
                  } `}
                >
                  ${' '}
                  {formCustomAmount.values.amount
                    ? formCustomAmount.values.amount
                    : formatPrice(values.refund, branch)}
                </div>
              </div>
            )}
            {isEditedAmount && (
              <div className="summary__prices__edit">
                <img
                  className="summary__prices__edit__icon"
                  src={Images.chipWarning}
                  alt={`icono label warning`}
                />
                <span>Valor editado</span>
              </div>
            )}
            <div className="summary__prices__divider"></div>
            <div className="summary__prices__content">
              <div className="summary__prices__label bold not--margin">
                Monto renovación
              </div>
              <div className="summary__prices__value bold not--margin">
                $ {formatPrice(values.newPrice, branch)}
              </div>
            </div>
          </>
        )}
      </div>
      <div className="summary__information__decorator__container">
        {steps.map((item: number) => (
          <div
            key={`step-${item}`}
            className="summary__information__decorator__step"
          ></div>
        ))}
      </div>
    </>
  );
};
