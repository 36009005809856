import { useEffect, useLayoutEffect, useState, useRef } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IAppReducer } from 'models/reducer.interface';
import * as Analytics from 'helpers/segment.helper';
import { resetCheckoutInformation } from 'pages/Checkout/store/actions/checkout.types';
import { TypeAppErrors } from 'models/app.interface';
import { sendLog } from 'shared/services/logs.service';

export const usePurchaseOrder = (props: {
  handleStep: (index: number) => void;
  link: string;
  quantityForPlan?: number[];
}) => {
  const cartCheckoutRef =
    useRef<{
      saveOrderInformation: () => void;
    }>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    checkoutReducer,
    branchReducer: { branch },
  } = useSelector((state: IAppReducer) => state);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [allProducts, setAllProducts] = useState<any>(null);
  const [requireValidation, setRequireValidation] = useState<boolean>(
    checkoutReducer.require_validation as boolean
  );
  const [planItemsQuantity, setPlanItemsQuantity] = useState<number[]>([]);
  const [popUp, setPopUp] = useState<{
    showModal: boolean;
    title: string;
    message: string;
    labelButton: string;
  }>({
    showModal: false,
    title: '¡Alto!',
    message:
      '<strong>Está por dejar el proceso incompleto.</strong> Recuerda que al al dejarlo incompleto deberá iniciarlo nuevamente cuando regreses.',
    labelButton: 'Continuar',
  });
  useLayoutEffect(() => {
    props.handleStep && props.handleStep(0);
  }, []);

  useEffect(() => {
    (async () => {
      let dataSelects: number[] = [];
      if (checkoutReducer?.order?.dataProducts?.planItemsQuantity.length) {
        dataSelects = checkoutReducer.order?.dataProducts.planItemsQuantity;
      }
      setPlanItemsQuantity([...dataSelects]);
      setAllProducts(checkoutReducer.products);
      const dataCheckoutStepVviewed = {
        step: 1,
        order_id: checkoutReducer.order?.id as string,
      };
      Analytics.checkoutStepVviewed(dataCheckoutStepVviewed);
    })();
  }, [Analytics.checkoutStepVviewed]);

  const formPaymentSubscription = useFormik({
    initialValues: {},
    onSubmit: async () => {
      try {
        cartCheckoutRef.current?.saveOrderInformation();
        const dataCheckoutStepCompleted = {
          step: 1,
        };
        Analytics.checkoutStepCompleted(dataCheckoutStepCompleted);
        navigate(props.link);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        const data = {
          nameError: TypeAppErrors.ERRORINTERNAL,
          error: {
            type: 'Error cartCheckout',
            message: 'Error al generar datos de compra',
            data: error.stack || error.message,
          },
          payload: {
            scope: 'PurchaseOrder',
            action: 'click',
            url: window.location.href,
            user: checkoutReducer.email as string,
            funelId: checkoutReducer.funelId,
            method: 'formPaymentSubscription.onSubmit',
            orderId: checkoutReducer.order?.id as string,
          },
        };
        sendLog(data);
      } finally {
        formPaymentSubscription.setSubmitting(false);
      }
    },
  });

  const showInfoPopUp = () => {
    popUp.showModal = !popUp.showModal;
    setPopUp({ ...popUp });
  };

  const exitProcess = () => {
    dispatch(resetCheckoutInformation());
    showInfoPopUp();
    navigate('/login', { state: null });
  };
  const plandIsValidated = (validated: boolean) => {
    setRequireValidation(!validated);
  };

  return {
    cartCheckoutRef,
    formPaymentSubscription,
    allProducts,
    branch,
    planItemsQuantity,
    checkoutReducer,
    requireValidation,
    popUp,
    showInfoPopUp,
    exitProcess,
    plandIsValidated,
  };
};
