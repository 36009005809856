import { ItemCardSkeleton } from 'components/skeleton/molecules/Item-card-skeleton/Item-card-skeleton';
import { TitleSectionSkeleton } from 'components/skeleton/molecules/Title-section-skeleton/Title-section-skeleton';
import './Skeleton-properties.scss';

export const SkeletonProperties = () => {
  return (
    <div className="properties__container">
      <TitleSectionSkeleton />
      <div className="properties__property__container__items">
        {[1, 2].map((item) => (
          <ItemCardSkeleton key={item} />
        ))}
      </div>
    </div>
  );
};
