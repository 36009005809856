import { useSelector } from 'react-redux';
import { IAppReducer } from 'models/reducer.interface';
import { TitleSection } from 'components/molecules/Tiltle-section/Title-section';
import { ButtonAddItem } from '../../atoms/Button-add-item/Button-add-item';
import { useNavigate } from 'react-router';
import './Renewal-subscription.scss';

export const RenewalSubscription = () => {
  const navigate = useNavigate();
  const user = useSelector((state: IAppReducer) => state.authReducer);
  const goToARenewalSubscription = () => {
    navigate('/renewal-subscription');
  };
  return (
    <>
      {user.agentId && (
        <div className="renewal__container__section">
          <TitleSection title="Renovar suscripción">
            <ButtonAddItem
              testid="btn_payment_billing_renewal_plan"
              label="Renovar"
              onClick={goToARenewalSubscription}
              template="primary"
            />
          </TitleSection>
          <div className="payments__content">
            Espacio utilizado para renovar las suscripciones utilizando su id
          </div>
        </div>
      )}
    </>
  );
};
