export interface IFormContactInformation {
  name: string;
  lastname: string;
  email: string;
  indicator: string;
  phone: string;
  country_id: string | number;
  acceptTerms: boolean;
  acceptTermsYearCar: boolean;
  password: string;
  province: string;
  district: string;
  canton: string;
  documentNumber: string;
  zipCode: string;
}
export interface IFormErrorsContactInformation {
  name?: string;
  lastname?: string;
  email?: string;
  indicator?: string;
  phone?: string;
  country_id?: string;
  acceptTerms?: string;
  acceptTermsYearCar?: string;
  password?: string;
  province?: string;
  district?: string;
  canton?: string;
  documentNumber?: string;
  zipCode?: string;
}

export const enum CountryCode {
  co = 'CO',
  cr = 'CRC',
  pr = 'PR',
  pty = 'PAN',
}
