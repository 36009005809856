import './Radio-button.scss';

export interface IPropsRadioButton {
  label?: string;
  isSelected: boolean;
}

export const RadioButton = (props: IPropsRadioButton) => {
  return (
    <div className="radio__button__contianer">
      <div
        className={`radio__button__btn ${
          props.isSelected ? 'radio__button__btn--selected' : ''
        }`}
      ></div>
      <span>{props.label}</span>
    </div>
  );
};
