import { CadrInfo } from 'components/atoms/Car-info/Card-info';

export const ContactCard = () => {
  return (
    <CadrInfo
      message="¿Necesitas suscribir 10
      o más vehículos?"
    ></CadrInfo>
  );
};
