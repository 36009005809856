import { HistoryCardSkeleton } from 'components/skeleton/molecules/History-card-skeleton/History-card-skeleton';
import './Services-history-skeleton.scss';

export const ServicesHistorySkeleton = () => {
  return (
    <div className="services__history__skeleton__container">
      <div className="filters">
        <div className="filters__status skeleton"></div>
        <div className="filters__calendary skeleton"></div>
      </div>
      <div className="items">
        {[1, 2].map((item) => (
          <HistoryCardSkeleton key={'history' + item} />
        ))}
      </div>
    </div>
  );
};
