import { useNavigate } from 'react-router';
import './Item-empty.scss';
import { Button } from 'components/atoms/Button/Button';
import { Link } from 'components/atoms/Link/Link';

export interface IPropsItemEmpty {
  info: string;
  title: string;
  image: any;
  template: 'primary' | 'secondary';
  typeAction: 'navigate' | 'invoke' | undefined;
  link: string;
  labelLink?: string;
}

export const ItemEmpty = (props: IPropsItemEmpty) => {
  const navigate = useNavigate();
  return (
    <div
      className={`item__empty__container item__empty__container--${props.template}`}
    >
      <div className="item__empty__info">
        <h3>{props.title}</h3>
        <p
          className="item__empty__title"
          dangerouslySetInnerHTML={{ __html: props.info }}
        ></p>
        {props.typeAction &&
          (props.typeAction === 'navigate' ? (
            <Button
              className="item__empty__button"
              size="small"
              color="secondary"
              template="primary"
              typeStyle="rounded"
              type="button"
              onClick={() => navigate(props.link)}
            >
              agregar nuevo
            </Button>
          ) : (
            <Link
              rightIcon={true}
              type="primary"
              label={props.labelLink ? props.labelLink : 'Solicitar servicio'}
              link={props.link}
            ></Link>
          ))}
      </div>
      <div className="item__empty__icon">{props.image}</div>
    </div>
  );
};
