import './Card.scss';
import * as CSS from 'csstype';
import { ReactNode } from 'react';

export interface IPropsCard {
  styleInLine?: CSS.Properties<string | number>;
  children: ReactNode;
  id?: string;
  WrapperClassName?: string;
  isSelected?: boolean;
  onClick?: () => void;
}

export const Card = (props: IPropsCard) => {
  return (
    <div
      className={`container__card ${
        props.WrapperClassName ? props.WrapperClassName : ''
      } ${props.isSelected ? 'container__card--selected' : ''}`}
      style={props.styleInLine}
      id={props.id}
      onClick={props.onClick && props.onClick}
    >
      {props.children}
    </div>
  );
};
