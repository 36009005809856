import { Input } from 'components/atoms/Input/Input';
import { PopupRequiredValidationGeneric } from 'components/molecules/Popup-required-validation-generic/Popup-required-validation-generic';
import { useFormik } from 'formik';
import './Popup-required-validation-fake.scss';
import { useState } from 'react';

interface IpopupRequiredValidationFake {
  message: string;
  onFormIsValid: (plate: boolean) => void;
}
interface IformPopupRequiredValidationFake {
  code?: string;
}

interface IformPopupRequiredValidationFakeErrors {
  code?: string;
}

export const PopupRequiredValidationFake = (
  props: IpopupRequiredValidationFake
  ) => {
  const [isLoading, setIsLoading] = useState(false);
  const formPopupRequiredValidationInitial = {
    code: '',
  };
  const formPopupRequiredValidationValidate = (
    values: IformPopupRequiredValidationFake
  ) => {
    const errors: IformPopupRequiredValidationFakeErrors = {};
    if (!values.code) {
      errors.code = 'Este campo es requerido';
    }
    return errors;
  };

  const formPopupRequiredValidation = useFormik({
    initialValues: formPopupRequiredValidationInitial,
    validate: formPopupRequiredValidationValidate,
    onSubmit: async () => {
      try {
        setIsLoading(true);
        setTimeout(() => {
          setIsLoading(false);
          props.onFormIsValid(true);
        }, 1500);
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <PopupRequiredValidationGeneric
      title="Hola!"
      labelButton="Validar"
      closePopUp={formPopupRequiredValidation.handleSubmit}
      isLoading={isLoading}
    >
      <strong className="popup__required__message">{props.message}</strong>
      <Input
        label="Código"
        id="popup-required-code"
        type="alphanumeric"
        name="code"
        onChange={formPopupRequiredValidation.handleChange}
        onBlur={formPopupRequiredValidation.handleBlur}
        value={formPopupRequiredValidation.values.code}
        isTouched={formPopupRequiredValidation.touched.code}
        errorInput={formPopupRequiredValidation.errors.code}
        errorLabel={formPopupRequiredValidation.errors.code}
        isRequired={true}
        form={formPopupRequiredValidation}
        textTransform={true}
      />
    </PopupRequiredValidationGeneric>
  );
};
