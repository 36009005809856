import {
  checkoutAction,
  TypeActionsCheckout,
} from '../actions/checkout.types';
import { initalStateCreateAccount } from '../state/checkout.state';

export const checkoutReducer = (
  state = initalStateCreateAccount,
  action: checkoutAction
) => {
  switch (action.type) {
    case TypeActionsCheckout.saveInformation:
      return {
        ...state,
        ...action.data,
      };
    case TypeActionsCheckout.resetInformation:
      return initalStateCreateAccount;
    default:
      return state;
  }
};
