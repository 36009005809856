import { ButtonClose } from 'components/atoms/Button-close/Button-close';
import { PopupOverlay } from 'components/atoms/Popup-overlay/Popup-overlay';
import { ReactNode } from 'react';
import './Popup-informative-custom-service.scss';

interface IPropsPopUpInformativeCustomService {
  closePopUp: () => void;
  children?: ReactNode;
}
export const PopUninformativeCustomService = (
  props: IPropsPopUpInformativeCustomService
) => {
  return (
    <PopupOverlay>
      <div className={`popup__informative_custom_service__container`}>
        <div className="popup__informative_custom_service__header">
          <h5 className="popup__informative_custom_service__title">
            Información de pago
          </h5>
          <ButtonClose
            size="medium"
            template="dark"
            className="popup__informative_custom_service__close"
            onClick={props.closePopUp}
          />
        </div>
        {props.children}
      </div>
    </PopupOverlay>
  );
};
