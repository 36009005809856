import { PopUpInformative } from 'components/molecules/Popup-informative/Popup-informative';
import './Popup-error-recover-password.scss';

export interface IPopupErrorRecoverPassword {
  closePopUp: () => void;
  email: string;
}

export const PopupErrorRecoverPassword = (
  props: IPopupErrorRecoverPassword
) => {
  return (
    <PopUpInformative title="Error" type="error" closePopUp={props.closePopUp}>
      <div className="popup__error__recover__pasword">
        <div className="popup__error__recover__pasword--error">
          <span className="popup__error__recover__pasword--bold">
            Hemos detectado actividad que pone en riesgo la seguridad de sus
            datos
          </span>
          . Se ha bloqueado la pantalla temporalmente. Intentelo más tarde, o
          bien contacte servicio al cliente para mayor atención.
        </div>
      </div>
    </PopUpInformative>
  );
};
