export const countryIndicatorOptions = [
  { value: '+1', description: '+1' },
  { value: '+506', description: '+506' },
  { value: '+507', description: '+507' },
  { value: '+57', description: '+57' },
  { value: '+52', description: '+52' },
];

export const countryOptions = [
  { value: 1, description: 'Puerto Rico' },
  { value: 2, description: 'Costa Rica' },
  { value: 3, description: 'Panama' },
  { value: 4, description: 'Colombia' },
  { value: 5, description: 'México' },
];

export const countryValue = { 1: 'pr', 2: 'cr', 3: 'pty', 4: 'co' , 5: 'mx' };

export const getLabels = (branch: string) => {
  return {
    co: {
      province: 'Departamento',
      district: 'Municipio',
    },
    cr: {
      province: 'Provincia',
      district: 'Distrito',
    },
    pr: {
      province: 'Provincia',
      district: 'Ciudad',
    },
    pty: {
      province: 'Provincia',
      district: 'Distrito',
    },
  }[branch];
};


export const personalInformationStepper = {
  index: 2,
  steps: 2,
};
