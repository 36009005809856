import { defaultFirebaseAuth } from 'config/firebase-config';
import { signOut } from 'firebase/auth';
import { IDetailStep } from 'models/info.steps.interface';
import { IAppReducer } from 'models/reducer.interface';
import { setSignOut } from 'pages/sing-in/store/actions/auth.types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { setStateError } from 'store/actions/app.types';
import { getContactInfo } from 'utils/connect-info.utils';

export const useProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    branchReducer: { branch },
    authReducer,
  } = useSelector((state: IAppReducer) => state);
  const steps: IDetailStep[] = [
    {
      description: 'ajustes',
      state: 'active',
      link: '/settings',
      permissionToNavigate: true,
    },
    {
      description: 'mi perfil',
      state: 'active',
      link: '/settings/profile',
      permissionToNavigate: false,
    },
  ];
  const handleSignOut = async () => {
    try {
      signOut(defaultFirebaseAuth);
      dispatch(setSignOut());
    } catch (error) {
      dispatch(setStateError(true));
    }
  };
  const goToTermsAndConditions = () => {
    window.open(getContactInfo(branch).url_terms, '_blank');
  };
  const goToResetPassword = () => {
    navigate('/reset-password');
  };
  return {
    steps,
    navigate,
    authReducer,
    branch,
    goToTermsAndConditions,
    goToResetPassword,
    handleSignOut,
  };
};
