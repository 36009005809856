import { createStore, combineReducers } from 'redux';
import { authReducer } from 'pages/sing-in/store/reducer/auth.reducer';
import { createAccountReducer } from 'pages/Create-account/store/reducer/create-account.reducer';
import { checkoutReducer } from 'pages/Checkout/store/reducer/checkout.reducer';
import { editPlanReducer } from 'pages/Edit-plan/Edit-plan-information/store/reducer/edit-plan.reducer';
import { composeWithDevTools } from '@redux-devtools/extension';
import { branchReducer } from './reducer/branch.reducer';
import { appReducer } from './reducer/app.reducer';
import { hydrationMetaReducer, saveState } from './hydration/hydration.reducer';

const reducers = combineReducers({
  authReducer,
  branchReducer,
  createAccountReducer,
  appReducer,
  checkoutReducer,
  editPlanReducer,
});

const persistedState = hydrationMetaReducer();
const store = createStore(reducers, persistedState, composeWithDevTools());
store.subscribe(() => {
  saveState({
    authReducer: store.getState().authReducer,
    branchReducer: store.getState().branchReducer,
    createAccountReducer: store.getState().createAccountReducer,
    checkoutReducer: store.getState().checkoutReducer,
    editPLanReducer: store.getState().editPlanReducer,
  });
});

export { store };
