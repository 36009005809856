import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IAppReducer } from 'models/reducer.interface';

import { getUserServices } from 'shared/services/user';
import { IDetailStep } from 'models';

export const useServicesHistory = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [servicesHistory, setServicesHistory] = useState<any>(undefined);
  const {
    authReducer: user,
    branchReducer: { branch },
  } = useSelector((state: IAppReducer) => state);

  useEffect(() => {
    (async () => {
      try {
        const serviceResponse = await getUserServices(user.uid);
        setServicesHistory(serviceResponse);
      } catch (error) {
        setServicesHistory(null);
        console.log('Notificar errores', error);
      }
    })();
  }, [setServicesHistory]);
  const steps: IDetailStep[] = [
    {
      description: 'ajustes',
      state: 'active',
      link: '/settings',
      permissionToNavigate: true,
    },
    {
      description: 'Historial servicios',
      state: 'active',
      link: '/settings/services-history',
      permissionToNavigate: false,
    },
  ];
  return { steps, navigate, servicesHistory, branch, user };
};
