import './Button-add-item.scss';
import * as CSS from 'csstype';

interface IPropsItemAddItem {
  label: string;
  styleInLine?: CSS.Properties<string | number>;
  onClick: () => void;
  template: 'primary' | 'secondary';
  testid: string;
}
export const ButtonAddItem = (props: IPropsItemAddItem) => {
  return (
    <button
      data-testid={props.testid}
      style={props.styleInLine}
      className={`button__add__item ${
        props.template === 'secondary' ? 'button__add__item--secondary' : ''
      }`}
      onClick={props.onClick}
    >
      {props.label}
      <span
        className={`button__add__item__icon ${
          props.template === 'secondary'
            ? 'button__add__item__icon--secondary'
            : ''
        }`}
      ></span>
    </button>
  );
};
