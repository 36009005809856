import { IPropsIcon } from './icons.interface';
import { useColorHook } from 'hooks/Color.hook';

export const BillingSvg = (props: IPropsIcon) => {
  const color = useColorHook(props.color);
  return (
    <svg
      width="100%"
      height="100%"
      className={props.className}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="ICONOS">
        <path
          id="Vector"
          d="M6.76494 8.11145V12.4183H5.74353C4.60999 12.4183 3.6886 13.3397 3.6886 14.4733V21.4503C3.6886 22.5839 4.60999 23.5053 5.74353 23.5053H17.6428C18.7763 23.5053 19.6977 22.5839 19.6977 21.4503V21.0442H26.4687V8.11145H6.76494ZM5.74353 13.658H17.6428C18.0913 13.658 18.4581 14.0247 18.4581 14.4733V19.8015H4.92823V14.4733C4.92823 14.0247 5.29496 13.658 5.74353 13.658ZM18.4581 21.4503C18.4581 21.8989 18.0913 22.2656 17.6428 22.2656H5.74353C5.29496 22.2656 4.92823 21.8989 4.92823 21.4503V21.0442H18.4581V21.4503ZM25.229 19.8046H19.7007V14.4763C19.7007 13.3427 18.7793 12.4214 17.6458 12.4214H8.0076V9.35411H25.229V19.8076V19.8046Z"
          fill={color}
        />
        <path
          id="Vector_2"
          d="M8.61378 14.8854H6.15271V18.577H8.61378V14.8854Z"
          fill={color}
        />
        <path
          id="Vector_3"
          d="M24.0015 10.5725H9.23206V11.8121H24.0015V10.5725Z"
          fill={color}
        />
        <path
          id="Vector_4"
          d="M24.0015 17.3434H20.9252V18.5831H24.0015V17.3434Z"
          fill={color}
        />
        <path
          id="Vector_5"
          d="M17.2335 17.3434H9.84729V18.5831H17.2335V17.3434Z"
          fill={color}
        />
        <path
          id="Vector_6"
          d="M24.0015 12.8305H20.9252V14.0701H24.0015V12.8305Z"
          fill={color}
        />
        <path
          id="Vector_7"
          d="M24.0015 15.0854H20.9252V16.3251H24.0015V15.0854Z"
          fill={color}
        />
        <path
          id="Vector_8"
          d="M16 0.115967C7.16499 0.115967 0 7.28096 0 16.116C0 24.951 7.16499 32.116 16 32.116C24.835 32.116 32 24.951 32 16.116C32 7.28096 24.838 0.115967 16 0.115967ZM16 30.8854C7.84391 30.8854 1.23054 24.2721 1.23054 16.116C1.23054 7.95988 7.84391 1.3465 16 1.3465C24.1561 1.3465 30.7695 7.95988 30.7695 16.116C30.7695 24.2721 24.1561 30.8854 16 30.8854Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
