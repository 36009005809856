import './Chips.scss';

export interface IPropsChips {
  label: string;
  onClick?: () => void;
}

export const Chips = (props: IPropsChips) => {
  return (
    <div
      className={`chips__container ${props.onClick ? 'pointer' : ''}`}
      onClick={props.onClick && props.onClick}
    >
      {props.label}
    </div>
  );
};
