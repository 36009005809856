import { AuthFromGeneric } from 'components/templates/Auth-from-generic/Auth-from-generic';
import './Create-profile.scss';
import { Input } from 'components/atoms/Input/Input';
import { UseCreateProfileValidation } from './hooks/Create-profile.hook';
import {
  createProfileActiveBenefitsHeaderInformation,
  createProfileHeaderInformation,
  createProfileStepper,
} from './Constant/Create-profile.constant';
import { createPasswordListOfInformation } from 'shared/constants/password.constants';

export const CreateProfile = () => {
  const {
    isLoading,
    formCreateProfile,
    handlePassword,
    handleConfirmPassword,
    createAccountReducer
  } = UseCreateProfileValidation();

  return (
    <form onSubmit={formCreateProfile.handleSubmit} noValidate>
      <AuthFromGeneric
        labelPrimaryAction="Continuar"
        headerInformation={
          createAccountReducer.plan_id
            ? createProfileActiveBenefitsHeaderInformation
            : createProfileHeaderInformation
        }
        isLoading={isLoading}
        handleClick={formCreateProfile.handleSubmit}
        labelSecondaryAction="Ya tengo cuenta"
        secondaryActionPath="/login"
        listOfInformation={createPasswordListOfInformation}
        stepper={createProfileStepper}
      >
        <Input
          label="Correo Electrónico"
          id="login-email"
          type="email"
          name="email"
          onChange={formCreateProfile.handleChange}
          onBlur={formCreateProfile.handleBlur}
          value={formCreateProfile.values.email}
          isTouched={formCreateProfile.touched.email}
          errorInput={formCreateProfile.errors.email}
          errorLabel={formCreateProfile.errors.email}
          isRequired={true}
          form={formCreateProfile}
          isDisabled={true}
        />
        <Input
          label="Contraseña"
          id="create-user-password"
          type="password"
          name="password"
          onChange={(e) => handlePassword(e, 'change')}
          onBlur={(e) => {
            handlePassword(e, 'blur');
          }}
          value={formCreateProfile.values.password}
          isTouched={formCreateProfile.touched.password}
          errorInput={formCreateProfile.errors.password}
          errorLabel={formCreateProfile.errors.password}
          isRequired={true}
          form={formCreateProfile}
        />
        <Input
          WrapperClassName="grid__span-6"
          label="Confirmar Contraseña"
          id="create-user-conFirmPassword"
          type="password"
          name="conFirmPassword"
          onChange={(e) => handleConfirmPassword(e, 'change')}
          onBlur={(e) => {
            handleConfirmPassword(e, 'blur');
          }}
          value={formCreateProfile.values.conFirmPassword}
          isTouched={formCreateProfile.touched.conFirmPassword}
          errorInput={formCreateProfile.errors.conFirmPassword}
          errorLabel={formCreateProfile.errors.conFirmPassword}
          isRequired={true}
          form={formCreateProfile}
        />
      </AuthFromGeneric>
    </form>
  );
};
