import { ButtonClose } from 'components/atoms/Button-close/Button-close';
import { PopupOverlay } from 'components/atoms/Popup-overlay/Popup-overlay';
import './Popup-freemiun.scss';
import { Button } from 'components/atoms/Button/Button';
import { useNavigate } from 'react-router';
import { config } from 'config/constants';
import { Images } from 'assets/images/images.index';

interface IPopupFreemiun {
  closePopUp: () => void;
}
export const PopupFreemiun = (props: IPopupFreemiun) => {
  const navigate = useNavigate();
  const goToActivatePlan = () => {
    navigate(
      `/active-plan/${config.default_plans.free}?&utm_source=referral&utm_medium=checkout&utm_campaign=popup`
    );
  };
  return (
    <PopupOverlay classContainer="popup__freemiun__popup">
      <div className="popup__freemiun">
        <div className={`popup__freemiun__container`}>
          <div className={`popup__freemiun__content`}>
            <div className="popup__freemiun__header">
              <ButtonClose
                size="medium"
                template="dark"
                className="popup__freemiun__header__close"
                onClick={props.closePopUp}
              />
            </div>
            <div className="popup__freemiun__content__row">
              <div className="popup__freemiun__content__left">
                <h5 className="popup__freemiun__content__title">
                  ¿Aún no <br />
                  estás listo?
                </h5>
                <p className="popup__freemiun__content__text">
                  ¡No te preocupes! Regístrate <strong>SIN COSTO</strong> y{' '}
                  <strong>OBTÉN un 10% de DESCUENTO</strong>
                  en servicios para el hogar como Plomería, Electricidad,
                  Cerrajería, Handyman y más.
                </p>
                <div className="popup__freemiun__content__actions desktop">
                  <div className="popup__freemiun__content__button">
                    <Button
                      color="secondary"
                      size="medium"
                      template="primary"
                      typeStyle="rounded"
                      className="freemiun__button"
                      type="button"
                      isDisabled={false}
                      onClick={goToActivatePlan}
                      dataClickType="cat"
                      dataClickName="checkout"
                      dataClickObjective="active-freemiun"
                    >
                      REGÍSTRATE HOY
                    </Button>
                  </div>
                  <p className="popup__freemiun__content__terms">
                    *Valido únicamente para clientes nuevos
                  </p>
                </div>
              </div>
              <img
                className="popup__freemiun__content__image"
                src={Images.freemuin}
                alt=""
              />
            </div>
            <div className="popup__freemiun__content__actions mobile">
              <div className="popup__freemiun__content__button">
                <Button
                  color="secondary"
                  size="small"
                  template="primary"
                  typeStyle="rounded"
                  className="freemiun__button"
                  type="button"
                  isDisabled={false}
                  onClick={goToActivatePlan}
                  dataClickType="cat"
                  dataClickName="checkout"
                  dataClickObjective="active-freemiun"
                >
                  REGÍSTRATE HOY
                </Button>
              </div>
              <p className="popup__freemiun__content__terms">
                *Valido únicamente para clientes nuevos
              </p>
            </div>
          </div>
        </div>
      </div>
    </PopupOverlay>
  );
};
