import { PopUpInformative } from 'components/molecules/Popup-informative/Popup-informative';
import { maskEmail } from 'utils/mask.utils';
import './Popup-email-send.scss';

export interface IPopupEmailSend {
  closePopUp: () => void;
  email: string;
}

export const PopupEmailSend = (props: IPopupEmailSend) => {
  return (
    <PopUpInformative
      title="¡Enviado con éxito!"
      type="successful"
      closePopUp={props.closePopUp}
      subtitle={`Revise su correo electrónico </br> ${maskEmail(
        props.email,
        1
      )}`}
      text=" Deberá haber recibido un enlace para restablecer su contraseña."
    ></PopUpInformative>
  );
};
