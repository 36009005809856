import { PersonPhoneSvg } from 'assets/icons/person-phone';
import { ItemEmpty } from 'components/molecules/Item-empty/Item-empty';
import { IAppReducer } from 'models/reducer.interface';
import { shallowEqual, useSelector } from 'react-redux';

export const PlanItemEmpty = (props: { userType: string | undefined }) => {
  const {
    authReducer: { plansId },
  } = useSelector((state: IAppReducer) => state, shallowEqual);
  return (
    <ItemEmpty
      title=""
      image={<PersonPhoneSvg color="tertiary" />}
      info="No tiene planes <br />suscritos a esta cuenta"
      template="secondary"
      link={`/buy-plan?planId=${plansId}`}
      typeAction={props.userType === 'colaborador' ? undefined : 'navigate'}
    ></ItemEmpty>
  );
};
