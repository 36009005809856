import { ItemAddPropertySkeleton } from 'components/skeleton/molecules/Item-add-property-skeleton/Item-add-property-skeleton';
import './Cart-checkout-skeleton.scss';
export const CartCheckoutSkeleton = () => {
  return (
    <div className="cart__checkout__skeleton__container ">
      <div className="cart__checkout__skeleton__body">
        <div className="cart__checkout__skeleton__body__content">
          <div className="title skeleton"></div>
          {[1, 1, 1].map((_, index) => (
            <ItemAddPropertySkeleton key={index}></ItemAddPropertySkeleton>
          ))}
        </div>
        <div className="cart__checkout__skeleton__body__content">
          <div className="title skeleton"></div>
          {[1, 1].map((_, index) => (
            <div
              key={'producto__skeleton' + index}
              className="periodicity skeleton"
            >
              <div className="radio__button">
                <div className="radio skeleton"></div>
                <div className="label skeleton"></div>
              </div>
              <div className="value skeleton"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
