import {
  TitleSection,
  ButtonAddItem,
  ItemCard,
  SkeletonProperties,
  PopupDelete,
  HeaderWithAlert,
  GenericError,
} from 'components';
import { Images } from 'assets/images/images.index';
import { SettingsFooter } from 'components/molecules/Settings-footer/Settings-footer';
import './Properties.scss';
import { useProperties } from './hooks/useProperties';

export const Properties = () => {
  const {
    deleteProperty,
    messagesAlert,
    user,
    steps,
    showAlert,
    navigate,
    error,
    getProperties,
    handlePropertiesLimit,
    propertiesInfo,
    goToAddProperty,
    propertiesSummary,
    infoPopUp,
    showPopUp,
    goToDetailProperty,
    goToEditProperty,
    goToWhatsApp,
    handlePopUp,
    branch,
    propertiesLimit,
    handleDleteProperty,
    isLoading,
    connectWebAnchor
  } = useProperties();
  return (
    <div className="properties__section">
      <HeaderWithAlert
        title={
          deleteProperty.id
            ? messagesAlert.deleteProperty.title
            : messagesAlert.editProperty.title
        }
        message={`Se envio comprobante al <strong>${user.email}</strong`}
        steps={steps}
        showAlert={showAlert}
        onClick={() => {
          navigate('/settings');
        }}
      ></HeaderWithAlert>
      <div className="properties__title__container">
        <h6>Mis propiedades</h6>
      </div>

      {error && (
        <div className="properties__container">
          <GenericError
            message="¡Ups! Ha ocurrido un error de conexión, por favor inténtalo nuevamente."
            title=""
            onClick={getProperties}
          />
        </div>
      )}
      {!propertiesInfo &&
        !error &&
        [1, 2].map((item) => <SkeletonProperties key={item} />)}
      {propertiesInfo && !error && (
        <>
          <div className="properties__container">
            <div className="properties__property__container">
              <TitleSection
                title="Autos"
                subtitle={propertiesInfo.vehicles.length > 0 ? '' : ''}
              >
                <ButtonAddItem
                  testid="btn_properties_add_auto"
                  label="Agregar"
                  onClick={() => goToAddProperty(1)}
                  styleInLine={{ height: 'max-content' }}
                  template="primary"
                />
              </TitleSection>
              {propertiesInfo.vehicles.length > 0 ? (
                <>
                  <p className="properties__available">
                    {propertiesSummary.properties.auto} de{' '}
                    {propertiesSummary.products.auto} propiedades disponibles
                  </p>
                  <div className="properties__property__container__items">
                    {propertiesInfo.vehicles.map(
                      (itemVehicle, index) =>
                        index < propertiesLimit.vehicles && (
                          <ItemCard
                            testid={
                              itemVehicle.attributes[
                                itemVehicle.attributes.length - 1
                              ]
                            }
                            key={`card-${index}`}
                            id={itemVehicle.id}
                            WrapperClassName={`${
                              infoPopUp?.id === itemVehicle.id && !showPopUp
                                ? 'bounceOut'
                                : ''
                            }`}
                            title={itemVehicle.title}
                            chipsInfo={itemVehicle.attributes}
                            status={itemVehicle.status}
                            onClickDetail={goToDetailProperty}
                            onClickEdit={
                              user.agentId !== undefined
                                ? goToEditProperty
                                : goToWhatsApp
                            }
                            onClickDelete={
                              user.agentId !== undefined
                                ? handlePopUp
                                : goToWhatsApp
                            }
                            label="propiedad"
                          ></ItemCard>
                        )
                    )}
                  </div>
                </>
              ) : (
                <div className="properties__property__container__items__empty">
                  <img src={Images.auto} alt="Automovil" />
                  <p>
                    Por el momento no tienes propiedades disponibles para cubrir
                    con tus planes actuales.
                  </p>
                </div>
              )}
              {propertiesInfo.vehicles.length > propertiesLimit.vehicles && (
                <div
                  className="more__options"
                  onClick={() => handlePropertiesLimit('vehicles')}
                >
                  <span>Ver más </span>
                  <img src={Images.arrowDown} alt="Desplegar menu" />
                </div>
              )}
            </div>
          </div>
          {branch === 'co' &&
            user.user_type !== 'colaborador' &&
            !user.agentId && (
              <div className="properties__container">
                <div className="properties__property__container">
                  <TitleSection title="Mascotas">
                    <ButtonAddItem
                      testid="btn_properties_add_pets"
                      label="Agregar"
                      onClick={() => goToAddProperty(3)}
                      styleInLine={{ height: 'max-content' }}
                      template="primary"
                    />
                  </TitleSection>
                  {propertiesInfo.pets.length > 0 ? (
                    <>
                      <p className="properties__available">
                        {propertiesSummary.properties.pets} de{' '}
                        {propertiesSummary.products.pets} propiedades
                        disponibles
                      </p>
                      <div className="properties__property__container__items">
                        {propertiesInfo.pets.map(
                          (itemPet, index) =>
                            index < propertiesLimit.pets && (
                              <ItemCard
                                testid={
                                  itemPet.attributes[
                                    itemPet.attributes.length - 1
                                  ]
                                }
                                key={`home-${index}`}
                                id={itemPet.id}
                                WrapperClassName={`${
                                  infoPopUp?.id === itemPet.id && !showPopUp
                                    ? 'bounceOut'
                                    : ''
                                }`}
                                title={itemPet.title}
                                chipsInfo={itemPet.attributes}
                                status={itemPet.status}
                                onClickDetail={goToDetailProperty}
                                onClickEdit={goToEditProperty}
                                onClickDelete={handlePopUp}
                                label="propiedad"
                              ></ItemCard>
                            )
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="properties__property__container__items__empty">
                      <img src={Images.mascota} alt="Hogar" />
                      <p>
                        Por el momento no tienes propiedades disponibles para
                        cubrir con tus planes actuales.
                      </p>
                    </div>
                  )}
                  {propertiesInfo.pets.length > propertiesLimit.pets && (
                    <div
                      className="more__options"
                      onClick={() => handlePropertiesLimit('pets')}
                    >
                      <span>
                        ({propertiesInfo.pets.length - propertiesLimit.pets})
                        Ver más{' '}
                        <img src={Images.arrowDropdown} alt="Desplegar menu" />
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )}
          {branch === 'pr' && (
            <>
              <div className="properties__container">
                <div className="properties__property__container">
                  <TitleSection title="Hogares">
                    <ButtonAddItem
                      testid="btn_properties_add_hogar"
                      label="Agregar"
                      onClick={() => goToAddProperty(2)}
                      styleInLine={{ height: 'max-content' }}
                      template="primary"
                    />
                  </TitleSection>
                  {propertiesInfo.homes.length > 0 ? (
                    <>
                      <p className="properties__available">
                        {propertiesSummary.properties.homes} de{' '}
                        {propertiesSummary.products.homes} propiedades
                        disponibles
                      </p>
                      <div className="properties__property__container__items">
                        {propertiesInfo.homes.map(
                          (itemHome, index) =>
                            index < propertiesLimit.homes && (
                              <ItemCard
                                testid={
                                  itemHome.attributes[
                                    itemHome.attributes.length - 1
                                  ]
                                }
                                key={`home-${index}`}
                                id={itemHome.id}
                                WrapperClassName={`${
                                  infoPopUp?.id === itemHome.id && !showPopUp
                                    ? 'bounceOut'
                                    : ''
                                }`}
                                title={itemHome.title}
                                chipsInfo={itemHome.attributes}
                                status={itemHome.status}
                                onClickDetail={goToDetailProperty}
                                onClickEdit={goToEditProperty}
                                onClickDelete={handlePopUp}
                                label="propiedad"
                              ></ItemCard>
                            )
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="properties__property__container__items__empty">
                      <img src={Images.hogar} alt="Hogar" />
                      <p>
                        Por el momento no tienes propiedades disponibles para
                        cubrir con tus planes actuales.
                      </p>
                    </div>
                  )}
                  {propertiesInfo.homes.length > propertiesLimit.homes && (
                    <div
                      className="more__options"
                      onClick={() => handlePropertiesLimit('homes')}
                    >
                      <span>
                        ({propertiesInfo.homes.length - propertiesLimit.homes})
                        Ver más{' '}
                        <img src={Images.arrowDropdown} alt="Desplegar menu" />
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="properties__container">
                <div className="properties__property__container">
                  <TitleSection title="Motoras">
                    <ButtonAddItem
                      testid="btn_properties_add_moto"
                      label="Agregar"
                      onClick={() => goToAddProperty(4)}
                      styleInLine={{ height: 'max-content' }}
                      template="primary"
                    />
                  </TitleSection>
                  {propertiesInfo.motorcycles.length > 0 ? (
                    <>
                      <p className="properties__available">
                        {propertiesSummary.properties.motorcycles} de{' '}
                        {propertiesSummary.products.motorcycles} propiedades
                        disponibless
                      </p>
                      <div className="properties__property__container__items">
                        {propertiesInfo.motorcycles.map(
                          (itemMotorcycle, index) =>
                            index < propertiesLimit.motorcycles && (
                              <ItemCard
                                testid={
                                  itemMotorcycle.attributes[
                                    itemMotorcycle.attributes.length - 1
                                  ]
                                }
                                key={`motorcycle-${index}`}
                                id={itemMotorcycle.id}
                                WrapperClassName={`${
                                  infoPopUp?.id === itemMotorcycle.id &&
                                  !showPopUp
                                    ? 'bounceOut'
                                    : ''
                                }`}
                                title={itemMotorcycle.title}
                                chipsInfo={itemMotorcycle.attributes}
                                status={itemMotorcycle.status}
                                onClickDetail={goToDetailProperty}
                                onClickEdit={goToEditProperty}
                                onClickDelete={handlePopUp}
                                label="propiedad"
                              ></ItemCard>
                            )
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="properties__property__container__items__empty">
                      <img src={Images.moto} alt="Motocicleta" />
                      <p>
                        Por el momento no tienes propiedades disponibles para
                        cubrir con tus planes actuales.
                      </p>
                    </div>
                  )}
                  {propertiesInfo.motorcycles.length >
                    propertiesLimit.motorcycles && (
                    <div
                      className="more__options"
                      onClick={() => handlePropertiesLimit('motorcycles')}
                    >
                      <span>
                        (
                        {propertiesInfo.motorcycles.length -
                          propertiesLimit.motorcycles}
                        ) Ver más{' '}
                        <img src={Images.arrowDropdown} alt="Desplegar menu" />
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
      <a
        ref={connectWebAnchor}
        className="anchor__link"
        rel="noreferrer"
        target="_blank"
      ></a>
      <SettingsFooter></SettingsFooter>
      {showPopUp && infoPopUp && (
        <PopupDelete
          chipsInfo={infoPopUp.attributes}
          itemTitle={infoPopUp.title}
          title="¿Estás seguro de eliminar esta propiedad?"
          closePopUp={handlePopUp}
          onAcceptAction={handleDleteProperty}
          isLoading={isLoading}
          type="deleteProperty"
        ></PopupDelete>
      )}
    </div>
  );
};
