import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
} from '@stripe/react-stripe-js';

import { Images } from 'assets/images/images.index';
import { Input, Checkbox, ButtonDelete } from 'components';
import { forwardRef } from 'react';
import './Payment-method-stripe.scss';
import {
  IPropsPaymentMethodStripe,
  PaymentMethodStripeRef,
} from './models/Payment-method-stripe';
import { inputOptions } from 'shared/constants/Payment-information';
import { usePaymentMethodStripe } from './hooks/usePaymentMethodStripe.hook';

export const PaymentMethodStripe = forwardRef<
  PaymentMethodStripeRef,
  IPropsPaymentMethodStripe
>((props, ref) => {
  const {
    stripeRef,
    stripeLoad,
    onReady,
    stateInputStripe,
    style,
    changeInputStripe,
    onBlurInputStripe,
    refCardNumberElement,
    refCardExpiryElement,
    refCardCvcElement,
    setRefCardExpiryElement,
    setRefCardCvcElement,
  } = usePaymentMethodStripe({
    ...props,
    reference: ref,
  });
  return (
    <div ref={stripeRef}>
      <Elements stripe={stripeLoad}>
        <Input
          label={props.inputNameLabel}
          id="payment-method-name"
          type="text"
          name={props.inputNameControl}
          onChange={props.handleChangeNameInput}
          onBlur={props.handleBlurNameInput}
          value={props.valueNameInput}
          isTouched={props.touchedNameInput}
          errorInput={props.errorNameInput}
          errorLabel={props.errorNameInput}
          isDisabled={false}
          isRequired={true}
          form={props.form}
        />
        <div className="payment__method__stripe__container container__input">
          <CardNumberElement
            id={props.id}
            onReady={(e) => onReady(e)}
            options={{
              ...inputOptions,
              classes: {
                ...inputOptions.classes,
                base: stateInputStripe.cardNumber.errorMessage
                  ? 'input__generic error'
                  : 'input__generic',
              },
              placeholder: '',
              showIcon: !stateInputStripe.cardNumber.isEmpty,
              style,
            }}
            onChange={changeInputStripe}
            onBlur={() => onBlurInputStripe('cardNumber')}
          ></CardNumberElement>
          {((!stateInputStripe.cardNumber.isEmpty &&
            !stateInputStripe.cardNumber.isValid) ||
            stateInputStripe.cardNumber.errorMessage) && (
            <span className="input__error">
              {stateInputStripe.cardNumber.errorMessage}
            </span>
          )}
          <label
            onClick={() => refCardNumberElement?.focus()}
            className={`input__label ${
              (!stateInputStripe.cardNumber.isEmpty &&
                !stateInputStripe.cardNumber.isValid) ||
              stateInputStripe.cardNumber.errorMessage
                ? 'label--error'
                : ''
            } ${
              !stateInputStripe.cardNumber.isEmpty ? 'input__label--value' : ''
            }`}
          >
            Número de Tarjeta
            <img src={Images.inputRequired} alt="Campo requerido" />
          </label>
          {!stateInputStripe.cardNumber.isEmpty && (
            <div className="container__button__delete">
              <ButtonDelete
                isDisabled={false}
                error={!stateInputStripe.cardNumber.isValid}
                onClick={() => refCardNumberElement?.clear()}
              />
            </div>
          )}
        </div>
        <div className="payment__method__stripe__container__elements">
          <div className="payment__method__stripe__container container__input">
            <CardExpiryElement
              onReady={(e) => setRefCardExpiryElement(e)}
              options={{
                ...inputOptions,
                classes: {
                  ...inputOptions.classes,
                  base: stateInputStripe.cardExpiry.errorMessage
                    ? 'input__generic error'
                    : 'input__generic',
                },
              }}
              onChange={changeInputStripe}
              onBlur={() => onBlurInputStripe('cardExpiry')}
            />

            {((!stateInputStripe.cardExpiry.isEmpty &&
              !stateInputStripe.cardExpiry.isValid) ||
              stateInputStripe.cardExpiry.errorMessage) && (
              <span className="input__error">
                {stateInputStripe.cardExpiry.errorMessage}
              </span>
            )}
            <label
              role="button"
              onClick={() => refCardExpiryElement?.focus()}
              className={`input__label ${
                (!stateInputStripe.cardExpiry.isEmpty &&
                  !stateInputStripe.cardExpiry.isValid) ||
                stateInputStripe.cardExpiry.errorMessage
                  ? 'label--error'
                  : ''
              } ${
                !stateInputStripe.cardExpiry.isEmpty
                  ? 'input__label--value'
                  : ''
              }`}
            >
              Fecha de vencimiento
              <img src={Images.inputRequired} alt="Campo requerido" />
            </label>
            <div className="container__button__delete">
              {!stateInputStripe.cardExpiry.isEmpty && (
                <ButtonDelete
                  isDisabled={false}
                  error={!stateInputStripe.cardExpiry.isValid}
                  onClick={() => refCardExpiryElement?.clear()}
                />
              )}
            </div>
          </div>
          <div className="payment__method__stripe__container container__input">
            <CardCvcElement
              onReady={(e) => setRefCardCvcElement(e)}
              options={{
                ...inputOptions,
                classes: {
                  ...inputOptions.classes,
                  base: stateInputStripe.cardCvc.errorMessage
                    ? 'input__generic error'
                    : 'input__generic',
                },
              }}
              onChange={changeInputStripe}
              onBlur={() => onBlurInputStripe('cardCvc')}
            />
            {((!stateInputStripe.cardCvc.isEmpty &&
              !stateInputStripe.cardCvc.isValid) ||
              stateInputStripe.cardCvc.errorMessage) && (
              <span className="input__error">
                {stateInputStripe.cardCvc.errorMessage}
              </span>
            )}
            <label
              onClick={() => refCardCvcElement?.focus()}
              className={`input__label ${
                (!stateInputStripe.cardCvc.isEmpty &&
                  !stateInputStripe.cardCvc.isValid) ||
                stateInputStripe.cardCvc.errorMessage
                  ? 'label--error'
                  : ''
              } ${
                !stateInputStripe.cardCvc.isEmpty ? 'input__label--value' : ''
              }`}
            >
              CVC
              <img src={Images.inputRequired} alt="Campo requerido" />
            </label>
            <div className="container__button__delete">
              {!stateInputStripe.cardCvc.isEmpty && (
                <ButtonDelete
                  isDisabled={false}
                  error={!stateInputStripe.cardCvc.isValid}
                  onClick={() => refCardCvcElement?.clear()}
                />
              )}
            </div>
          </div>
        </div>
        {props.checkBoxNameControl && (
          <div className="payment__method__stripe__container__checkbox">
            <Checkbox
              id="mark-as-main-method"
              name={props.checkBoxNameControl}
              onChange={
                props.handleChangeCheck
                  ? props.handleChangeCheck
                  : () => {
                      return;
                    }
              }
              checked={props.checkBoxValue}
            >
              Marca como método principal
            </Checkbox>
          </div>
        )}
      </Elements>
    </div>
  );
});

PaymentMethodStripe.displayName = 'PaymentMethodStripe';
