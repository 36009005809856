import { useColorHook } from 'hooks/Color.hook';
import { IPropsIcon } from './icons.interface';

export const PersonPhoneSvg = (props: IPropsIcon) => {
  const color = useColorHook(props.color);
  return (
    <svg
      width="100%"
      height="100%"
      className={props.className}
      viewBox="0 0 89 89"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M44.4999 42.7887C49.2205 42.7887 53.056 38.9617 53.056 34.2327H35.9438C35.9438 38.9533 39.7709 42.7887 44.4999 42.7887Z"
          fill={color}
        />
        <path
          d="M44.5 0C19.9276 0 0 19.9276 0 44.5C0 69.0724 19.9276 89 44.5 89C69.0724 89 89 69.0724 89 44.5C89 19.9276 69.0808 0 44.5 0ZM52.6093 43.0332C50.3923 45.0732 47.5178 46.2028 44.5 46.2028C41.4822 46.2028 38.6077 45.0816 36.3907 43.0332C33.9377 40.7741 32.5299 37.5624 32.5299 34.2327C32.5299 27.6323 37.8996 22.2626 44.5 22.2626C51.1004 22.2626 56.4701 27.6323 56.4701 34.2327C56.4701 37.5624 55.0623 40.7741 52.6093 43.0332ZM34.8565 46.2618C37.5877 48.4535 40.968 49.6505 44.5 49.6505C48.032 49.6505 51.4123 48.4535 54.1435 46.2618C59.1423 48.1416 63.4414 51.4039 66.6193 55.7536C70.0586 60.4573 71.8794 66.0377 71.8794 71.8879V75.1333C68.8363 77.856 65.3886 80.1236 61.6374 81.8517V59.9094C61.6374 57.0686 59.3277 54.7589 56.4869 54.7589H32.5215C29.6807 54.7589 27.371 57.0686 27.371 59.9094V81.8517C23.6198 80.1236 20.1721 77.856 17.129 75.1333V71.8879C17.129 66.0377 18.9498 60.4573 22.3891 55.7536C25.5671 51.4039 29.8662 48.1332 34.8649 46.2618H34.8565ZM75.3187 71.6603C75.2681 65.161 73.2282 58.9568 69.4011 53.722C66.122 49.2374 61.7807 45.7729 56.7482 43.598C58.7882 40.9258 59.9178 37.6299 59.9178 34.2327C59.9178 25.7357 53.0055 18.8149 44.5 18.8149C35.9945 18.8149 29.0822 25.7272 29.0822 34.2327C29.0822 37.6299 30.2202 40.9258 32.2518 43.598C27.2193 45.7729 22.878 49.2374 19.5989 53.722C15.7718 58.9652 13.7234 65.161 13.6813 71.6603C7.30006 64.4192 3.42243 54.9106 3.42243 44.5C3.42243 21.8159 21.8159 3.42243 44.5 3.42243C67.1841 3.42243 85.5776 21.8159 85.5776 44.5C85.5776 54.9106 81.6999 64.4192 75.3187 71.6603Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
