import {
  createAccountAction,
  TypeActionsCreateAccount,
} from '../actions/create-account.types';
import { initalStateCreateAccount } from '../state/create-account.state';

export const createAccountReducer = (
  state = initalStateCreateAccount,
  action: createAccountAction
) => {
  switch (action.type) {
    case TypeActionsCreateAccount.saveInformation:
      return {
        ...state,
        ...action.data,
      };
    case TypeActionsCreateAccount.resetInformation:
      return initalStateCreateAccount;
    default:
      return state;
  }
};
