import { CardBody } from 'components/atoms/Card-body/Card-body';
import './Item-periodicity.scss';
import { ItemPeriodicityType } from 'components/molecules/Item-periodicity-type/Item-periodicity-type';
import { PeriodicityTypesOptions } from 'pages/Checkout/models/Payment-subscription.interface';
import { formatPrice } from 'utils/payments.utils';
import { ItemDiscount } from 'components/molecules/Item-discount/Item-discount';
import { PeriodicityOptions } from 'models/periodicity.interface';
import { isPromoActive } from 'utils/pricing.utils';

export interface IPropsItemPeriodicity {
  onClick: (element: PeriodicityTypesOptions) => void;
  branch: string;
  subTotal: number;
  total: number;
  otherTotal: number;
  subOtherTotal: number;
  discount: number;
  otherDiscount: number;
  periodicity: PeriodicityTypesOptions;
  options: PeriodicityTypesOptions[];
  templete?: 'outline' | 'default';
}

export const ItemPeriodicity = (props: IPropsItemPeriodicity) => {
  const getPercentage = (item: PeriodicityTypesOptions): number => {
    const valueTotal =
      item === PeriodicityOptions.ANUAL ? props.subTotal : props.subOtherTotal;
    const valueDiscount =
      item === PeriodicityOptions.ANUAL
        ? valueTotal - props.total
        : valueTotal - props.otherTotal;
    const value = (100 * valueDiscount) / valueTotal;
    return !isPromoActive ? value : Number(value.toString().split('.')[0]);
  };

  return (
    <CardBody templete={props.templete}>
      <h5 className="item__periodicity__title">Forma de pago</h5>
      <div className="item__periodicity__options">
        {props.options.map((item, index) => (
          <div key={`option-${index}`} className="item__periodicity__option">
            <ItemPeriodicityType
              key={`radio-${index}`}
              label={item}
              isSelected={props.periodicity === item}
              onClick={() => props.onClick(item)}
              price={
                item === PeriodicityOptions.ANUAL
                  ? formatPrice(props.total, props.branch)
                  : formatPrice(props.otherTotal, props.branch)
              }
            ></ItemPeriodicityType>
            {getPercentage(item) ? (
              <div className="item__periodicity__discount">
                <ItemDiscount value={getPercentage(item)}></ItemDiscount>
              </div>
            ) : (
              ' '
            )}
          </div>
        ))}
      </div>
    </CardBody>
  );
};
