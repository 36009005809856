import { useFormik } from 'formik';
import { useState } from 'react';
import { IFormAuth, IFormAuthErrors } from '../models/Auth.interface';
import { useLocation, useNavigate, useParams } from 'react-router';
import * as _authService from '../services/Auth.service';
import { setStateError } from 'store/actions/app.types';
import { useDispatch } from 'react-redux';
import { setCreateAccountInformation } from 'pages/Create-account/store/actions/create-account.types';
import { getCountryForPlan } from 'shared/services/plans';
import { setBranch } from 'store/actions/branch.types';
import * as utils from 'utils/branch.utils';

export const UseAuthValidation = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [recaptcha, setRecaptcha] = useState<any | null>(null);
  const { planId } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { search } = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(search);
  const emailParams = params.get('email') as string;
  const handleLoading = async () => {
    setIsLoading((state) => !state);
  };
  const formAuthInitialValues: IFormAuth = {
    email: emailParams ?? '',
  };
  const formLoginValidate = (values: IFormAuth) => {
    const errors: IFormAuthErrors = {};
    if (!values.email) {
      errors.email = 'Este campo es requerido';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'No es un correo valido, debe tener @ y dominio';
    }
    return errors;
  };
  const validateEmail = async (email: string, authorizationToken: string) => {
    const response = await _authService.validateEmail(
      email,
      authorizationToken
    );
    if (response.userCreated) {
      navigate('/login', { state: { email: email } });
    } else {
      if (planId) {
        const country = await getCountryForPlan(Number(planId.split(',')[0]));
        dispatch(
          setCreateAccountInformation({
            plan_id: planId,
            country_id: country.id as string,
            indicator: utils.getPhoneIndicator(country.acronym),
          })
        );
        dispatch(setBranch(country.acronym));
      }
      navigate('/create-account', { state: { email: email } });
    }
    return response;
  };
  const validateCaptcha = async () => {
    await recaptcha.execute();
    const authorizationToken = recaptcha.getResponse();
    return authorizationToken;
  };
  const formAuth = useFormik({
    initialValues: formAuthInitialValues,
    validate: formLoginValidate,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const authorizationToken = await validateCaptcha();
        await validateEmail(values.email, authorizationToken);
      } catch (error) {
        dispatch(setStateError(true));
      } finally {
        setIsLoading(false);
      }
    },
  });
  return {
    validateEmail,
    isLoading,
    handleLoading,
    formAuth,
    setRecaptcha,
    planId
  };
};
