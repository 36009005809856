import { IAppReducer } from 'models/reducer.interface';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
interface IAuthGuardPros {
  children: JSX.Element;
  privateRoute: boolean;
}

export const AuthGuard = ({ children, privateRoute }: IAuthGuardPros) => {
  const user = useSelector((state: IAppReducer) => state.authReducer);
  if (privateRoute) {
    return user.uid ? children : <Navigate to="/login" />;
  } else {
    return user.uid ? <Navigate to="/settings" /> : children;
  }
};
