import {
  appSetErrorAction,
  TypeActionsSetState,
} from 'store/actions/app.types';
const appInitialState = {
  error: false,
  editProperty: { id: null },
  deleteProperty: { id: null },
};
export const appReducer = (
  state = { ...appInitialState },
  action: appSetErrorAction
) => {
  switch (action.type) {
    case TypeActionsSetState.setStateError:
      return {
        ...state,
        error: action.data,
      };
    case TypeActionsSetState.setStateEditProperty:
      return {
        ...state,
        editProperty: { id: action.data },
      };
    case TypeActionsSetState.setStateDeleteProperty:
      return {
        ...state,
        deleteProperty: { id: action.data },
      };
    case TypeActionsSetState.resetEditAndDeleteProperty:
      return {
        ...state,
        editProperty: { id: null },
        deleteProperty: { id: null },
      };
    default:
      return state;
  }
};
