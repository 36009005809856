import { IAuthFromGeneric } from 'components/templates/Auth-from-generic/models/Auth-from-generic.interface';

export const createProfileHeaderInformation: IAuthFromGeneric['headerInformation'] =
  {
    title: '¡Crea tu cuenta!',
    text: 'Únete y disfruta de una gestión sencilla y segura de tus servicios.',
  };
export const createProfileActiveBenefitsHeaderInformation: IAuthFromGeneric['headerInformation'] =
  {
    title: '¡Crea tu cuenta!',
    text: 'Te encuentras a pocos pasos de recibir tu descuento',
  };

export const createProfileStepper = {
  index: 1,
  steps: 2,
};
