export const enum TypeActionsSetBranch {
  setBranch = "[BRANCH APP] set branch",
}

export type appSetBranchAction = {
  type: TypeActionsSetBranch;
  data: string;
};

export const setBranch = (branch: string) => ({
  type: TypeActionsSetBranch.setBranch,
  data: branch,
});
