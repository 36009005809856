export const enum TypeActionsSetState {
  setStateError = '[APP] set state error',
  setStateEditProperty = '[APP] set state edit property',
  setStateDeleteProperty = '[APP] set state delete property',
  resetEditAndDeleteProperty = '[APP] set reset edit & delete property',
}

export type appSetErrorAction = {
  type: TypeActionsSetState;
  data: string;
};

export const setStateError = (error: boolean) => ({
  type: TypeActionsSetState.setStateError,
  data: error,
});

export const setStateEditProperty = (id: number) => ({
  type: TypeActionsSetState.setStateEditProperty,
  data: id,
});

export const setStateDeleteProperty = (id: number) => ({
  type: TypeActionsSetState.setStateDeleteProperty,
  data: id,
});

export const resetEditAndDeleteProperty = () => ({
  type: TypeActionsSetState.resetEditAndDeleteProperty,
});
