import { CheckoutRouter } from './routes/Checkout.routes';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IAppReducer } from 'models/reducer.interface';
import { HeaderWithSteps } from 'components/organisms/Header-with-steps/Header-with-steps';
import { IDetailStep } from 'models/info.steps.interface';
import { PopupExistProcess } from 'components/molecules/Popup-exist-porcess/Popup-exist-porcess';
import { useNavigate } from 'react-router';
import './Checkout.scss';
import { getContactInfo } from 'utils/connect-info.utils';
import { setCheckoutInformation } from './store/actions/checkout.types';
import { getUrlForCountry } from 'utils/branch.utils';
import { PopUpDropOff } from './components/PopUpDropOff/PopUpDropOff';
import { PopupFreemiun } from 'components/molecules/Popup-freemiun/Popup-freemiun';

export const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const connectWebAnchor = useRef<HTMLAnchorElement>(null);
  const {
    branchReducer: { branch },
    checkoutReducer: { accessToken, short_link },
  } = useSelector((state: IAppReducer) => state);
  const steps: IDetailStep[] = [
    {
      description: 'Confirma',
      state: 'active',
      link: '/checkout/orden',
      permissionToNavigate: false,
    },
    {
      description: 'Datos',
      state: 'disabled',
      link: '/checkout/contact-information',
      permissionToNavigate: false,
    },
    {
      description: 'Pago',
      state: 'disabled',
      link: '/checkout/payment-information',
      permissionToNavigate: false,
    },
    {
      description: '...',
      state: 'disabled',
      link: '',
      permissionToNavigate: false,
    },
  ];
  const stepsShortLink: IDetailStep[] = [
    {
      description: 'Pago',
      state: 'active',
      link: '',
      permissionToNavigate: false,
    },
    {
      description: 'Propiedades',
      state: 'disabled',
      link: '',
      permissionToNavigate: false,
    },
  ];
  const [step, setStep] = useState<IDetailStep[]>(
    short_link ? stepsShortLink : steps
  );
  const [popUp, setPopUp] = useState(false);
  const [isVisiblePopUpDropOff, setIsVisiblePopUpDropOff] = useState(false);
  const [isVisiblePopUpFreemium, setIsVisiblePopUpFreemium] = useState(false);
  const [typePopUp, setTypePopUp] = useState<'create' | 'other'>('create');

  const handleStep = (indexStep: number, typePopUp: 'create' | 'other') => {
    dispatch(
      setCheckoutInformation({
        step: indexStep,
      })
    );
    if (indexStep >= 3) {
      setStep([
        {
          description: 'Propiedades',
          state: 'active',
          link: '',
          permissionToNavigate: false,
        },
      ]);
    } else {
      step.forEach((element: IDetailStep, index: number) => {
        if (index === indexStep) {
          element.state = 'active';
          element.permissionToNavigate = true;
        } else if (indexStep > index) {
          element.state = 'completed';
          element.permissionToNavigate = true;
        } else {
          element.state = 'disabled';
          element.permissionToNavigate = false;
        }
      });
      setStep([...step]);
    }
    setTypePopUp(typePopUp);
  };

  const handlePopUp = () => {
    if (step[0].description !== 'Propiedades') {
      if (branch === 'pr') {
        setIsVisiblePopUpFreemium((status) => !status);
      } else {
        setIsVisiblePopUpDropOff((status) => !status);
      }
    } else {
      setPopUp((status) => !status);
    }
  };
  const handlePopUpstate = () => {
    setIsVisiblePopUpDropOff((status) => !status);
    setPopUp((status) => !status);
  };
  const showInfoPopUp = () => {
    setPopUp((status) => !status);
  };
  const exitProcess = () => {
    showInfoPopUp();
    if (accessToken || step[2].state === 'active') {
      navigate('/confirmation');
    } else {
      const url = getUrlForCountry(branch);
      if (connectWebAnchor.current) {
        connectWebAnchor.current.href = url;
        connectWebAnchor.current.click();
      }
    }
  };

  const getText = () => {
    if (typePopUp === 'create') {
      return {
        message: `Si es así, recuerde que deberá iniciar nuevamente desde el principio. Si necesita ayuda contacte a nuestros agentes de servicio al cliente al <strong>${
          getContactInfo(branch).phone
        } </strong>`,
      };
    } else {
      return {
        message:
          'Estás a punto de salir sin completar el formulario, recuerda que hasta no tener toda la información, no podrás solicitar el servicio de asistencia.',
      };
    }
  };
  return (
    <>
      <HeaderWithSteps
        navbarTemplate="light"
        steps={step}
        onClick={handlePopUp}
      />
      <section className="checkout__container">
        <CheckoutRouter
          handleStep={(index: number, typePopUp: 'create' | 'other') =>
            handleStep(index, typePopUp)
          }
        ></CheckoutRouter>
        <a
          ref={connectWebAnchor}
          className="checkout__link"
          rel="noreferrer"
        ></a>
      </section>
      {isVisiblePopUpFreemium && (
        <PopupFreemiun closePopUp={() => setIsVisiblePopUpFreemium(false)} />
      )}
      {isVisiblePopUpDropOff && (
        <PopUpDropOff
          closePopUp={() => setIsVisiblePopUpDropOff(false)}
          exitProcess={handlePopUpstate}
        />
      )}
      {popUp && (
        <PopupExistProcess
          title="¿Deseas salir?"
          message={getText().message}
          labelButton="Continuar"
          labelButtonCancel="Salir"
          type="warning"
          closePopUp={showInfoPopUp}
          exitProcess={exitProcess}
        />
      )}
    </>
  );
};
