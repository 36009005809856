import {
  loadStripe,
  Stripe,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import {
  ICheckouRequest,
  IFormErrorPaymentSubscription,
  IFormPaymentSubscription,
} from '../../../models/Payment-subscription.interface';
import { useFormik } from 'formik';
import { getKeyStripe } from 'utils/branch.utils';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IAppReducer } from 'models/reducer.interface';

import * as Analytics from 'helpers/segment.helper';
import { updateLead } from 'shared/services/lead.service';
import {
  makePayment,
  updateOrderId,
} from 'pages/Checkout/services/checkout.service';
import {
  resetCheckoutInformation,
  setCheckoutInformation,
} from 'pages/Checkout/store/actions/checkout.types';
import { v4 as uuidv4 } from 'uuid';
import { IInputFocusEvent } from 'components/molecules/Payment-method-dlocal/models/payment-method-dlocal.interface';
import { IProduct } from '../models/Payment-information.interface';
import { ICouponInformation } from 'components/organisms/Form-cupon/models/Form-cupon.interface';
import { getArrayForPlanIds } from 'utils/pricing.utils';
import { getContactInfo } from 'utils/connect-info.utils';
import { PeriodicityOptions } from 'models/periodicity.interface';
declare const dataLayer: any;
export const usePaymentInformation = (props: {
  handleStep: (index: number) => void;
  link: string;
}) => {
  const dlocalRef = useRef(null);
  const stripeRef = useRef(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [recaptcha, setCapcha] = useState<any | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { checkoutReducer, branchReducer } = useSelector(
    (state: IAppReducer) => state
  );
  const [stripeLoad, setStripeLoad] = useState<Stripe | null>(null);
  const cartCheckoutRef =
    useRef<{
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      sendOrderCompleted: (order: any) => any;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      saveOrderInformation: () => any;
      resetCopunInfo: () => void;
      getCoupon: (coupon: ICouponInformation) => void;
    }>(null);
  const formCouponRef =
    useRef<{
      validateCoupon: (
        planIds: number[],
        coupon: ICouponInformation,
        isOnlyCalculate: boolean
      ) => Promise<void>;
    }>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [errorPayment, setErrorPayment] = useState<any>(null);

  const [popUp, setPopUp] = useState<{
    showModal: boolean;
    title: string;
    message: string;
    labelButton: string;
  }>({
    showModal: false,
    title: '¡Alto!',
    message:
      '<strong>Está por dejar el proceso incompleto.</strong> Recuerda que al al dejarlo incompleto deberá iniciarlo nuevamente cuando regreses.',
    labelButton: 'Continuar',
  });

  const [phoneBranch, setPhoneBranch] = useState<string>('');

  useLayoutEffect(() => {
    const phone = getContactInfo(branchReducer.branch).phone;
    setPhoneBranch(phone);
  }, [branchReducer.branch]);

  useLayoutEffect(() => {
    const loadStripeElement = async () => {
      const stripeKey = getKeyStripe(branchReducer.branch);
      const stripe = (await loadStripe(stripeKey)) as Stripe;
      setStripeLoad(stripe);
    };
    loadStripeElement();
    props.handleStep && props.handleStep(2);
  }, [setStripeLoad]);

  useEffect(() => {
    (async () => {
      const dataCheckoutStepVviewed = {
        step: 3,
        order_id: checkoutReducer.order?.id as string,
      };
      Analytics.checkoutStepVviewed(dataCheckoutStepVviewed);
    })();
  }, [Analytics.checkoutStepVviewed]);

  const validateCaptcha = async () => {
    try {
      await recaptcha.execute();
      const token = recaptcha.getResponse();
      return token;
    } catch (error) {
      console.log('error', error);
    }
  };

  const formPaymentSubscriptionInitialValues: IFormPaymentSubscription = {
    plan_id: checkoutReducer.plan_id as string,
    products: [],
    card_token: '',
    coupon: '',
    country_id: checkoutReducer.country_id as number,
    card_name: '',
    cardNumber: '',
    cardExpiry: '',
    cardCvc: '',
  };

  const formPaymentSubscriptionValidate = (
    values: IFormPaymentSubscription
  ) => {
    const errors: IFormErrorPaymentSubscription = {};

    if (!values.card_name) {
      errors.card_name = 'Este campo es requerido';
    }
    if (!values.cardNumber) {
      errors.cardNumber = 'Este campo es requerido';
    }
    if (!values.cardExpiry) {
      errors.cardExpiry = 'Este campo es requerido';
    }
    if (!values.cardCvc) {
      errors.cardCvc = 'Este campo es requerido';
    }
    return errors;
  };

  const formPaymentSubscription = useFormik({
    initialValues: formPaymentSubscriptionInitialValues,
    validate: formPaymentSubscriptionValidate,
    onSubmit: async (values: IFormPaymentSubscription) => {
      try {
        setErrorPayment(null);
        if (
          branchReducer.branch === 'pr' ||
          (
            checkoutReducer?.products as IProduct[]
          )[0].attributes?.company.data.attributes.formToShow.toLowerCase() ===
            'stripe'
        ) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          values.card_token = await (stripeRef?.current as any).getTokenCard();
        } else {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          values.card_token = await (dlocalRef?.current as any).getTokenCard();
        }
        values.country_id =
          (checkoutReducer.country_id as number) ||
          (branchReducer.country_id as number);
        const dataCartCheckout =
          cartCheckoutRef.current?.saveOrderInformation();
        const tokenCaptcha = await validateCaptcha();
        const dataRequestsubscriptionAdded: ICheckouRequest = {
          ...values,
          referrerId: checkoutReducer.referrerId,
          company: checkoutReducer.company?.toLocaleLowerCase() as string,
          orderId: checkoutReducer.order?.id as string,
          plan_id: dataCartCheckout.order?.dataProducts.plan_id as string,
          coupon: dataCartCheckout.resumen.discountCoupon
            ? checkoutReducer.order?.dataOrderCompleted.coupon?.coupon_code
            : '',
          products: [...(dataCartCheckout?.order?.dataProducts.products as [])],
          cardNumber: undefined,
          cardExpiry: undefined,
          cardCvc: undefined,
          agentId: checkoutReducer.agentId,
          agentName: checkoutReducer.agentName as string,
          subTotalEmail: !checkoutReducer.order?.dataOrderCompleted?.products[0]
            ?.profit_price
            ? dataCartCheckout.resumen.subTotal
            : dataCartCheckout.resumen.subTotal -
              checkoutReducer.order?.dataOrderCompleted?.products[0]
                ?.profit_price *
                checkoutReducer.order?.dataOrderCompleted?.products[0]
                  ?.quantity,
          totalEmail: checkoutReducer.resumen?.discountForFreeTrial
            ? dataCartCheckout.resumen.price -
              dataCartCheckout.resumen.discountForFreeTrial
            : dataCartCheckout.resumen.price,
          discountForPeriodicity:
            (dataCartCheckout.resumen.discount as number) +
            (dataCartCheckout.resumen.discountForFreeTrial as number),
          discountForCoupon:
            (dataCartCheckout.resumen.discountCoupon as number) || 0,
          userData: {
            name: checkoutReducer.name as string,
            identification: checkoutReducer.documentNumber as string,
            lastname: checkoutReducer.lastname as string,
            email: checkoutReducer.email?.toLocaleLowerCase() as string,
            phone: checkoutReducer.phone as string,
            zipCode: checkoutReducer.zipCode as string,
            address: '',
            password: uuidv4(),
            indicator: checkoutReducer.indicator as string,
            district: checkoutReducer.district,
            province: checkoutReducer.province,
            canton: checkoutReducer.canton,
          },
        };
        const responseMakePayment = await makePayment(
          dataRequestsubscriptionAdded,
          tokenCaptcha
        );

        const updateData = {
          _id: checkoutReducer.funelId,
          branch: branchReducer.branch,
          status: 'sold',
        };

        cartCheckoutRef.current?.sendOrderCompleted({
          ...dataCartCheckout,
          utms: checkoutReducer.utms,
          order_id: checkoutReducer.order?.id as string,
        });
        updateLead(updateData);
        // Obliga para crear propiedades
        const arrUserPlansAdded = responseMakePayment.data.map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (p: { plan_id: any; id: number; attributes: any }) => p.plan_id.id,
          []
        );
        const checkoutStepCompleted = {
          step: 3,
        };
        Analytics.checkoutStepCompleted(checkoutStepCompleted);
        dispatch(
          setCheckoutInformation({
            id_user_product: responseMakePayment.data[0].id,
            referenceId: responseMakePayment.data[0].referenceId,
            user_plans_added: arrUserPlansAdded,
            auth_id: responseMakePayment.user.auth_id,
            accessToken: responseMakePayment.jwt,
          })
        );
        await updateOrderId({
          orderId: checkoutReducer.order?.id as string,
          email: checkoutReducer.email?.toLocaleLowerCase() as string,
          phone: checkoutReducer.phone as string,
          country: Number(values.country_id),
          plans: checkoutReducer.plan_id as string,
          raw_data: {
            ...checkoutReducer.order?.dataOrderCompleted,
            ...checkoutReducer.order?.dataProducts,
          },
        });
        (dataLayer as any).push({
          event: 'purchase',
          transaction_id: checkoutReducer.order?.id as string,
          value: dataRequestsubscriptionAdded.totalEmail,
          currency: branchReducer.branch === 'co' ? 'COP' : ' USD',
          coupon: checkoutReducer.order?.dataOrderCompleted.coupon?.coupon_code,
          items: checkoutReducer.order?.dataOrderCompleted.products.map(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (p: any, index: number) => {
              return {
                item_id: p.product_id,
                item_name: p.name + '|' + p.category,
                discount:
                  p.price * p.quantity -
                  (p.quantity >= p.maxAdditional ? p.adicionalPrice : p.price) *
                    p.quantity,
                index,
                item_brand: p.alias_nam,
                item_category: p.category,
                price:
                  p.quantity >= p.maxAdditional ? p.adicionalPrice : p.price,
                quantity: p.quantity,
              };
            },
            []
          ),
        });
        props.handleStep && props.handleStep(3);
        navigate(props.link);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        setErrorPayment(error);
        window.scrollTo({ behavior: 'smooth', top: 0 });
      } finally {
        formPaymentSubscription.setSubmitting(false);
      }
    },
  });

  const changeValueInputStripe = (
    event:
      | StripeCardNumberElementChangeEvent
      | StripeCardExpiryElementChangeEvent
      | StripeCardCvcElementChangeEvent
      | IInputFocusEvent
  ) => {
    let value = '';
    if (event.complete) {
      value = 'complete';
    }
    let element: 'cardCvc' | 'cardExpiry' | 'cardNumber';
    if ('elementType' in event) {
      element = event.elementType;
    } else {
      element = event.element;
    }
    formPaymentSubscription.setFieldValue(element, value);
    formPaymentSubscription.values[element] = value;
    if (
      formPaymentSubscription.values.cardNumber === 'complete' &&
      formPaymentSubscription.values.cardCvc === 'complete' &&
      formPaymentSubscription.values.cardExpiry === 'complete'
    ) {
      Analytics.paymentInfoEntered(checkoutReducer.order?.id as string);
    }
  };

  const showInfoPopUp = () => {
    popUp.showModal = !popUp.showModal;
    setPopUp({ ...popUp });
  };

  const exitProcess = () => {
    dispatch(resetCheckoutInformation());
    showInfoPopUp();
    navigate('/settings/payments');
  };

  const onCartCheckoutChanged = async (
    planIds: number[],
    isOnlyCalculate = false
  ) => {
    await formCouponRef?.current?.validateCoupon(
      planIds,
      checkoutReducer.order?.dataOrderCompleted.coupon as ICouponInformation,
      isOnlyCalculate
    );
  };
  const handleCouponInfo = (coupon?: null | ICouponInformation) => {
    dispatch(
      setCheckoutInformation({
        order: {
          ...checkoutReducer.order,
          dataOrderCompleted: {
            ...checkoutReducer.order?.dataOrderCompleted,
            coupon,
          },
        },
      })
    );
    if (!coupon) {
      cartCheckoutRef.current?.resetCopunInfo();
    } else {
      cartCheckoutRef.current?.getCoupon(coupon);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const planIDs = checkoutReducer?.order?.dataOrderCompleted.products?.map(
        (p: any) => {
          return { quantity: p.quantity, product_id: p.product_id };
        }
      ) as { quantity: number; product_id: number }[];
      const arrayPlanIds = getArrayForPlanIds(planIDs);
      onCartCheckoutChanged(arrayPlanIds, true);
    }
  };
  const options = {
    fonts: [
      {
        src: 'https://connect-assistant-public-assets.s3.amazonaws.com/connect/assets/font/Connect-Regular.otf',
        family: 'ConnectRegular',
      },
    ],
  };
  const addMonthsOrYears = () => {
    const result = new Date();
    if (
      checkoutReducer.order?.dataOrderCompleted.periodicity ===
      PeriodicityOptions.ANUAL
    ) {
      result.setFullYear(result.getFullYear() + 1);
    } else {
      result.setMonth(result.getMonth() + 1);
    }
    return result.toLocaleDateString();
  };

  return {
    phoneBranch,
    stripeLoad,
    cartCheckoutRef,
    stripeRef,
    dlocalRef,
    setCapcha,
    formCouponRef,
    handleCouponInfo,
    options,
    checkoutReducer,
    branchReducer,
    onCartCheckoutChanged,
    formPaymentSubscription,
    changeValueInputStripe,
    errorPayment,
    addMonthsOrYears,
    popUp,
    showInfoPopUp,
    exitProcess,
  };
};
