import { Button } from 'components/atoms/Button/Button';
import './Purchase-order.scss';
import {
  PopupExistProcess,
  TitleWhitImage,
  ItemList,
  CartCheckout,
  CartCheckoutSkeleton,
} from 'components';
import { Images } from 'assets/images/images.index';
import {} from 'components/atoms/Item-list/Item-list';
import { getContactInfo } from 'utils/connect-info.utils';
import { ICompany } from './models/Purchase-order.interface';
import { usePurchaseOrder } from './hooks/usePurchaseOrder';
import { PopupRequiredValidation } from './components/Popup-required-validation/Popup-required-validation';
import { PopupRequiredValidationFake } from './components/Popup-required-validation-fake/Popup-required-validation-fake';

export const PurchaseOrder = (props: {
  handleStep: (index: number) => void;
  link: string;
  quantityForPlan?: number[];
}) => {
  const {
    cartCheckoutRef,
    formPaymentSubscription,
    allProducts,
    branch,
    planItemsQuantity,
    checkoutReducer,
    requireValidation,
    popUp,
    showInfoPopUp,
    exitProcess,
    plandIsValidated,
  } = usePurchaseOrder({ ...props });

  return (
    <>
      <form
        className="purchase__order__container container__generic"
        onSubmit={formPaymentSubscription.handleSubmit}
        noValidate
      >
        <div className="purchase__order__padding">
          <TitleWhitImage
            title="Elige el tipo de <br />asistencia"
            image={Images.vanSrConnect}
            styleInLineImage={{ width: '6.25rem', height: '6.25rem' }}
          ></TitleWhitImage>
        </div>
        <div className="purchase__order__content">
          {allProducts ? (
            <CartCheckout
              branch={branch}
              planItemsQuantity={planItemsQuantity}
              allProducts={allProducts}
              orderId={checkoutReducer.order?.id as string}
              coupon={checkoutReducer.order?.dataOrderCompleted?.coupon}
              ref={cartCheckoutRef}
              templete="default"
              agentId={checkoutReducer.short_link_properties?.agentId || ''}
              companyName={checkoutReducer.company || ''}
            ></CartCheckout>
          ) : (
            <CartCheckoutSkeleton />
          )}
        </div>
        <div className="purchase__order__container__button">
          <Button
            color="primary"
            size="medium"
            template="primary"
            typeStyle="rounded"
            type="button"
            label="Continuar"
            onClick={formPaymentSubscription.handleSubmit}
            isDisabled={!formPaymentSubscription.isValid || requireValidation}
            isLoading={formPaymentSubscription.isSubmitting}
            testid="btn__buy__plan"
          ></Button>
        </div>
        <div className="purchase__order__container__list">
          {branch !== 'cr' && (
            <ItemList>
              Recibe descuentos añadiendo{' '}
              <strong className="purchase__order__list--bold">
                2 o más propiedades.
              </strong>
            </ItemList>
          )}
          <ItemList>
            ¿Necesitas suscribir{' '}
            {checkoutReducer.order?.dataOrderCompleted?.products.length
              ? checkoutReducer.order?.dataOrderCompleted?.products[0]?.maxCount
              : '5'}{' '}
            o más propiedades?{' '}
            <a
              className="purchase__order__list__link"
              href={`tel:${getContactInfo(branch).phone}`}
              target="_blank"
              rel="noreferrer"
            >
              Contáctanos
            </a>
          </ItemList>
        </div>
      </form>
      {popUp.showModal && (
        <PopupExistProcess
          title={popUp.title}
          message={popUp.message}
          labelButton={popUp.labelButton}
          labelButtonCancel="Salir"
          type="warning"
          closePopUp={showInfoPopUp}
          exitProcess={exitProcess}
        />
      )}
      {requireValidation && checkoutReducer.company === ICompany.ruedaz && (
        <PopupRequiredValidation
          message={`Ingresa la placa y documento registrado en ${checkoutReducer.company} para acceder al beneficio`}
          onFormIsValid={plandIsValidated}
        ></PopupRequiredValidation>
      )}
      {requireValidation &&
        checkoutReducer.company &&
        checkoutReducer.company !== ICompany.ruedaz && (
          <PopupRequiredValidationFake
            message={`Ingresa tu código de validación PARA CONTINUAR`}
            onFormIsValid={plandIsValidated}
          ></PopupRequiredValidationFake>
        )}
    </>
  );
};
