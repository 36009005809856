import './Button-delete.scss';

export interface IPropsButtonDelete {
  error: boolean | '' | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (e?: any) => void;
  isDisabled?: boolean;
}

export const ButtonDelete = (props: IPropsButtonDelete) => {
  return (
    <button
      type="button"
      className={`button__delete ${props.error ? 'btn--error' : ''}${
        props.isDisabled ? 'button__delete--disabled' : ''
      }`}
      onClick={props.onClick}
    >
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="18" height="18" rx="9" fill="#001D3D" />
        <path
          d="M6.3865 5.75949L12.2406 11.6136L11.6134 12.2408L5.75928 6.38672L6.3865 5.75949Z"
          fill="white"
        />
        <path
          d="M5.75928 11.6133L11.6134 5.75919L12.2406 6.38642L6.3865 12.2405L5.75928 11.6133Z"
          fill="white"
        />
      </svg>
    </button>
  );
};
