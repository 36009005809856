import {
  GenericSectionError,
  ServicesHistorySkeleton,
  ServicesHistory as ServicesHistoryComponent,
  HeaderWithAlert,
} from 'components';
import { useServicesHistory } from './hooks/useServicesHistory';
import './Services-history.scss';

export const ServicesHistory = () => {
  const { steps, navigate, servicesHistory, branch, user } =
    useServicesHistory();
  return (
    <>
      <HeaderWithAlert
        steps={steps}
        onClick={() => {
          navigate('/settings');
        }}
      ></HeaderWithAlert>
      <div className="properties__title__container">
        <h6>Historial servicios</h6>
        <p>Revisa tu historial de servicios, facturas y demás.</p>
      </div>
      <div className="history__container">
        {servicesHistory ? (
          <div className="payments__content not--padding">
            <ServicesHistoryComponent
              branch={branch}
              data={servicesHistory}
              infoIsHidden={true}
              userType={
                user.user_type && !user.agentId ? user.user_type : undefined
              }
            ></ServicesHistoryComponent>
          </div>
        ) : servicesHistory === undefined ? (
          <ServicesHistorySkeleton />
        ) : (
          <div className="payments__content">
            <GenericSectionError template="tertiary" />
          </div>
        )}
      </div>
    </>
  );
};
