import { Checkbox } from 'components/atoms/Checkbox/Checkbox';
import { Input } from 'components/atoms/Input/Input';
import './Personal-information.scss';
import { useSelector, shallowEqual } from 'react-redux';
import { Select } from 'components/atoms/Select/Select';
import { IAppReducer } from 'models/reducer.interface';
import * as personalInformationConstants from './constants/Personal-information.constants';
import { getContactInfo } from 'utils/connect-info.utils';
import { AuthFromGeneric } from 'components/templates/Auth-from-generic/Auth-from-generic';
import { createProfileActiveBenefitsHeaderInformation, createProfileHeaderInformation } from '../Create-profile/Constant/Create-profile.constant';
import { UsePersonalInformationValidation } from './hooks/Personal-information.hook';

export const PersonalInformation = () => {
  const {
    createAccountReducer,
    formPersonalInformation,
    handleFocus,
    countryAdministrativeDivision,
    handleOptionCountry,
    handleOptionCanton,
    getZipCodeInfo,
    handleOptionProvince,
    handleChangeCheckTerms,
    handleOptionDistrict,
  } = UsePersonalInformationValidation();

  const {
    branchReducer: { branch },
  } = useSelector((state: IAppReducer) => state, shallowEqual);

  return (
    <AuthFromGeneric
      labelPrimaryAction="Continuar"
      headerInformation={
        createAccountReducer.plan_id
          ? createProfileActiveBenefitsHeaderInformation
          : createProfileHeaderInformation
      }
      isLoading={formPersonalInformation.isSubmitting}
      handleClick={formPersonalInformation.handleSubmit}
      stepper={personalInformationConstants.personalInformationStepper}
    >
      <Input
        label="Nombre"
        id="personal-information-name"
        type="text"
        name="name"
        onChange={formPersonalInformation.handleChange}
        onBlur={(event) => {
          handleFocus(event);
        }}
        value={formPersonalInformation.values.name}
        isTouched={formPersonalInformation.touched.name}
        errorInput={formPersonalInformation.errors.name}
        errorLabel={formPersonalInformation.errors.name}
        isRequired={true}
        form={formPersonalInformation}
      />
      <Input
        label="Apellido"
        id="personal-information-lastname"
        type="text"
        name="lastname"
        onChange={formPersonalInformation.handleChange}
        onBlur={(event) => {
          handleFocus(event);
        }}
        value={formPersonalInformation.values.lastname}
        isTouched={formPersonalInformation.touched.lastname}
        errorInput={formPersonalInformation.errors.lastname}
        errorLabel={formPersonalInformation.errors.lastname}
        isRequired={true}
        form={formPersonalInformation}
      />
      <Select
        id="personal-information-country"
        placeholder="País"
        value={formPersonalInformation.values.country_id}
        name="country"
        onClick={handleOptionCountry}
        options={personalInformationConstants.countryOptions}
        isRequired={true}
        errorSelect={formPersonalInformation.errors.country_id}
        onBlur={formPersonalInformation.handleBlur}
        isTouched={formPersonalInformation.touched.country_id}
        isDisabled={createAccountReducer.plan_id ? true : false}
      />
      <Input
        label="Teléfono"
        id="personal-information-phone"
        type="tel"
        name="phone"
        onChange={formPersonalInformation.handleChange}
        onBlur={formPersonalInformation.handleBlur}
        value={formPersonalInformation.values.phone}
        isTouched={formPersonalInformation.touched.phone}
        errorInput={formPersonalInformation.errors.phone}
        errorLabel={formPersonalInformation.errors.phone}
        isRequired={true}
        tooltip={
          'Este número lo vamos a utilizar para contactarte en caso de una emergencia o información importante de tu cuenta'
        }
        prefix={`(${formPersonalInformation.values.indicator})`}
      />
      {branch === 'pr' && (
        <Input
          WrapperClassName="grid__span-3"
          label="Código Postal"
          id="personal-information-zipCode"
          type="tel"
          name="zipCode"
          onChange={getZipCodeInfo}
          onBlur={formPersonalInformation.handleBlur}
          value={formPersonalInformation.values.zipCode}
          isTouched={formPersonalInformation.touched.zipCode}
          errorInput={formPersonalInformation.errors.zipCode}
          errorLabel={formPersonalInformation.errors.zipCode}
          isRequired={true}
          onClickDeleteAction={() => handleOptionDistrict('')}
        />
      )}
      {!['pr', 'pty', 'mx'].includes(branch) && (
        <Select
          WrapperClassName="grid__span-3"
          id="personal-information-province"
          placeholder={personalInformationConstants.getLabels(branch)?.province}
          value={formPersonalInformation.values.province}
          name="province"
          onClick={(event) => handleOptionProvince(event)}
          options={countryAdministrativeDivision.provinces}
          errorSelect={formPersonalInformation.errors.province}
          isRequired={true}
          isTouched={formPersonalInformation.touched.province}
          onBlur={formPersonalInformation.handleBlur}
        />
      )}
      {['cr'].includes(branch) && (
        <Select
          WrapperClassName="grid__span-3"
          id="personal-information-canton"
          placeholder="Cantón"
          value={formPersonalInformation.values.canton}
          name="canton"
          onClick={(event) => handleOptionCanton(event)}
          options={countryAdministrativeDivision.cantons}
          errorSelect={formPersonalInformation.errors.canton}
          isTouched={formPersonalInformation.touched.canton}
          isRequired={true}
          onBlur={formPersonalInformation.handleBlur}
        />
      )}
      {!['pty', 'mx'].includes(branch) && (
        <Select
          WrapperClassName="grid__span-3"
          id="personal-information-district"
          placeholder={personalInformationConstants.getLabels(branch)?.district}
          value={formPersonalInformation.values.district}
          name="district"
          onClick={handleOptionDistrict}
          options={countryAdministrativeDivision.districts}
          errorSelect={formPersonalInformation.errors.district}
          isTouched={formPersonalInformation.touched.district}
          isDisabled={branch === 'pr'}
          isRequired={true}
          onBlur={formPersonalInformation.handleBlur}
        />
      )}
      {!['pr', 'pty', 'mx'].includes(branch) && (
        <Input
          label="Cédula Personal"
          id="personal-information-documentNumber"
          type="tel"
          name="documentNumber"
          onChange={formPersonalInformation.handleChange}
          onBlur={handleFocus}
          value={formPersonalInformation.values.documentNumber}
          isTouched={formPersonalInformation.touched.documentNumber}
          errorInput={formPersonalInformation.errors.documentNumber}
          errorLabel={formPersonalInformation.errors.documentNumber}
          isRequired={true}
          form={formPersonalInformation}
        />
      )}
      <div className="container__btns__checkbox">
        <Checkbox
          id="accept-terms"
          name="acceptTerms"
          onChange={handleChangeCheckTerms}
          checked={formPersonalInformation.values.acceptTerms}
        >
          <a
            className="btns__checkbox--link"
            href={`${getContactInfo(branch).url_terms}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Acepto los términos y condiciones de Connect
          </a>
        </Checkbox>
      </div>
    </AuthFromGeneric>
  );
};
