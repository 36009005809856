import { ICard } from 'models/card.interface';
import cms from './cms';
import { IPreSales } from 'pages/Short-link/models/short-link.interface';

/**
 * Function to get all products
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function addUserProperty(propertyData: any) {
  try {
    const response = await cms.post('property-users', propertyData, {
      headers: { 'Content-Type': 'application/json' },
    });
    return parseInt(response.data.data.id);
  } catch (error) {
    console.log(error);
  }
}

/**
 * Function to delete a property user by id property
 * @param propertyId number
 * @returns
 */
export async function deleteUserProperty(
  propertyId: number | string,
  uid: string
) {
  const response = await cms.delete(`property-users/${propertyId}/${uid}`);
  return response.data.data;
}

/**
 * Function to update a user property
 * @param propertyId number
 * @param propertyData object
 * @returns object
 */
export async function updateUserProperty(
  propertyId: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  propertyData: any
) {
  const response = await cms.put(`property-users/${propertyId}`, propertyData);
  return response.data.data;
}

/**
 * Function to get all properties by user id
 * @param uuid string
 * @returns Object
 */
export async function getUserProperties(uuid: string) {
  const response = await cms.get(`property-users/user/${uuid}`);
  return response.data.data;
}

/**
 * Function to get all properties by user id
 * @param uuid string
 * @returns Object
 */
export async function getUserProperty(uuid: string, propertyId: string) {
  const response = await cms.get(`property-users/${propertyId}/${uuid}`);
  return response.data.data;
}

export async function getUserByUid(uuid: string) {
  const response = await cms.get('users/' + uuid);
  return response.data.data;
}

export async function getUserCards(uuid: string) {
  const response = await cms.get('users/cards/' + uuid);
  return response.data.data.attributes.data;
}

export async function deleteUserCard(uuid: string, cardId: string) {
  const response = await cms.delete(`users/cards/${uuid}/${cardId}`);
  return response.data.data;
}
export async function addUserCard(
  uuid: string,
  cardToken: string,
  isDefault: boolean
) {
  const response = await cms.post(`users/card/${uuid}`, {
    is_default: isDefault,
    card_token: cardToken,
  });
  return response.data.data;
}
export async function updateUserCard(uuid: string, cardId: any) {
  const response = await cms.put(`users/card/${uuid}`, { card_id: cardId });
  return response.data.data;
}
export async function getUserCard(uuid: string, cardId: string) {
  const response = await cms.get(`users/card/${uuid}/${cardId}`);
  return response.data.data;
}
export async function getResumeUserProperties(uuid: string) {
  const response = await cms.get(`property-users/resume/user/${uuid}`);
  return response.data;
}

export async function getUserServices(uid: string) {
  const response = await cms.post(`user-subscriptions/services/user/${uid}`);
  return response.data;
}

export async function getInfoPreSale(preSaleId: string): Promise<IPreSales> {
  const preSale = await cms.get(`users/presale/${preSaleId}`);
  const preSalesData = {
    ...preSale.data,
    properties: {
      autos: preSale.data.properties.autos,
      homes: preSale.data.properties.homes,
      motorcycles: preSale.data.properties.motorcycles,
    },
  };
  return preSalesData;
}

export async function impSales(email: string) {
  const response = await cms.post('users/impersonate', {
    email: email,
  });
  return response.data;
}
