import './Link.scss';
import * as CSS from 'csstype';

export interface IPropsLink {
  label?: string;
  onClick?: () => void;
  isDisabled?: boolean;
  type: 'primary' | 'secondary';
  styleInLine?: CSS.Properties<string | number>;
  image?: string;
  leftIcon?: boolean;
  rightIcon?: boolean;
  link?: string;
  size?: 's';
}

export const Link = (props: IPropsLink) => {
  return (
    <div
      className={`connect__link__container ${
        props.isDisabled ? 'disabled' : ''
      }${props.type ? props.type : ''}`}
    >
      {props.leftIcon && (
        <svg
          className={`connect__link__icon`}
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Icono">
            <path
              id="Vector (Stroke)"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.88672 11.563L20.8867 11.563L20.8867 13.563L4.88672 13.563L4.88672 11.563Z"
              className={`connect__link__icon--path`}
            />
            <path
              id="Vector (Stroke)_2"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.30101 12.5631L13.301 19.5631L11.8868 20.9773L4.17969 13.2702L4.17969 11.856L11.8868 4.14887L13.301 5.56308L6.30101 12.5631Z"
              className={`connect__link__icon--path`}
            />
          </g>
        </svg>
      )}
      {!props.link ? (
        <span
          className={`connect__link__label ${props.size ? 'small' : 'x-small'}`}
          onClick={props.onClick}
          style={props.styleInLine}
        >
          {props.label}
        </span>
      ) : (
        <a
          className={`connect__link__label`}
          style={props.styleInLine}
          href={props.link}
          target="_blank"
          rel="noreferrer"
        >
          {props.label}
        </a>
      )}
      {props.rightIcon && (
        <svg
          className={`connect__link__icon`}
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Icono">
            <path
              id="Vector (Stroke)"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.1797 13.5632L4.17969 13.5632L4.17969 11.5632L20.1797 11.5632L20.1797 13.5632Z"
              className={`connect__link__icon--path`}
            />
            <path
              id="Vector (Stroke)_2"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18.7654 12.5631L11.7654 5.56314L13.1796 4.14893L20.8867 11.856L20.8867 13.2702L13.1796 20.9774L11.7654 19.5631L18.7654 12.5631Z"
              className={`connect__link__icon--path`}
            />
          </g>
        </svg>
      )}
    </div>
  );
};
