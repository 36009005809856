import { useRoutes } from 'react-router-dom';
import { CreateAccountGuard } from '../guards/Create-account.guard';
import { PersonalInformation } from '../pages/Personal-information/Personal-information';
import { AuthGuard } from 'guards/auth.guard';
import { CreateProfile } from '../pages/Create-profile/Create-profile';
import { ActivateBenefits } from '../pages/Activate-benefits/Activate-benefits';
import { ConfirmationActivated } from '../pages/Confirmation-activated/Confirmation-activated';

export const CreateAccountRouter = () => {
  const element = useRoutes([
    {
      children: [
        {
          path: 'create-profile',
          element: (
            <CreateAccountGuard>
              <CreateProfile />
            </CreateAccountGuard>
          ),
        },
        {
          path: 'personal-information',
          element: (
            <CreateAccountGuard>
              <PersonalInformation />
            </CreateAccountGuard>
          ),
        },
        {
          path: 'activate-benefits',
          element: (
            <AuthGuard privateRoute={true}>
              <ActivateBenefits></ActivateBenefits>
            </AuthGuard>
          ),
        },
        {
          path: 'confirmation-activated',
          element: (
            <AuthGuard privateRoute={true}>
              <ConfirmationActivated />
            </AuthGuard>
          ),
        },
        {
          path: '/*',
          element: (
            <CreateAccountGuard>
              <CreateProfile />
            </CreateAccountGuard>
          ),
        },
      ],
    },
  ]);

  return element;
};
