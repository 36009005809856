import { Images } from 'assets/images/images.index';
import { IAppReducer } from 'models/reducer.interface';
import { shallowEqual, useSelector } from 'react-redux';
import { getContactInfo } from 'utils/connect-info.utils';
import './Generic-section-error.scss';

export interface IPropsGenericSectionError {
  template: 'primary' | 'secondary' | 'tertiary';
}
export const GenericSectionError = (props: IPropsGenericSectionError) => {
  const { branch } = useSelector(
    (state: IAppReducer) => state.branchReducer,
    shallowEqual
  );
  return (
    <div
      className={`generic__section__error__container generic__section__error__container--${props.template}`}
    >
      <div className="generic__section__error__content">
        <div className="generic__section__error__info">
          <p className="generic__section__error__title">
            Se ha producido un <br />
            error al cargar los datos
          </p>
          <div className="generic__section__error__title--small">
            Espere 5 minutos e intentelo de nuevo, o bien contacte
            <br />
            <a
              className="generic__section__error__link"
              href={`tel:${getContactInfo(branch).phone}`}
              target="_blank"
              rel="noreferrer"
            >
              servicio al cliente
            </a>
          </div>
        </div>
        <img
          className="generic__section__error__icon"
          src={Images.section_error_icon}
          alt="Icono item vacio"
        />
      </div>
    </div>
  );
};
