import { ICouponInformation } from 'components/organisms/Form-cupon/models/Form-cupon.interface';
import { PeriodicityOptions } from 'models/periodicity.interface';
import { PeriodicityTypesOptions } from 'pages/Checkout/models/Payment-subscription.interface';
import { IProduct } from 'pages/Checkout/pages/Payment-information/models/Payment-information.interface';

const calculateTotal = (
  products: IProduct[],
  dataSelects: number[],
  infoCoupon: ICouponInformation | undefined
) => {
  let subtotal = 0;
  let discount = 0;
  let total = 0;
  let multiple = 1;
  let couponDiscount = 0;
  const totalsForPlan: {
    price: number;
    priceByUnit: number;
  }[] = [];
  let discountPeriodicity = 0;
  const plansId: number[] = [];
  let priceForPlan = 0;
  let priceForRoad = 0;
  let productData;
  let periocityName: string;
  let discountForFreeTrial = 0;
  let priceByUnit = 0;
  let productPrice = 0;
  products.forEach((element, index: number) => {
    productData = element.attributes.productos.data[0].attributes;
    periocityName = productData.periocity_id.data.attributes.name;
    if (dataSelects[index]) {
      plansId[index] = element.id;
    }

    if (
      dataSelects[index] < productData.max_additional ||
      (productData.price_discount === 0 &&
        periocityName === PeriodicityOptions.ANUAL)
    ) {
      productPrice = productData.price;
    } else {
      productPrice = productData.additional_price;
    }

    if (periocityName === PeriodicityOptions.ANUAL) {
      multiple = 12;
    } else if (periocityName === PeriodicityOptions.SEMESTRAL) {
      multiple = 6;
    } else {
      multiple = 1;
    }
    priceByUnit = productPrice;
    subtotal +=
      productData.base_price * multiple * dataSelects[index] +
      (productData.is_addon
        ? (productData.profit_price as number) * dataSelects[index]
        : 0);
    discount += calculatePrice(
      productData.base_price,
      multiple,
      dataSelects[index],
      productPrice,
      element.id
    );
    discountForFreeTrial += productData.has_trial ? subtotal - discount : 0;

    couponDiscount += getCouponAmountForPlan(
      infoCoupon,
      productData.base_price * multiple,
      dataSelects[index],
      element.id
    );
    discountPeriodicity += calculatePrice(
      productData.base_price,
      multiple,
      dataSelects[index],
      productPrice,
      element.id
    );
    priceForPlan = calculatePriceForPlan(productPrice, dataSelects[index]);
    if (
      element.attributes.alias_name &&
      element.attributes.alias_name.toLocaleLowerCase().indexOf('carretera') >=
        0
    ) {
      priceForRoad += priceForPlan;
    }
    totalsForPlan[index] = { price: priceForPlan, priceByUnit: priceByUnit };
  });

  if (
    infoCoupon &&
    infoCoupon.amount !== undefined &&
    !infoCoupon.is_for_multiplan &&
    ((infoCoupon.is_anual && multiple === 12) ||
      (infoCoupon.is_mensual && multiple === 1) ||
      (infoCoupon.is_semestral && multiple === 6)) &&
    plansIsIncludes(infoCoupon, plansId)
  ) {
    couponDiscount = infoCoupon.is_percent
      ? ((subtotal - discount) * infoCoupon.amount) / 100
      : infoCoupon.amount;
    if (!infoCoupon.is_additional_discount) {
      couponDiscount = couponDiscount + discount;
      discount = 0;
    }
  }

  total = subtotal - discount - couponDiscount;

  const summaryPrices = {
    subtotal,
    discount,
    total,
    discountPeriodicity,
    totalsForPlan,
    couponAmount: couponDiscount,
    discountForFreeTrial,
    priceForRoad,
  };
  return summaryPrices;
};

const calculatePrices = (
  item: PeriodicityTypesOptions,
  itemsQuantity: number[],
  couponInfo: ICouponInformation | undefined,
  dataAllProducts: IProduct[]
) => {
  const otherProductsFiltered = dataAllProducts.filter((itemProductsFiltered) =>
    [
      PeriodicityOptions.MENSUAL,
      PeriodicityOptions.SEMESTRAL,
      PeriodicityOptions.Freemium,
    ].includes(
      itemProductsFiltered.attributes.periocidad.data.attributes
        .name as PeriodicityOptions
    )
  );
  const productsFiltered = dataAllProducts.filter(
    (itemProductsFiltered) =>
      itemProductsFiltered.attributes.periocidad.data.attributes.name ===
      PeriodicityOptions.ANUAL
  );

  if (itemsQuantity.length === 0) {
    const products = productsFiltered.length
      ? productsFiltered
      : otherProductsFiltered;
    itemsQuantity = Array.from(products, (x, index) => (index > 0 ? 0 : 1));
  }

  const otherDataPricing = calculateTotal(
    [...otherProductsFiltered],
    itemsQuantity,
    couponInfo
  );
  const dataPricing = calculateTotal(
    [...productsFiltered],
    itemsQuantity,
    couponInfo
  );

  return {
    productsPeriodicityAnual: { data: productsFiltered, prices: dataPricing },
    productsPeriodicityMensual: {
      data: otherProductsFiltered,
      prices: otherDataPricing,
    },
    itemsQuantity,
  };
};
const calculatePriceForPlan = (priceDiscount: number, dataSelect: number) => {
  return priceDiscount * dataSelect;
};
const calculatePrice = (
  basePrice: number,
  multiplo: number,
  dataSelect: number,
  priceDiscount: number,
  planId: number,
  infoCoupon?: ICouponInformation
) => {
  let priceFull = basePrice * multiplo * dataSelect;
  if (!priceDiscount) {
    priceFull = 0;
  }
  if (
    infoCoupon &&
    infoCoupon.is_for_multiplan &&
    dataSelect - infoCoupon.limit_quantity_for_plan <=
      infoCoupon.limit_quantity_for_plan &&
    planIdIsIncluded(infoCoupon, planId) &&
    ((multiplo === 12 && infoCoupon.is_anual) ||
      (multiplo === 1 && infoCoupon.is_mensual) ||
      (multiplo === 6 && infoCoupon.is_semestral))
  ) {
    return (
      priceFull -
      priceDiscount * dataSelect -
      getDiscountCouponForPlan(
        infoCoupon,
        priceFull - priceDiscount * dataSelect,
        planId
      )
    );
  } else {
    return priceFull - priceDiscount * dataSelect;
  }
};
const planIdIsIncluded = (
  infoCoupon: ICouponInformation,
  planId: number
): boolean => {
  const idPlans = infoCoupon.plans.data.map((p) => p.id);
  const idIsIncluded = idPlans.some((element) => element === planId);
  return idIsIncluded;
};
const plansIsIncludes = (
  infoCoupon: ICouponInformation,
  plansId: number[]
): boolean => {
  const idPlans = infoCoupon.plans.data.map((p) => p.id);
  const planIsInclude = idPlans.some((elemento) => plansId.includes(elemento));
  return planIsInclude;
};
const getArrayForPlanIds = (
  plans: { quantity: number; product_id: number }[]
) => {
  const idPlans = plans.filter((p) => p && p.quantity > 0);
  return idPlans.map((p) => p.product_id);
};
const getCouponAmountForPlan = (
  infoCoupon: ICouponInformation | undefined,
  subTotal: number,
  dataSelects: number,
  planId: number
) => {
  let price = 0;
  let quantity = 0;
  if (infoCoupon && infoCoupon.is_for_multiplan) {
    if (dataSelects <= infoCoupon.limit_quantity_for_plan) {
      quantity = dataSelects;
    } else {
      quantity = infoCoupon.limit_quantity_for_plan;
    }
    price = getDiscountCouponForPlan(infoCoupon, subTotal * quantity, planId);
  }
  return price;
};
const getDiscountCouponForPlan = (
  infoCoupon: ICouponInformation | undefined,
  subTotal: number,
  planId: number
) => {
  if (!infoCoupon || !Object.keys(infoCoupon).length) {
    return 0;
  }
  return planIdIsIncluded(infoCoupon, planId)
    ? getDiscountCoupon(infoCoupon, subTotal)
    : 0;
};
const getDiscountCoupon = (
  infoCoupon: ICouponInformation | undefined,
  subTotal: number
) => {
  if (!infoCoupon || !subTotal) {
    return 0;
  }
  const discount: number = infoCoupon.is_percent
    ? (subTotal * infoCoupon.amount) / 100
    : infoCoupon.amount;
  return discount || 0;
};

const getPlanIsFreeTrial = (dataOrder: any) => {
  let isFreeTrial = false;
  dataOrder.products.forEach((product: any) => {
    if (product.trialPeriod) {
      isFreeTrial = true;
    }
  });
  return isFreeTrial;
};

const getDiffAddon = (
  diffPrice: number,
  coupon: ICouponInformation
): number => {
  if (!coupon) return 0;
  if (coupon.is_percent) {
    const discount = diffPrice * (coupon.amount / 100);
    return diffPrice - discount;
  }
  return diffPrice - coupon.amount;
};

const isPromoActive = new Date().getTime() <= new Date(2023, 11, 31).getTime();
export {
  calculatePrices,
  calculateTotal,
  getArrayForPlanIds,
  getDiffAddon,
  getDiscountCoupon,
  getPlanIsFreeTrial,
  isPromoActive,
  plansIsIncludes,
};
