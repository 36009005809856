import { CustomerSupport } from 'components/molecules/Customer-support/Customer-support';
import { Images } from 'assets/images/images.index';
import './Generic-error.scss';
import { SettingsFooter } from 'components/molecules/Settings-footer/Settings-footer';
import { Button } from 'components/atoms/Button/Button';

export interface IPropsGenericError {
  onClick: () => void;
  message: string;
  title: string;
}

export const GenericError = (props: IPropsGenericError) => {
  return (
    <div className="generic__error__container">
      <div className="generic__error__content">
        <img
          className="generic__error__icon"
          src={Images.chipWarning}
          alt="Icono error"
        />
        <h3 className="generic__error__title">{props.title}</h3>
        <p className="generic__error__message">{props.message}</p>
        <Button
          className="generic__error__button"
          size="medium"
          template="primary"
          color="primary"
          typeStyle="square"
          type="button"
          isDisabled={false}
          onClick={props.onClick}
          isLoading={false}
        >
          Recargar
        </Button>
      </div>
      <CustomerSupport onClick={props.onClick} />
      <SettingsFooter />
    </div>
  );
};
