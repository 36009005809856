import { ReactNode } from 'react';
import './Popup-overlay.scss';

interface IPropsPopupOverlay {
  children: ReactNode;
  classContainer?: string;
}
export const PopupOverlay = (props: IPropsPopupOverlay) => {
  return (
    <div className="pop__up__overlay">
      <div
        className={`pop__up__container ${
          props.classContainer ? props.classContainer : ''
        }`}
      >
        {props.children}
      </div>
    </div>
  );
};
