import { useLocation, useNavigate } from 'react-router';
import { defaultFirebaseAuth } from 'config/firebase-config';
import { confirmPasswordReset } from 'firebase/auth';
import { useDispatch } from 'react-redux';
import { setResetPassword } from '../store/actions/auth.types';
import { setStateError } from 'store/actions/app.types';
import { CreatePassword } from 'components/templates/Create-password/Create-password';
import { AESEncryptDecryptService } from 'shared/services/aes-encrypt-decrypt.service';
import { newPasswordHeaderInformation } from './Constant/new-password.constant';
import './New-password.scss';

export const NewPassword = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = new URLSearchParams(search);
  const code = params.get('oobCode') as string;

  const handleConfirmPasswordFirmPasswordReset = async (newPassword: string) => {
    try {
      const textEncrypt = new AESEncryptDecryptService();
      await confirmPasswordReset(
        defaultFirebaseAuth,
        code,
        textEncrypt.decrypt(newPassword)
      );
      dispatch(setResetPassword(true));
      navigate('/login', { state: null });
    } catch (error) {
      dispatch(setStateError(true));
    }
  };

  return (
    <>
      <CreatePassword
        headerInformation={newPasswordHeaderInformation}
        handleChangePassword={handleConfirmPasswordFirmPasswordReset}
      ></CreatePassword>
    </>
  );
};
