import { useState } from 'react';
import { useNavigate } from 'react-router';

export const UseAuthFromGeneric = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const navigateToPage = (root: string) => {
    navigate(root);
  };

  return {
    isLoading,
    setIsLoading,
    navigateToPage,
  };
};
