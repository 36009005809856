import { IAppReducer } from 'models/reducer.interface';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';

interface ICheckoutGuardPros {
  children: JSX.Element;
}

export const CheckoutGuard = ({ children }: ICheckoutGuardPros) => {
  const { accessToken } = useSelector(
    (state: IAppReducer) => state.checkoutReducer
  );
  if (accessToken) {
    return children;
  } else {
    return <Navigate to="/checkout" />;
  }
};
