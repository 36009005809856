import { Navigate, useLocation } from "react-router-dom";
export const VerificationAuthActionGuard = () => {
  const { search } = useLocation();

  const params = new URLSearchParams(search);
  const mode = params.get("mode") as string;

  switch (mode) {
    case "resetPassword":
      return <Navigate to={`/sing-in/new-password${search}`} />;
    case "verifyEmail":
      return <Navigate to={`/sing-in/email-verification${search}`} />;
    case "recoverEmail":
      return <Navigate to={`/sing-in/recover-email${search}`} />;
    default:
      return <Navigate to="/login" />;
  }
};
