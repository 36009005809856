import {
  appSetBranchAction,
  TypeActionsSetBranch,
} from 'store/actions/branch.types';
import { getCountry } from 'utils/branch.utils';

export const branchReducer = (
  state = { branch: '', country_id: '' },
  action: appSetBranchAction
) => {
  switch (action.type) {
    case TypeActionsSetBranch.setBranch:
      return {
        ...state,
        branch: action.data,
        country_id: getCountry(action.data),
      };
    default:
      return state;
  }
};
