import iconTag from './icon-tag.svg';
import factura from './factura.svg';
import profile from './profile.svg';
import properties from './properties.svg';
import contactCenter from './contact-center.svg';
import email from './correo.svg';
import chat from './chat.svg';
import support from './support.svg';

export const Icons = {
  iconTag,
  factura,
  profile,
  properties,
  contactCenter,
  email,
  chat,
  support
};
