import { config } from 'config/constants';

const getBranch = (): string => {
  return (
    {
      'connect.cr': 'cr',
      'connect.com.pa': 'pty',
      'connect.com.co': 'co',
    }[window.location.hostname] || (config.defaultBranch as string)
  );
};

const getUrlForCountry = (branch: string): string => {
  return (
    {
      pr: config.connectWeb.pr,
      cr: config.connectWeb.cr,
      pa: config.connectWeb.pty,
      co: config.connectWeb.co,
      mx: config.connectWeb.mx,
    }[branch] || ''
  );
};

const getDefaultPlansByBranch = (branch: string) => {
  return {
    pr: config.default_plans.pr,
    cr: config.default_plans.cr,
    pty: config.default_plans.pa,
    co: config.default_plans.co,
    mx: config.default_plans.pr,
  }[branch];
};

const getBranchIndicatorCountry = (country: string): string => {
  return {
    'Puerto Rico': 'pr',
    'Costa Rica': 'cr',
    Panama: 'pty',
    Colombia: 'co',
    México: 'mx',
  }[country] as string;
};
const getNameCountry = (branch: string): string => {
  return {
    pr: 'Puerto Rico',
    cr: 'Costa Rica',
    pty: 'Panama',
    co: 'Colombia',
    mx: 'México',
  }[branch] as string;
};
const getCountry = (branch: string): string => {
  return (
    {
      pr: '1',
      cr: '2',
      pty: '3',
      co: '4',
      mx: '5',
    }[branch] || '1'
  );
};
const getCurrencyForCountry = (idCountry: number): string => {
  return (
    {
      4: 'COP',
    }[idCountry] || 'USD'
  );
};

const getPhoneIndicator = (branch: string): string => {
  return (
    {
      pr: '+1',
      cr: '+506',
      pty: '+507',
      co: '+57',
      mx: '+52',
    }[branch] || '+1'
  );
};

const lengthPhone = (branch: string) => {
  return (
    {
      pr: {
        min: 10,
        max: 10,
      },
      cr: {
        min: 8,
        max: 8,
      },
      co: {
        min: 10,
        max: 10,
      },
      mx: {
        min: 10,
        max: 10,
      },
      pty: {
        min: 7,
        max: 8,
      },
    }[branch] || {
      min: 10,
      max: 10,
    }
  );
};

const getKeyStripe = (branch: string): string => {
  return (
    {
      pr: config.stripe.pr,
      cr: config.stripe.cr,
      co: config.stripe.co,
    }[branch] || (config.stripe.pr as string)
  );
};

export {
  getBranch,
  getCountry,
  getPhoneIndicator,
  getKeyStripe,
  lengthPhone,
  getDefaultPlansByBranch,
  getNameCountry,
  getCurrencyForCountry,
  getBranchIndicatorCountry,
  getUrlForCountry,
};
