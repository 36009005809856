import { Images } from 'assets/images/images.index';
import { Button } from 'components/atoms/Button/Button';
import { Input, TypeInput } from 'components/atoms/Input/Input';
import { Select, IOptionSelect } from 'components/atoms/Select/Select';
import { PopupExistProcess } from 'components/molecules/Popup-exist-porcess/Popup-exist-porcess';
import { typeProperty } from 'constants/app.constants';
import { useFormik } from 'formik';
import { IAppReducer } from 'models/reducer.interface';
import { IPropertiesInfoAttributes } from 'pages/Settings/models/Properties.interface';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { getUserPlans } from 'shared/services/plans';
import { updateProductProperties } from 'shared/services/products';
import { getUserProperty } from 'shared/services/user';
import { setStateEditProperty } from 'store/actions/app.types';
import './Edit-property.scss';
import {
  IEditPropertyRequest,
  IUpdatePropertiy,
  IUserSubscription,
  IUserSubscriptionProductsData,
} from './models/Edit-Property.interface';
import { HeaderWithSteps } from 'components/organisms/Header-with-steps/Header-with-steps';

export const EditProperty = () => {
  const { propertyId } = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state: IAppReducer) => state.authReducer);
  const navigate = useNavigate();
  const [property, setProperty] = useState<IPropertiesInfoAttributes>();
  const [planesOptions, setPlanesOptions] = useState<IOptionSelect[]>([
    { value: '', description: '', info: '' },
  ]);
  const [popUp, setPopUp] = useState<{
    type: string;
    showModal: boolean;
    title: string;
    message: string;
    labelButton: string;
    labelButtonCancel: string;
  }>({
    type: '',
    showModal: false,
    title: '',
    message: '',
    labelButton: '',
    labelButtonCancel: '',
  });
  const [
    formEditPropertyInitialValues,
    setFormEditPropertyInitialValues,
  ]: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any = useState({});

  const handlePopUp = (type: 'exitsProcess' | 'selectPlan' | undefined) => {
    const infoSetPopUp = {
      showModal: true,
      title: '',
      message: '',
      labelButton: '',
      labelButtonCancel: '',
      type: '',
    };
    if (type === 'exitsProcess') {
      infoSetPopUp.type = 'exitsProcess';
      infoSetPopUp.title = '¡Alto!';
      infoSetPopUp.message =
        '<strong>Está por dejar el proceso incompleto.</strong> Recuerda que al al dejarlo incompleto deberá iniciarlo nuevamente cuando regreses.';
      infoSetPopUp.labelButton = 'Continuar';
      infoSetPopUp.labelButtonCancel = 'Salir';
    } else if (type === 'selectPlan') {
      infoSetPopUp.type = 'selectPlan';
      infoSetPopUp.title = 'Acción requerida';
      infoSetPopUp.message =
        'Para poder usar este plan debes aumentar de categoría o remover un auto.';
      infoSetPopUp.labelButton = 'Comprar plan';
      infoSetPopUp.labelButtonCancel = 'Editar plan';
    } else {
      infoSetPopUp.showModal = false;
    }
    setPopUp({ ...infoSetPopUp });
  };

  const handleClosePopUp = () => {
    handlePopUp(undefined);
    if (popUp.type === 'selectPlan') {
      navigate('/settings/payments');
    } else {
      navigate('/settings/properties');
    }
  };

  useEffect(() => {
    (async () => {
      const response = await getUserProperty(user.uid, propertyId as string);
      const dataResponse: IPropertiesInfoAttributes = response[0].attributes;
      dataResponse.property_attributes.forEach((element) => {
        formEditPropertyInitialValues[
          `${dataResponse.tipo_producto.data.attributes.name}__${element.propiedad.name}__${element.propiedad.id}__${element.id}`
        ] = element.valor || '';
        // type=dataResponse.tipo_producto.data.attributes.type
      });
      setProperty({ ...dataResponse });
      setFormEditPropertyInitialValues({ ...formEditPropertyInitialValues });
      getPlans(
        dataResponse.tipo_producto.data.id,
        dataResponse.productos_usuario.data[0]?.id || 0
      );
    })();
  }, [setPlanesOptions]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formEditPropertyValidate = (values: any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const errors: any = {};
    property?.property_attributes.forEach((item) => {
      if (
        item.propiedad.required &&
        !values[
          `${property.tipo_producto.data.attributes.name}__${item.propiedad.name}__${item.propiedad.id}__${item.id}`
        ]
      ) {
        errors[
          `${property.tipo_producto.data.attributes.name}__${item.propiedad.name}__${item.propiedad.id}__${item.id}`
        ] = 'Campo requerido';
      }
    });

    return errors;
  };
  const formEditProperty = useFormik({
    initialValues: formEditPropertyInitialValues,
    validate: formEditPropertyValidate,
    onSubmit: async (values) => {
      try {
        const userProductInfo: { user_product?: number; to_delete?: number } =
          {};
        if (values.userProduct === 0) {
          if (
            property?.productos_usuario.data &&
            property.productos_usuario.data.length > 0
          ) {
            userProductInfo.to_delete = property?.productos_usuario.data[0].id;
          }
        }
        userProductInfo.user_product = values.userProduct;
        const data: IUpdatePropertiy[] = [ { atributos: [] } ];
        Object.keys(values).forEach((keyValue, index) => {
          data[0].atributos[index] = {
            id: +keyValue.split('_')[6],
            valor: values[keyValue],
            status: true,
            propiedad: +keyValue.split('_')[4],
            propiedad_usuario: +(propertyId as string),
            ...userProductInfo,
          };
        });
        await updateProductProperties(user.uid, {
          data: [...data],
        });
        dispatch(setStateEditProperty(+(propertyId as string)));
        navigate('/settings/properties');
      } catch (error) {
        // TODO: Notificar error
        console.log('error', error);
      } finally {
        formEditProperty.setSubmitting(false);
      }
    },
  });
  const handleSelectOption = (value: number | string) => {
    const option = planesOptions.find((p) => p.value === value)?.info;
    if (option && +(option as string).split(' ')[0] <= 0) {
      handlePopUp('selectPlan');
      return;
    }
    formEditProperty.setFieldValue('userProduct', value);
  };
  const validateTypeProduct = (
    idTypeProperty: number,
    array: IUserSubscriptionProductsData[]
  ) => {
    let isDisabled = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    array.forEach((item) => {
      if (item.attributes.product_type_id.data.id !== idTypeProperty) {
        isDisabled = true;
      }
    });
    return isDisabled;
  };
  const getPlans = async (idTypeProperty: number, planId: number) => {
    try {
      const response: IUserSubscription[] = await getUserPlans(user.uid);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const options = response.map((item: IUserSubscription) => {
        const calculateAvailableProperties =
          item.attributes.plan_id.data.attributes.productos.data.reduce(
            (
              acc: number,
              currentValue: {
                attributes: { max_count: number; properties_added?: number };
              }
            ) =>
              acc +
              item.attributes.plan_id.data.attributes.user_products[0]
                .quantity -
              (currentValue.attributes.properties_added || 0),
            0
          );
        return {
          value: item.attributes.plan_id.data.attributes.user_products[0].id,
          description: `${item.attributes.plan_id.data.attributes.alias_name} | ${item.attributes.plan_id.data.attributes.alias_product_type}`,
          info: `${calculateAvailableProperties} propiedades disponibles`,
          readonly: calculateAvailableProperties <= 0,
          isDisabled: validateTypeProduct(
            idTypeProperty,
            item.attributes.plan_id.data.attributes.productos.data
          ),
        };
      }, []);
      // options.push({
      //   value: 0,
      //   description: 'Sin Cobertura',
      //   info: '',
      //   isDisabled: false,
      //   readonly: false,
      // });
      formEditPropertyInitialValues.userProduct = planId;
      setPlanesOptions(options);
    } catch (error) {
      console.log('error', error);
    }
  };
  const getTitleProperty = () => {
    const propertyFind = property?.property_attributes.find(
      (p) =>
        p.propiedad.name === 'Placa' || p.propiedad.name === 'Nombre Mascota'
    );
    const titleTypeProduct = {
      '1': 'Placa',
      '2': 'Hogar',
      '3': 'Mascota',
      '4': 'Placa',
    }[property?.tipo_producto.data.id || '1'];
    return `${titleTypeProduct} ${propertyFind?.valor}`;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getTypeProperty = (): any => {
    return (
      (property && { ...typeProperty }[property.tipo_producto.data.id]) || ''
    );
  };

  return (
    <>
      <HeaderWithSteps
        navbarTemplate="light"
        notProgress={true}
        onClick={() => handlePopUp('exitsProcess')}
        steps={[
          {
            description: `${getTitleProperty()}`,
            state: 'active',
            link: '/',
            permissionToNavigate: false,
          },
        ]}
      />
      <section className="edit__property__container">
        <>
          <img
            className="edit__property__icon"
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            src={(Images as any)[getTypeProperty() || 'auto']}
            alt="Icono Propiedad"
          />
          <span>
            Comunícate con nuestro Servicio al Cliente para actualizar o editar
            los datos de tu {property && getTypeProperty()}.
          </span>
          <div className="edit__property__form__container grid rg-0 grid__col-6 ">
            {property &&
              property.property_attributes.map((item, index) => (
                <Input
                  key={`${item.propiedad.name}__${index}`}
                  WrapperClassName={
                    item.propiedad.large ? 'grid__span-6' : 'grid__span-3'
                  }
                  label={item.propiedad.name}
                  id={`edit-vehicle-${item.propiedad.name}`}
                  type={item.propiedad.type as TypeInput}
                  name={`${property.tipo_producto.data.attributes.name}__${item.propiedad.name}__${item.propiedad.id}__${item.id}`}
                  onChange={formEditProperty.handleChange}
                  onBlur={formEditProperty.handleBlur}
                  value={
                    formEditProperty.values[
                      `${property.tipo_producto.data.attributes.name}__${item.propiedad.name}__${item.propiedad.id}__${item.id}`
                    ] || ''
                  }
                  isTouched={
                    formEditProperty.touched[
                      `${property.tipo_producto.data.attributes.name}__${item.propiedad.name}__${item.propiedad.id}__${item.id}`
                    ] as boolean
                  }
                  errorInput={
                    formEditProperty.errors[
                      `${property.tipo_producto.data.attributes.name}__${item.propiedad.name}__${item.propiedad.id}__${item.id}`
                    ] as string
                  }
                  errorLabel={
                    formEditProperty.errors[
                      `${property.tipo_producto.data.attributes.name}__${item.propiedad.name}__${item.propiedad.id}__${item.id}`
                    ] as string
                  }
                  isRequired={item.propiedad.required}
                  maxLength={item.propiedad.length}
                  form={formEditProperty}
                  textTransform={item.propiedad.name === 'Placa' ? true : false}
                  isDisabled={!user.agentId}
                />
              ))}
            <Select
              id="edit-property-select"
              placeholder="Seleccione cobertura"
              value={formEditProperty.values.userProduct}
              name="edit-property-select"
              onClick={handleSelectOption}
              isRequired={true}
              options={planesOptions}
              WrapperClassName="grid__span-6"
              onBlur={formEditProperty.handleBlur}
            />
          </div>
          <Button
            color="primary"
            size="medium"
            template="primary"
            typeStyle="rounded"
            type="submit"
            label="guardar cambios"
            isDisabled={
              formEditProperty.isSubmitting || !formEditProperty.isValid
            }
            isLoading={formEditProperty.isSubmitting}
            onClick={formEditProperty.handleSubmit}
          ></Button>
        </>
      </section>
      {popUp.showModal && (
        <PopupExistProcess
          title={popUp.title}
          message={popUp.message}
          labelButton={popUp.labelButton}
          labelButtonCancel={popUp.labelButtonCancel}
          type="warning"
          closePopUp={() => handlePopUp(undefined)}
          exitProcess={handleClosePopUp}
        />
      )}
    </>
  );
};
