import { IInputPaymentMethodState } from 'shared/models/input-payment-method.interface';
import { config } from 'config/constants';

export const enum ContainersElements {
  cardNumber = 'containerCardNumber',
  cardExpiration = 'containerExpiration',
  carCVV = 'containerCVV',
}
export const getDlocalSmartFieldsKey = (branch: 'cr' | 'co') => {
  return { cr: config.dlocal.cr, co: config.dlocal.co }[branch];
};

export const InputsState: IInputPaymentMethodState = {
  cardNumber: {
    isEmpty: true,
    isValid: false,
    errorMessage: undefined,
  },
  cardCvc: {
    isEmpty: true,
    isValid: false,
    errorMessage: undefined,
  },
  cardExpiry: {
    isEmpty: true,
    isValid: false,
    errorMessage: undefined,
  },
};

export const dlocalInstanceField = {
  style: {
    base: {
      fontSize: '14px',
      fontFamily: "'Inter-Regular', sans-serif",
      lineHeight: '14px',
      fontSmoothing: 'antialiased',
      fontWeight: '500',
      '::placeholder': {
        color: 'white',
      },
    },
    focus: {
      '::placeholder': {
        color: '#adbfd3',
      },
    },
  },
  placeholder: ' ',
};
