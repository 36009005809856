import { useFormik } from 'formik';
import { AESEncryptDecryptService } from 'shared/services/aes-encrypt-decrypt.service';
import {
  IFormNewPassword,
  IFormNewPasswordErrors,
} from '../models/New-password.interface';
export interface IPropsNewPassword {
  handleChangePassword: (password: string) => Promise<void>;
}
export const useCreatePassword = (props: IPropsNewPassword) => {
  const formNewPasswordInitialValues: IFormNewPassword = {
    password: '',
    conFirmPassword: '',
  };
  const formNewPasswordValidate = (values: IFormNewPassword) => {
    const errors: IFormNewPasswordErrors = {};
    if (!values.password) {
      errors.password = 'Este campo es requerido';
    } else if (values.password.length < 6) {
      errors.password = 'Este campo debe tener mínimo 6 caracteres';
    }

    if (!values.conFirmPassword) {
      errors.conFirmPassword = 'Este campo es requerido';
    }

    if (
      values.conFirmPassword &&
      values.password &&
      values.password !== values.conFirmPassword
    ) {
      errors.conFirmPassword = 'Tu clave no coincide';
      errors.password = 'Tu clave no coincide';
    }

    return errors;
  };

  const formNewPassword = useFormik({
    initialValues: formNewPasswordInitialValues,
    validate: formNewPasswordValidate,
    onSubmit: async (values) => {
      const textEncrypt = new AESEncryptDecryptService();
      await props.handleChangePassword(textEncrypt.encrypt(values.password));
      formNewPassword.setSubmitting(false);
    },
  });

  return {
    formNewPassword,
  };
};
