import { Images } from 'assets/images/images.index';
import { ButtonClose } from 'components/atoms/Button-close/Button-close';
import { Chips } from 'components/atoms/Chips/Chips';
import { DetailAccordion } from 'components/atoms/Detail-accordion/Detail-accordion';
import { Navbar } from 'components/atoms/Navbar/Navbar';
import { ServicesHistory } from 'components/organisms/Services-history/Services-history';
import { typeProperty } from 'constants/app.constants';
import { IAppReducer } from 'models/reducer.interface';
import { IPropertiesInfoAttributes } from 'pages/Settings/models/Properties.interface';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { deleteUserProperty, getUserProperty } from 'shared/services/user';
import './Property.scss';
import { SettingsFooter } from 'components/molecules/Settings-footer/Settings-footer';
import { getContactInfo } from 'utils/connect-info.utils';

export const Property = () => {
  const connectWebAnchor = useRef<HTMLAnchorElement>(null);
  const { idProperty } = useParams();
  const navigate = useNavigate();

  const {
    authReducer: user,
    branchReducer: { branch },
  } = useSelector((state: IAppReducer) => state);
  const [property, setProperty] = useState<IPropertiesInfoAttributes>();
  useEffect(() => {
    (async () => {
      const response = await getUserProperty(user.uid, idProperty as string);
      setProperty({ ...response[0].attributes });
    })();
  }, [setProperty]);

  const goToEditProperty = () => {
    navigate(`/edit-property/${idProperty}`);
  };

  const getTitleProperty = () => {
    const propertyFind = property?.property_attributes.find(
      (p) => p.propiedad.name === 'Placa' || p.propiedad.name === 'Nombre'
    );
    let title = 'Vehículo';
    if (property?.tipo_producto.data.id == 2) {
      title = 'Casa';
    }
    return `${title} ${propertyFind?.valor}`;
  };
  const getTypeProperty = (): string => {
    return (
      (property && { ...typeProperty }[property.tipo_producto.data.id]) || ''
    );
  };
  const deleteProperty = async () => {
    await deleteUserProperty(idProperty as string, user.uid);
    navigate('/settings/properties');
  };
  const goToWhatsApp = () => {
    if (connectWebAnchor.current) {
      connectWebAnchor.current.href = `https://wa.me/${
        getContactInfo(branch).phone
      }`;
      connectWebAnchor.current.click();
    }
  };
  return (
    <>
      <Navbar template="light">
        <div className="property__nav__container">
          <div className="property__info__header">
            <img // eslint-disable-next-line @typescript-eslint/no-explicit-any
              src={(Images as any)[getTypeProperty() || 'auto']}
              alt="Logo tipo propiedad"
            />
            <span className="property__title">{getTitleProperty()}</span>
            <div className="property__actions__container">
              <button
                type="button"
                onClick={user.agentId ? goToEditProperty : goToWhatsApp}
              >
                Editar
              </button>
              <div className="property__actions__divider"></div>
              <button onClick={user.agentId ? deleteProperty : goToWhatsApp}>
                Eliminar{' '}
              </button>
            </div>
          </div>
          <ButtonClose
            template="dark"
            onClick={() => {
              navigate('/settings/properties');
            }}
          />
        </div>
      </Navbar>
      <div className="property__container">
        <section className="property__properties__container">
          {property?.property_attributes.map((item, index: number) => (
            <Chips label={item.valor} key={item.valor + index} />
          ))}
        </section>
        {property && property.productos_usuario.data.length > 0 && (
          <div className="property__notify__container">
            <DetailAccordion
              tilteCustom={` ${
                property.productos_usuario.data[0].attributes.subscription_id
                  .data.attributes.plan_id.data.attributes.alias_name
              } |   <strong>${property.productos_usuario.data[0].attributes.subscription_id.data.attributes.plan_id.data.attributes.alias_product_type.toUpperCase()}</strong> `}
              type="successful"
            >
              <ul className="list">
                {property.productos_usuario.data[0].attributes.subscription_id.data.attributes.plan_id.data.attributes.advantages.map(
                  (advantage: { name: string }, indexAdvantage: number) => (
                    <li key={`${advantage}_${indexAdvantage}`}>
                      {advantage.name}
                    </li>
                  )
                )}
              </ul>
            </DetailAccordion>
          </div>
        )}
        <section className="property__container__history">
          <ServicesHistory
            branch={branch}
            data={[]}
            userType={
              user.user_type && !user.agentId ? user.user_type : undefined
            }
          ></ServicesHistory>
        </section>
      </div>
      <SettingsFooter></SettingsFooter>
      <a
        ref={connectWebAnchor}
        className="anchor__link"
        rel="noreferrer"
        target="_blank"
      ></a>
    </>
  );
};
