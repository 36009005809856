import {
  Stripe,
  StripeCardCvcElement,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElement,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElement,
  StripeCardNumberElementChangeEvent,
  loadStripe,
} from '@stripe/stripe-js';
import { IAppReducer } from 'models';
import {
  ForwardedRef,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { IInputPaymentMethodState } from 'shared/models/input-payment-method.interface';
import { getKeyStripe } from 'utils/branch.utils';
import { PaymentMethodStripeRef } from '../models/Payment-method-stripe';

export const usePaymentMethodStripe = ({
  handleChangeStripeInput,
  valueNameInput,
  reference,
}: {
  handleChangeStripeInput: (
    event:
      | StripeCardNumberElementChangeEvent
      | StripeCardExpiryElementChangeEvent
      | StripeCardCvcElementChangeEvent
  ) => void;
  valueNameInput?: string;
  reference: ForwardedRef<PaymentMethodStripeRef>;
}) => {
  const stripeRef = useRef(null);

  const branch = useSelector(
    (state: IAppReducer) => state.branchReducer.branch
  );

  useImperativeHandle(reference, () => {
    return { getTokenCard: () => getToken() };
  });

  const [stripeLoad, setStripeLoad] = useState<Stripe | null>(null);
  const [refCardNumberElement, setRefCardNumberElement] =
    useState<StripeCardNumberElement>();
  const [refCardExpiryElement, setRefCardExpiryElement] =
    useState<StripeCardExpiryElement>();
  const [refCardCvcElement, setRefCardCvcElement] =
    useState<StripeCardCvcElement>();
  const [stateInputStripe, setStateInputStripe] =
    useState<IInputPaymentMethodState>({
      cardNumber: {
        isEmpty: true,
        isValid: false,
        errorMessage: undefined,
      },
      cardCvc: {
        isEmpty: true,
        isValid: false,
        errorMessage: undefined,
      },
      cardExpiry: {
        isEmpty: true,
        isValid: false,
        errorMessage: undefined,
      },
    });
  useLayoutEffect(() => {
    const loadStripeElement = async () => {
      const stripeKey = getKeyStripe(branch);
      const stripe = (await loadStripe(stripeKey)) as Stripe;
      setStripeLoad(stripe);
    };
    loadStripeElement();
  }, [setStripeLoad]);

  const onReady = (element: StripeCardNumberElement) => {
    setRefCardNumberElement(element);
  };
  const style = {
    base: {
      fontFamily: 'ConnectRegular',
    },
  };

  const changeInputStripe = (
    event:
      | StripeCardNumberElementChangeEvent
      | StripeCardExpiryElementChangeEvent
      | StripeCardCvcElementChangeEvent
  ) => {
    stateInputStripe[event.elementType].isEmpty = event.empty;
    stateInputStripe[event.elementType].isValid = !event.error;
    stateInputStripe[event.elementType].errorMessage = event.error
      ? event.error.message
      : undefined;
    setStateInputStripe({ ...stateInputStripe });
    handleChangeStripeInput(event);
  };
  const getToken = async () => {
    if (
      !stateInputStripe.cardNumber.isValid ||
      !stateInputStripe.cardExpiry.isValid ||
      !stateInputStripe.cardCvc.isValid ||
      !valueNameInput
    ) {
      return;
    }

    const token = await (stripeLoad as Stripe).createToken(
      refCardNumberElement as StripeCardNumberElement
    );
    return token?.token?.id;
  };
  const onBlurInputStripe = (
    event: 'cardCvc' | 'cardExpiry' | 'cardNumber'
  ) => {
    if (stateInputStripe[event].isEmpty) {
      stateInputStripe[event].errorMessage = 'Campo requerido';
    }
    setStateInputStripe({ ...stateInputStripe });
  };

  return {
    style,
    stripeRef,
    stripeLoad,
    onReady,
    onBlurInputStripe,
    changeInputStripe,
    stateInputStripe,
    refCardNumberElement,
    refCardExpiryElement,
    refCardCvcElement,
    setRefCardExpiryElement,
    setRefCardCvcElement,
  };
};
