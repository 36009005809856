import axios from 'axios';
import { config } from 'config/constants';

interface IRequestZapier {
  name: string;
  email: string;
  documentNumber: string;
  phone: number | string;
  documentNumberValidated: number | undefined;
  plateValidated: string | undefined;
  company: string | undefined | null;
}

/**
 * Function to add a subscription to a user
 * @param data IRequestRuedaz
 * @returns
 */
export const setInformationForUserRuedaz = async (data: IRequestZapier) => {
  await axios.post(config.zapier.webhookRuedaz, JSON.stringify(data));
};

interface IRequestDropOffZapier {
  date: string;
  options: string[];
  planIds: string;
  step: number;
  option: string;
}

/**
 * Function to add a subscription to a user
 * @param data IRequestDropOffZapier
 * @returns
 */
export const setInformationForDropOff = async (data: IRequestDropOffZapier) => {
  await axios.post('https://hooks.zapier.com/hooks/catch/15343934/30jqd97/', JSON.stringify(data));
};