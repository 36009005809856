import { IDetailStep } from 'models/info.steps.interface';
import {
  IPropertiesInfo,
  IPropertiesinfo,
  IPropertiesDetail,
  IpropertiesLimit,
  IPropertiesSummary,
} from 'pages/Settings/models/Properties.interface';
import { useEffect, useRef, useState } from 'react';
import {
  deleteUserProperty,
  getResumeUserProperties,
  getUserProperties,
} from 'shared/services/user';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { IAppReducer } from 'models/reducer.interface';
import { setStateDeleteProperty } from 'store/actions/app.types';
import { getStatusOfProperty } from 'utils/properties.utils';
import { getContactInfo } from 'utils/connect-info.utils';

export const useProperties = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const connectWebAnchor = useRef<HTMLAnchorElement>(null);
  const {
    authReducer: user,
    appReducer: { editProperty, deleteProperty },
    branchReducer: { branch },
  } = useSelector((state: IAppReducer) => state);
  const [infoPopUp, setInfoPopUp] =
    useState<IPropertiesDetail | undefined>(undefined);
  const [showPopUp, setshowPopUp] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const steps: IDetailStep[] = [
    {
      description: 'ajustes',
      state: 'active',
      link: '/settings',
      permissionToNavigate: true,
    },
    {
      description: 'propiedades',
      state: 'active',
      link: '/settings/properties',
      permissionToNavigate: false,
    },
  ];
  const messagesAlert = {
    editProperty: {
      title: '¡auto editado !',
      message: `Se envio comprobante al <strong>${user.email}</strong`,
    },
    deleteProperty: {
      title: '¡Propiedad eliminada !',
      message: `Se envio comprobante al <strong>${user.email}</strong`,
    },
  };
  const [propertiesInfo, setIPropertiesInfo] =
    useState<IPropertiesinfo | null>(null);
  const [propertiesLimit, setPropertiesLimit] = useState<IpropertiesLimit>({
    vehicles: 4,
    homes: 4,
    motorcycles: 4,
    pets: 4,
  });
  const [propertiesSummary, setPropertiesSummary] =
    useState<IPropertiesSummary>({
      properties: {
        auto: 0,
        homes: 0,
        motorcycles: 0,
        pets: 0,
      },
      products: {
        auto: 0,
        homes: 0,
        motorcycles: 0,
        pets: 0,
      },
    });
  const [error, setErrot] = useState<unknown | null>(null);
  const handlePopUp = (id?: number | string) => {
    let propertySelected: IPropertiesDetail | undefined = undefined;
    if (id) {
      propertySelected =
        propertiesInfo?.vehicles.find((p) => p.id === id) ||
        propertiesInfo?.homes.find((p) => p.id === id) ||
        propertiesInfo?.motorcycles.find((p) => p.id === id);
      setshowPopUp(true);
    } else {
      setshowPopUp(false);
    }
    setInfoPopUp(propertySelected);
  };

  useEffect(() => {
    (async () => {
      await getProperties();
      await getPropertiesSummary();
    })();
  }, [setIPropertiesInfo]);

  useEffect(() => {
    if (editProperty.id || deleteProperty.id) {
      setShowAlert(true);
    }
  }, [editProperty.id, deleteProperty.id]);

  const getProperties = async () => {
    try {
      const response: IPropertiesInfo[] = await getUserProperties(user.uid);
      const properties: IPropertiesinfo = {
        vehicles: [],
        homes: [],
        motorcycles: [],
        pets: [],
      };
      let infoAsing: IPropertiesDetail = {
        id: 0,
        title: '',
        attributes: [],
        status: 'pending',
        type: '',
      };
      for (const properti of response) {
        infoAsing = {
          id: properti.id,
          title: '',
          attributes: properti.attributes.property_attributes.map(
            (atribute) => atribute.valor,
            []
          ),
          type: properti.attributes.tipo_producto.data.attributes.name.toLocaleLowerCase(),
          status: getStatusOfProperty(properti.attributes.property_status),
        };
        if (
          properti.attributes.tipo_producto.data.id === 1 ||
          properti.attributes.tipo_producto.data.id === 4
        ) {
          const title =
            properti.attributes.property_attributes.find(
              (p) => p.propiedad.name === 'Placa'
            )?.valor || 'Propiedad';
          if (properti.attributes.tipo_producto.data.id === 1) {
            properties.vehicles.push({ ...infoAsing, title });
          } else {
            properties.motorcycles.push({ ...infoAsing, title });
          }
        }
        if (properti.attributes.tipo_producto.data.id === 2) {
          const title =
            properti.attributes.property_attributes.find(
              (p) => p.propiedad.name === 'Barrio'
            )?.valor || 'Propiedad';
          properties.homes.push({ ...infoAsing, title });
        }
        if (properti.attributes.tipo_producto.data.id === 3) {
          const title =
            properti.attributes.property_attributes.find(
              (p) => p.propiedad.name === 'Raza'
            )?.valor || 'Propiedad';
          properties.pets.push({ ...infoAsing, title });
        }
      }
      setIPropertiesInfo(properties);
    } catch (error) {
      setErrot(error);
      console.log('error', error);
    }
  };

  const getPropertiesSummary = async () => {
    try {
      const summary = await getResumeUserProperties(user.uid);
      setPropertiesSummary(summary);
    } catch (error) {
      setErrot(error);
    }
  };
  const goToDetailProperty = (id: number | string) => {
    navigate(`/property/${id}`);
  };
  const goToWhatsApp = () => {
    if (connectWebAnchor.current) {
      connectWebAnchor.current.href = `https://wa.me/${
        getContactInfo(branch).phone
      }`;
      connectWebAnchor.current.click();
    }
  };
  const goToEditProperty = (id: number | string) => {
    navigate(`/edit-property/${id}`);
  };
  const handleDleteProperty = async () => {
    try {
      if (!propertiesInfo) {
        return;
      }
      setIsLoading(true);
      await deleteUserProperty(infoPopUp?.id as number, user.uid);
      dispatch(setStateDeleteProperty(infoPopUp?.id as number));
      setshowPopUp(false);
      setTimeout(() => {
        propertiesInfo.vehicles = propertiesInfo?.vehicles.filter(
          (p) => p.id !== infoPopUp?.id
        );
        propertiesInfo.homes = propertiesInfo?.homes.filter(
          (p) => p.id !== infoPopUp?.id
        );
        propertiesInfo.pets = propertiesInfo?.pets.filter(
          (p) => p.id !== infoPopUp?.id
        );
        propertiesInfo.motorcycles = propertiesInfo?.motorcycles.filter(
          (p) => p.id !== infoPopUp?.id
        );
        setIPropertiesInfo({ ...propertiesInfo });
        handlePopUp(undefined);
        setShowAlert(false);
      }, 1500);
    } catch (error) {
      handlePopUp(undefined);
      console.log('error al eliminar la propiedad', error);
    } finally {
      setIsLoading(false);
    }
  };
  const goToAddProperty = (id: number) => {
    navigate('/add-property', {
      state: {
        typeProperty: id,
      },
    });
  };

  const handlePropertiesLimit = (
    key: 'vehicles' | 'homes' | 'motorcycles' | 'pets'
  ) => {
    if (!propertiesInfo) {
      return;
    }
    propertiesLimit[key] = (propertiesInfo[key] as IPropertiesDetail[]).length;
    setPropertiesLimit({ ...propertiesLimit });
  };

  return {
    deleteProperty,
    messagesAlert,
    user,
    steps,
    showAlert,
    navigate,
    error,
    getProperties,
    handlePropertiesLimit,
    propertiesInfo,
    goToAddProperty,
    propertiesSummary,
    infoPopUp,
    showPopUp,
    goToDetailProperty,
    goToEditProperty,
    goToWhatsApp,
    handlePopUp,
    branch,
    propertiesLimit,
    handleDleteProperty,
    isLoading,
    connectWebAnchor
  };
};
