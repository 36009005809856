import { ReactNode } from 'react';
import './Item-list.scss';
import { Images } from 'assets/images/images.index';

interface IPropsItemList {
  children?: ReactNode;
}

export const ItemList = (props: IPropsItemList) => {
  return (
    <div className={`item__list__contianer`}>
      <img
        className="item__list__image"
        src={Images.listCheck}
        alt="item de lista"
      />
      <div className='item__list__text'>{props.children}</div>
    </div>
  );
};
