import { Tap } from 'components/atoms/Tap/Tap';
import './Accordion-detail.scss';
import { ItemList } from 'components/atoms/Item-list/Item-list';
import { Images } from 'assets/images/images.index';
import { useState } from 'react';
import { Link } from 'components/atoms/Link/Link';

export interface IAccordionDetail {
  title: string;
  list: string[];
  icon: string;
  urlPolicies: string;
}

interface IAccordionDetailsProps {
  title?: string;
  titleTermsAndConditions?: string;
  data: IAccordionDetail[];
  planItemsQuantity: number[];
}

export const AccordionDetail = (props: IAccordionDetailsProps) => {
  const [active, setActive] = useState(0);
  const [isHiddenDetail, setIsHiddenDetail] = useState(true);
  return (
    <div
      className={`accordion__detail__container ${
        isHiddenDetail ? '' : 'is--open'
      }`}
    >
      <Link
        size="s"
        label={`${
          isHiddenDetail
            ? props.title
              ? props.title
              : '¿Qué incluye?'
            : 'Ver menos'
        }`}
        type={`${isHiddenDetail ? 'primary' : 'secondary'}`}
        rightIcon={true}
        onClick={() => setIsHiddenDetail((isHiddenDetail) => !isHiddenDetail)}
      />
      {!isHiddenDetail && (
        <div className="accordion__detail__content">
          <div className="accordion__detail__taps__container">
            <div className="border"></div>

            {props.data.map((item, index) =>
              props.planItemsQuantity[index] > 0 ? (
                <Tap
                  key={`tap__type__${index}`}
                  id={index}
                  label={item.title}
                  onClick={(id) => {
                    setActive(id as number);
                  }}
                  className={active !== index ? 'disabled' : ''}
                ></Tap>
              ) : (
                ''
              )
            )}
          </div>
          <div className="accordion__detail__container__list">
            Servicios que incluye
            <div className="accordion__detail__content__list">
              {props.data[active].list.map((item, index) => (
                <ItemList key={`item__list__${index}`}>{item}</ItemList>
              ))}
            </div>
          </div>
          <div className="accordion__detail__more__info">
            <img className="" src={Images.personaCel} alt="" />
            <span>
              Para más información <br />{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href={props.data[active].urlPolicies}
              >
                {props.titleTermsAndConditions
                  ? props.titleTermsAndConditions
                  : 'Ver detalles del contrato'}
              </a>
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
