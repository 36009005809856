import { Animations } from 'assets/animations/animations.index';
import './Item-discount.scss';
import { TagLabel } from 'components/atoms/Tag-label/Tag-label';

interface IPropsItemDiscount {
  value: number;
}
export const ItemDiscount = (props: IPropsItemDiscount) => {
  return (
    <TagLabel typeStyle="rounded" type="primary">
      {`AHORRE ${(props.value || 0).toLocaleString('en', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
      })}%`}
      <img
        className="item__discount__container__img"
        src={Animations.discount}
        alt="icono descuento"
      />
    </TagLabel>
  );
};
