import { useColorHook } from 'hooks/Color.hook';
import { IPropsIcon } from './icons.interface';

export const HistorySvg = (props: IPropsIcon) => {
  const color = useColorHook(props.color);
  return (
    <svg
      width="100%"
      height="100%"
      className={props.className}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="ICONOS">
        <path
          id="Vector"
          d="M23.3893 22.2966V9.32448C23.3893 7.65446 22.0314 6.29663 20.3614 6.29663H8.91075C7.72568 6.30572 6.76489 7.26954 6.76489 8.45462V10.6126H9.84123V25.0699C9.84123 26.261 10.8081 27.2279 11.9992 27.2279H24.3197C25.5018 27.2279 26.4656 26.2671 26.4656 25.082V22.2936H23.3893V22.2966ZM12.9176 25.0699C12.9176 25.576 12.5054 25.9882 11.9992 25.9882C11.4931 25.9882 11.0809 25.576 11.0809 25.0699V8.45462C11.0809 8.12728 11.0081 7.8151 10.8748 7.53626H20.3614C21.3464 7.53626 22.1496 8.33944 22.1496 9.32448V22.2966H12.9176V25.0699ZM25.226 25.082C25.226 25.5821 24.8198 25.9882 24.3197 25.9882H13.9511C14.0814 25.7094 14.1572 25.3972 14.1572 25.0699V23.5363H25.226V25.085V25.082Z"
          fill={color}
        />
        <path
          id="Vector_2"
          d="M20.9313 15.5287H12.3084V16.7683H20.9313V15.5287Z"
          fill={color}
        />
        <path
          id="Vector_3"
          d="M20.9313 12.4493H12.3084V13.689H20.9313V12.4493Z"
          fill={color}
        />
        <path
          id="Vector_4"
          d="M20.9313 9.37299H12.3084V10.6126H20.9313V9.37299Z"
          fill={color}
        />
        <path
          id="Vector_5"
          d="M20.9252 18.605H12.3084V19.8447H20.9252V18.605Z"
          fill={color}
        />
        <path
          id="Vector_6"
          d="M16 0.762268C7.16499 0.762268 0 7.92726 0 16.7623C0 25.5973 7.16499 32.7623 16 32.7623C24.835 32.7623 32 25.5973 32 16.7623C32 7.92726 24.838 0.762268 16 0.762268ZM16 31.5317C7.84391 31.5317 1.23054 24.9184 1.23054 16.7623C1.23054 8.60618 7.84391 1.9928 16 1.9928C24.1561 1.9928 30.7695 8.60618 30.7695 16.7623C30.7695 24.9184 24.1561 31.5317 16 31.5317Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
