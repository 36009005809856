import { formatPrice } from 'utils/payments.utils';
import { CardBody } from 'components/atoms/Card-body/Card-body';
import { Images } from 'assets/images/images.index';
import { IProductSegment } from 'models/segment.interface';
import './Summary-information-totals.scss';

export interface IPropsSummaryTotals {
  values: {
    subtotal: number;
    discount: number;
    total: number;
    couponAmount: number;
    discountForFreeTrial?: number;
    priceForRoad: number;
    totalsForPlan?: {
      price: number;
    }[];
  };
  branch: string;
  decorationHidden?: boolean;
  templete?: 'outline' | 'default';
  isTrialPeriod?: boolean;
  termsAndConditions?: string;
  couponName?: string;
  periodicity: string;
  products?: IProductSegment[];
}

export const SummaryInformationTotals = ({
  values,
  branch,
  templete,
  decorationHidden,
  isTrialPeriod,
  termsAndConditions,
  couponName,
  periodicity,
  products,
}: IPropsSummaryTotals) => {
  const steps = Array(12)
    .fill(1)
    .map((_, index) => index + 1);
  return (
    <CardBody templete={templete}>
      <div className="summary__information__container">
        <h5 className="summary__information__title">Resumen de la compra</h5>

        {products?.map((product, _index) =>
          product.quantity ? (
            <>
              {products[_index - 1] &&
              products[_index - 1].alias_name
                .toLocaleLowerCase()
                .indexOf('carretera') >= 0 &&
              product.alias_name.toLocaleLowerCase().indexOf('carretera') >= 0 &&
              products[_index - 1].quantity ? (
                ''
              ) : (
                <div className="summary__information__content title--header" key={`${_index}-${product.quantity}`}>
                  <div className="">{product.alias_name}</div>
                  <div className="">
                    ${' '}
                    {formatPrice(
                      product.alias_name
                        .toLocaleLowerCase()
                        .indexOf('carretera') > 0
                        ? values.priceForRoad
                        : (values.totalsForPlan as { price: number }[])[
                            _index
                          ] &&
                            (values.totalsForPlan as { price: number }[])[
                              _index
                            ].price,
                      branch
                    )}{' '}
                    /{periodicity}
                  </div>
                </div>
              )}
              <div className="summary__information__content__group">
                <div className="summary__information__content__detail">
                  <div className="product__type">
                    <img
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      src={
                        (Images as any)[
                          product.category.toLowerCase() + '_black'
                        ]
                      }
                      alt="Tipo de propiedad"
                    />
                    <span>
                      {product.category.toLocaleLowerCase() === 'hogar'
                        ? 'Hogares'
                        : product.category + 's'}{' '}
                      ({product.quantity} /unid)
                    </span>
                  </div>
                  <div className="product__price">
                    {' '}
                    ${' '}
                    {formatPrice(
                      (values.totalsForPlan as { price: number }[])[_index] &&
                        (values.totalsForPlan as { price: number }[])[_index]
                          .price,
                      branch
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            ''
          )
        )}
        <div className="summary__information__divider dashed"></div>
        <div className="summary__information__content__group">
          <div className="summary__information__content">
            <div className="summary__information__label">Subtotal</div>
            <div className="summary__information__value">
              $ {formatPrice(values.subtotal, branch)}
            </div>
          </div>
          {values.discount ? (
            <div className="summary__information__content">
              <div className="summary__information__label orange">
                Descuento
              </div>
              <div className="summary__information__value orange">
                - $ {formatPrice(values.discount, branch)}
              </div>
            </div>
          ) : (
            ''
          )}
          {values.couponAmount ? (
            <div className="summary__information__content color--primary">
              <div className="summary__information__label">
                {couponName ? couponName : 'Cupón'}
              </div>
              <div className="summary__information__value">
                - $ {formatPrice(values.couponAmount, branch)}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="summary__information__divider"></div>
        <div className="summary__information__content">
          <div className="summary__information__label bold ">Total</div>
          <div className="summary__information__value bold">
            $ {formatPrice(values.total, branch)}
          </div>
        </div>
        {isTrialPeriod && (
          <>
            <div className="summary__information__content">
              <div className="summary__information__label">Descuento hoy</div>
              <div className="summary__information__value">
                - $ {formatPrice(values.discountForFreeTrial, branch)}
              </div>
            </div>
            <div className="summary__information__content color--primary">
              <div className="summary__information__label bold">
                Total a pagar hoy
              </div>
              <div className="summary__information__value bold ">
                ${' '}
                {formatPrice(
                  values.total -
                    (values.discountForFreeTrial
                      ? values.discountForFreeTrial
                      : 0),
                  branch
                )}
              </div>
            </div>
          </>
        )}
        {values.couponAmount ? (
          <div
            className="summary__information__content coupon__terms"
            dangerouslySetInnerHTML={{ __html: termsAndConditions as string }}
          />
        ) : (
          ''
        )}
      </div>
      {!decorationHidden && (
        <div className="summary__information__decorator__container">
          {steps.map((item: number) => (
            <div
              key={`step-${item}`}
              className="summary__information__decorator__step"
            ></div>
          ))}
        </div>
      )}
    </CardBody>
  );
};
