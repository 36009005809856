import './Select-and-complement.scss';
import * as CSS from 'csstype';
import { IOptionSelect, Select } from 'components/atoms/Select/Select';
import { Input, TypeInput } from 'components/atoms/Input/Input';

export interface IPropsSelectAndComplement {
  idSelect: string;
  nameSelect: string;
  valueSelect: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (e: React.FocusEvent<any>) => void;
  onChangeSelect: (event: string | number) => void;
  placeholderSelect?: string;
  options: IOptionSelect[];
  label: string;
  errorSelect: string | undefined;
  styleInLineSelect?: CSS.Properties<string | number>;
  idInput: string;
  type: TypeInput;
  nameInput: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onBlurSelect: (e: React.FocusEvent<any>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onBlurInput: (e: React.FocusEvent<any>) => void;
  valueInput: string;
  errorInput: string | undefined;
  inputIsTouched: boolean | undefined;
  WrapperClassName?: string;
  selectIsDisabled?: boolean;
  inputIsDisabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form?: any;
}

export const SelectAndComplement = (props: IPropsSelectAndComplement) => {
  return (
    <div className={`select__complement__container ${props.WrapperClassName}`}>
      <div className="select__complement__wraper">
        <Select
          WrapperClassName="grid__span-2 select__indicator"
          id={props.idSelect}
          name={props.nameSelect}
          value={props.valueSelect}
          onClick={props.onChangeSelect}
          placeholder={props.placeholderSelect}
          options={props.options}
          errorSelect={props.errorSelect}
          isDisabled={props.selectIsDisabled}
          onBlur={props.onBlurSelect}
        />
        <Input
          WrapperClassName="grid__span-4"
          label={props.label}
          id={props.idInput}
          type={props.type}
          name={props.nameInput}
          onChange={props.onChange}
          onBlur={props.onBlurInput}
          value={props.valueInput}
          isTouched={props.inputIsTouched}
          errorInput={props.errorInput}
          isRequired={true}
          hiddenError={true}
          isDisabled={props.inputIsDisabled}
          form={props.form}
          tooltip={
            'Este número lo vamos a utilizar para contactarte en caso de una emergencia o información importante de tu cuenta'
          }
        />
      </div>
      {props.errorSelect ||
        (props.inputIsTouched && props.errorInput && (
          <span className="select__complement--error grid__col-6">
            {props.errorSelect || props.errorInput}
          </span>
        ))}
    </div>
  );
};
