export const initalStateAuth = {
  uid: '',
  name: '',
  lastname: '',
  phone: '',
  accessToken: null,
  refreshToken: null,
  idToken: null,
  authenticatedUser: false,
  isResetPassword: false,
  dateLogin: new Date(),
};
