interface IdataCreateAccountAction {
  funelId?: string;
  auth_id?: string;
  plan_id?: string;
  name?: string;
  lastname?: string;
  email?: string;
  password?: string;
  indicator?: string;
  phone?: string;
  country_id?: string;
  acceptTerms?: boolean;
  created?: boolean;
  step?: number;
  id_user_product?: number;
  referenceId?: string;
  user_plans_added?: object;
  short_link?: string | null;
  short_link_properties?: object;
  resumen?: {
    orderId?: string;
    plans?: string[];
    price?: number;
  };
}
export const enum TypeActionsCreateAccount {
  saveInformation = '[CREATE ACCOUNT] set information',
  resetInformation = '[CREATE ACCOUNT] remove information',
}

export type createAccountAction = {
  type: TypeActionsCreateAccount;
  data?: IdataCreateAccountAction;
};

export const setCreateAccountInformation = (
  data: IdataCreateAccountAction
) => ({
  type: TypeActionsCreateAccount.saveInformation,
  data,
});

export const resetCreateAccountInformation = () => ({
  type: TypeActionsCreateAccount.resetInformation,
});
