import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import { IDataAttributes } from '../../models/Create-properties.interface';
import { TitleWhitImage } from 'components/atoms/Title-whit-image/Title-whit-image';
import { Images } from 'assets/images/images.index';
import { FormikValues, useFormik } from 'formik';
import { Input, TypeInput } from 'components/atoms/Input/Input';
import { getNameCountry } from 'utils/branch.utils';
import { IAtributesProperties } from 'pages/Create-account/pages/Activate-benefits/models/Activate-benefits.interface';

export const FormHomes = forwardRef(function FormHomes(
  props: {
    properties: IDataAttributes[] | IAtributesProperties[];
    typeProduct: string;
    quantity: number;
    branch: string;
    values?: FormikValues;
  }, // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: any
) {
  const formHomesRef = useRef(null);
  const quantity = Array(props.quantity).fill(0);
  const formHomesPropertiesInitialValues: FormikValues = {};
  const formHomesPropertiesValidate = (values: FormikValues) => {
    const errors: FormikValues = {};
    quantity.forEach((_, quantityIndex) => {
      props.properties.forEach((property) => {
        if (
          property.attributes.required &&
          !values[
            `${props.typeProduct}__${property.attributes.name}__${quantityIndex}__${property.id}`
          ]
        ) {
          errors[
            `${props.typeProduct}__${property.attributes.name}__${quantityIndex}__${property.id}`
          ] = 'Campo requerido';
        }
      });
    });
    return errors;
  };
  const formHomesProperties = useFormik({
    initialValues: formHomesPropertiesInitialValues,
    validate: formHomesPropertiesValidate,
    onSubmit: async (values) => {
      return values;
    },
  });

  useMemo(() => {
    formHomesProperties.setValues({ ...props.values });
  }, [props.values]);

  useImperativeHandle(ref, () => {
    return { getValues };
  });
  const getValues = (): FormikValues => {
    if (formHomesProperties.isValid) {
      return formHomesProperties.values;
    } else {
      return {};
    }
  };

  return (
    <div className="grid__span-6" ref={formHomesRef}>
      {quantity.map((_, quantityIndex) => (
        <>
          <TitleWhitImage
            key={`title__whit__image__mascota-${quantityIndex}`}
            title={`Hogar ${quantityIndex + 1}`}
            image={Images.hogar}
            styleInLineImage={{
              width: '2rem',
              height: '2rem',
            }}
          ></TitleWhitImage>
          <div
            className="form__pets__container grid rg-0 grid__col-6 grid__span-6"
            key={`grid-${quantityIndex}`}
          >
            {props.properties.map((property, indexItem) => (
              <Input
                key={`${property.id}-${quantityIndex}-${indexItem}`}
                WrapperClassName={
                  property.attributes.large ? 'grid__span-6' : 'grid__span-3'
                }
                label={property.attributes.name}
                id={`create-properties-${props.typeProduct}__${property.attributes.name}__${quantityIndex}__${property.id}`}
                type={property.attributes.type as TypeInput}
                name={`${props.typeProduct}__${property.attributes.name}__${quantityIndex}__${property.id}`}
                onChange={formHomesProperties.handleChange}
                onBlur={formHomesProperties.handleBlur}
                value={
                  formHomesProperties.values[
                    `${props.typeProduct}__${property.attributes.name}__${quantityIndex}__${property.id}`
                  ] ||
                  (property.attributes.name === 'Pais'
                    ? getNameCountry(props.branch)
                    : '')
                }
                isTouched={
                  formHomesProperties.touched[
                    `${props.typeProduct}__${property.attributes.name}__${quantityIndex}__${property.id}`
                  ] as boolean
                }
                errorInput={
                  formHomesProperties.errors[
                    `${props.typeProduct}__${property.attributes.name}__${quantityIndex}__${property.id}`
                  ] as string
                }
                errorLabel={
                  formHomesProperties.errors[
                    `${props.typeProduct}__${property.attributes.name}__${quantityIndex}__${property.id}`
                  ] as string
                }
                isRequired={property.attributes.required as boolean}
                isDisabled={property.attributes.name === 'Pais'}
                maxLength={property.attributes.length as number}
                form={formHomesProperties}
              />
            ))}
            <div
              key={`properties__divider__home-${quantityIndex}`}
              className="create__properties__atributes__divider grid__span-6"
            ></div>
          </div>
        </>
      ))}
    </div>
  );
});
