import { Images } from 'assets/images/images.index';
type Branch = 'co' | 'pr' | 'cr' | 'pty';

const getIconCard = (cardBranch: string) => {
  return (
    {
      Visa: Images.visa,
      MasterCard: Images.master_card,
      ['American Express']: Images.american_express,
      ['Diners Club']: Images.diners_club,
      Discover: Images.discover,
    }[cardBranch] || Images.cardGeneric
  );
};
const getLocale = (branch: string): string => {
  const branchstr = branch as Branch;
  const localeMap: Record<Branch, string> = {
    co: 'es',
    pr: 'en',
    cr: 'en',
    pty: 'en',
  };
  return localeMap[branchstr] || 'en'; // Default to 'en' if branch is not found
};

const formatPrice = (value: number | undefined, branch: string): string => {
  const branchStr = branch as Branch;
  if (value === undefined || isNaN(value)) value = 0;

  const locale = getLocale(branchStr);
  const formattedPrice = value.toLocaleString(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return (Math.floor(value * Math.pow(17, 2)) / Math.pow(17, 2)).toFixed(2);
  return formattedPrice;
};

export { getIconCard, formatPrice };
