import { useLocation } from 'react-router';
import { AppRouter } from 'routes/routes';
import { useDispatch, useSelector } from 'react-redux';
import { setBranch } from 'store/actions/branch.types';
import { getBranch } from 'utils/branch.utils';
import { IAppReducer } from 'models/reducer.interface';
import { setStateError } from 'store/actions/app.types';
import { Suspense, useEffect, useState } from 'react';
import { Loader } from 'components/atoms/Loader/Loader';
import { PopUpInformative } from 'components/molecules/Popup-informative/Popup-informative';
import { ScrollRestoration } from 'hooks/Scroll-restoration.hook';
import { signOut } from 'firebase/auth';
import { defaultFirebaseAuth } from 'config/firebase-config';
import { setSignOut } from 'pages/sing-in/store/actions/auth.types';
import { useIdleTimer } from 'react-idle-timer';
import { PopupExistProcess } from 'components/molecules/Popup-exist-porcess/Popup-exist-porcess';
import { config } from 'config/constants';
import Hotjar from '@hotjar/browser';
import ErrorBoundary from 'hooks/Error-boundary';

const App = () => {
  const siteId = Number(config.hotjarId);
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion);
  const {
    branchReducer: { branch },
    authReducer: { accessToken, email, agentId },
    checkoutReducer: { email: emailCheckout, funelId },
    createAccountReducer: { email: emailCreateAccount },
  } = useSelector((state: IAppReducer) => state);
  const timeOfInactivity =
    1000 * 60 * Number(!agentId ? config.timer.timeOfInactivity : 240);
  const timeOfSession =
    1000 * 60 * Number(!agentId ? config.timer.timeOfSession : 250);
  const dispatch = useDispatch();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const country = params.get('country') as string;

  const handleInactivity = async () => {
    if (accessToken) {
      setShowAlertInactivity(true);
    }
  };
  const handleLogout = async () => {
    try {
      if (accessToken) {
        signOut(defaultFirebaseAuth);
        dispatch(setSignOut());
      }
    } catch (error) {
      // TODO Manejar error
    } finally {
      setShowAlertInactivity(false);
    }
  };
  const timer = useIdleTimer({
    timeout: timeOfInactivity,
    onIdle: handleInactivity,
    onAction: handleLogout,
    debounce: timeOfSession,
    startManually: true,
  });
  useEffect(() => {
    if (!branch && !accessToken && !country) {
      dispatch(setBranch(getBranch()));
    }
  }, [branch]);
  useEffect(() => {
    if (accessToken) {
      timer.reset();
      timer.start();
    }
  }, [accessToken]);
  const { error } = useSelector((state: IAppReducer) => state.appReducer);
  const [showAlertInactivity, setShowAlertInactivity] =
    useState<boolean>(false);

  const handlePopUp = () => {
    dispatch(setStateError(!error));
  };

  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundary
        user={email || emailCheckout || emailCreateAccount}
        agentId={agentId}
        funelId={funelId}
      >
        <>
          <ScrollRestoration>
            <AppRouter></AppRouter>
          </ScrollRestoration>
          {error && (
            <PopUpInformative
              title="Error"
              // subtitle="Hemos detectado un riesgo </br>
              // en la seguridad de sus datos."
              text="Se ha bloqueado la pantalla temporalmente. Intentelo más tarde, o bien contacte servicio al cliente para mayor atención. "
              type="error"
              // labelButton="solicitar soporte"
              closePopUp={handlePopUp}
            ></PopUpInformative>
          )}
          {showAlertInactivity && (
            <PopupExistProcess
              title="La sesión expira pronto"
              message="Detectamos inactividad y por seguridad cerraremos la sesión pronto. ¿Deseas continuar navegando?"
              labelButton="Continuar"
              labelButtonCancel="Salir"
              type="warning"
              closePopUp={() => setShowAlertInactivity(false)}
              exitProcess={handleLogout}
            />
          )}
        </>
      </ErrorBoundary>
    </Suspense>
  );
};

export default App;
