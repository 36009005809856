import { ButtonClose } from 'components/atoms/Button-close/Button-close';
import { Navbar } from 'components/atoms/Navbar/Navbar';
import { IDetailStep } from 'models/info.steps.interface';
import { useNavigate } from 'react-router';
import './Header-with-steps.scss';
import { PinConnect } from 'assets/icons/pin-connect';
import { Link } from 'react-router-dom';

interface IHeaderWithStepsProps {
  steps: IDetailStep[];
  notProgress?: boolean;
  notPadding?: boolean;
  navbarTemplate?: 'light';
  onClick?: () => void;
}
export const HeaderWithSteps = (props: IHeaderWithStepsProps) => {
  const navigate = useNavigate();
  const handleWidthProgress = () => {
    const stepsTotal = props.steps.length;
    const stepsActives = props.steps.filter(
      (item) => item.state !== 'disabled'
    );
    return (stepsActives.length * 100) / stepsTotal + '%';
  };
  const goToPage = (permissionToNavigate: boolean, link: string) => {
    if (!permissionToNavigate) {
      return;
    }
    navigate(link);
  };
  return (
    <Navbar template={props.navbarTemplate}>
      <div className="header__steps__container">
        <div className="header__steps__info title__header">
          {props.steps.map((item, index) => (
            <div
              className={`header__steps__step ${
                item.state !== 'disabled' ? 'active' : ''
              } ${props.navbarTemplate ?? ''}`}
              key={`${item.description}-${index}`}
              onClick={() => goToPage(item.permissionToNavigate, item.link)}
            >
              <span className="header__steps__step__title">
                {item.description}
              </span>
            </div>
          ))}
        </div>
        {props.onClick ? (
          <ButtonClose
            template={props.navbarTemplate ? 'dark' : 'light'}
            className="header__steps__close"
            onClick={props.onClick}
          />
        ) : (
          <Link to={'/settings'}>
            <PinConnect
              color="primary"
              className="header__logo pin"
            ></PinConnect>
          </Link>
        )}
      </div>
      <div
        className={`header__steps__progress ${
          props.notProgress ? 'not--progress' : ''
        }`}
      >
        <div
          className="bar__progress"
          style={{ width: handleWidthProgress() }}
        ></div>
      </div>
    </Navbar>
  );
};
