import { SettingsFooter } from 'components/molecules/Settings-footer/Settings-footer';
import { Images } from 'assets/images/images.index';
import { MenuList, PopUpWelcome, Header, Divider } from 'components';
import './Settings-options.scss';
import { useSettingsOptions } from './hooks/useSettingsOptions';

export const SettingsOptions = () => {
  const { handleSignOut, user, handleCopy, popUp, hiddenPopUp } =
    useSettingsOptions();
  return (
    <>
      <Header navbarTemplate="light">
        <div className="settings__options__Logout" onClick={handleSignOut}>
          <img src={Images.logout} alt="Salir" /> Salir
        </div>
        {user.agentId !== undefined && (
          <div
            role="button"
            className="settings__options__Logout__user"
            onClick={handleCopy(user.email)}
          >
            {user.email}
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 9H11C9.89543 9 9 9.89543 9 11V20C9 21.1046 9.89543 22 11 22H20C21.1046 22 22 21.1046 22 20V11C22 9.89543 21.1046 9 20 9Z"
                stroke="#F15B2B"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5 15H4C3.46957 15 2.96086 14.7893 2.58579 14.4142C2.21071 14.0391 2 13.5304 2 13V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H13C13.5304 2 14.0391 2.21071 14.4142 2.58579C14.7893 2.96086 15 3.46957 15 4V5"
                stroke="#F15B2B"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
      </Header>
      <div className="settings__options__container">
        <div className="settings__options__container__title">
          <div className="settings__options__container__title__info">
            <h6>Hola, {user.name}</h6>
            <p className="settings__label">
              Completa o modifica tus datos para que tengas las asistencias a tu
              medida
            </p>
          </div>
          <img src={Images.cambioDeLlanta} alt="" />
        </div>
        <Divider margin="small" />
        <div className="settings__options__container__content">
          <MenuList></MenuList>
        </div>
      </div>
      <SettingsFooter />
      {popUp && (
        <PopUpWelcome
          info={{
            onClick: hiddenPopUp,
            name: user.name,
          }}
        ></PopUpWelcome>
      )}
    </>
  );
};
