import cms from 'shared/services/cms';

/**
 * Function to get all coupon
 * @returns
 */
export async function getCoupons() {
  const response = await cms.get('coupons');
  return response.data;
}

/**
 * Function to get a coupon by id
 * @param code string
 * @returns object
 */
export async function getCouponByCode(code: string) {
  const response = await cms.get(`coupons/${code}`);
  return response.data;
}
