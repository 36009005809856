import { Images } from 'assets/images/images.index';
import { IAccordionDetail } from 'components/organisms/Accordion-detail/Accordion-detail';
import { IProduct } from '../../Payment-information/models/Payment-information.interface';
export const getTitle = (type: string) => {
  return (
    {
      auto: 'Autos',
      moto: 'Motos',
      hogar: 'Hogares',
      motora: 'Motoras',
      mascota: 'Mascotas',
    }[type.toLowerCase()] || 'Asistencia'
  );
};
export const getImage = (type: string) => {
  return (
    {
      auto: Images.auto,
      moto: Images.moto,
      motora: Images.moto,
      hogar: Images.hogar,
      mascota: Images.mascota,
    }[type.toLowerCase()] || Images.auto
  );
};

export const accordionDetailInformationMapping = (products: IProduct[]) => {
  const productsMapped = products.map((product) => {
    return {
      title: getTitle(product.attributes.alias_product_type),
      list: product.attributes.advantages.map(
        (advantage: { name: string }) => advantage.name
      ),
      icon: getImage(product.attributes.alias_product_type.toLowerCase()),
      urlPolicies: product.attributes.url_policies,
    };
  }, []);
  return productsMapped as IAccordionDetail[];
};
