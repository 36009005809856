import './Checkbox.scss';
import * as CSS from 'csstype';
import { ReactNode, useState } from 'react';

export interface IPropsCheckbox {
  children?: ReactNode;
  checked?: boolean;
  id: string;
  name: string;
  isDisabled?: boolean;
  onChange: (item: boolean | undefined) => void;
  styleInLine?: CSS.Properties<string | number>;
  dangerouslySetInnerHTML?: {
    __html: string;
  };
}

export const Checkbox = (props: IPropsCheckbox) => {
  const [checked, setChecked] = useState(props.checked);
  const handleCheck = () => {
    if (props.isDisabled) {
      return;
    }
    setChecked(!checked);
    return checked;
  };
  return (
    <div className="container__checkbox" style={props.styleInLine}>
      <input
        type="checkbox"
        className="checkbox__input"
        id={props.id}
        name={props.name}
        checked={checked}
        onChange={() => {
          handleCheck();
        }}
        disabled={props.isDisabled}
      />
      <label
        className="checkbox__label"
        htmlFor={props.name}
        onClick={() => props.onChange(handleCheck())}
      >
        {props.dangerouslySetInnerHTML ? (
          <span dangerouslySetInnerHTML={props.dangerouslySetInnerHTML} />
        ) : (
          props.children
        )}
      </label>
    </div>
  );
};
