import { Images } from 'assets/images/images.index';
import { ButtonSocial } from 'components/atoms/Button-social/Button-social';
import { IAppReducer } from 'models/reducer.interface';
import { useSelector } from 'react-redux';
import { getContactInfo } from 'utils/connect-info.utils';
import './Settings-footer.scss';

export const SettingsFooter = () => {
  const branch = useSelector(
    (state: IAppReducer) => state.branchReducer.branch
  );

  return (
    <footer className="settings__footer__container">
      <div className="settings__footer__body container__generic">
        <div className="settings__footer__body__buttons">
          <ButtonSocial
            image={Images.logoIstagramFooter}
            redSocial="instagram"
            url={getContactInfo(branch).url_instagram}
            iconClassName="logo__instagram"
          />
          <ButtonSocial
            image={Images.logoFacebookFooter}
            redSocial="facebook"
            url={getContactInfo(branch).url_facebook}
            iconClassName="logo__facebook"
          />
        </div>
        <img
          className="logo__connect"
          src={Images.logoConnectOnly}
          alt="logo__connect"
        />
      </div>
    </footer>
  );
};
