import { Input } from 'components/atoms/Input/Input';
import { PopupRequiredValidationGeneric } from 'components/molecules/Popup-required-validation-generic/Popup-required-validation-generic';
import { useFormik } from 'formik';
import './Popup-required-validation.scss';
import { getUserRuedaz } from 'pages/Checkout/services/ruedaz.service';
import { useDispatch } from 'react-redux';
import { setCheckoutInformation } from 'pages/Checkout/store/actions/checkout.types';

interface IpopupRequiredValidation {
  message: string;
  onFormIsValid: (plate: boolean) => void;
}
interface IformPopupRequiredValidation {
  plate?: string;
  document?: number;
}

interface IformPopupRequiredValidationErrors {
  plate?: string;
  document?: string;
}

export const PopupRequiredValidation = (props: IpopupRequiredValidation) => {
  const dispatch = useDispatch();
  const formPopupRequiredValidationInitial = {
    plate: '',
    document: undefined,
  };
  const formPopupRequiredValidationValidate = (
    values: IformPopupRequiredValidation
  ) => {
    const errors: IformPopupRequiredValidationErrors = {};
    if (!values.plate) {
      errors.plate = 'Este campo es requerido';
    }
    if (!values.document) {
      errors.document = 'Este campo es requerido';
    }
    return errors;
  };

  const formPopupRequiredValidation = useFormik({
    initialValues: formPopupRequiredValidationInitial,
    validate: formPopupRequiredValidationValidate,
    onSubmit: async (values) => {
      try {
        const response = await getUserRuedaz({
          plate: values.plate.toUpperCase(),
          documentNumber: Number(values.document),
        });
        if (response) {
          props.onFormIsValid(true);
          dispatch(
            setCheckoutInformation({
              companyAdditionalInformation: {
                plate: values.plate.toUpperCase(),
                documentNumber: Number(values.document),
              },
            })
          );
        } else {
          formPopupRequiredValidation.setErrors({
            plate: 'Placa incorrecta',
            document: 'Documento incorrecto',
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <PopupRequiredValidationGeneric
      title="Hola!"
      labelButton="Validar"
      closePopUp={formPopupRequiredValidation.handleSubmit}
      isLoading={formPopupRequiredValidation.isSubmitting}
    >
      <strong className="popup__required__message">{props.message}</strong>
      <Input
        label="Placa"
        id="popup-required-plate"
        type="alphanumeric"
        name="plate"
        onChange={formPopupRequiredValidation.handleChange}
        onBlur={formPopupRequiredValidation.handleBlur}
        value={formPopupRequiredValidation.values.plate}
        isTouched={formPopupRequiredValidation.touched.plate}
        errorInput={formPopupRequiredValidation.errors.plate}
        errorLabel={formPopupRequiredValidation.errors.plate}
        isRequired={true}
        form={formPopupRequiredValidation}
        textTransform={true}
      />
      <Input
        label="Documento de identidad"
        id="popup-required-document"
        type="tel"
        name="document"
        onChange={formPopupRequiredValidation.handleChange}
        onBlur={formPopupRequiredValidation.handleBlur}
        value={formPopupRequiredValidation.values.document}
        isTouched={formPopupRequiredValidation.touched.document}
        errorInput={formPopupRequiredValidation.errors.document}
        errorLabel={formPopupRequiredValidation.errors.document}
        isRequired={true}
        form={formPopupRequiredValidation}
        textTransform={true}
      />
    </PopupRequiredValidationGeneric>
  );
};
