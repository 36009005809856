import './History-card-skeleton.scss';

export const HistoryCardSkeleton = () => {
  return (
    <div className="history__card__skeleton">
      <div className="titles">
        <div className="info__service">
          <div className="type__service skeleton"></div>
          <div className="id__service skeleton"></div>
        </div>
        <div className="value skeleton"></div>
      </div>
      <div className="detail__service">
        <div className="data skeleton"></div>
        <div className="data data--small skeleton"></div>
      </div>
      <div className="info__tag">
        <div className="name skeleton"></div>
        <div className="status skeleton"></div>
      </div>
    </div>
  );
};
