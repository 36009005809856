import { Images } from 'assets/images/images.index';

const getPropertyInfo = (
  typeProperty: number
): { title: string; message: string; description: string; image: string } => {
  return {
    1: {
      title: 'Agregar nuevos autos',
      message: '¿Cuántos autos deseas ingresar?',
      description:
        'Ingresa uno a uno la información de los autos que deseas cubrir con el plan: ',
      image: Images.auto,
    },
    2: {
      title: 'Agregar nuevos hoagores',
      message: '¿Cuántos hoages deseas ingresar?',
      description:
        'Ingresa la información del hogar que deseas cubrir con el plan:',
      image: Images.hogar,
    },
    3: {
      title: 'Agregar nuevas macotas',
      message: '¿Cuántas mascotas deseas ingresar?',
      description:
        'Ingresa la información de la mascota que deseas cubrir con el plan:',
      image: Images.mascota,
    },
    4: {
      title: 'Agregar nuevas motocicletas',
      message: '¿Cuántas motocicletas deseas ingresar?',
      description:
        'Ingresa uno a uno la información de las motocicletas que deseas cubrir con el plan:',
      image: Images.moto,
    },
  }[typeProperty || 1] as {
    title: string;
    message: string;
    description: string;
    image: string;
  };
};

const getIgameForTypeProperty = (typeProperty: number) => {
  return {
    1: {
      image: Images.plan_auto,
    },
    2: {
      image: Images.plan_auto,
    },
    3: {
      image: Images.plan_auto,
    },
    4: {
      image: Images.plan_auto,
    },
  }[typeProperty || 1];
};

const getLabelsForProperty = (value: number) => {
  return {
    available: ` cobertura${value > 1 || value === 0 ? 's' : ''} disponible${
      value > 1 ? 's' : ''
    }.`,
    cover: `propiedad${value > 1 || value === 0 ? 'es' : ''} cubierta${
      value > 1 || value === 0 ? 's' : ''
    }:`,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getStatusOfProperty = (arg: { data: any; id: number } | undefined) => {
  if (arg && arg.data.id) {
    if (arg.data.id === 1) {
      return 'pending';
    } else if (arg.data.id === 3) {
      return 'cancelled';
    } else {
      if (arg.data.id) {
        return 'successful';
      } else {
        return 'error';
      }
    }
  } else {
    return 'error';
  }
};

export {
  getPropertyInfo,
  getIgameForTypeProperty,
  getLabelsForProperty,
  getStatusOfProperty,
};
