import { Images } from 'assets/images/images.index';
import { ReactNode, useRef, useState } from 'react';
import './Detail-accordion.scss';

interface IPropsDetailAccordion {
  tilte?: string;
  tilteCustom?: string;
  subTilte?: string;
  type: 'successful' | 'error' | 'warning' | 'informative';
  children?: ReactNode;
  wrapperClassName?: string;
  isOpen?: boolean;
}
export const DetailAccordion = (props: IPropsDetailAccordion) => {
  const [clicked, setClicked] = useState(props.isOpen ? props.isOpen : false);
  const contentEl = useRef<HTMLDivElement>(null);
  const setIcon = () => {
    return {
      successful: Images.check,
      error: Images.error,
      warning: Images.warning,
      informative: Images.checkInformative,
    }[props.type];
  };
  const handleToggle = () => {
    setClicked((prev) => !prev);
  };
  return (
    <div
      className={`detail__accordion__container ${
        props.type ? props.type : ''
      } ${props.wrapperClassName ? props.wrapperClassName : ''}`}
    >
      <div className="detail__accordion__container__header">
        <div className="detail__accordion__container__title">
          <img
            className="detail__accordion__icon"
            src={setIcon()}
            alt={`estado ${props.type}`}
          />
          {!props.tilteCustom ? (
            <h1 className={`${props.type}`}>
              {props.tilte} <small>{props.subTilte}</small>
            </h1>
          ) : (
            <h1
              className={`custom ${props.type}`}
              dangerouslySetInnerHTML={{ __html: props.tilteCustom }}
            ></h1>
          )}
        </div>
        {props.children && (
          <div className="detail__accordion__btn" onClick={handleToggle}>
            Ver {!clicked ? 'detalles' : 'menos'}
            <img
              className={` ${clicked ? 'rotar' : ''}`}
              src={Images.arrowAccordion}
              alt="Ver mas"
            />
          </div>
        )}
      </div>
      <div
        ref={contentEl}
        style={
          clicked
            ? { height: contentEl?.current?.scrollHeight }
            : { height: '0px' }
        }
        className="detail__accordion__content"
      >
        <div className="detail__accordion__text">{props.children}</div>
      </div>
    </div>
  );
};
