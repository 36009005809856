import { IAppReducer } from 'models/reducer.interface';
import { setAuthenticatedUser } from 'pages/sing-in/store/actions/auth.types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SettingsRouter } from './routes/Settings.routes';
import './Settings.scss';

export const Settings = () => {
  const { authenticatedUser, agentId } = useSelector(
    (state: IAppReducer) => state.authReducer
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (!authenticatedUser) {
      dispatch(setAuthenticatedUser(true));
      if (agentId) {
        window.location.reload();
      }
    }
  }, [authenticatedUser]);

  return (
    <section className="settings__container">
      <SettingsRouter />
    </section>
  );
};
