import { TypeState } from 'models/app.interface';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  IFormForgotPassword,
  IFormForgotPasswordErrors,
} from '../models/Forgot-password.interface';
import { IDetailStep } from 'models/info.steps.interface';
import { useFormik } from 'formik';
import { sendPasswordResetEmail } from 'shared/services/users';
import { IAppReducer } from 'models/reducer.interface';

export const UseForgotPasswordValidation = () => {
  const accessToken = useSelector(
    (state: IAppReducer) => state.authReducer.accessToken
  );
  const navigate = useNavigate();
  const [popUp, setPopUp] = useState<{
    isVisible: boolean;
    type: TypeState;
  }>({ isVisible: false, type: 'successful' });
  const formForgotPasswordInitialValues: IFormForgotPassword = {
    email: '',
  };
  const steps: IDetailStep[] = [
    {
      description: 'Cambio contraseña ',
      state: 'active',
      link: '/forgot-password',
      permissionToNavigate: false,
    },
  ];
  const formForgotPasswordValidate = (values: IFormForgotPassword) => {
    const errors: IFormForgotPasswordErrors = {};
    if (!values.email) {
      errors.email = 'Este campo es requerido';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Formato de correo electrónico incorrecto';
    }
    return errors;
  };
  const formForgotPassword = useFormik({
    initialValues: formForgotPasswordInitialValues,
    validate: formForgotPasswordValidate,
    onSubmit: async ({ email }) => {
      popUp.type = 'successful';
      try {
        await sendPasswordResetEmail(email);
      } catch (error: unknown) {
        popUp.type = 'error';
      } finally {
        setPopUp({ ...popUp, isVisible: true });
      }
    },
  });

  const showInfoPopUp = () => {
    setPopUp({ ...popUp, isVisible: !popUp.isVisible });
  };

  return {
    accessToken,
    navigate,
    steps,
    showInfoPopUp,
    formForgotPassword,
    popUp,
  };
};
