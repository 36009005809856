import { config } from 'config/constants';
import apiSales from './apiSales';

const enum Meraki {
  label = 'Meraki',
}

const createLead = async (branch: string) => {
  const getFunnelInfo = await apiSales.post('/sales/cSalesFunel', {
    branch: branch,
    utm: {},
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    accountId: (config.accounts as any)[branch].id,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    accountName: (config.accounts as any)[branch].name,
    sourceType: Meraki.label,
  });
  return getFunnelInfo.data.data.funelId;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const updateLead = async (data: any) => {
  apiSales.post('/sales/uSalesFunel', {
    _id: data._id,
    fields: { ...data, sourceType: Meraki.label },
    utm: {},
  });
};


export { createLead, updateLead};
