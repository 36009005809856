import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { HeaderWithSteps } from 'components/organisms/Header-with-steps/Header-with-steps';
import { IDetailStep } from 'models/info.steps.interface';
import { EditPlanRouter } from './routes/edit-plan.routes';
import { PopupExistProcess } from 'components/molecules/Popup-exist-porcess/Popup-exist-porcess';

export const EditPlan = () => {
  const navigate = useNavigate();
  const { planId } = useParams();
  const steps: IDetailStep[] = [
    {
      description: 'Editar plan',
      state: 'active',
      link: `/edit-plan/${planId}`,
      permissionToNavigate: true,
    },
    {
      description: 'Pagar',
      state: 'disabled',
      link: 'payment-information',
      permissionToNavigate: false,
    },
    {
      description: 'Propiedades',
      state: 'disabled',
      link: '/login',
      permissionToNavigate: false,
    },
  ];
  let stepsCopy: IDetailStep[] = [{ ...steps[0] }];
  const [step, setStep] = useState<IDetailStep[]>(steps);
  const [popUp, setPopUp] = useState(false);
  const handleStep = (indexStep: number) => {
    if (indexStep === 0) {
      stepsCopy = [{ ...steps[0] }];
    } else {
      stepsCopy = [...steps];
    }
    stepsCopy.forEach((element: IDetailStep, index: number) => {
      if (index === indexStep) {
        element.state = 'active';
      } else if (indexStep > index) {
        element.state = 'completed';
      } else {
        element.state = 'disabled';
      }
    });
    setStep([...stepsCopy]);
  };
  const showInfoPopUp = () => {
    setPopUp((status) => !status);
  };
  const exitProcess = () => {
    showInfoPopUp();
    navigate('/settings/payments');
  };
  return (
    <>
      <HeaderWithSteps
        navbarTemplate="light"
        steps={step}
        onClick={showInfoPopUp}
        notProgress={step.length === 1}
      />
      <div className="edit__plan__container">
        <EditPlanRouter handleStep={(index: number) => handleStep(index)} />
      </div>
      {popUp && (
        <PopupExistProcess
          title="¡Alto!"
          message="Está por dejar el proceso incompleto. Recuerda que al al dejarlo incompleto deberá iniciarlo nuevamente cuando regreses."
          labelButton="Continuar"
          labelButtonCancel="salir"
          type="warning"
          closePopUp={showInfoPopUp}
          exitProcess={exitProcess}
        />
      )}
    </>
  );
};
