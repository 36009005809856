import { ReactNode } from 'react';
import './Navbar.scss';
interface IPropsNavbar {
  children?: ReactNode;
  template?: 'light';
  notPaddingBottom?: boolean;
}
export const Navbar = (props: IPropsNavbar) => {
  return (
    <div
      className={`container__navbar ${
        props.notPaddingBottom ? 'not--padding__bottom' : ''
      } ${props.template ?? ''}`}
    >
      {props.children}
    </div>
  );
};
