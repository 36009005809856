import { Link } from 'components/atoms/Link/Link';
import { useNavigate } from 'react-router';
import './Have-account.scss';
export interface IPropsHaveAccount {
  onClick?: () => void;
}
export const HaveAccount = (props: IPropsHaveAccount) => {
  const navigate = useNavigate();
  const goToLogin = () => {
    navigate('/login', { state: null });
  };
  return (
    <div className="have__account__container">
      <span>¿Ya tienes cuenta?</span>
      <Link
        label="INGRESAR"
        onClick={props.onClick ? props.onClick : goToLogin}
        type="primary"
      ></Link>
    </div>
  );
};
