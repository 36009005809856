import { HeaderWithSteps } from 'components/organisms/Header-with-steps/Header-with-steps';
import { useFormik } from 'formik';
import { IDetailStep } from 'models/info.steps.interface';
import { useLocation, useNavigate } from 'react-router';
import { Elements } from '@stripe/react-stripe-js';
import {
  loadStripe,
  Stripe,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';
import { useLayoutEffect, useState, useRef } from 'react';
import { getKeyStripe } from 'utils/branch.utils';
import { useDispatch, useSelector } from 'react-redux';
import { IAppReducer } from 'models/reducer.interface';
import { PaymentMethodStripe } from 'components/molecules/Payment-method-stripe/Payment-method-stripe';
import { Button } from 'components/atoms/Button/Button';
import './Edit-payment-method.scss';
import {
  IFormEditPaymentMethod,
  IFormErrorsEditPaymentMethod,
} from './models/Edit-payment-method.interface';
import { updateUserCard } from 'shared/services/user';
import { setStateError } from 'store/actions/app.types';
import { PaymentMethodDlocal } from 'components/molecules/Payment-method-dlocal/Payment-method-dlocal';
import { IInputFocusEvent } from 'components/molecules/Payment-method-dlocal/models/payment-method-dlocal.interface';

interface LocationState {
  card: any;
}

export const EditPaymentMethod = () => {
  const dispatch = useDispatch();

  const branch = useSelector(
    (state: IAppReducer) => state.branchReducer.branch
  );
  const user = useSelector((state: IAppReducer) => state.authReducer);
  const location = useLocation();
  const { card } = location.state as LocationState;
  console.log('card',card)
  const [stripeLoad, setStripeLoad] = useState<Stripe | null>(null);
  const stripeRef = useRef(null);
  const dlocalRef = useRef(null);

  const navigate = useNavigate();
  const steps: IDetailStep[] = [
    {
      description: 'Editar método de pago',
      state: 'active',
      link: '/add-payment-method',
      permissionToNavigate: false,
    },
  ];

  useLayoutEffect(() => {
    const loadStripeElement = async () => {
      const stripeKey = getKeyStripe(branch);
      const stripe = (await loadStripe(stripeKey)) as Stripe;
      setStripeLoad(stripe);
    };
    loadStripeElement();
  }, [setStripeLoad]);

  const changeValueInput = (
    event:
      | StripeCardNumberElementChangeEvent
      | StripeCardExpiryElementChangeEvent
      | StripeCardCvcElementChangeEvent
      | IInputFocusEvent
  ) => {
    let value = '';
    if (event.complete) {
      value = 'complete';
    }
    let element: 'cardCvc' | 'cardExpiry' | 'cardNumber';
    if ('elementType' in event) {
      element = event.elementType;
    } else {
      element = event.element;
    }
    formAddPaymentMethod.setFieldValue(element, value);
    formAddPaymentMethod.values[element] = value;
  };

  const handleChangeCheck = (event: boolean | undefined) => {
    formAddPaymentMethod.setFieldValue('isPrincipal', !event);
  };
  const formAddPaymentMethodInitialValues: IFormEditPaymentMethod = {
    name: '',
    cardNumber: '',
    cardExpiry: '',
    cardCvc: '',
  };

  const formAddPaymentMethodValidate = (values: IFormEditPaymentMethod) => {
    const errors: IFormErrorsEditPaymentMethod = {};
    return errors;
  };

  const formAddPaymentMethod = useFormik({
    initialValues: formAddPaymentMethodInitialValues,
    validate: formAddPaymentMethodValidate,
    onSubmit: async (values) => {
      try {
        let token = '';
        if (branch === 'pr') {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          token = await (stripeRef?.current as any).getTokenCard();
        } else {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          token = await (dlocalRef?.current as any).getTokenCard();
        }
        // TODO: Armar objeto con datos del formulario reales que info se envia
        const cardInfo = {
          card_id: card.card_id || card.id,
          exp_month: "",
          exp_year: "",
          brand: "",
          card_token: token,
        };
        await updateUserCard(user.uid, cardInfo.card_id);
        navigate('/settings/payments');
      } catch (error) {
        dispatch(setStateError(true));
      } finally {
        formAddPaymentMethod.setSubmitting(false);
      }
    },
  });
  return (
    <>
      <HeaderWithSteps
        notProgress={true}
        steps={steps}
        onClick={() => {
          navigate('/settings/payments');
        }}
      />
      <Elements stripe={stripeLoad}>
        <form
          className="add__payment__method__container container__generic"
          onSubmit={formAddPaymentMethod.handleSubmit}
          noValidate
        >
          {branch === 'pr' ||
          !user.formToShow ||
          user.formToShow.toLocaleLowerCase() === 'stripe' ? (
            <PaymentMethodStripe
              id="add-card-number"
              inputNameLabel="Nombre del titular de la tarjeta"
              inputNameControl={'name'}
              handleChangeNameInput={formAddPaymentMethod.handleChange}
              handleBlurNameInput={formAddPaymentMethod.handleBlur}
              touchedNameInput={formAddPaymentMethod.touched.name}
              errorNameInput={formAddPaymentMethod.errors.name}
              valueNameInput={formAddPaymentMethod.values.name}
              handleChangeCheck={handleChangeCheck}
              form={formAddPaymentMethod}
              handleChangeStripeInput={changeValueInput}
              ref={stripeRef}
            />
          ) : (
            <PaymentMethodDlocal
              inputNameLabel="Nombre del titular de la tarjeta"
              inputNameControl="name"
              handleChangeNameInput={formAddPaymentMethod.handleChange}
              handleBlurNameInput={formAddPaymentMethod.handleBlur}
              touchedNameInput={formAddPaymentMethod.touched.name}
              errorNameInput={formAddPaymentMethod.errors.name}
              valueNameInput={formAddPaymentMethod.values.name}
              form={formAddPaymentMethod}
              handleChangeDlocalInput={changeValueInput}
              ref={dlocalRef}
              country={branch as 'co' | 'cr'}
            ></PaymentMethodDlocal>
          )}

          <Button
            color="primary"
            size="medium"
            template="primary"
            typeStyle="rounded"
            type="submit"
            label="Guardar"
            isDisabled={
              formAddPaymentMethod.isSubmitting ||
              !formAddPaymentMethod.isValid ||
              !formAddPaymentMethod.dirty
            }
            isLoading={formAddPaymentMethod.isSubmitting}
            onClick={formAddPaymentMethod.handleSubmit}
          ></Button>
        </form>
      </Elements>
    </>
  );
};
