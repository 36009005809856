import { ButtonClose } from '../Button-close/Button-close';
import './Alert.scss';
import sound from 'assets/sound/sonido_alerta.mp3';
import { useEffect } from 'react';

interface IPropsAlert {
  type: 'successful' | 'error' | 'warning';
  title: string;
  message: string;
  onClick?: () => void;
}

export const Alert = (props: IPropsAlert) => {
  const audio = new Audio(sound);
  const start = () => {
    audio.play();
  };
  useEffect(() => {
    if (audio) {
      start();
    }
  }, [audio]);
  return (
    <div className={`alert__contianer ${props.type ? props.type : ''}`}>
      <div className="alert__content">
        <div className="alert__container__header">
          <span className="alert__title">{props.title}</span>
          <ButtonClose onClick={props.onClick} />
        </div>
        <p
          className="alert__text"
          dangerouslySetInnerHTML={{ __html: props.message }}
        ></p>
      </div>
      <div className="alert__bar">
        <div className="alert__bar__progress"></div>
      </div>
    </div>
  );
};
