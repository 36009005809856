import { Icons } from 'assets/icons/icons.index';
import { Button } from 'components/atoms/Button/Button';
import { CardBody } from 'components/atoms/Card-body/Card-body';
import { Input } from 'components/atoms/Input/Input';
import { TagAlert } from 'components/atoms/Tag-alert/Tag-alert';
import { TagLabel } from 'components/atoms/Tag-label/Tag-label';
import * as Analytics from 'helpers/segment.helper';
import { PeriodicityOptions } from 'models/periodicity.interface';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { getCouponByCode } from 'shared/services/coupons';
import { formatPrice } from 'utils/payments.utils';
import { isPromoActive, plansIsIncludes } from 'utils/pricing.utils';
import './Form-cupon.scss';
import { ICouponInformation } from './models/Form-cupon.interface';

export interface IPropsFormCoupon {
  orderId: string;
  onResetCuponInfo: () => void;
  value?: string;
  periodicity: string;
  branch: string;
  coupon?: ICouponInformation;
  onGetCoupon: (coupon: ICouponInformation) => void;
  marginSmall?: boolean;
  planIds: number[];
  templete: 'outline' | 'default';
}

export const FormCoupon = forwardRef(function FormCoupon(
  props: IPropsFormCoupon,
  ref
) {
  useImperativeHandle(ref, () => {
    return { validateCoupon };
  });
  const formCouponRef = useRef(null);
  const [couponValue, setCouponValue] = useState<string | undefined>(
    props.value
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [typeAlert, setTypeAlert] = useState<'success' | 'error'>('success');
  const [coupon, setCoupon] = useState<ICouponInformation | undefined | null>(
    props.coupon
  );
  useEffect(() => {
    if (props.coupon && props.planIds.length) {
      validateCoupon(props.planIds, props.coupon as ICouponInformation);
    }
  }, [props.coupon]);

  const setErrorToCoupon = () => {
    setTypeAlert('error');
    setCoupon(null);
  };
  const resetCouponInfo = () => {
    if (coupon?.is_read_only) {
      return;
    }
    if (coupon) {
      Analytics.couponEvent('Coupon Removed', {
        order_id: props.orderId,
        coupon_id: coupon.coupon_code,
        coupon_name: coupon.name,
        discount: coupon.amount,
      });
    }
    setCouponValue('');
    setCoupon(undefined);
    props.onResetCuponInfo();
  };
  const getCoupon = async (plans?: number[], isOnlyCalculate = false) => {
    if (!couponValue) {
      return;
    }
    const planIds = plans ?? props.planIds;
    setIsLoading(true);
    try {
      const response = await getCouponByCode(
        (couponValue as string).toLocaleUpperCase()
      );
      if (response.data) {
        if (
          (response.data.attributes.is_anual &&
            props.periodicity === PeriodicityOptions.ANUAL &&
            plansIsIncludes(response.data.attributes, planIds)) ||
          (response.data.is_mensual &&
            props.periodicity === PeriodicityOptions.MENSUAL) ||
          plansIsIncludes(response.data.attributes, planIds)
        ) {
          setCoupon(response.data.attributes);
          if (!isOnlyCalculate) {
            props.onGetCoupon(response.data.attributes);
          }
          Analytics.couponEntered({
            order_id: props.orderId,
            coupon_id: response.data.attributes.coupon_code,
          });
          Analytics.couponEvent('Coupon Applied', {
            order_id: props.orderId,
            coupon_id: response.data.attributes.coupon_code,
            coupon_name: response.data.attributes.name,
            discount: response.data.attributes.amount,
          });
          setTypeAlert('success');
          return response.data.attributes;
        } else {
          setErrorToCoupon();
          Analytics.couponDenied({
            order_id: props.orderId,
            coupon: couponValue as string,
            reason: 'coupon invalid',
          });
        }
      } else {
        setErrorToCoupon();
        Analytics.couponDenied({
          order_id: props.orderId,
          coupon: couponValue as string,
          reason: 'coupon not exist',
        });
      }
    } catch (error) {
      setErrorToCoupon();
    } finally {
      setIsLoading(false);
    }
  };
  const validateCoupon = (
    planIds: number[],
    coupon: ICouponInformation,
    isOnlyCalculate = false
  ) => {
    if (!couponValue) {
      return;
    }
    try {
      if (
        (coupon.is_anual &&
          props.periodicity === PeriodicityOptions.ANUAL &&
          plansIsIncludes(coupon, planIds)) ||
        (coupon.is_mensual &&
          props.periodicity === PeriodicityOptions.MENSUAL) ||
        plansIsIncludes(coupon, planIds)
      ) {
        setCoupon(coupon);
        if (!isOnlyCalculate) {
          props.onGetCoupon(coupon);
        }
        Analytics.couponEntered({
          order_id: props.orderId,
          coupon_id: coupon.coupon_code,
        });
        Analytics.couponEvent('Coupon Applied', {
          order_id: props.orderId,
          coupon_id: coupon.coupon_code,
          coupon_name: coupon.name,
          discount: coupon.amount,
        });
        setTypeAlert('success');
        return coupon;
      } else {
        setErrorToCoupon();
        Analytics.couponDenied({
          order_id: props.orderId,
          coupon: couponValue,
          reason: 'coupon invalid',
        });
      }
    } catch (error) {
      setErrorToCoupon();
    }
  };
  return (
    <CardBody templete={props.templete}>
      <div
        className={`form__coupon__container ${
          props.marginSmall ? 'margin--small' : ''
        }`}
        ref={formCouponRef}
      >
        <div className="form__coupon__title">
          <h5>¿Tienes un cupón?</h5>
          <svg
            width="33"
            height="33"
            viewBox="0 0 33 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.5 26.7947C22.0228 26.7947 26.5 22.3175 26.5 16.7947C26.5 11.2718 22.0228 6.79468 16.5 6.79468C10.9772 6.79468 6.5 11.2718 6.5 16.7947C6.5 22.3175 10.9772 26.7947 16.5 26.7947Z"
              stroke="#001D3D"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.4979 14.6599C17.0824 14.6599 17.5562 15.1337 17.5562 15.7182V21.3621C17.5562 21.9466 17.0824 22.4204 16.4979 22.4204C15.9135 22.4204 15.4397 21.9466 15.4397 21.3621V15.7182C15.4397 15.1337 15.9135 14.6599 16.4979 14.6599Z"
              fill="#001D3D"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.4397 11.6017C15.4397 11.0172 15.9135 10.5435 16.4979 10.5435H16.5121C17.0965 10.5435 17.5703 11.0172 17.5703 11.6017C17.5703 12.1862 17.0965 12.6599 16.5121 12.6599H16.4979C15.9135 12.6599 15.4397 12.1862 15.4397 11.6017Z"
              fill="#001D3D"
            />
          </svg>
        </div>
        <div className="form__coupon__content">
          <Input
            label="Agregar Cupón"
            id="form__coupon__content__input"
            type="alphanumeric"
            name="coupon"
            onChange={(e) => setCouponValue(e.target.value)}
            onBlur={(e) => {
              if (e.target.value) {
                Analytics.couponEntered({
                  order_id: props.orderId,
                  coupon_id: e.target.value,
                });
              }
            }}
            value={couponValue || ''}
            placeholder=""
            isTouched={false}
            errorInput={''}
            isRequired={false}
            isDisabled={coupon?.is_read_only}
            WrapperClassName="w-70"
          />
          <Button
            className="button__coupon"
            size="medium"
            template="primary"
            color="secondary"
            typeStyle="square"
            type="button"
            isDisabled={!couponValue || coupon?.is_read_only}
            onClick={() => getCoupon()}
            isLoading={isLoading}
          >
            Aplicar
          </Button>
        </div>
        {((couponValue && coupon === null) ||
          (couponValue && coupon?.coupon_code)) && (
          <div className="form__coupon__info__container">
            <TagAlert type={typeAlert} size="medium" typeStyle="square">
              Cupón <strong>{couponValue.toLocaleUpperCase()}</strong>{' '}
              {typeAlert === 'error' ? 'no válido' : 'aplicado'}
            </TagAlert>
            {coupon && coupon.amount !== undefined && (
              <TagLabel
                type="secondary"
                typeStyle="square"
                onClick={!coupon.is_read_only ? resetCouponInfo : undefined}
              >
                <img src={Icons.iconTag} alt="icono tag" />
                <div className="tag__label__info__coupon">
                  <span className="tag__label__info__coupon--bold">
                    {couponValue?.toLocaleUpperCase()}
                  </span>
                  {!isPromoActive && (
                    <span>
                      {coupon.is_percent
                        ? `${coupon.amount}% dcto.`
                        : `-$${formatPrice(coupon.amount, props.branch)}`}{' '}
                    </span>
                  )}
                </div>
              </TagLabel>
            )}
          </div>
        )}
      </div>
    </CardBody>
  );
});
