import './Button.scss';
import * as CSS from 'csstype';
import { ReactNode } from 'react';

export interface IPropsButton {
  label?: string;
  className?: string;
  typeStyle: 'rounded' | 'square';
  onClick?: () => void;
  type?: 'submit' | 'button';
  template: 'primary' | 'secondary' | 'tertiary';
  color: 'primary' | 'secondary' | 'tertiary';
  isDisabled?: boolean;
  children?: ReactNode;
  isLoading?: boolean;
  styleInLine?: CSS.Properties<string | number>;
  testid?: string;
  size: 'small' | 'medium' | 'large';
  dataClickType?: string;
  dataClickName?: string;
  dataClickObjective?: string;
}

export const Button = (props: IPropsButton) => {
  return (
    <button
      data-click_type={props.dataClickType}
      data-click_name={props.dataClickName}
      data-click_objective={props.dataClickObjective}
      data-testid={props.testid}
      type={props.type}
      onClick={
        props.isLoading || props.isDisabled
          ? () => {
              return;
            }
          : props.onClick
      }
      disabled={props.isDisabled}
      className={`btn__button ${props.isDisabled ? 'disabled' : ''} ${
        props.isLoading ? 'loading' : ''
      }  btn__button--${props.typeStyle}  btn__button--${
        props.template
      }  btn__button--${props.size} btn__button__color--${props.color} ${
        props.className ? props.className : ''
      }`}
      style={props.styleInLine}
    >
      {props.isLoading && (
        <div className="loader__container">
          <div className="loader"></div>
        </div>
      )}

      {!props.children ? (
        <span className="btn__label">{props.label}</span>
      ) : (
        props.children
      )}
    </button>
  );
};
