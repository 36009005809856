import { Link } from 'react-router-dom';

export const Home = () => {

  return (
    <>
      <section style={{ display: 'flex', flexDirection: 'column' , lineHeight: '24px'}}>
        <h1>Link de paginas</h1>
        <Link to={'/create-account?planId=2'}>Crear cuenta / Informacion personal</Link>
        <Link to={'/create-account/payment-information'}>Crear cuenta / Informacion de Pago</Link>
        <Link to={'/settings'}>Ajustes</Link>
        <Link to={'/settings/properties'}>Propiedades</Link>
        <Link to={'/property'}>Ver Propiedad</Link>
        <Link to={'/edit-property/120'}>Editar Propiedad</Link>
      </section>
    </>
  );
};
