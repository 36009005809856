import { forwardRef } from 'react';
import './Payment-method-dlocal.scss';
import { ButtonDelete } from 'components/atoms/Button-delete/Button-delete';
import { Images } from 'assets/images/images.index';
import { Input } from 'components/atoms/Input/Input';
import {
  IPropsPaymentMethodDlocal,
  PaymentMethodDlocalRef,
} from './models/payment-method-dlocal.interface';
import { ContainersElements } from './constants/payment-method-dlocal.constants';
import { Checkbox } from 'components/atoms/Checkbox/Checkbox';
import { usePaymentMethodDlocal } from './hooks/usePaymentMethodDlocal';

export const PaymentMethodDlocal = forwardRef<
  PaymentMethodDlocalRef,
  IPropsPaymentMethodDlocal
>((props, ref) => {
  const { dLocalRef, fields, focusInput, stateInputDlocal, clearInput } =
    usePaymentMethodDlocal(props, ref);
  return (
    <div className={`payment__method__dlocal`} ref={dLocalRef}>
      <Input
        label={props.inputNameLabel}
        id="payment-method-name-dlocal"
        type="text"
        name={props.inputNameControl}
        onChange={props.handleChangeNameInput}
        onBlur={props.handleBlurNameInput}
        value={props.valueNameInput}
        isTouched={props.touchedNameInput}
        errorInput={props.errorNameInput}
        errorLabel={props.errorNameInput}
        isDisabled={false}
        isRequired={true}
        form={props.form}
      />
      <div className="container__input">
        <label
          id={`label__${fields[0].alias}`}
          htmlFor={ContainersElements.cardNumber}
          onClick={() => focusInput('cardNumber')}
          className={`input__label ${
            (!stateInputDlocal.cardNumber.isEmpty &&
              !stateInputDlocal.cardNumber.isValid) ||
            stateInputDlocal.cardNumber.errorMessage
              ? 'label--error input__label--value'
              : !stateInputDlocal.cardNumber.isEmpty &&
                stateInputDlocal.cardNumber.isValid
              ? 'input__label--value'
              : ''
          }`}
        >
          Número de Tarjeta
          <img src={Images.inputRequired} alt="Campo requerido" />
        </label>
        <div
          id={ContainersElements.cardNumber}
          className={`input__generic ${
            !stateInputDlocal.cardNumber.isEmpty ? 'completed' : ''
          } ${stateInputDlocal.cardNumber.errorMessage ? 'error' : ''}`}
          onClick={() => focusInput('cardNumber')}
        ></div>

        {((!stateInputDlocal.cardNumber.isEmpty &&
          !stateInputDlocal.cardNumber.isValid) ||
          stateInputDlocal.cardNumber.errorMessage) && (
          <span className="input__error">
            {stateInputDlocal.cardNumber.errorMessage}
          </span>
        )}
        <div className="input__button__action">
          {!stateInputDlocal.cardNumber.isEmpty && (
            <div className="container__button__delete">
              <ButtonDelete
                isDisabled={false}
                error={!stateInputDlocal.cardNumber.isValid ? true : false}
                onClick={() => clearInput('cardNumber')}
              />
            </div>
          )}
        </div>
      </div>
      <div className="payment__method__stripe__container__elements">
        <div className="container__input">
          <label
            id={`label__${fields[1].alias}`}
            htmlFor={ContainersElements.cardExpiration}
            onClick={() => focusInput('cardExpiry')}
            className={`input__label ${
              (!stateInputDlocal.cardExpiry.isEmpty &&
                !stateInputDlocal.cardExpiry.isValid) ||
              stateInputDlocal.cardExpiry.errorMessage
                ? 'label--error input__label--value'
                : !stateInputDlocal.cardExpiry.isEmpty &&
                  stateInputDlocal.cardExpiry.isValid
                ? 'input__label--value'
                : ''
            }`}
          >
            Fecha de vencimiento
            <img src={Images.inputRequired} alt="Campo requerido" />
          </label>
          <div
            id={ContainersElements.cardExpiration}
            className={`input__generic ${
              !stateInputDlocal.cardExpiry.isEmpty ? 'completed' : ''
            } ${stateInputDlocal.cardExpiry.errorMessage ? 'error' : ''}`}
            onClick={() => focusInput('cardExpiry')}
          ></div>
          {((!stateInputDlocal.cardExpiry.isEmpty &&
            !stateInputDlocal.cardExpiry.isValid) ||
            stateInputDlocal.cardExpiry.errorMessage) && (
            <span className="input__error">
              {stateInputDlocal.cardExpiry.errorMessage}
            </span>
          )}
          <div className="input__button__action">
            {!stateInputDlocal.cardExpiry.isEmpty && (
              <div className="container__button__delete">
                <ButtonDelete
                  isDisabled={false}
                  error={!stateInputDlocal.cardExpiry.isValid ? true : false}
                  onClick={() => clearInput('cardExpiry')}
                />
              </div>
            )}
          </div>
        </div>
        <div className="container__input">
          <label
            id={`label__${fields[2].alias}`}
            htmlFor={ContainersElements.carCVV}
            onClick={() => focusInput('cardCvc')}
            className={`input__label ${
              (!stateInputDlocal.cardCvc.isEmpty &&
                !stateInputDlocal.cardCvc.isValid) ||
              stateInputDlocal.cardCvc.errorMessage
                ? 'label--error input__label--value'
                : !stateInputDlocal.cardCvc.isEmpty &&
                  stateInputDlocal.cardCvc.isValid
                ? 'input__label--value'
                : ''
            }`}
          >
            CVV
            <img src={Images.inputRequired} alt="Campo requerido" />
          </label>
          <div
            id={ContainersElements.carCVV}
            className={`input__generic ${
              !stateInputDlocal.cardCvc.isEmpty ? 'completed' : ''
            } ${stateInputDlocal.cardCvc.errorMessage ? 'error' : ''}`}
            onClick={() => focusInput('cardCvc')}
          ></div>

          {((!stateInputDlocal.cardCvc.isEmpty &&
            !stateInputDlocal.cardCvc.isValid) ||
            stateInputDlocal.cardCvc.errorMessage) && (
            <span className="input__error">
              {stateInputDlocal.cardCvc.errorMessage}
            </span>
          )}
          <div className="input__button__action">
            {!stateInputDlocal.cardCvc.isEmpty && (
              <div className="container__button__delete">
                <ButtonDelete
                  isDisabled={false}
                  error={!stateInputDlocal.cardCvc.isValid ? true : false}
                  onClick={() => clearInput('cardCvc')}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {props.checkBoxNameControl && (
        <div className="payment__method__stripe__container__checkbox">
          <Checkbox
            id="mark-as-main-method"
            name={props.checkBoxNameControl}
            onChange={
              props.handleChangeCheck
                ? props.handleChangeCheck
                : () => {
                    return;
                  }
            }
            checked={props.checkBoxValue}
          >
            Marca como método principal
          </Checkbox>
        </div>
      )}
    </div>
  );
});

PaymentMethodDlocal.displayName = 'PaymentMethodDlocal';