import { ReactNode } from 'react';
import './Card-body.scss';

export interface IPropsCardBody {
  children: ReactNode;
  templete?: 'outline' | 'default';
  overflowIsVisible?: boolean;
  style?: React.CSSProperties;
}

export const CardBody = (props: IPropsCardBody) => {
  return (
    <div
      style={props.style}
      className={`card__body__container ${
        props.overflowIsVisible ? 'overflowIsVisible' : ''
      }`}
    >
      <div className={`card__body__content ${props.templete ?? ''}`}>
        {props.children}
      </div>
    </div>
  );
};
