import { Divider } from 'components/atoms/Divider/Divider';
import { ButtonAddItemSkeleton } from 'components/skeleton/atoms/Button-add-item-skeleton/Button-add-item-skeleton';
import './Title-section-skeleton.scss';

export const TitleSectionSkeleton = () => {
  return (
    <>
      <div className="title__section__skeleton__container">
        <div className="title skeleton"></div>
        <ButtonAddItemSkeleton></ButtonAddItemSkeleton>
      </div>
      <Divider margin="small"></Divider>
    </>
  );
};
