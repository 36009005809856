import { IAppReducer } from 'models/reducer.interface';
import {
  IPlanDetailAtrebuttes,
  IPlanUserProduct,
} from 'pages/Plan-detail/models/Plan-detail.inteface';
import { IPropertiesInfo } from 'pages/Settings/models/Properties.interface';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import {
  getPropertyForPlan,
  getUserPlans,
  removePlanFromUser,
  tryToUpdateSubscription,
  tryToUpdateSubscriptionCustomAmount,
} from 'shared/services/plans';
import { getUserCards, getUserProperties } from 'shared/services/user';

import {
  loadStripe,
  Stripe,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';
import { Images } from 'assets/images/images.index';
import { IOptionSelect } from 'components/atoms/Select/Select';
import { IInputFocusEvent } from 'components/molecules/Payment-method-dlocal/models/payment-method-dlocal.interface';
import { ICouponInformation } from 'components/organisms/Form-cupon/models/Form-cupon.interface';
import { useFormik } from 'formik';
import { ICreditCar } from 'models/card.interface';
import { PeriodicityOptions } from 'models/periodicity.interface';
import { IProduct } from 'pages/Checkout/pages/Payment-information/models/Payment-information.interface';
import { IUserSubscription } from 'pages/Edit-Property/models/Edit-Property.interface';
import { getYear } from 'shared/constants/year.constants';
import { createProductProperties } from 'shared/services/products';
import { getKeyStripe } from 'utils/branch.utils';
import { getContactInfo } from 'utils/connect-info.utils';
import { calculateTotal, getDiscountCoupon } from 'utils/pricing.utils';
import { RequestDataEditPlan } from '../models/Edit-plan-information.interface';

export const useEditPlanInformation = (props: {
  handleStep: (index: number) => void;
}) => {
  const stripeRef = useRef(null);
  const dlocalRef = useRef(null);
  const { authReducer, branchReducer } = useSelector(
    (state: IAppReducer) => state
  );
  const { planId, subscriptionId } = useParams();
  const navigate = useNavigate();
  const [planInformation, setPlanInformation] =
    useState<IPlanDetailAtrebuttes>();
  const [periodicity, setPeriodicity] = useState<PeriodicityOptions>();

  const [propertiesInfoSelect, setIPropertiesInfoSelect] = useState<
    IOptionSelect[]
  >([{ value: '', description: '', info: '' }]);
  const [propertiesInfo, setIPropertiesInfo] = useState<IPropertiesInfo[]>([]);
  const [typeProperty, setTypeProperty] = useState<number>();
  const [infoProperties, setInfoProperties] =
    useState<
      | {
          quantity: number;
        }
      | undefined
    >(undefined);
  const [planItemsQuantity, setPlanItemsQuantity] = useState<number>(0);
  const [propertiesNumberEdit, setPropertiesNumberEdit] = useState<number[]>(
    []
  );
  const [isNewCreditCard, setIsNewCreditCard] = useState<boolean>(false);
  const [amountCustom, setAmountCustom] = useState<string>('');
  const [isOpenModalReason, setIsOpenModalReason] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [userCards, setUserCards] = useState<any>(null);
  const [addNewProperty, setAddNewProperty] = useState<boolean>(false);
  const [showPopUpDelete, setShowPopUpDelete] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [processIsIncomplete, setProcessIsIncomplete] =
    useState<boolean>(false);
  const [isLoadingScreen, setIsLoadingScreen] = useState<boolean>(true);
  const [userPlans, setUserPlans] =
    useState<IUserSubscription[] | undefined | null>(undefined);
  const [stripeLoad, setStripeLoad] = useState<Stripe | null>(null);
  const [prices, setPrices] = useState({
    current: 0,
    refund: 0,
    newPrice: 0,
    priceByUnit: 0,
  });
  const [errorPayment, setErrorPayment] = useState<any>(null);
  const [phoneBranch, setPhoneBranch] = useState<string>('');

  const {
    authReducer: user,
    branchReducer: { branch, country_id },
    editPlanReducer: { data: editPlanData },
  } = useSelector((state: IAppReducer) => state);
  const contentEl = useRef<HTMLDivElement>(null);
  const year = getYear(branch);
  const formCouponRef =
    useRef<{
      validateCoupon: (
        planIds: number[],
        coupon: ICouponInformation
      ) => Promise<void>;
    }>(null);

  useLayoutEffect(() => {
    props.handleStep && props.handleStep(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    const phone = getContactInfo(branchReducer.branch).phone;
    setPhoneBranch(phone);
  }, [branchReducer.branch]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formEditPlanInitialValues: any = {
    card_name: '',
    cardNumber: '',
    cardExpiry: '',
    cardCvc: '',
    id_card: '',
    quantity: 0,
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formAddPropertyInitialValues: any = {};
  useLayoutEffect(() => {
    const loadStripeElement = async () => {
      const stripeKey = getKeyStripe(branchReducer.branch);
      const stripe = (await loadStripe(stripeKey)) as Stripe;
      setStripeLoad(stripe);
    };
    loadStripeElement();
    props.handleStep && props.handleStep(1);
  }, [setStripeLoad]);
  useEffect(() => {
    (async () => {
      if (!country_id) {
        return;
      }
      const response: IPlanDetailAtrebuttes[] = await getPropertyForPlan(
        user.uid,
        planId as string
      );
      const responsePlans = await getUserPlans(
        user.uid,
        subscriptionId as string
      );
      setUserPlans(responsePlans);
      const idTyeProperty =
        response[0].attributes?.plan_id.data.attributes.productos.data[0]
          .attributes.product_type_id.data.id;
      setTypeProperty(idTyeProperty);
      setPlanInformation(response[0]);
      setPeriodicity(
        response[0]?.attributes.plan_id.data.attributes.productos.data[0]
          .attributes.periocity_id.data.attributes.name as PeriodicityOptions
      );
      const infoPrices = {
        quantity:
          response[0].attributes.plan_id.data.attributes.user_products[0]
            .quantity,
      };
      setInfoProperties({
        ...infoPrices,
      });
      const quantity = (response[0] as IPlanDetailAtrebuttes).attributes.plan_id
        .data.attributes.user_products[0].quantity;
      const max_additional = (response[0] as IPlanDetailAtrebuttes).attributes
        .plan_id.data.attributes.productos.data[0].attributes.max_additional;

      setPlanItemsQuantity(
        response[0].attributes.plan_id.data.attributes.user_products[0].quantity
      );
      setPrices({
        ...prices,
        current: Number(response[0].attributes.stripe_amount),
        priceByUnit:
          quantity >= max_additional
            ? (response[0] as IPlanDetailAtrebuttes).attributes.plan_id.data
                .attributes.productos.data[0].attributes.additional_price
            : (response[0] as IPlanDetailAtrebuttes).attributes.plan_id.data
                .attributes.productos.data[0].attributes.price,
      });

      const allProperties = await getProperties();
      filterOptions(0, allProperties, response[0]);
      setIsLoadingScreen(false);
    })();
  }, [country_id]);
  useEffect(() => {
    (async () => {
      try {
        const responseCards = await getUserCards(authReducer.uid);
        setUserCards(responseCards);
        const cardPrincipal = responseCards.find(
          (p: ICreditCar) => p.is_principal
        );
        handleSelectCard(cardPrincipal.id);
      } catch (error) {
        console.log('Notificacr errores', error);
      }
    })();
  }, [setUserCards]);

  const getProperties = async () => {
    try {
      const responseProperties: IPropertiesInfo[] = await getUserProperties(
        user.uid
      );
      setIPropertiesInfo(responseProperties);
      return responseProperties;
    } catch (error) {
      // setErrot(error);
      console.log('error', error);
    }
  };
  const setOptionsSelect = async (
    data: IPropertiesInfo[],
    difference: number
  ) => {
    try {
      let make: undefined | { valor: string } = undefined;
      let year: undefined | { valor: string } = undefined;
      const options = data.map((item) => {
        const licensePlate = item.attributes.property_attributes.find(
          (p) =>
            p.propiedad.name === 'Placa' ||
            p.propiedad.name === 'Direccion 1' ||
            p.propiedad.name === 'Nombre'
        );
        if (item.attributes.tipo_producto.data.id === 1) {
          make = item.attributes.property_attributes.find(
            (p) => p.propiedad.name === 'Marca'
          );
          year = item.attributes.property_attributes.find(
            (p) => p.propiedad.name === 'Año'
          );
        } else {
          make = undefined;
          year = undefined;
        }
        const itemIsDisabled = propertiesInfoSelect.find(
          (p) => p.value === item.id
        )?.isDisabled;
        return {
          value: item.id,
          description: `${licensePlate?.valor}${
            make ? ' | ' + make.valor : ''
          }${year ? ' | ' + year.valor : ''}`,
          isDisabled: !!itemIsDisabled,
          readonly: false,
          icon: '',
        };
      }, []);
      if (difference >= 1) {
        options.unshift({
          value: 0,
          description: `Agregar auto nuevo`,
          isDisabled: false,
          readonly: true,
          icon: Images.plus,
        });
      }
      setIPropertiesInfoSelect(options);
    } catch (error) {
      // setErrot(error);
      console.log('error', error);
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formEditPlanValidate = (values: any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const errors: any = {};
    if (planItemsQuantity < (infoProperties?.quantity as number)) {
      propertiesNumberEdit.forEach((item, index) => {
        if (!values[`property_${index}`]) {
          errors[`property_${index}`] = 'Campo requerido';
        }
      });
    } else {
      if (isNewCreditCard) {
        if (!values.card_name) {
          errors.card_name = 'Este campo es requerido';
        }
        if (!values.cardNumber) {
          errors.cardNumber = 'Este campo es requerido';
        }
        if (!values.cardExpiry) {
          errors.cardExpiry = 'Este campo es requerido';
        }
        if (!values.cardCvc) {
          errors.cardCvc = 'Este campo es requerido';
        }
      } else {
        if (!values.id_card) {
          errors.card_id = 'Este campo es requerido';
        }
      }
    }
    return errors;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formAddPropertyValidate = (values: any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const errors: any = {};
    planInformation?.attributes.plan_id.data.attributes.productos.data[0].attributes.properties.data.forEach(
      (atribute) => {
        if (
          atribute.attributes.required &&
          !values[`${atribute.attributes.name}__${atribute.id}`]
        ) {
          errors[`${atribute.attributes.name}__${atribute.id}`] =
            'Campo requerido';
        } else {
          if (
            `${atribute.attributes.name}__${atribute.id}`.includes('Año') &&
            Number(values[`${atribute.attributes.name}__${atribute.id}`]) < year
          ) {
            errors[
              `${atribute.attributes.name}__${atribute.id}`
            ] = `El año debe ser mayor a ${year}`;
          }
        }
      }
    );
    return errors;
  };
  const formEditPlan = useFormik({
    initialValues: formEditPlanInitialValues,
    validate: formEditPlanValidate,
    onSubmit: () => {
      goToEditPlan();
    },
  });
  const formReasonInitialValues = {
    reason: '',
  };
  const formReasonValidate = (values: { reason: string | undefined }) => {
    const errors: any = {};
    if (!values.reason) {
      errors.amount = 'Este campo es requerido';
    }
    return errors;
  };
  const formReason = useFormik({
    initialValues: formReasonInitialValues,
    validate: formReasonValidate,
    onSubmit: async (values) => {
      formReason.setSubmitting(false);
    },
  });
  const formAddProperty = useFormik({
    initialValues: formAddPropertyInitialValues,
    validate: formAddPropertyValidate,
    onSubmit: async () => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const dataRequest: any[] = [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const dataAtributes: any[] = [];
        const increaseDecreasPlan =
          planItemsQuantity - (infoProperties ? infoProperties.quantity : 0) > 0
            ? 1
            : -1;
        planInformation?.attributes.plan_id.data.attributes.productos.data[0].attributes.properties.data.forEach(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (atribute, indexAtribute: number) => {
            dataAtributes[indexAtribute] = {
              valor:
                formAddProperty.values[
                  `${atribute.attributes.name}__${atribute.id}`
                ],
              status: true,
              propiedad: atribute.id,
              user_product: 0,
              product_type_id:
                planInformation?.attributes.plan_id.data.attributes.productos
                  .data[0].attributes.product_type_id.data.id,
            };
          }
        );
        dataRequest[0] = {
          atributos: [...dataAtributes],
        };
        await createProductProperties(user.uid, {
          data: [...dataRequest],
        });
        const newData = await getProperties();
        formAddProperty.resetForm();
        filterOptions(increaseDecreasPlan, newData);
        setAddNewProperty(false);
      } catch (error) {
        // TODO: Notificar error
      } finally {
        formAddProperty.setSubmitting(false);
      }
    },
  });
  const handlePlanItemsQuantity = (value: number) => {
    if (!infoProperties) {
      return;
    }
    const differenceOfQuantity = value - (infoProperties?.quantity as number);
    if (formEditPlan.values[`property_${Math.abs(differenceOfQuantity)}`]) {
      formEditPlan.setFieldValue(
        `property_${Math.abs(differenceOfQuantity)}`,
        ''
      );
    }
    const arrayEmptOfQuantities = Array(Math.abs(differenceOfQuantity)).fill(
      ''
    );
    setPropertiesNumberEdit([...arrayEmptOfQuantities]);
    setPlanItemsQuantity(value);
    formEditPlan.setFieldValue('quantity', value);
    filterOptions(differenceOfQuantity);
    getInfoNewPrices(value, differenceOfQuantity);
  };

  const getDaysBetweenDates = (start: Date, end: Date): number =>
    (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24);

  const getProration = (
    user_products: IPlanUserProduct,
    price: number,
    getDaysBetweenDates: (start: Date, end: Date) => number
  ): number => {
    if (!user_products) {
      throw new Error('No se encontraron productos de usuario.');
    }

    const { date_init, date_expire } = user_products;
    if (!date_init || !date_expire) {
      throw new Error('Fechas de inicio o expiración no válidas.');
    }

    const periodStart = new Date(date_init.split('T')[0]);
    const periodEnd = new Date(date_expire.split('T')[0]);
    const prorationTime = new Date(new Date().toISOString().split('T')[0]);

    const periodLength = getDaysBetweenDates(periodStart, periodEnd);
    const elapsedSinceStart = getDaysBetweenDates(periodStart, prorationTime);

    return (price / periodLength) * (periodLength - elapsedSinceStart);
  };

  const getInfoNewPrices = async (
    quantityOfPlan: number,
    difference: number
  ) => {
    if (!planInformation) {
      throw new Error('planInformation undefined');
    }

    const {
      productos,
      amount: amountPlan,
      user_products,
    } = planInformation.attributes.plan_id.data.attributes;

    const additionalDiscount = productos.data[0].attributes.additional_price;
    let price = 0;

    const max_additional = productos.data[0].attributes.max_additional;
    if (quantityOfPlan >= max_additional - 1) {
      price = additionalDiscount;
    } else {
      price = amountPlan;
    }
    const { total } = setInfoSubscription(planInformation?.id, quantityOfPlan);
    const invoice = productos.data[0].attributes.proration
      ? getProration(user_products[0], price, getDaysBetweenDates)
      : price;

    const subTotal = invoice * difference;
    let refundAmount = 0;
    if (difference > 0) {
      refundAmount = subTotal;
    } else {
      const valueWhitDiscount = getDiscountCoupon(
        user_products[0].coupon,
        subTotal
      );
      refundAmount = (subTotal - valueWhitDiscount) * -1;
    }
    setPrices((oldPrices) => {
      return {
        ...oldPrices,
        refund: refundAmount,
        newPrice: total,
        priceByUnit: price,
      };
    });
  };
  const goToEditPlan = () => {
    if (planItemsQuantity === 0) {
      setShowPopUpDelete(true);
    } else {
      let infoIsComplete = true;
      propertiesNumberEdit.forEach((item, index) => {
        if (!formEditPlan.values[`property_${index}`]) {
          infoIsComplete = false;
        }
      });
      if (infoIsComplete) {
        handleCloseModalReason();
      } else {
        handleClosePopUpProcessIncompleted();
      }
    }
  };

  const editPlan = async () => {
    try {
      setIsLoading(true);
      setErrorPayment(false);
      let source = '';
      if (!formEditPlan.values.id_card) {
        if (
          branch === 'pr' ||
          planInformation?.attributes?.plan_id?.data?.attributes
            ?.payment_provider?.id === 1 ||
          !planInformation?.attributes?.plan_id?.data?.attributes
            ?.payment_provider?.id
        ) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          source = await (stripeRef?.current as any).getTokenCard();
        } else {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          source = await (dlocalRef?.current as any).getTokenCard();
        }
        formEditPlan.setFieldValue('id_card', source);
      }
      const userProductId = planInformation?.attributes.plan_id.data.attributes
        .user_products[0].id as number;
      const idProduct = planInformation?.attributes.plan_id.data.attributes
        .productos.data[0].id as number;
      const data: RequestDataEditPlan = {
        products: [
          {
            id: idProduct as number,
            quantity: planItemsQuantity,
            userProductId,
          },
        ],
        source: source || formEditPlan.values.id_card,
        coupon: '',
        propertiesToDelete: [],
        propertiesToAdd: [],
      };
      const dataMapping: { id: number; userProductId: number }[] = [];
      propertiesNumberEdit.forEach((_, index) => {
        if (formEditPlan.values[`property_${index}`]) {
          dataMapping.push({
            id: formEditPlan.values[`property_${index}`],
            userProductId,
          });
        }
      });
      if (planItemsQuantity > (infoProperties?.quantity as number)) {
        data.propertiesToDelete = [];
        data.propertiesToAdd = [...dataMapping];
      } else {
        data.propertiesToDelete = [...dataMapping];
        data.propertiesToAdd = [];
      }
      if (amountCustom) {
        await tryToUpdateSubscriptionCustomAmount(planId as string, user.uid, {
          ...data,
          reason: formReason.values.reason,
          amount: amountCustom,
          agentId: authReducer.agentId as number,
        });
      } else {
        await tryToUpdateSubscription(planId as string, user.uid, {
          ...data,
          reason: formReason.values.reason,
          agentId: authReducer.agentId as number,
        });
      }
      navigate(`/plan-detail/${planId}`, { state: { editPlan: true } });
    } catch (error) {
      setErrorPayment(error);
      window.scrollTo({ behavior: 'smooth', top: 0 });
    } finally {
      handleCloseModalReason();
      setIsLoading(false);
    }
  };

  const filterOptions = (
    difference: number,
    data?: IPropertiesInfo[],
    planData?: IPlanDetailAtrebuttes
  ) => {
    const plan = (planData ?? planInformation)?.attributes.plan_id.data
      .attributes;
    const userProduct = plan?.user_products[0].id;
    let dataFilter = [];
    if (difference < 0) {
      dataFilter = (data ?? propertiesInfo).filter(
        (p) =>
          p.attributes.productos_usuario.data.length > 0 &&
          p.attributes.productos_usuario.data[0].id === userProduct
      );
    } else {
      dataFilter = (data ?? propertiesInfo).filter(
        (p) =>
          p.attributes.productos_usuario.data.length === 0 &&
          p.attributes.tipo_producto.data.id ===
            plan?.productos.data[0].attributes.product_type_id.data.id
      );
    }
    setOptionsSelect(dataFilter, difference);
  };
  const handleSelectOption = (
    value: number | string,
    index: number | undefined
  ) => {
    if ((value as number) <= 0) {
      setAddNewProperty(true);
      return;
    } else {
      if (formEditPlan.values[`property_${index}`]) {
        const indexItemOld = propertiesInfoSelect.findIndex(
          (p) => p.value === formEditPlan.values[`property_${index}`]
        );
        propertiesInfoSelect[indexItemOld].isDisabled = false;
      }
      const indexItem = propertiesInfoSelect.findIndex(
        (p) => p.value === value
      );
      propertiesInfoSelect[indexItem].isDisabled = true;
      setIPropertiesInfoSelect([...propertiesInfoSelect]);
      formEditPlan.setFieldValue(`property_${index}`, value);
    }
  };

  const changeValueInputStripe = (
    event:
      | StripeCardNumberElementChangeEvent
      | StripeCardExpiryElementChangeEvent
      | StripeCardCvcElementChangeEvent
      | IInputFocusEvent
  ) => {
    let value = '';
    if (event.complete) {
      value = 'complete';
    }
    let element: 'cardCvc' | 'cardExpiry' | 'cardNumber';
    if ('elementType' in event) {
      element = event.elementType;
    } else {
      element = event.element;
    }
    formEditPlan.setFieldValue(element, value);
    formEditPlan.values[element] = value;
  };

  const handleSelectCard = (id: string | number) => {
    formEditPlan.setFieldValue('id_card', id);
    setIsNewCreditCard(false);
  };

  const handleCloseModalReason = () => {
    setIsOpenModalReason((open) => !open);
  };
  const handleClosePopUpProcessIncompleted = () => {
    setProcessIsIncomplete((processIsIncompleted) => !processIsIncompleted);
  };
  const handleInfoIncompleted = () => {
    handleClosePopUpProcessIncompleted();
    handleCloseModalReason();
  };
  const newPaymentMethod = () => {
    formEditPlan.setFieldValue('id_card', '');
    setIsNewCreditCard((isNewCreditCard) => !isNewCreditCard);
    contentEl?.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const deleteItemFromUser = async () => {
    try {
      setIsLoading(true);
      await removePlanFromUser(user.uid, planId as string);
      setShowPopUpDelete(false);
      navigate(`/settings/payments`);
    } catch (error) {
      setErrorPayment(true);
    } finally {
      setIsLoading(false);
    }
  };

  const setInfoSubscription = (planId: number, quantityOfPlan: number) => {
    let arrayPlansFilter = userPlans as IUserSubscription[];
    const quantityForPlan = arrayPlansFilter.map((plan: any) => {
      return plan.id === planId
        ? quantityOfPlan
        : plan.attributes.plan_id.data.attributes.user_products[0].quantity;
    }, []);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    arrayPlansFilter = arrayPlansFilter.map((item: any) => {
      return {
        ...item,
        attributes: {
          ...item.attributes,
          productos: {
            data: [
              {
                attributes: {
                  ...item.attributes.plan_id.data.attributes.productos.data[0]
                    .attributes,
                },
              },
            ],
          },
        },
      };
    });
    const pricing = calculateTotal(
      arrayPlansFilter as unknown as IProduct[],
      quantityForPlan,
      undefined
    );
    return pricing;
  };

  return {
    stripeLoad,
    branch,
    errorPayment,
    phoneBranch,
    authReducer,
    propertiesInfo,
    planInformation,
    periodicity,
    isLoadingScreen,
    typeProperty,
    planItemsQuantity,
    handlePlanItemsQuantity,
    prices,
    infoProperties,
    setAmountCustom,
    propertiesNumberEdit,
    formEditPlan,
    handleSelectOption,
    propertiesInfoSelect,
    isNewCreditCard,
    newPaymentMethod,
    contentEl,
    changeValueInputStripe,
    stripeRef,
    dlocalRef,
    branchReducer,
    userCards,
    isLoading,
    isOpenModalReason,
    editPlan,
    handleCloseModalReason,
    formReason,
    amountCustom,
    processIsIncomplete,
    handleInfoIncompleted,
    handleClosePopUpProcessIncompleted,
    addNewProperty,
    setAddNewProperty,
    formAddProperty,
    showPopUpDelete,
    setShowPopUpDelete,
    handleSelectCard,
    deleteItemFromUser,
  };
};
