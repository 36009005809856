import { Card } from 'components/atoms/Card/Card';
import './Item-periodicity-type.scss';
import { RadioButton } from 'components/atoms/Radio-button/Radio-button';

export interface IPropsItemPeriodicityType {
  onClick: () => void;
  label: string;
  isSelected: boolean;
  price: string;
}

export const ItemPeriodicityType = (props: IPropsItemPeriodicityType) => {
  return (
    <Card
      id={`item__periodicity__type__${props.label.toLocaleLowerCase()}`}
      WrapperClassName={`item__periodicity__type__container`}
      isSelected={props.isSelected}
      onClick={props.onClick}
    >
      <RadioButton isSelected={props.isSelected}></RadioButton>
      <div className="item__periodicity__type__info">
        <span className="item__periodicity__type__info__detail">
          Pago {props.label.toLowerCase()}
        </span>
        <h3
          className={`item__periodicity__type__info__price ${
            props.isSelected ? 'selected' : ''
          }`}
        >
          $ {props.price}
        </h3>
      </div>
    </Card>
  );
};
