import { config } from 'config/constants';
import * as CryptoJS from 'crypto-js';
/**
 * CryptoJs implementation
 */
export class AESEncryptDecryptService {
  public secretKey = config.aesKey as string;
  /**
   * Encrypt Method using AES
   * @param value Value to encrypt
   */
  encrypt(value: string): string {
    return CryptoJS.AES.encrypt(value, this.secretKey.trim()).toString();
  }
  /**
   * Decrypt Method using AES
   * @param textToDecrypt Value to descrypt
   */
  decrypt(textToDecrypt: string): string {
    return CryptoJS.AES.decrypt(textToDecrypt, this.secretKey.trim()).toString(
      CryptoJS.enc.Utf8
    );
  }
}
