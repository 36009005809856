import { Images } from 'assets/images/images.index';
import { useRef, useState } from 'react';
import './Popup-detail-accordion.scss';

interface IPropsPopupDetailAccordion {
  title: string;
  icon: string;
  subTilte?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any;
}
export const PopupDetailAccordion = (props: IPropsPopupDetailAccordion) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentEl = useRef<HTMLDivElement>(null);
  const handleClick = () => {
    setIsOpen((prev) => !prev);
  };
  const steps = Array(100)
    .fill(1)
    .map((_, index) => index + 1);
  return (
    <div
      className={`popup__detail__accordion__container ${
        isOpen ? 'popup__detail__accordion__container--open' : ''
      }`}
    >
      <div className="popup__detail__accordion__content">
        <div className="popup__detail__accordion__header">
          <div className="popup__detail__accordion__header__title">
            <img src={props.icon} alt="icono expandir detalle" />
            <span>{props.title}</span>
          </div>
          <div
            className="popup__detail__accordion__header__control"
            onClick={handleClick}
          >
            {isOpen ? 'cerrar' : 'Ver detalle'}

            <img
              className={` ${isOpen ? 'rotar' : ''}`}
              src={Images.arrowAccordion}
              alt="Ver mas"
            />
          </div>
        </div>
        <div
          className={`popup__detail__accordion__body ${
            isOpen ? 'popup__detail__accordion__body--open' : ''
          }`}
          ref={contentEl}
        >
          <div className="popup__detail__accordion__body__content">
            {props.children}
          </div>
          <div className="popup__detail__decorator__container">
            {steps.map((item: number) => (
              <div
                key={`step-${item}`}
                className="popup__detail__decorator__step"
              ></div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
