import { BillingSvg } from 'assets/icons/billing';
import { ItemEmpty } from 'components/molecules/Item-empty/Item-empty';

export const PaymentMethodItemEmpty = () => {
  return (
    <ItemEmpty
      title=""
      image={<BillingSvg color="tertiary" />}
      info="No tiene método de pago <br/> activos en esta cuenta "
      template="secondary"
      link="/add-payment-method"
      typeAction="navigate"
    ></ItemEmpty>
  );
};
