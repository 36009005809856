import { IFormPersonalInformation } from 'pages/Create-account/pages/Personal-information/models/Personal-information.interface';
import { IFormEditProfileInformation } from 'pages/Edit-profiles/models/Edit-profile.interface';
import cms from 'shared/services/cms';
import { IFormPaymentSubscription } from '../../pages/Create-account/pages/Payment-information/models/Payment-subscription.interface';
import { IRequestAddPropertiy } from 'pages/Create-account/pages/Create-properties/models/Create-properties.interface';

/**
 * Function to call api to create a new user
 * @param user IFormPersonalInformation
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function registerUser(user: any) {
  const response = await cms.post('firebase/register', user);
  return response.data;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function loginUser(user: any) {
  const response = await cms.post('firebase/auth', user);
  return response.data;
}

export async function updatePasswordUser(
  email: string,
  uid: string,
  password: string
) {
  const response = await cms.post('firebase/update-password', {
    email,
    uid,
    password,
  });
  return response.data;
}

export async function sendPasswordResetEmail(email: string) {
  const response = await cms.post('firebase/generate-reset-password', {
    email,
  });
  return response.data;
}

/**
 * Function to add a subscription to a user
 * @param subscription IformPaymentSubscription
 * @returns
 */
export async function addSubscription(
  subscription: IFormPaymentSubscription,
  tokenCaptcha: string
) {
  const data = {
    ...subscription,
    authorizationToken: tokenCaptcha,
  };

  const response = await cms.post('user-subscriptions', data);
  return response.data;
}
/**
 * Function to add a subscription to a user
 * @param subscription IformPaymentSubscription
 * @returns
 */
export async function addSubscriptionFreemium(
  subscription: IFormPaymentSubscription,
  dataProperties: IRequestAddPropertiy
) {
  const response = await cms.post('user-subscriptions/activate-plan', {
    ...subscription,
    ...dataProperties,
  });
  return response.data;
}

/**
 * Function to update a info user
 * @param user IformPersonalInformation
 * @param userId user id
 * @returns
 */
export async function updateUser(
  user: IFormPersonalInformation | IFormEditProfileInformation,
  uid: string
) {
  const body = { data: user };
  const response = await cms.put('users/' + uid, body);
  return response.data;
}

export async function authUserGoogle(token: string) {
  const dataToAuth = { stsTokenManager: { accessToken: token } };
  return await loginUser(dataToAuth);
}
