import {
  IAdvantage,
  ProductosDatum,
} from '../../Payment-information/models/Payment-information.interface';
export interface IAccordionDetailInformationMapping {
  maxItems: number;
  products: ProductosDatum[];
  title: string;
  aliasName: string;
  type: string;
  id: number;
  advantages: IAdvantage[];
  urlPolicies: string;
}
export enum ICompany {
  ruedaz = 'ruedaz',
  tmobile = 'TMobile',
}
