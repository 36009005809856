import { ItemAddProperty } from 'components/atoms/Item-add-property/Item-add-property';
import { TypeProperty } from 'models/app.interface';
import './Item-buy-product.scss';
import { TagLabel } from 'components/atoms/Tag-label/Tag-label';
import { formatPrice } from 'utils/payments.utils';
import { ProductosDatum } from 'pages/Checkout/pages/Payment-information/models/Payment-information.interface';
export interface IPropsItemBuyProduct {
  title: string;
  aliasName: string;
  price?: number;
  type: string;
  onClick: (event: string | number, index?: number) => void;
  products: ProductosDatum[];
  isDisabledButtonLess?: boolean;
  isDisabledButtonPlus?: boolean;
  maxItems: number;
  count?: number;
  id: number;
  branch: string;
  periodicity: string;
  priceForUnit?: number;
  priceForRoad: number;
  showTitle: boolean;
  index: number;
}

export const ItemBuyProduct = (props: IPropsItemBuyProduct) => {
  return (
    <div className="item__buy__product__container">
      {props.products.map((product, index) => (
        <div key={`products_${index}`}>
          {props.showTitle && (
            <div className="item__buy__product__tag">
              <span>{props.aliasName}</span>
              {/* {props.price || props.index === 0 ? (
                <TagLabel typeStyle="rounded" type="primary">
                  {`$ ${formatPrice(
                    product.attributes.alias_name
                      .toLocaleLowerCase()
                      .indexOf('carretera') > 0
                      ? props.priceForRoad
                      : props.price,
                    props.branch
                  )} ${props.periodicity}`}
                </TagLabel>
              ) : (
                ''
              )} */}
            </div>
          )}
          <ItemAddProperty
            id={`buy__${props.id}`}
            count={props.count}
            template="secondary"
            maxItems={props.maxItems}
            isDisabled={false}
            type={props.type as TypeProperty}
            onClick={props.onClick}
            isDisabledButtonLess={props.isDisabledButtonLess}
            isDisabledButtonPlus={props.isDisabledButtonPlus}
            price={props.price}
            priceForUnit={props.priceForUnit}
            branch={props.branch}
          />
        </div>
      ))}
    </div>
  );
};
