import { AuthFromGeneric } from 'components/templates/Auth-from-generic/Auth-from-generic';
import './Auth.scss';
import { UseAuthValidation } from './hooks/Auth.hook';
import { activeBenefitsHeaderInformation, headerInformation } from './Constant/auth.constant';
import { Input } from 'components/atoms/Input/Input';
import Recaptcha from 'react-google-invisible-recaptcha';
import { config } from 'config/constants';

export const Auth = () => {
  const { isLoading, formAuth, setRecaptcha, planId } = UseAuthValidation();

  return (
    <form onSubmit={formAuth.handleSubmit} noValidate>
      <AuthFromGeneric
        labelPrimaryAction="Continuar"
        titleFrom="tu email para ingresar"
        headerInformation={
          planId ? activeBenefitsHeaderInformation : headerInformation
        }
        isLoading={isLoading}
        handleClick={formAuth.handleSubmit}
      >
        <Input
          label="Correo Electrónico"
          id="login-email"
          type="email"
          name="email"
          onChange={formAuth.handleChange}
          onBlur={formAuth.handleBlur}
          value={formAuth.values.email}
          isTouched={formAuth.touched.email}
          errorInput={formAuth.errors.email}
          errorLabel={formAuth.errors.email}
          isRequired={true}
          form={formAuth}
        />
        <Recaptcha
          ref={(ref: unknown) => setRecaptcha(ref)}
          sitekey={config.recaptchaKey}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onResolved={() => {}}
        />
      </AuthFromGeneric>
    </form>
  );
};
