import * as CSS from 'csstype';
import './Title-whit-image.scss';

interface IPropsTitleWhitImage {
  title: string;
  image: string;
  styleInLineImage?: CSS.Properties<string | number>;
  index?: number;
  marginSmall?: boolean;
}

export const TitleWhitImage = (props: IPropsTitleWhitImage) => {
  return (
    <div
      className={`title__whit__imagen__contianer ${
        props.marginSmall ? 'margin__small' : ''
      }`}
    >
      <h3
        key={`title__whit__image-title-${props.index}`}
        dangerouslySetInnerHTML={{ __html: props.title }}
      ></h3>
      <img
        key={`title__whit__image-icon-${props.index}`}
        style={props.styleInLineImage}
        src={props.image}
        alt="Imagen del titulo"
      />
    </div>
  );
};
