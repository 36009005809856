import { AuthGuard } from 'guards/auth.guard';
import { AddPaymentMethod } from 'pages/Add-payment-method/Add-payment-method';
import { AddProperty } from 'pages/Add-property/Add-property';
import { Auth } from 'pages/Auth/Auth';
import { BuyPlan } from 'pages/Buy-plan/Buy-plan';
import { Checkout } from 'pages/Checkout/Checkout';
import { CheckoutGuard } from 'pages/Checkout/guards/Checkout.guard';
import { PaymentConfirmation } from 'pages/Checkout/pages/Payment-confirmation/Payment-confirmation';
import { CreateAccount } from 'pages/Create-account/Create-account';
import { EditPaymentMethod } from 'pages/Edit-payment-method/Edit-payment-method';
import { EditPlan } from 'pages/Edit-plan/Edit-plan';
import { EditProfile } from 'pages/Edit-profiles/Edit-profile';
import { EditProperty } from 'pages/Edit-Property/Edit-property';
import { Home } from 'pages/Home/Home';
import { Offline } from 'pages/Offline/Offline';
import { PlanDetail } from 'pages/Plan-detail/Plan-detail';
import { Property } from 'pages/Property/Property';
import { RenewalSubscriptionPage } from 'pages/Renewal-subscription-page/Renewal-subscription-page';
import { ResetPassword } from 'pages/Reset-password/Reset-password';
import { Sales } from 'pages/sales/Sales';
import { Settings } from 'pages/Settings/Settings';
import { ShortLink } from 'pages/Short-link/Short-link';
import { ForgotPassword } from 'pages/sing-in/Forgot-password/Forgot-password';
import { SingInGuard } from 'pages/sing-in/guards/sign-in.guard';
import { VerificationAuthActionGuard } from 'pages/sing-in/guards/verification-method.guard';
import Login from 'pages/sing-in/Login/Login';
import { SingInRouter } from 'pages/sing-in/routes/sing-in.routes';
import UpdatePassword from 'pages/Update-password/Update-password';
import { useRoutes, Navigate } from 'react-router-dom';

export const AppRouter = () => {
  const routes = useRoutes([
    {
      path: '/',
      element: (
        <AuthGuard privateRoute={false}>
          <Auth />
        </AuthGuard>
      ),
    },
    {
      path: '/auth',
      element: (
        <AuthGuard privateRoute={false}>
          <Auth />
        </AuthGuard>
      ),
    },
    {
      path: '/active-plan/:planId',
      element: (
        <AuthGuard privateRoute={false}>
          <Auth />
        </AuthGuard>
      ),
    },
    {
      path: '/home',
      element: (
        <AuthGuard privateRoute={true}>
          <Home />
        </AuthGuard>
      ),
    },
    {
      path: '/login',
      element: (
        <AuthGuard privateRoute={false}>
          <Login />
        </AuthGuard>
      ),
    },
    {
      path: '/reset-password',
      element: (
        <AuthGuard privateRoute={true}>
          <ResetPassword />
        </AuthGuard>
      ),
    },
    {
      path: '/forgot-password',
      element: (
        <AuthGuard privateRoute={false}>
          <ForgotPassword />
        </AuthGuard>
      ),
    },
    {
      path: '/sales',
      element: (
        <AuthGuard privateRoute={true}>
          <Sales />
        </AuthGuard>
      ),
    },
    {
      path: '/verification-auth-action',
      element: (
        <AuthGuard privateRoute={false}>
          <VerificationAuthActionGuard />
        </AuthGuard>
      ),
    },
    {
      path: '/sing-in/*',
      element: (
        <AuthGuard privateRoute={false}>
          <SingInGuard>
            <SingInRouter />
          </SingInGuard>
        </AuthGuard>
      ),
    },
    {
      path: '/create-account/*',
      element: <CreateAccount />,
    },
    {
      path: '/checkout/*',
      element: <Checkout />,
    },
    {
      path: '/confirmation',
      element: (
        <CheckoutGuard>
          <PaymentConfirmation />
        </CheckoutGuard>
      ),
    },
    {
      path: '/update-password/:oobCode',
      element: (
        <AuthGuard privateRoute={false}>
          <UpdatePassword />
        </AuthGuard>
      ),
    },
    {
      path: '/create-password',
      element: (
        <CheckoutGuard>
          <UpdatePassword />
        </CheckoutGuard>
      ),
    },
    {
      path: '/settings/*',
      element: (
        <AuthGuard privateRoute={true}>
          <Settings />
        </AuthGuard>
      ),
    },
    {
      path: '/edit-profile',
      element: (
        <AuthGuard privateRoute={true}>
          <EditProfile />
        </AuthGuard>
      ),
    },
    {
      path: '/property/:idProperty',
      element: (
        <AuthGuard privateRoute={true}>
          <Property />
        </AuthGuard>
      ),
    },
    {
      path: '/edit-property/:propertyId',
      element: (
        <AuthGuard privateRoute={true}>
          <EditProperty />
        </AuthGuard>
      ),
    },
    {
      path: '/add-property',
      element: (
        <AuthGuard privateRoute={true}>
          <AddProperty />
        </AuthGuard>
      ),
    },
    {
      path: '/plan-detail/:planId',
      element: (
        <AuthGuard privateRoute={true}>
          <PlanDetail />
        </AuthGuard>
      ),
    },
    {
      path: '/edit-plan/*',
      element: (
        <AuthGuard privateRoute={true}>
          <EditPlan />
        </AuthGuard>
      ),
    },
    {
      path: '/add-payment-method',
      element: (
        <AuthGuard privateRoute={true}>
          <AddPaymentMethod />
        </AuthGuard>
      ),
    },
    {
      path: '/edit-payment-method',
      element: (
        <AuthGuard privateRoute={true}>
          <EditPaymentMethod />
        </AuthGuard>
      ),
    },
    {
      path: '/buy-plan/*',
      element: (
        <AuthGuard privateRoute={true}>
          <BuyPlan />
        </AuthGuard>
      ),
    },
    {
      path: '/renewal-subscription',
      element: (
        <AuthGuard privateRoute={true}>
          <RenewalSubscriptionPage />
        </AuthGuard>
      ),
    },
    {
      path: '/*',
      element: <Navigate to="/settings" />,
    },
    {
      path: '/shortlink',
      element: <ShortLink />,
    },
    {
      path: '/offline',
      element: <Offline />,
    },
  ]);
  return routes;
};
