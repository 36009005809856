import { Card } from 'components/atoms/Card/Card';
import { Chips } from 'components/atoms/Chips/Chips';
import { Divider } from 'components/atoms/Divider/Divider';
import { Button } from 'components/atoms/Button/Button';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Images } from 'assets/images/images.index';
import { useState, useEffect } from 'react';
import 'swiper/css';
import './Date-picker.scss';
import { Swiper as SwiperInterface } from 'swiper/types';
import { IDatePicker, IDay } from './models/Date-picker.interface';

export const DatePicker = (props: IDatePicker) => {
  const daysOfWeek = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const today = new Date();
  const [isCalendarHidden, setIsCalendarHidden] = useState<boolean>(true);
  const [month, setMonth] = useState(today.getMonth());
  const [days, setDays] = useState<IDay[]>();
  const [startingDay, serStartingDay] = useState<{
    day: number;
    month: number;
    year: number;
    date: Date | null;
  }>({ day: 0, month: 0, year: 0, date: null });
  const [finalDay, setFinalDay] = useState<{
    day: number;
    month: number;
    year: number;
    date: Date | null;
  }>({ day: 0, month: 0, year: 0, date: null });
  const [year] = useState<number>(new Date().getFullYear());
  const [dataFilters, setDataFilters] = useState<{
    start: Date | null;
    end: Date | null;
  }>({ start: null, end: null });
  const [swiper, setSwiperInstance] = useState<SwiperInterface>();
  const onChangeSwiper = (e: SwiperInterface) => {
    setMonth(e.realIndex);
    getDays(e.realIndex);
  };
  useEffect(() => {
    getDays();
  }, [setDays]);
  const getDays = (argMonth?: number) => {
    const monthValue = typeof argMonth === 'number' ? argMonth : month;
    const daysCurrentMonth = new Date(year, monthValue + 1, 0).getDate();
    const days = Array(daysCurrentMonth)
      .fill(1)
      .map((item, index) => {
        return {
          isCurrentMonth: true,
          day: item + index,
          month: monthValue,
          isInRange: false,
          isDisabled: false,
          isSelected: false,
          date: new Date(year, monthValue, item + index).toDateString(),
        };
      });
    const dayWeek = new Date(year, monthValue, 1).getDay();
    const daysBeforeMonth = new Date(year, monthValue, 0).getDate();
    for (let index = 0; index < dayWeek; index++) {
      days.unshift({
        isCurrentMonth: false,
        day: daysBeforeMonth - index,
        month: monthValue - 1,
        isInRange: false,
        isDisabled: false,
        isSelected: false,
        date: new Date(
          year,
          monthValue - 1,
          daysBeforeMonth - index
        ).toDateString(),
      });
    }
    const daysAfter = 35 - (daysCurrentMonth + dayWeek);
    for (let index = 1; index <= daysAfter; index++) {
      days.push({
        isCurrentMonth: false,
        day: index,
        month: monthValue + 1,
        isInRange: false,
        isDisabled: false,
        isSelected: false,
        date: new Date(year, monthValue + 1, index).toDateString(),
      });
    }
    const daysMapping = days.map((item) => {
      const dayItem = new Date(item.date);
      return {
        ...item,
        isInRange:
          startingDay.date && finalDay.date
            ? dayItem.getTime() > (startingDay.date as Date).getTime() &&
              dayItem.getTime() < (finalDay.date as Date).getTime()
            : false,
        isDisabled: startingDay.date
          ? dayItem.getTime() < (startingDay.date as Date).getTime()
          : false,
        isSelected:
          (startingDay.date
            ? dayItem.getTime() === (startingDay.date as Date).getTime()
            : false) ||
          (finalDay.date
            ? dayItem.getTime() === (finalDay.date as Date).getTime()
            : false),
      };
    });
    setDays([...daysMapping]);
  };
  const setDaysToFilter = (day: IDay, isInitial?: boolean) => {
    if (day.isDisabled) {
      return;
    }
    let newDays: IDay[] = [];
    const dayFilterSelected = new Date(day.date as string);
    if (!startingDay.day && !isInitial) {
      const dayFilter = startingDay.date
        ? (startingDay.date as Date).getTime()
        : dayFilterSelected.getTime();
      serStartingDay({
        day: day.day,
        month: day.month,
        year: year,
        date: new Date(year, day.month, day.day),
      });
      newDays = days?.map((item) => {
        const dayItem = new Date(item.date as string);
        return {
          ...item,
          isSelected: dayItem.getTime() === dayFilter,
          isDisabled: dayItem.getTime() < dayFilter,
        };
      }, []) as IDay[];
    } else {
      const dayFilter = day.date
        ? dayFilterSelected.getTime()
        : (finalDay.date as Date).getTime();
      newDays = days?.map((item) => {
        const dayItem = new Date(item.date as string);
        return {
          ...item,
          isSelected:
            (startingDay.date as Date).getTime() === dayItem.getTime() ||
            dayFilter === dayItem.getTime(),
          isInRange:
            dayItem.getTime() > (startingDay.date as Date).getTime() &&
            dayItem.getTime() < dayFilter,
        };
      }, []) as IDay[];
      setFinalDay({
        day: day.day,
        month: day.month,
        year: year,
        date: new Date(year, day.month, day.day),
      });
    }
    setDays([...newDays]);
  };
  const resetDaysToFilter = () => {
    serStartingDay({ day: 0, month: 0, year: 0, date: null });
    serStartingDay({ day: 0, month: 0, year: 0, date: null });
    const newDays = days?.map((item) => {
      return {
        ...item,
        isSelected: false,
        isDisabled: false,
        isInRange: false,
      };
    }, []) as IDay[];
    setDays([...newDays]);
  };

  const addOrSubtractDays = (date: Date, days: number) => {
    date.setDate(date.getDate() + days);
    return date;
  };
  const emitFilter = () => {
    setIsCalendarHidden(true);
    setDataFilters({
      start: startingDay.date,
      end: finalDay.date,
    });
    props.onApplyFilters({
      start: startingDay.date,
      end: finalDay.date,
    });
  };

  const openCalendar = () => {
    setIsCalendarHidden((isCalendarHidden) => !isCalendarHidden);
    if (dataFilters.start && dataFilters.end) {
      const dayStart = new Date(dataFilters.start).getTime();
      const dayEnd = new Date(dataFilters.end).getTime();
      const newDays = days?.map((item) => {
        const dayItem = new Date(item.date as string);
        return {
          ...item,
          isInRange: dayItem.getTime() > dayStart && dayItem.getTime() < dayEnd,
          isSelected:
            dayItem.getTime() === dayStart || dayItem.getTime() === dayEnd,
          isDisabled: dayItem.getTime() < dayStart,
        };
      }, []) as IDay[];
      serStartingDay({
        day: dataFilters.start.getDate(),
        month: dataFilters.start.getMonth(),
        year: year,
        date: new Date(
          year,
          dataFilters.start.getMonth(),
          dataFilters.start.getDate()
        ),
      });
      setFinalDay({
        day: dataFilters.end.getDate(),
        month: dataFilters.end.getMonth(),
        year: year,
        date: new Date(
          year,
          dataFilters.end.getMonth(),
          dataFilters.end.getDate()
        ),
      });
      setDays([...newDays]);
    }
  };

  const setRageDaysToFilter = (
    type: 'today' | 'last 8 days' | 'last month'
  ) => {
    let newDays: IDay[] = [];
    const today = new Date().toDateString();

    const dayFilterSelected = new Date(today);
    switch (type) {
      case 'today':
        newDays = days?.map((item) => {
          const dayItem = new Date(item.date as string);
          return {
            ...item,
            isInRange: false,
            isSelected: dayItem.getTime() === dayFilterSelected.getTime(),
            isDisabled: dayItem.getTime() < dayFilterSelected.getTime(),
          };
        }, []) as IDay[];
        serStartingDay({
          day: dayFilterSelected.getDate(),
          month: dayFilterSelected.getMonth(),
          year: year,
          date: new Date(
            year,
            dayFilterSelected.getMonth(),
            dayFilterSelected.getDate()
          ),
        });
        setFinalDay({
          day: dayFilterSelected.getDate(),
          month: dayFilterSelected.getMonth(),
          year: year,
          date: new Date(
            year,
            dayFilterSelected.getMonth(),
            dayFilterSelected.getDate()
          ),
        });
        break;
      case 'last 8 days':
        // eslint-disable-next-line no-case-declarations
        const lastDays = addOrSubtractDays(new Date(today), -7);
        newDays = days?.map((item) => {
          const dayItem = new Date(item.date as string);
          return {
            ...item,
            isInRange:
              dayItem.getTime() > lastDays.getTime() &&
              dayItem.getTime() < dayFilterSelected.getTime(),
            isSelected:
              dayItem.getTime() === dayFilterSelected.getTime() ||
              dayItem.getTime() === lastDays.getTime(),
            isDisabled: dayItem.getTime() < lastDays.getTime(),
          };
        }, []) as IDay[];
        serStartingDay({
          day: lastDays.getDate(),
          month: lastDays.getMonth(),
          year: year,
          date: new Date(year, lastDays.getMonth(), lastDays.getDate()),
        });
        setFinalDay({
          day: dayFilterSelected.getDate(),
          month: dayFilterSelected.getMonth(),
          year: year,
          date: new Date(
            year,
            dayFilterSelected.getMonth(),
            dayFilterSelected.getDate()
          ),
        });
        break;
      case 'last month':
        // eslint-disable-next-line no-case-declarations
        const daysBeforeMonth = new Date(
          year,
          dayFilterSelected.getMonth(),
          0
        ).getDate();
        // eslint-disable-next-line no-case-declarations
        const startingDay = new Date(year, dayFilterSelected.getMonth() - 1, 1);
        // eslint-disable-next-line no-case-declarations
        const finalDay = new Date(
          year,
          dayFilterSelected.getMonth() - 1,
          daysBeforeMonth
        );
        newDays = days?.map((item) => {
          const dayItem = new Date(item.date as string);
          return {
            ...item,
            isInRange:
              dayItem.getTime() > startingDay.getTime() &&
              dayItem.getTime() < finalDay.getTime(),
            isSelected:
              dayItem.getTime() === startingDay.getTime() ||
              dayItem.getTime() === finalDay.getTime(),
            isDisabled: dayItem.getTime() < startingDay.getTime(),
          };
        }, []) as IDay[];
        serStartingDay({
          day: 1,
          month: dayFilterSelected.getMonth() - 1,
          year: year,
          date: new Date(year, dayFilterSelected.getMonth() - 1, 1),
        });
        setFinalDay({
          day: daysBeforeMonth,
          month: dayFilterSelected.getMonth() - 1,
          year: year,
          date: new Date(
            year,
            dayFilterSelected.getMonth() - 1,
            daysBeforeMonth
          ),
        });
        break;
    }
    setDays([...newDays]);
  };

  return (
    <div className="date__picker__select">
      <div className="date__picker__select__container " onClick={openCalendar}>
        <div className="">
          {dataFilters.start && dataFilters.end ? (
            <span>{`${dataFilters.start?.getDate()} ${
              months[dataFilters.start?.getMonth()]
            } - ${dataFilters.end?.getDate()} ${
              months[dataFilters.end?.getMonth()]
            }`}</span>
          ) : (
            <img
              className="date__picker__select__icon"
              src={Images.calendari}
              alt="Icono de select"
            />
          )}
        </div>
      </div>
      {!isCalendarHidden && (
        <div className="date__picker">
          <Card WrapperClassName="date__picker__container">
            <div className="date__picker__chips__container">
              <Chips
                label="Today"
                onClick={() => setRageDaysToFilter('today')}
              />
              <Chips
                label="Last 8 days"
                onClick={() => setRageDaysToFilter('last 8 days')}
              />
              <Chips
                label="Last month"
                onClick={() => setRageDaysToFilter('last month')}
              />
            </div>
            <Divider margin="notMargin"></Divider>
            <div className="date__picker__content">
              <div className="date__picker__content__info">
                {daysOfWeek.map((item) => (
                  <div className="date__picker__item" key={item}>
                    {item}
                  </div>
                ))}
                {days?.map((item, index) => (
                  <div
                    onClick={() => setDaysToFilter(item)}
                    className={`date__picker__item date__picker__day ${
                      item.isSelected ? 'selected' : ''
                    } ${!item.isCurrentMonth ? 'another__month' : ''} ${
                      item.isDisabled ? 'disabled' : ''
                    }  ${item.isInRange ? 'selected--range' : ''}`}
                    key={`${item.month}-${item.day}-${index}`}
                  >
                    {item.day}
                  </div>
                ))}
              </div>
              <div className="date__picker__content__months">
                <div
                  className="date__picker__controls"
                  onClick={() => swiper?.slidePrev()}
                >
                  <img
                    className="date__picker__arrow date__picker__arrow--rotated"
                    src={Images.arrowCalendar}
                    alt="mes anterior"
                  />
                </div>
                <div className="date__picker__container__months">
                  <Swiper
                    direction={'vertical'}
                    className="container__swiper"
                    slidesPerView={5}
                    spaceBetween={10}
                    centeredSlides={true}
                    onSlideChange={onChangeSwiper}
                    initialSlide={month}
                    onSwiper={(swiper) => setSwiperInstance(swiper)}
                  >
                    {months.map((item, index) => (
                      <SwiperSlide
                        className={`date__picker__month ${
                          month === index ? 'selected' : ''
                        } ${
                          month - 1 === index || month + 1 === index
                            ? 'next'
                            : ''
                        }`}
                        key={item}
                      >
                        {item}
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
                <div className="date__picker__controls">
                  <img
                    onClick={() => swiper?.slideNext()}
                    className="date__picker__arrow"
                    src={Images.arrowCalendar}
                    alt="siguente mes"
                  />
                </div>
              </div>
            </div>
            <Divider margin="notMargin"></Divider>
            <div className="date__picker__buttons__container">
              <Button
                size="small"
                color="primary"
                template="secondary"
                typeStyle="rounded"
                className="button__small clear"
                type="button"
                onClick={resetDaysToFilter}
              >
                Clear
              </Button>
              <Button
                size="medium"
                color="primary"
                template="primary"
                typeStyle="rounded"
                className="button__small"
                type="button"
                onClick={emitFilter}
              >
                Apply
              </Button>
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};
