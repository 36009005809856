export const formatDate = (arg: number) => {
  const opciones = { month: 'short', day: 'numeric', year: 'numeric' };
  return new Date(arg).toLocaleDateString('es-ES', opciones as any);
};
export const formatDateWithDigits = (date: string) => {
  const options = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  };
  return new Intl.DateTimeFormat('es-ES', options as any).format(
    new Date(date)
  );
};
