import * as CSS from "csstype";
import './Image.scss';

export interface IImagesProps {
  image: string;
  description: string;
  size: 'small' | 'medium' | 'big' | 'custom',
  styleInLine?: CSS.Properties<string | number>;
  customClass?: string;
}

export const Image = (props: IImagesProps) => {
  return (
    <img
      src={props.image}
      alt={props.description}
      className={`image--${props.size} ${props.customClass}`}
      style={props.styleInLine}
    />
  );
};
