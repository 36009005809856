import { IContanInfo } from 'components/molecules/Settings-footer/models/Settings-footer.interface';

const getContactInfo = (branch: string): IContanInfo => {
  return (
    ({
      pr: {
        email: 'pagos@connect.pr',
        phone: '+17879190835',
        phone_mask: '(787) 999-5050',
        url_facebook: 'https://www.facebook.com/connectassistancepr/',
        url_instagram: 'https://www.instagram.com/connectassistancepr/',
        url_faq: 'https://connect.pr/faq',
        url_terms: 'https://www.connect.pr/declaracion-de-privacidad',
      },
      co: {
        email: 'pagos@connect.com.co',
        phone: '+576014322350',
        phone_mask: '(57) 601 432-2350',
        url_facebook: 'https://www.facebook.com/ConnectAssistanceCO',
        url_instagram: 'https://www.instagram.com/connectassistanceco/',
        url_faq: 'https://connect.com.co/faq',
        url_terms: 'https://www.connect.pr/declaracion-de-privacidad',
      },
      cr: {
        email: 'pagos@connect.cr',
        phone: '+50625046565',
        phone_mask: '(506) 2504-6565',
        url_facebook: 'https://www.facebook.com/ConnectAssistanceCR',
        url_instagram: 'https://www.instagram.com/connectassistancecr/',
        url_faq: 'http://connect.cr/faq',
        url_terms: 'https://www.connect.pr/declaracion-de-privacidad',
      },
      pty: {
        email: 'pagos@cra.pr',
        phone: '+5073072626',
        phone_mask: '(507) 307-2626',
        url_facebook: 'https://www.facebook.com/connectassistancepr',
        url_instagram: 'https://www.instagram.com/connectassistancepr',
        url_faq: 'https://connect.pr/faq',
        url_terms: 'https://www.connect.pr/declaracion-de-privacidad',
      },
    }[branch] as IContanInfo) || {
      email: '',
      phone: '',
      phone_mask: '',
      url_facebook: '',
      url_instagram: '',
      url_faq: '',
      url_terms: '',
    }
  );
};

export { getContactInfo };
