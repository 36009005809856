export const enum TypeActionsEditPlan {
  saveInformation = '[EDIT PLAN] set information',
  resetInformation = '[EDIT PLAN] remove information',
}

export type editPlantAction = {
  type: TypeActionsEditPlan;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setEditPlanInformation = (data: any) => ({
  type: TypeActionsEditPlan.saveInformation,
  data,
});

export const resetCheckoutInformation = () => ({
  type: TypeActionsEditPlan.resetInformation,
});
