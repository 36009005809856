import { useSelector } from 'react-redux';
import { IAppReducer } from 'models/reducer.interface';
import { Images } from 'assets/images/images.index';
import './Confirmation-activated.scss';
import { Link } from 'components/atoms/Link/Link';
import { Button } from 'components/atoms/Button/Button';
import { useNavigate } from 'react-router';
import { getContactInfo } from 'utils/connect-info.utils';

export const ConfirmationActivated = () => {
  const navigate = useNavigate();
  const {
    checkoutReducer: { resumen },
    createAccountReducer: { name },
    branchReducer: { branch },
  } = useSelector((state: IAppReducer) => state);

  return (
    <div className="payment__confirmation__container container__generic">
      <div className="payment__confirmation__header">
        <img
          className="logo__connect"
          src={Images.connetLogoWhite}
          alt="Logo connect"
        />
        <img className="logo__check" src={Images.checkWhite} alt="Logo check" />
        <h4>¡Gracias, {name}!</h4>
        <h1>
          tu activación
          <br /> fue exitosa
        </h1>
        <p>
          Tus descuentos los puedes utilizar <strong>1 hora después</strong> de
          la confirmación de datos de tu{' '}
          {(resumen?.category as []).join(', ').toLocaleLowerCase()}.
        </p>
      </div>
      <div className="payment__confirmation__body">
        <div className="payment__confirmation__info">
          <p>
            Bienvenido a la familia Connect, gracias por confiar en nosotros.
            <br />
            <br />
            Aquí tienes un resumen de tu <br /> activación:
            <br />
            <br />
            <ul>
              <li>
                Descuento del 10% en servicios para el{' '}
                {(resumen?.category as []).join(', ').toLocaleLowerCase()}.
              </li>
            </ul>
            <br />
            <br />
            Comunícate con nosotros y te asesoraremos.
          </p>
        </div>
        <div className="payment__confirmation__info__container">
          <div className="payment__confirmation__info__title">
            <h1> Encuentra la solución perfecta para ti!</h1>
            <span>
              Comunícate con <br />
              nosotros solicita tus <br />
              servicios y disfruta de tus <br />
              descuentos.
            </span>
          </div>
          <a
            className="btn__button    btn__button--rounded  btn__button--primary  btn__button--medium btn__button__color--secondary payment__confirmation__info__button"
            href={`tel:${getContactInfo(branch).phone}`}
          >
            contactar
          </a>
        </div>
        <div className="payment__confirmation__support">
          <div className="payment__confirmation__support__divider"></div>
          <span>Para más información, contáctanos</span>
          <a
            className="payment__confirmation__support__link "
            href={`tel:${getContactInfo(branch).phone}`}
          >
            aquí
          </a>
        </div>
      </div>
    </div>
  );
};
