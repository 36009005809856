import { Alert } from 'components/atoms/Alert/Alert';
import { ButtonClose } from 'components/atoms/Button-close/Button-close';
import { Chips } from 'components/atoms/Chips/Chips';
import { DetailAccordion } from 'components/atoms/Detail-accordion/Detail-accordion';
import { Divider } from 'components/atoms/Divider/Divider';
import { Navbar } from 'components/atoms/Navbar/Navbar';
import { IAppReducer } from 'models/reducer.interface';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { getNextPayment } from 'shared/services/payments';
import { getPropertyForPlan, removePlanFromUser } from 'shared/services/plans';
import { getContactInfo } from 'utils/connect-info.utils';
import { getIgameForTypeProperty } from 'utils/properties.utils';
import { IPlanDetailAtrebuttes } from './models/Plan-detail.inteface';
import './Plan-detail.scss';
interface LocationState {
  editPlan: boolean;
}
export const PlanDetail = () => {
  const connectWebAnchor = useRef<HTMLAnchorElement>(null);
  const location = useLocation();
  const locationState = location.state as LocationState;
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [infoNextPayment, setInfoNextPayment] = useState<any>(undefined);
  const [infoProperties, setInfoProperties] =
    useState<
      | {
          quantity: number;
          properties_added: number;
        }
      | undefined
    >(undefined);
  const [planInformation, setPlanInformation] =
    useState<IPlanDetailAtrebuttes>();
  const {
    authReducer: user,
    branchReducer: { branch, country_id },
  } = useSelector((state: IAppReducer) => state);

  const { planId } = useParams();
  useEffect(() => {
    (async () => {
      if (!country_id) {
        return;
      }
      const response: IPlanDetailAtrebuttes[] = await getPropertyForPlan(
        user.uid,
        planId as string
      );
      setPlanInformation(response[0]);
      setInfoProperties({
        quantity:
          response[0].attributes.plan_id.data.attributes.user_products[0]
            .quantity,
        properties_added:
          response[0].attributes.plan_id.data.attributes.productos.data[0]
            .attributes.properties_added || 0,
      });
    })();
  }, [country_id]);
  useEffect(() => {
    (async () => {
      const nextPayment = await getNextPayment(user.uid);
      setInfoNextPayment(nextPayment);
    })();
  }, [user.uid]);
  useEffect(() => {
    if (locationState?.editPlan) {
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 16000);
    }
  }, [locationState?.editPlan]);
  const deletePlan = async () => {
    await removePlanFromUser(user.uid, planId as string);
    navigate('/settings/payments');
  };
  const goToEditPlan = async () => {
    navigate(`/edit-plan/${planId}`);
  };
  const getIcon = () => {
    const typeProperty = planInformation?.attributes?.plan_id.data.attributes
      .productos.data[0].attributes.product_type_id.data.id as number;
    return getIgameForTypeProperty(typeProperty);
  };
  const goToWhatsApp = () => {
    if (connectWebAnchor.current) {
      connectWebAnchor.current.href = `https://wa.me/${
        getContactInfo(branch).phone
      }`;
      connectWebAnchor.current.click();
    }
  };
  const getLabel = () => {
    const productType =
      planInformation?.attributes.plan_id.data.attributes.alias_product_type.toLocaleLowerCase();
    return {
      auto: 'Placa',
      motora: 'Placa',
      hogar: 'Direccion',
      mascota: 'Nombre',
    }[productType || 'auto'];
  };
  return (
    <>
      {showAlert && (
        <Alert
          type="successful"
          onClick={() => {
            setShowAlert(false);
          }}
          title={'¡Plan actualizado !'}
          message={`Se envio comprobante al <strong>${user.email}</strong`}
        />
      )}
      <Navbar template='light'>
        <div className="plan__detail__nav__container">
          <div className="plan__detail__info__header">
            <img src={getIcon()?.image} alt="Logo plan" />
            <span className="plan__detail__title">
              {planInformation?.attributes.plan_id.data.attributes.alias_title}{' '}
              |{' '}
              <strong>
                {
                  planInformation?.attributes.plan_id.data.attributes
                    .alias_product_type
                }
              </strong>
            </span>

            <div className="property__actions__container">
              <button onClick={user.agentId ? goToEditPlan : goToWhatsApp}>
                Editar
              </button>
              <div className="property__actions__divider"></div>
              <button onClick={user.agentId ? deletePlan : goToWhatsApp}>
                Eliminar
              </button>
            </div>
          </div>
          <ButtonClose
          template='dark'
            onClick={() => {
              navigate('/settings/payments');
            }}
          />
        </div>
      </Navbar>
      <div className="plan__detail__container">
        <section className="plan__detail__content">
          <div className="plan__detail__chips__container">
            {planInformation &&
              [
                `${infoProperties?.properties_added}/${infoProperties?.quantity} propiedades`,
                planInformation?.attributes.plan_id.data.attributes.productos
                  .data[0].attributes.periocity_id.data.attributes.name,
              ].map((item, index: number) => (
                <Chips label={item} key={item + index} />
              ))}
          </div>
          {infoProperties && (
            <div className="plan__detail__info__properties">
              <p className="bold">Propiedades cubiertas:</p>
              {planInformation?.attributes.plan_id.data.attributes.plan_properties.map(
                (p, indexProperty) => (
                  <div key={`${p.id}-${indexProperty}`} className="info">
                    <span className="title__principal">
                      {getLabel() + ' '}
                      {
                        p.atributos.find(
                          (a) =>
                            a.propiedad.name === 'Placa' ||
                            a.propiedad.name === 'Direccion 1'
                        )?.valor
                      }
                    </span>
                    <span className="title__secondary">
                      {' | '}
                      {
                        p.atributos.find((a) => a.propiedad.name === 'Marca')
                          ?.valor
                      }
                    </span>
                    <span className="title__secondary">
                      {' | '}
                      {
                        p.atributos.find((a) => a.propiedad.name === 'Año')
                          ?.valor
                      }
                    </span>
                  </div>
                )
              )}
            </div>
          )}
        </section>
        <Divider type="bar" />
        <div className="plan__detail__accordion__container">
          <DetailAccordion
            tilte="Plan al día."
            subTilte={
              infoNextPayment
                ? `Próximo Pago ${infoNextPayment.month}/${infoNextPayment.day}/${infoNextPayment.year}`
                : ' '
            }
            type="successful"
          ></DetailAccordion>
        </div>
        <Divider type="bar" />
        <section className="plan__detail__advantages__container">
          {planInformation &&
            planInformation?.attributes?.plan_id.data.attributes.advantages && (
              <>
                <h3 className="advantages__title">Incluye</h3>
                <Divider />
                <ul className="advantages__list">
                  {planInformation.attributes.plan_id.data.attributes.advantages.map(
                    (advantage: { name: string }, indexAdvantage: number) => (
                      <li key={`${advantage}_${indexAdvantage}`}>
                        {advantage.name}
                      </li>
                    )
                  )}
                </ul>
              </>
            )}
          <a
            className="plan__detail__link"
            href={getContactInfo(branch).url_terms}
            target="_blank"
            rel="noreferrer"
          >
            Leer términos y condiciones de contrato
          </a>
        </section>
        <Divider type="bar" />
      </div>
      <a
        ref={connectWebAnchor}
        className="anchor__link"
        rel="noreferrer"
        target="_blank"
      ></a>
    </>
  );
};
