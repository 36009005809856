import { Button } from 'components/atoms/Button/Button';
import { Input } from 'components/atoms/Input/Input';
import './Forgot-password.scss';
import { Images } from 'assets/images/images.index';
import { CadrInfo } from 'components/atoms/Car-info/Card-info';
import { PopupEmailSend } from 'components/templates/Popup-email-send/Popup-email-send';
import { PopupErrorRecoverPassword } from 'components/templates/Popup-error-recover-password/Popup-error-recover-password';
import { UseForgotPasswordValidation } from './hook/Forgot-password.hook';
import { SettingsFooter } from 'components/molecules/Settings-footer/Settings-footer';
import { Animations } from 'assets/animations/animations.index';
import { Header } from 'components/organisms/Header/Header';

export const ForgotPassword = () => {
  const { navigate, showInfoPopUp, formForgotPassword, popUp } =
    UseForgotPasswordValidation();

  return (
    <>
      <div className="forgot__password__container">
        <div className="forgot__password__content">
          <Header navbarTemplate="light">
            <div
              className="settings__options__Logout"
              onClick={() => navigate('/login')}
            >
              <img className="forgot__password__content__image--rotate" src={Images.arrowDown} alt="Salir" />
              Regresar
            </div>
          </Header>
          <div className="forgot__password__content__body container__generic">
            <h3>Cambio de contraseña </h3>
            <div className="forgot__password__content__body__content">
              <div className="forgot__password__content__body__content__text">
                <p className="body__content__text body__content__text__title">
                  Para cambiar su contraseña ingrese el correo asociado a su
                  cuenta.
                </p>
                <p className="body__content__text body__content__text__detail">
                  Si el correo electrónico cuenta con un perfil activo le
                  enviaremos un enlace para restablecer su contraseña.
                </p>
              </div>
            </div>
            <Input
              WrapperClassName="forgot__password__input"
              label="Correo Electrónico"
              id="login-email"
              type="email"
              name="email"
              onChange={formForgotPassword.handleChange}
              onBlur={formForgotPassword.handleBlur}
              value={formForgotPassword.values.email}
              placeholder=""
              isTouched={formForgotPassword.touched.email}
              errorInput={formForgotPassword.errors.email}
              errorLabel={formForgotPassword.errors.email}
              isRequired={true}
              form={formForgotPassword}
            />
            <Button
              color="primary"
              size="medium"
              template="primary"
              typeStyle="rounded"
              className="forgot__password__button"
              type="button"
              isDisabled={
                formForgotPassword.isSubmitting ||
                !formForgotPassword.isValid ||
                !formForgotPassword.dirty
              }
              isLoading={formForgotPassword.isSubmitting}
              onClick={formForgotPassword.handleSubmit}
            >
              enviar
              <img style={{ marginLeft: '0.5rem' }} src={Images.email} alt="" />
            </Button>
          </div>
        </div>
        <aside className="forgot__password__content__aside container__generic">
          <CadrInfo message="Para soporte, contáctanos"></CadrInfo>
        </aside>
      </div>
      <SettingsFooter />
      {popUp.isVisible &&
        (popUp.type === 'successful' ? (
          <PopupEmailSend
            email={formForgotPassword.values.email}
            closePopUp={showInfoPopUp}
          />
        ) : (
          <PopupErrorRecoverPassword
            email={formForgotPassword.values.email}
            closePopUp={showInfoPopUp}
          />
        ))}
      {formForgotPassword.isSubmitting && (
        <div className="container__loading__screen__auth">
          <img className="" src={Animations.loader} alt="loading" />
        </div>
      )}
    </>
  );
};
