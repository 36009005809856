import { Images } from 'assets/images/images.index';
import { IPropsItemPlan } from 'models/itemPlan.interface';
import { removePlanFromUser } from 'shared/services/plans';
import './Item-plan.scss';
import { ItemStatus, Link } from 'components';
export const ItemPlan = (props: IPropsItemPlan) => {
  const editPlan = (planId: number, uid: string) => {
    console.log(planId);
    console.log(uid);
  };
  const removePlan = (planId: number, uid: string) => {
    removePlanFromUser(uid, planId);
  };
  return (
    <div className="item__plan__container">
      <div className="item__plan__container__info">
        <div className="container__info__title">
          <h5 className="info__title">{props.planName}</h5>
          <ItemStatus status={props.status} />
        </div>
        <div className="info__detail">
          <span>{props.paymentType}</span>
          <span>
            {props.product_count}/{props.max_count} Autos
          </span>
        </div>
      </div>
      <div className="item__plan__container__actions">
        <Link
          onClick={() => {
            editPlan(props.id, props.uid);
          }}
          type="primary"
          label="Editar Plan"
          image={Images.edit}
        />
        <Link
          onClick={() => {
            removePlan(props.id, props.uid);
          }}
          type="primary"
          label="Eliminar Plan"
          image={Images.remove}
        />
      </div>
    </div>
  );
};
