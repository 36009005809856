export type TypeProperty = 'auto' | 'motora' | 'hogar' | 'mascota';
export type TypeState = 'warning' | 'error' | 'successful';
export enum TypeCodeServiceAppErrors {
  INTERNAL = 500,
  UNAUTHORIZED = 403,
  NOTALLOWED = 405,
}
export enum TypeAppErrors {
  ERRORBOUNDARY = 'Catch ErrorBoundary',
  ERRORSERVICE = 'Catch error service',
  ERRORINTERNAL = 'Catch error internal',
  USERINTERACTIONISSUE = 'Catch error user interaction issue',
}
export enum TypeAppEvents {
  INTERACTIVE = 'Usuario interactuó con la pagina',
}
