
/* eslint-disable react/no-unknown-property */ 
import { ButtonClose } from 'components/atoms/Button-close/Button-close';
import { useState } from 'react';
import { Icons } from 'assets/icons/icons.index';
import './PopUpDropOff.scss';
import { RadioButton } from 'components/atoms/Radio-button/Radio-button';
import { Button } from 'components/atoms/Button/Button';
import { getContactInfo } from 'utils/connect-info.utils';
import { useSelector } from 'react-redux';
import { IAppReducer } from 'models/reducer.interface';
import { setInformationForDropOff } from 'pages/Checkout/services/zapier.service';

interface IPropsPopupPopUpDropOff {
  closePopUp: () => void;
  exitProcess?: () => void;
}
export const PopUpDropOff = (props: IPropsPopupPopUpDropOff) => {
  const {
    branchReducer: { branch },
    checkoutReducer,
  } = useSelector((state: IAppReducer) => state);
  const [flow] = useState(() => (Math.random() < 0.5 ? 0 : 1));
  const [options, setOptions] = useState([
    { message: 'Solo estaba cotizando', isSelected: false },
    { message: 'Precios más altos de lo esperado', isSelected: false },
    { message: 'mi auto no califica', isSelected: false },
  ]);
  const [isSent, setIsSent] = useState(false);
  const handleOption = (index: number) => {
    options[index].isSelected = !options[index].isSelected;
    setOptions([...options]);
  };
  const sendOptionDropOffs = () => {
    try {
      const data = {
        date: new Date().toISOString(),
        options: options
          .filter((p) => p.isSelected)
          .map((option) => option.message),
        planIds: checkoutReducer.order?.dataOrderCompleted?.products
          .filter((plan: { quantity: number }) => plan.quantity > 0)
          .map((plan: { product_id: number }) => plan.product_id),
        step: checkoutReducer.step ?? 0 + 1,
        option: 'close view X',
        utms: checkoutReducer.utms,
      };
      setInformationForDropOff(data);
      localStorage.setItem('drop-of', (new Date().getMonth() + 1).toString());
    } catch (error) {
      console.log('Error al enviar datos');
    } finally {
      setIsSent((state) => !state);
    }
  };
  return (
    <div className={`popup__drop__off__overload`}>
      <div className={`popup__drop__off__container`}>
        <div className={`popup__drop__off__close`}>
          <ButtonClose
            size="medium"
            template="dark"
            className="popup__drop__off__button"
            onClick={isSent ? props.exitProcess : props.closePopUp}
          />
        </div>
        <div className="popup__drop__off__body">
          <img
            className="popup__drop__off__icon"
            src={Icons.contactCenter}
            alt="Necesitas ayuda"
          />
          {isSent ? (
            <div className="popup__drop__off__header">
              <h3 className="popup__drop__off__header__title">
                ¡Muchas gracias!
              </h3>
              <span className="popup__drop__off__header__subtitle">
                ya enviamos la información
              </span>
              <p className="popup__drop__off__header__text">
                La utilizaremos para mejorar tu experiencia de compra.
              </p>
            </div>
          ) : (
            <>
              <div className="popup__drop__off__header">
                <h3 className="popup__drop__off__header__title">
                  ¿ya te vas?{' '}
                </h3>
                <span className="popup__drop__off__header__subtitle">
                  aún no terminamos la compra
                </span>
                {flow == 0 ? (
                  <div
                    className="popup__drop__off__footer"
                    style={{ marginBottom: '24px' }}
                  >
                    <p>Quieres realizar tu compra con ayuda</p>
                    <a
                      className="popup__drop__off__footer__button"
                      data-click_type="call"
                      data-click_name="main"
                      data-click_objective="Subscription"
                      href={`tel:${getContactInfo(branch).phone}`}
                    >
                      llamar a un agente
                      <img
                        className="popup__drop__off__icon"
                        src={Icons.support}
                        alt="Necesitas ayuda"
                      />
                    </a>
                    <div
                      className="popup__drop__off__divider"
                      style={{ marginTop: '12px' }}
                    ></div>
                  </div>
                ) : (
                  ''
                )}
                <p className="popup__drop__off__header__text">
                  Por favor selecciona el motivo por el que abandonas el
                  proceso.
                </p>
              </div>
              <div className="popup__drop__off__options">
                {options.map((option, index) => (
                  <div
                    key={'radio__' + index + '__option'}
                    className={`popup__drop__off__option ${
                      option.isSelected ? 'selected' : ''
                    }`}
                    onClick={() => handleOption(index)}
                    role="button"
                  >
                    {option.message}
                    <RadioButton isSelected={option.isSelected}></RadioButton>
                  </div>
                ))}
              </div>
              {options.filter((option) => option.isSelected).length ? (
                <div className="popup__drop__off__button">
                  <Button
                    className="button"
                    size="small"
                    template="primary"
                    color="primary"
                    typeStyle="square"
                    type="button"
                    isDisabled={false}
                    onClick={sendOptionDropOffs}
                    isLoading={false}
                  >
                    ENVIAR
                  </Button>
                </div>
              ) : (
                ''
              )}
            </>
          )}

          {flow === 1 || isSent ? (
            <>
              <div className="popup__drop__off__divider"></div>
              <div className="popup__drop__off__footer">
                <p>Quieres realizar tu compra con ayuda</p>
                <a
                  className="popup__drop__off__footer__button"
                  data-click_type="call"
                  data-click_name="secundary"
                  data-click_objective="Subscription"
                  href={`tel:${getContactInfo(branch).phone}`}
                >
                  llamar a un agente
                  <img
                    className="popup__drop__off__icon"
                    src={Icons.support}
                    alt="Necesitas ayuda"
                  />
                </a>
              </div>
            </>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};
