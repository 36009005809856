import './Item-menu-list.scss';
import * as CSS from 'csstype';
import { useNavigate } from 'react-router-dom';
import { Images } from 'assets/images/images.index';

export interface IPropsItemMenuList {
  label: string;
  subTitle: string;
  styleInLine?: CSS.Properties<string | number>;
  active?: boolean;
  link: string;
  icon: string | JSX.Element;
  id: string;
}
export const ItemMenuList = (props: IPropsItemMenuList) => {
  const navigate = useNavigate();
  const goToPage = (path: string) => {
    navigate(path);
  };
  return (
    <div
      className="item__menu__container"
      id={props.id}
      onClick={() => goToPage(props.link)}
    >
      <div className="item__menu">
        <div className="item__menu__info">
          {typeof props.icon === 'string' ? (
            <img src={props.icon} alt="Navegar a pagina" />
          ) : (
            props.icon
          )}
          <div className="item__menu__labels">
            <div className="item__menu__labels__title">
              {props.label}
              <img src={Images.arrowItemMenu} alt="" />
            </div>
            <span>{props.subTitle}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
