import cms from 'shared/services/cms';

export async function getProvincias(countryCode: string) {
  const provinces = await cms.get(`/country/provinces/${countryCode}`);
  return provinces.data.data.attributes.data;
}

export async function getCantons(countryCode: string, provinceId: string) {
  const cantons = await cms.get(
    `/country/cantons/${countryCode}/${provinceId}`
  );
  return cantons.data.data.attributes.data;
}

export async function getDistricts(
  countryCode: string,
  provinceId: string,
  cantonId: string
) {
  const districts = await cms.get(
    `/country/districts/${countryCode}/${provinceId}/${cantonId}`
  );
  return districts.data.data.attributes.data;
}

export const getDeparments = async () => {
  const departments = await cms.get(`/country/departments/CO`);
  return departments.data.data.attributes.data;
};

export const getMunicipalities = async (departmentId: string) => {
  const municipalities = await cms.get(
    `/country/municipality/CO/${departmentId}`
  );
  return municipalities.data.data.attributes.data;
};

export const getZipCodeInfoPuertoRico = async (zipCode: string) => {
  const municipalities = await cms.get(`/country/zipCode/PR/${zipCode}`);
  return municipalities.data.data.attributes.data;
};
