import {
  TypeActionsEditPlan,
  editPlantAction,
} from '../actions/edit-plan.types';
import { initialStateEditPlan } from '../state/edit-plan.state';

export const editPlanReducer = (
  state = initialStateEditPlan,
  action: editPlantAction
) => {
  switch (action.type) {
    case TypeActionsEditPlan.saveInformation:
      return {
        ...state,
        data: [...action.data],
      };
    case TypeActionsEditPlan.resetInformation:
      return initialStateEditPlan;
    default:
      return state;
  }
};
