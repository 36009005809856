import { useFormik } from 'formik';
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import {
  IFormCreateProfile,
  IFormCreateProfileErrors,
} from '../models/Create-profile.interface';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setCreateAccountInformation } from 'pages/Create-account/store/actions/create-account.types';
import { AESEncryptDecryptService } from 'shared/services/aes-encrypt-decrypt.service';
import { IAppReducer } from 'models/reducer.interface';

interface LocationState {
  redirect: string;
  email: string;
}

export const UseCreateProfileValidation = () => {
  const {
    createAccountReducer,
  } = useSelector((state: IAppReducer) => state, shallowEqual);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [timer, setTimer] = useState<NodeJS.Timeout>(setTimeout(() => '', 0));
  const locationState = location.state as LocationState;
  const textEncrypt = new AESEncryptDecryptService();
  const handleLoading = async () => {
    setIsLoading((state) => !state);
  };
  const formCreateProfileInitialValues: IFormCreateProfile = {
    email: locationState?.email ?? '',
    password: '',
    conFirmPassword: '',
  };
  const formCreateProfileValidate = (values: IFormCreateProfile) => {
    const errors: IFormCreateProfileErrors = {};
    if (!values.email) {
      errors.email = 'Este campo es requerido';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'No es un correo valido, debe tener @ y dominio';
    }
    if (!values.password) {
      errors.password = 'Este campo es requerido';
    } else if (values.password.length < 6) {
      errors.password = 'Minino 6 caracteres';
    }
    if (!values.conFirmPassword) {
      errors.conFirmPassword = 'Este campo es requerido';
    }
    return errors;
  };
  const processInformation = async () => {
    try {
      dispatch(
        setCreateAccountInformation({
          email: formCreateProfile.values.email,
          password: textEncrypt.encrypt(formCreateProfile.values.password),
        })
      );
      navigate('/create-account/personal-information');
    } catch (error) {
      console.log('error', error);
    }
  };
  const formCreateProfile = useFormik({
    initialValues: formCreateProfileInitialValues,
    validate: formCreateProfileValidate,
    onSubmit: async () => {
      try {
        setIsLoading(true);
        await processInformation();
      } catch (error) {
        console.log('Error con servidor');
      } finally {
        setIsLoading(false);
      }
    },
  });

  const handlePassword = (
    event:
      | React.FocusEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLInputElement>,
    typeEvent: 'blur' | 'change'
  ) => {
    const { value } = event.target;
    clearTimeout(timer);
    formCreateProfile.setFieldValue('password', value, false);
    if (!value) {
      formCreateProfile.handleBlur(event);
    } else {
      if (
        formCreateProfile.values.conFirmPassword &&
        formCreateProfile.values.conFirmPassword !== value
      ) {
        setTimer(
          setTimeout(() => {
            formCreateProfile.setFieldError(
              'password',
              'Las contraseñas no coinciden'
            );
            formCreateProfile.setFieldError(
              'conFirmPassword',
              'Las contraseñas no coinciden'
            );
          }, 1000)
        );
      } else {
        if (typeEvent === 'blur') {
          formCreateProfile.handleBlur(event);
        } else {
          formCreateProfile.handleChange(event);
        }
      }
    }
  };

  const handleConfirmPassword = (
    event:
      | React.FocusEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLInputElement>,
    typeEvent: 'blur' | 'change'
  ) => {
    const { value } = event.target;
    clearTimeout(timer);
    formCreateProfile.setFieldValue('conFirmPassword', value, false);
    if (!value) {
      formCreateProfile.handleBlur(event);
    } else {
      if (
        formCreateProfile.values.password &&
        formCreateProfile.values.password !== value
      ) {
        setTimer(
          setTimeout(() => {
            formCreateProfile.setFieldError(
              'password',
              'Las contraseñas no coinciden'
            );
            formCreateProfile.setFieldError(
              'conFirmPassword',
              'Las contraseñas no coinciden'
            );
          }, 1000)
        );
      } else {
        if (typeEvent === 'blur') {
          formCreateProfile.handleBlur(event);
        } else {
          formCreateProfile.handleChange(event);
        }
      }
    }
  };
  return {
    processInformation,
    isLoading,
    handleLoading,
    formCreateProfile,
    handlePassword,
    handleConfirmPassword,
    createAccountReducer
  };
};
