import { Button, Input, Image, Divider, HaveAccount } from 'components';
import { Images } from 'assets/images/images.index';
import useUpdatePassword from './hooks/pdate-password.hook';
import './Update-password.scss';

const UpdatePassword = () => {
  const { formUpdatePassword } = useUpdatePassword();
  return (
    <div className="update__password__container container__generic">
      <Image
        size="medium"
        image={Images.logoConnect}
        description="Logo Connect"
        styleInLine={{ marginLeft: 'auto' }}
      ></Image>
      <div className="update__password__from__content">
        <h6 className="update__password__from__title">¡Crea tu cuenta!</h6>
        <p className="update__password__from__text">
          Bienvenido/a a Connect Assistance. <br /> Únete y disfruta de
          [Beneficios].
        </p>
        <form onSubmit={formUpdatePassword.handleSubmit} noValidate>
          <Input
            label="Correo Electrónico"
            id="update-password-email"
            type="email"
            name="email"
            onChange={formUpdatePassword.handleChange}
            onBlur={formUpdatePassword.handleBlur}
            value={formUpdatePassword.values.email}
            isTouched={formUpdatePassword.touched.email}
            errorInput={formUpdatePassword.errors.email}
            errorLabel={formUpdatePassword.errors.email}
            isRequired={true}
            form={formUpdatePassword}
          />
          <Input
            label="Contraseña"
            id="update-password"
            type="password"
            name="password"
            onChange={formUpdatePassword.handleChange}
            onBlur={formUpdatePassword.handleBlur}
            value={formUpdatePassword.values.password}
            placeholder=""
            isTouched={formUpdatePassword.touched.password}
            errorInput={formUpdatePassword.errors.password}
            errorLabel={formUpdatePassword.errors.password}
            isRequired={true}
            form={formUpdatePassword}
          />
          <Input
            label="Confirmar Contraseña"
            id="update-password-confimrPassword"
            type="password"
            name="confimrPassword"
            onChange={formUpdatePassword.handleChange}
            onBlur={formUpdatePassword.handleBlur}
            value={formUpdatePassword.values.confimrPassword}
            placeholder=""
            isTouched={formUpdatePassword.touched.confimrPassword}
            errorInput={formUpdatePassword.errors.confimrPassword}
            errorLabel={formUpdatePassword.errors.confimrPassword}
            isRequired={true}
            form={formUpdatePassword}
          />
          <Button
            color="primary"
            size="medium"
            template="primary"
            typeStyle="rounded"
            className="update__password__from__button"
            type="submit"
            onClick={formUpdatePassword.handleSubmit}
            isDisabled={
              formUpdatePassword.isSubmitting || !formUpdatePassword.isValid
            }
            isLoading={formUpdatePassword.isSubmitting}
          >
            Crear Cuenta
          </Button>
          <Divider classWrapper="update__password__from__divider"></Divider>
        </form>
      </div>
      <HaveAccount />
    </div>
  );
};
export default UpdatePassword;
