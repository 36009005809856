import { Card } from 'components/atoms/Card/Card';
import { Chips } from 'components/atoms/Chips/Chips';
import { RadioButton } from 'components/atoms/Radio-button/Radio-button';
import { ItemStatus } from 'components/atoms/Item-status/Item-status';
import * as paymentsUtils from 'utils/payments.utils';
import './Item-credit-card.scss';

export interface IPropsItemCreditCard {
  id: string;
  brand: string | undefined;
  WrapperClassName?: string;
  onClickSelect: (id: number | string) => void;
  isSelected?: boolean;
  isPrincipal: boolean;
  lastDigits: string | number;
  expMonth: string | number;
  expYear: string | number;
}

export const ItemCreditCard = (props: IPropsItemCreditCard) => {
  return (
    <Card
      id={`item__credit__card__${props.id}`}
      WrapperClassName={`item__credit__card__container is__clickable ${
        props.WrapperClassName ? props.WrapperClassName : ' '
      }`}
      isSelected={props.isSelected}
      onClick={() => props.onClickSelect(props.id)}
    >
      <RadioButton isSelected={props.isSelected as boolean}></RadioButton>
      <div className="item__credit__card__content">
        <div className="item__credit__card__brand">
          <img
            className="item__credit__card__logo"
            src={paymentsUtils.getIconCard(props.brand as string)}
            alt={props.brand}
          />
          {props.isPrincipal && (
            <ItemStatus
              hidenIcon={true}
              message={'Principal'}
              status={'successful'}
            ></ItemStatus>
          )}
        </div>
        <div className="item__credit__card__info">
          <h6 className="item__credit__card__digits">
            Terminada en <strong>{props.lastDigits}</strong>
          </h6>
          <Chips label={`Expira ${props.expMonth}/${props.expYear}`} />
        </div>
      </div>
    </Card>
  );
};
