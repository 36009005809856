import './Button-add-item-skeleton.scss';

export const ButtonAddItemSkeleton = () => {
  return (
    <div className="button__add__item__skeleton__container">
      <div className="label skeleton"></div>
      <div className="button skeleton"></div>
    </div>
  );
};
