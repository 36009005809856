import React from 'react';
import { sendLog } from 'shared/services/logs.service';
import { GenericError } from 'components/molecules/Generic-error/Generic-error';
import { TypeAppErrors } from 'models/app.interface';

interface IPropsErrorBoundary {
  children: React.JSX.Element;
  user: string;
  agentId: number | null;
  funelId: string | undefined;
}
interface IStateErrorBoundary {
  hasError: boolean;
}
class ErrorBoundary extends React.Component<
  IPropsErrorBoundary,
  IStateErrorBoundary
> {
  constructor(props: IPropsErrorBoundary) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentDidCatch(error: any, info: any) {
    const data = {
      nameError: TypeAppErrors.ERRORBOUNDARY,
      error: {
        type: error.name,
        message: error.message,
        data: info.componentStack.toString(),
      },
      payload: {
        scope: 'app',
        action: 'Cargando pagina',
        url: window.location.href,
        user: this.props.user,
        agentId: this.props.agentId,
        funelId: this.props.funelId,
      },
    };
    // sendLog(data);
  }

  render() {
    if (this.state.hasError) {
      return (
        <GenericError
          title="¡Ups! Ha ocurrido un error"
          message="Por favor inténtalo mas tarde."
          onClick={() => window.location.reload()}
        />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
