import { FormikValues, useFormik } from 'formik';
import { Input, TypeInput } from 'components/atoms/Input/Input';
import './Form-pets.scss';
import { Upload } from 'components/atoms/Upload/Upload';
import { IDataAttributes } from '../../models/Create-properties.interface';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { TitleWhitImage } from 'components/atoms/Title-whit-image/Title-whit-image';
import { Images } from 'assets/images/images.index';

export const FormPets = forwardRef(function FormPets(
  props: {
    properties: IDataAttributes[];
    typeProduct: string;
    quantity: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFilesData: (data: any, control: string) => void;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: any
) {
  const formPetsRef = useRef(null);
  const quantity = Array(props.quantity).fill(0);
  const formPetsPropertiesInitialValues: FormikValues = {};
  const formPetsPropertiesValidate = (values: FormikValues) => {
    const errors: FormikValues = {};
    quantity.forEach((_, quantityIndex) => {
      props.properties.forEach((property) => {
        if (
          property.attributes.required &&
          !values[
            `${props.typeProduct}__${property.attributes.name}__${quantityIndex}__${property.id}`
          ]
        ) {
          errors[
            `${props.typeProduct}__${property.attributes.name}__${quantityIndex}__${property.id}`
          ] = 'Campo requerido';
        }
      });
    });
    return errors;
  };

  const formPetsProperties = useFormik({
    initialValues: formPetsPropertiesInitialValues,
    validate: formPetsPropertiesValidate,
    onSubmit: async (values) => {
      return values;
    },
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setArchive = async (data: any, name: string, control: string) => {
    if (data) {
      const fileName = data[0].name;
      await formPetsProperties.setFieldValue(name, fileName);
      props.setFilesData(data[0], control);
    } else {
      await formPetsProperties.setFieldValue(name, '');
    }
    formPetsPropertiesValidate(formPetsProperties.values);
  };
  useImperativeHandle(ref, () => {
    return { getValues };
  });

  const getValues = (): FormikValues => {
    if (formPetsProperties.isValid) {
      return formPetsProperties.values;
    } else {
      return {};
    }
  };
  return (
    <div className="grid__span-6" ref={formPetsRef}>
      {quantity.map((_, quantityIndex) => (
        <>
          <TitleWhitImage
            key={`title__whit__image__mascota-${quantityIndex}`}
            title={`Mascota ${quantityIndex + 1}`}
            image={Images.mascota}
            styleInLineImage={{
              width: '2rem',
              height: '2rem',
            }}
          ></TitleWhitImage>
          <div
            className="form__pets__container grid rg-0 grid__col-6 grid__span-6"
            key={`grid-${quantityIndex}`}
          >
            {props.properties.map((property, indexItem) =>
              property.attributes.type === 'file' ? (
                <Upload
                  indexItem={quantityIndex}
                  key={`${property.id}-${quantityIndex}`}
                  errorLabel={
                    formPetsProperties.errors[
                      `${props.typeProduct}__${property.attributes.name}__${quantityIndex}__${property.id}`
                    ] as string
                  }
                  setFileURL={(event) =>
                    setArchive(
                      event,
                      `${props.typeProduct}__${property.attributes.name}__${quantityIndex}__${property.id}`,
                      `file-${quantityIndex}-${property.id}`
                    )
                  }
                  WrapperClassName={
                    property.attributes.large ? 'grid__span-6' : 'grid__span-3'
                  }
                  isRequired={property.attributes.required as boolean}
                ></Upload>
              ) : (
                <Input
                  key={`${property.id}-${quantityIndex}-${indexItem}`}
                  WrapperClassName={
                    property.attributes.large ? 'grid__span-6' : 'grid__span-3'
                  }
                  label={property.attributes.name}
                  id={`create-properties-${props.typeProduct}__${property.attributes.name}__${quantityIndex}__${property.id}`}
                  type={property.attributes.type as TypeInput}
                  name={`${props.typeProduct}__${property.attributes.name}__${quantityIndex}__${property.id}`}
                  onChange={formPetsProperties.handleChange}
                  onBlur={formPetsProperties.handleBlur}
                  value={
                    formPetsProperties.values[
                      `${props.typeProduct}__${property.attributes.name}__${quantityIndex}__${property.id}`
                    ] || ''
                  }
                  isTouched={
                    formPetsProperties.touched[
                      `${props.typeProduct}__${property.attributes.name}__${quantityIndex}__${property.id}`
                    ] as boolean
                  }
                  errorInput={
                    formPetsProperties.errors[
                      `${props.typeProduct}__${property.attributes.name}__${quantityIndex}__${property.id}`
                    ] as string
                  }
                  errorLabel={
                    formPetsProperties.errors[
                      `${props.typeProduct}__${property.attributes.name}__${quantityIndex}__${property.id}`
                    ] as string
                  }
                  isRequired={property.attributes.required as boolean}
                  isDisabled={false}
                  maxLength={property.attributes.length as number}
                  form={formPetsProperties}
                />
              )
            )}
            <div
              key={`properties__divider__pets-${quantityIndex}`}
              className="create__properties__atributes__divider grid__span-6"
            ></div>
          </div>
        </>
      ))}
    </div>
  );
});
