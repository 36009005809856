import { TypeInput } from 'components/atoms/Input/Input';

export interface IPropertiesInfo {
  id: number;
  attributes: IPropertiesInfoAttributes;
}

export interface IPropertiesInfoAttributes {
  property_status: IProductsUser;
  status: boolean;
  createdAt: Date;
  updatedAt: Date;
  publishedAt: Date;
  tipo_producto: ITypeProduct;
  productos_usuario: IProductsUser;
  property_attributes: PropertyAttribute[];
}

interface ITypeProduct {
  data: ITypeProductData;
}

interface ITypeProductData {
  id: number;
  attributes: ITypeProductAttributes;
}

interface ITypeProductAttributes {
  name: string;
  enabled: boolean;
  createdAt: Date;
  updatedAt: Date;
  publishedAt: Date;
  propiedades: IProperties;
  type: TypeInput;
}

interface IProperties {
  data: IPropertiesData[];
}

interface IPropertiesData {
  id: number;
  attributes: IPropertiesAttributes;
}

interface IPropertiesAttributes {
  name: string;
  status: boolean;
  createdAt: Date;
  updatedAt: Date;
  publishedAt: Date;
  type: string;
  length: number;
  required: boolean;
}

export interface IProductsUser {
  data: IProductsUserData[];
  id: number;
}
export interface IProductsUserData {
  id: number;
  attributes:
    | {
        subscription_id?: {
          data: {
            attributes: {
              status_subscription_id: {
                data: {
                  attributes: {
                    enabled: boolean;
                  };
                };
              };
            };
          };
        };
      }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | any;
}
interface PropertyAttribute {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  publishedAt: Date;
  status: boolean;
  valor: string;
  propiedad: Property;
}

interface Property {
  id: number;
  status: boolean;
  type: TypeInput;
  required: boolean;
  length: number;
  name: string;
  large: boolean | null;
}

export interface IPropertiesinfo {
  vehicles: IPropertiesDetail[];
  homes: IPropertiesDetail[];
  motorcycles: IPropertiesDetail[];
  pets: IPropertiesDetail[];
}

export enum TypeProperties {
  VEHICULOS = 'Vehículos',
  VEHICULOS_WITHOUT_ACCENT = 'Vehiculos',
  AUTO = 'Auto',
  HOGAR = 'Hogar',
  MOTOCICLETA = 'Motocicleta',
  MOTORA = 'Motora',
  MASCOTA = 'Mascota',
}

export interface IPropertiesDetail {
  id: number;
  title: string;
  attributes: string[];
  status: 'successful' | 'pending' | 'error' | 'cancelled';
  type: TypeProperties | string;
}

export interface IItemPropertiesData {
  name?: string;
  race?: string;
  make?: string;
  model?: string;
  licensePalte?: string;
  year?: string;
  country?: string;
  address?: string;
  city?: string;
  zipCode?: string;
  status?: 'successful' | 'pending' | 'error';
}

export interface IpropertiesLimit {
  vehicles: number;
  homes: number;
  motorcycles: number;
  pets: number;
}

export interface IPropertiesSummary {
  properties: IPropertiesQuantity;
  products: IPropertiesQuantity;
}

export interface IPropertiesQuantity {
  auto: number;
  motorcycles: number;
  homes: number;
  pets: 0;
}
