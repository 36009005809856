import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router';
interface IElemnt {
  children: JSX.Element;
}
export const ScrollRestoration = (props: IElemnt) => {
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
    window.analytics.page('Loaded a Page');
  }, [pathname]);
  return props.children;
};
