import { IDetailStep } from "models/info.steps.interface";

export const stepsRenewalSubscription: IDetailStep[] = [
  {
    description: 'Renovar suscripción',
    state: 'active',
    link: `/renewal-subscription`,
    permissionToNavigate: false,
  },
];
