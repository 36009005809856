import { Navigate, useRoutes } from 'react-router';
import { EditPlanInformation } from '../Edit-plan-information/Edit-plan-information';

export const EditPlanRouter = (pros: {
  handleStep: (index: number) => void;
}) => {
  const element = useRoutes([
    {
      children: [
        {
          path: '/:planId/:subscriptionId',
          element: (
            <EditPlanInformation
              handleStep={(index: number) => pros.handleStep(index)}
            />
          ),
        },
        {
          path: '/*',
          element: <Navigate to="/settings" />,
        },
      ],
    },
  ]);

  return element;
};
