import { ItemCardSkeleton } from 'components/skeleton/molecules/Item-card-skeleton/Item-card-skeleton';
import { TitleSectionSkeleton } from 'components/skeleton/molecules/Title-section-skeleton/Title-section-skeleton';

export const PaymentsBillingSkeleton = () => {
  return (
    <div className="payments__billing__skeleton__container">
      <TitleSectionSkeleton />
      <div className="payments__container__items">
        {[1, 2].map((item) => (
          <ItemCardSkeleton key={item} />
        ))}
      </div>
    </div>
  );
};
