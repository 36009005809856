import { Images } from 'assets/images/images.index';
import { ButtonClose } from 'components/atoms/Button-close/Button-close';
import { PopupOverlay } from 'components/atoms/Popup-overlay/Popup-overlay';
import { useState } from 'react';

import './PopUp-welcome.scss';

export interface IPropsPopUpWelcome {
  info: {
    name: string;
    onClick: () => void;
  };
}

export const PopUpWelcome = ({
  info: { name, onClick },
}: IPropsPopUpWelcome) => {
  const [indexSlide, setIndexSlide] = useState<string>('1');

  const goToSlide = (idElement: string) => {
    const $slide = document.querySelector(idElement);
    if ($slide) {
      setIndexSlide(idElement.split('_')[1]);
      $slide.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <PopupOverlay>
      <div className="popUp__welcome__contianer">
        <div className="popUp__welcome__background__header"></div>

        <div className="popUp__welcome__btn__close">
          <ButtonClose template='dark' size="medium" onClick={onClick}></ButtonClose>
        </div>
        <div className="carousel__container">
          <div className="carousel__content">
            <div
              id="welcome_1"
              className="carousel__item"
              onClick={() => goToSlide('#welcome_2')}
            >
              <img
                src={Images.welcome_1}
                alt="Bienvenida"
                className="carousel__image"
              />
              <h5 className="carousel__title">
                ¡Bienvenid@ <br />
                {name}!
              </h5>
              <p className="carousel__item__text center">
                Somos una <strong>plataforma digital de asistencia</strong> en
                carretera y hogar que se adapta a tus necesidades y a las de tu
                familia.
              </p>
            </div>
            <div
              id="welcome_2"
              className="carousel__item"
              onClick={() => goToSlide('#welcome_1')}
            >
              <img
                src={Images.welcome_2}
                alt="Funcionalidad connect"
                className="carousel__image"
              />
              <p className="carousel__item__text ">
                Aquí podrás <strong>personalizar</strong> toda la
                <br />
                información que se necesita para
                <br />
                que disfrutes de nuestras
                <br />
                asistencias:
              </p>
              <ul>
                <li>Actualizar o editar tu perfil.</li>
                <li>Gestionar tus propiedades.</li>
                <li>Darle seguimiento al historial de facturación y pago.</li>
              </ul>
            </div>
          </div>
          <div className="carousel__controls">
            <div className="controls__dots">
              <a
                className="controls__dot"
                onClick={() => goToSlide('#welcome_1')}
              >
                <span></span>
              </a>
              <a
                className="controls__dot"
                onClick={() => goToSlide('#welcome_2')}
              >
                <span></span>
              </a>
            </div>
            <div className="controls__arrow">
              {indexSlide === '1' ? (
                <a
                  className="controls__arrow--right"
                  onClick={() => goToSlide('#welcome_2')}
                >
                  <img src={Images.arrowSlider} alt="Pasar slide 2" />
                </a>
              ) : (
                <a
                  className="controls__arrow--left"
                  onClick={() => goToSlide('#welcome_1')}
                >
                  <img src={Images.arrowSlider} alt="Regresar a slide 1" />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </PopupOverlay>
  );
};
