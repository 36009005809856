import { Images } from 'assets/images/images.index';
import { ButtonClose } from 'components/atoms/Button-close/Button-close';
import { PopupOverlay } from 'components/atoms/Popup-overlay/Popup-overlay';
import { TypeState } from 'models/app.interface';
import { ReactNode } from 'react';
import './Popup-informative.scss';
import { Button } from 'components/atoms/Button/Button';

interface IPropsPopUpInformative {
  type: TypeState;
  title: string;
  subtitle?: string;
  text?: string;
  labelButton?: string;
  closePopUp: () => void;
  children?: ReactNode;
}
export const PopUpInformative = (props: IPropsPopUpInformative) => {
  const setIcon = () => {
    return {
      error: Images.error,
      warning: Images.chipWarning,
      successful: Images.check,
    }[props.type];
  };
  return (
    <PopupOverlay>
      <div
        className={`popup__informative__container popup__informative--${props.type}`}
      >
        <ButtonClose
          size="medium"
          className="popup__informative__close"
          onClick={props.closePopUp}
          template="light"
        />
        <div className="popup__informative__body">
          <img
            className="popup__informative__icon"
            src={setIcon()}
            alt="Icono tipo modal"
          />
          <h2
            className="popup__informative__title"
            dangerouslySetInnerHTML={{ __html: props.title }}
          ></h2>
          {props.subtitle && (
            <h6
              className="popup__informative__subtitle"
              dangerouslySetInnerHTML={{ __html: props.subtitle }}
            ></h6>
          )}
          {props.text && (
            <p
              className="popup__informative__text"
              dangerouslySetInnerHTML={{ __html: props.text }}
            ></p>
          )}
          {props.children}
          {props.labelButton && (
            <Button
              className={`popup__informative__button ${props.type}`}
              type="button"
              size="small"
              template="secondary"
              typeStyle="square"
              isDisabled={false}
              label={props.labelButton}
              color="primary"
              onClick={props.closePopUp}
            />
          )}
        </div>
      </div>
    </PopupOverlay>
  );
};
