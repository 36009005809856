import cms from 'shared/services/cms';
import { ICheckouRequest } from '../models/Payment-subscription.interface';

interface ICreateOrUpdateOrder {
  email: string;
  phone: string;
  country: number;
  plans: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  raw_data: any;
  orderId?: string;
}

/**
 * Function to add a subscription to a user
 * @param subscription ICheckouRequest
 * @returns
 */
export async function makePayment(
  subscription: ICheckouRequest,
  tokenCaptcha: string
) {
  const data = {
    ...subscription,
    authorizationToken: tokenCaptcha,
  };

  const response = await cms.post('user-subscriptions/direct-selling', data);
  return response.data;
}

export const createOrderId = async (data: ICreateOrUpdateOrder) => {
  const response = await cms.post('purchaseorder/', {
    ...data,
  });
  return response.data.order;
};

export const updateOrderId = async (data: ICreateOrUpdateOrder) => {
  const response = await cms.put('purchaseorder/', {
    ...data,
  });
  return response.data;
};
