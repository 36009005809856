import './Select.scss';
import { Images } from 'assets/images/images.index';
import { CSSProperties, useEffect, useRef, useState } from 'react';

export interface IOptionSelect {
  value: string | number;
  description: string;
  info?: string;
  isDisabled?: boolean;
  readonly?: boolean;
  id?: string | number;
  icon?: string;
}

export interface IPropsSelect {
  placeholder?: string;
  name: string;
  id: string;
  options: IOptionSelect[];
  value: string | number;
  onClick: (event: string | number, index?: number) => void;
  WrapperClassName?: string;
  errorSelect?: string | undefined;
  isDisabled?: boolean;
  type?: 'small';
  indexSelect?: number;
  icon?: string;
  isRequired?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onBlur: (e: React.FocusEvent<any>) => void;
  isTouched?: boolean;
}

export const Select = (props: IPropsSelect) => {
  const contentElement = useRef<HTMLDivElement>(null);
  const [showOptionSelect, setShowOptionSelect] = useState<boolean>(false);
  const [optionSelected, setOptionSelected] =
    useState<string | number | undefined>(undefined);
  const [styleDropDown, setStyleDropDown] =
    useState<CSSProperties | undefined>(undefined);
  useEffect(() => {
    if (validateSelectedOption(props.value)) {
      setOptionSelected(props.value);
    } else {
      setOptionSelected(undefined);
    }
  }, [props.value]);
  const validateSelectedOption = (
    value: string | number | undefined
  ): string | undefined => {
    return props.options.find((p) => p.value == value)?.description || '';
  };
  const selectIsValid = () => {
    return props.errorSelect && props.isTouched;
  };
  useEffect(() => {
    if (contentElement.current) {
      const div = contentElement.current as HTMLElement;
      const rect = div.getBoundingClientRect();
      const clientHeight = document.documentElement.clientHeight;
      const styles = { top: '54px' };
      if (rect.bottom > clientHeight - 48) {
        setStyleDropDown({ ...styles });
        styles.top = -div.clientHeight + 'px';
      }
      setStyleDropDown({ ...styles });
    }
  }, [showOptionSelect]);

  const openSelect = () => {
    setShowOptionSelect(true);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const closeSelect = (e?: any) => {
    setTimeout(() => {
      setStyleDropDown(undefined);
      setShowOptionSelect(false);
      e && props.onBlur(e);
    }, 200);
  };
  const handleSelectOption = (
    value: string | number,
    isDisabled: boolean | undefined,
    readOnly: boolean | undefined
  ) => {
    if (props.isDisabled) {
      return;
    }
    if (!isDisabled) {
      if (!readOnly) {
        setOptionSelected(value);
      }
      closeSelect();
      props.onClick(value, props.indexSelect);
    }
  };

  return (
    <>
      <div className={`container__select__generic ${props.WrapperClassName}`}>
        <div className="select__generic__base">
          <input
            className={`select__generic ${selectIsValid() ? 'error' : ''}${
              props.type === 'small' ? 'small' : ''
            } ${props.isDisabled ? 'disabled' : ''} ${
              !props.placeholder ? 'placeholder' : ''
            }`}
            autoComplete="off"
            type="text"
            name={props.name}
            id={props.id}
            readOnly={true}
            onFocus={
              !props.isDisabled
                ? openSelect
                : () => {
                    return;
                  }
            }
            // onBlur={(e) => {
            //   closeSelect(e);
            // }}
            value={validateSelectedOption(optionSelected)}
          />
          <label
            className={`select__label ${
              selectIsValid() ? 'label--error' : ''
            } ${
              (validateSelectedOption(optionSelected) ? true : false) &&
              props.placeholder
                ? 'select__label--value'
                : ''
            }`}
            htmlFor={props.id}
          >
            {props.placeholder}
            {props.isRequired && (
              <img
                className="select__image__required"
                src={Images.inputRequired}
                alt="Campo requerido"
              />
            )}
          </label>
          <img
            className={`select__generic__icon ${
              props.isDisabled ? 'disabled' : ''
            }`}
            src={Images.arrowDropdown}
            alt="icono select"
          />

          {!optionSelected && props.icon && (
            <img
              className="select__generic__text__icon"
              src={props.icon}
              alt="Icono de select"
            />
          )}
        </div>
        {showOptionSelect && (
          <div
            className="select__generic__options"
            ref={contentElement}
            style={styleDropDown}
          >
            {props.options.map((item, index) => (
              <div
                className={`select__generic__option ${
                  props.type === 'small' ? 'small' : ''
                } ${item.isDisabled ? 'disabled' : ''} ${item.id}`}
                key={`option-${index}`}
                onClick={() => {
                  handleSelectOption(
                    item.value,
                    item.isDisabled,
                    item.readonly
                  );
                }}
              >
                <div className="select__generic__option__info">
                  {item.description}
                  {item.info ? <span>{item.info}</span> : ''}
                </div>
                {item.icon && (
                  <img
                    className="select__generic__option__icon"
                    src={item.icon}
                    alt="icono opccion"
                  />
                )}
              </div>
            ))}
          </div>
        )}
        {selectIsValid() && (
          <span className="select__error">{props.errorSelect}</span>
        )}
      </div>
      {showOptionSelect && (
        <div className="overlay__mask" onClick={() => closeSelect()}></div>
      )}
    </>
  );
};
