export const ErrorSvg = () => {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.90084 0.853109C2.16288 0.853109 0.753988 2.262 0.753988 3.99996C0.753988 5.73792 2.16288 7.14682 3.90084 7.14682C5.6388 7.14682 7.04769 5.73792 7.04769 3.99996C7.04769 2.262 5.6388 0.853109 3.90084 0.853109ZM0.0546875 3.99996C0.0546875 1.87579 1.77667 0.153809 3.90084 0.153809C6.02501 0.153809 7.747 1.87579 7.747 3.99996C7.747 6.12413 6.02501 7.84612 3.90084 7.84612C1.77667 7.84612 0.0546875 6.12413 0.0546875 3.99996Z"
        fill="#F44336"
      />
      <path
        d="M0.753988 3.99996C0.753988 2.262 2.16288 0.853109 3.90084 0.853109C5.6388 0.853109 7.04769 2.262 7.04769 3.99996C7.04769 5.73792 5.6388 7.14682 3.90084 7.14682C2.16288 7.14682 0.753988 5.73792 0.753988 3.99996Z"
        fill="#F44336"
      />
      <path
        d="M2.73922 2.55961L5.34103 5.16143L5.06227 5.4402L2.46045 2.83838L2.73922 2.55961Z"
        fill="white"
      />
      <path
        d="M2.46045 5.16138L5.06227 2.55956L5.34103 2.83833L2.73922 5.44014L2.46045 5.16138Z"
        fill="white"
      />
    </svg>
  );
};
