import { Images } from 'assets/images/images.index';
import { ButtonClose } from 'components/atoms/Button-close/Button-close';
import { PopupOverlay } from 'components/atoms/Popup-overlay/Popup-overlay';
import { TypeState } from 'models/app.interface';
import { ReactNode } from 'react';
import './Popup-exist-porcess.scss';

interface IPropsPopupExistProcess {
  type: TypeState;
  title: string;
  message: string;
  labelButton?: string;
  labelButtonCancel?: string;
  closePopUp: () => void;
  exitProcess?: () => void;
  children?: ReactNode;
  iconIsHidden?: boolean;
  reverseCallToAction?: boolean;
  isLoading?: boolean;
  closeActionDisabled?: boolean;
  isDisabled?: boolean;
}
export const PopupExistProcess = (props: IPropsPopupExistProcess) => {
  const setIcon = () => {
    return {
      error: Images.errorOutline,
      warning: Images.warningOutline,
      successful: Images.checkInformative,
    }[props.type];
  };
  return (
    <PopupOverlay>
      <div className={`popup__exist_porcess ${props.type}`}>
        {!props.closeActionDisabled && (
          <ButtonClose
            size="medium"
            template="dark"
            className="popup__exist_porcess__close"
            onClick={
              !props.reverseCallToAction ? props.closePopUp : props.exitProcess
            }
          />
        )}
        {!props.iconIsHidden && (
          <img
            className="popup__exist_porcess__icon"
            src={setIcon()}
            alt="Icono tipo modal"
          />
        )}
        <h6>{props.title}</h6>
        {props.children ? (
          props.children
        ) : (
          <p
            className="popup__exist_porcess__text"
            dangerouslySetInnerHTML={{ __html: props.message }}
          ></p>
        )}

        <div className="popup__exist_porcess__container__btns">
          {props.exitProcess && (
            <button
              type="button"
              className={`popup__exist_porcess__btn ${props.type} ghost`}
              onClick={props.exitProcess}
            >
              {props.labelButtonCancel}
            </button>
          )}
          <button
            className={`popup__exist_porcess__btn ${props.type} ${
              props.isDisabled ? 'disabled' : ' '
            }`}
            onClick={
              !props.isLoading && !props.isDisabled
                ? props.closePopUp
                : () => ''
            }
            type="button"
            disabled={props.isDisabled}
          >
            {!props.isLoading ? (
              props.labelButton
            ) : (
              <div className="loader small"></div>
            )}
          </button>
        </div>
      </div>
    </PopupOverlay>
  );
};
