import { useColorHook } from 'hooks/Color.hook';
import { IPropsIcon } from './icons.interface';

export const ProcesadorSvg = (props: IPropsIcon) => {
  const color = useColorHook(props.color);
  return (
    <svg
      style={{ marginRight: '8px' }}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="copiar" clipPath="url(#clip0_5912_11178)">
        <path
          id="Vector"
          d="M11.6667 5.25H6.41667C5.77233 5.25 5.25 5.77233 5.25 6.41667V11.6667C5.25 12.311 5.77233 12.8333 6.41667 12.8333H11.6667C12.311 12.8333 12.8333 12.311 12.8333 11.6667V6.41667C12.8333 5.77233 12.311 5.25 11.6667 5.25Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M2.91675 8.75033H2.33341C2.024 8.75033 1.72725 8.62741 1.50846 8.40862C1.28966 8.18982 1.16675 7.89308 1.16675 7.58366V2.33366C1.16675 2.02424 1.28966 1.72749 1.50846 1.5087C1.72725 1.28991 2.024 1.16699 2.33341 1.16699H7.58342C7.89283 1.16699 8.18958 1.28991 8.40837 1.5087C8.62717 1.72749 8.75008 2.02424 8.75008 2.33366V2.91699"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5912_11178">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
