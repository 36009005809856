import { formatPrice } from 'utils/payments.utils';
import './Summary-information-totals-short-link.scss';
import { CardBody } from 'components/atoms/Card-body/Card-body';
import { IProductSegment } from 'models/segment.interface';

export interface IPropsSummaryTotals {
  values: {
    subtotal: number;
    discount: number;
    total: number;
    couponAmount: number;
    discountForFreeTrial?: number;
    priceForRoad: number;
    totalsForPlan?: {
      price: number;
    }[];
  };
  branch: string;
  decorationHidden?: boolean;
  templete?: 'outline' | 'default';
  isTrialPeriod?: boolean;
  termsAndConditions?: string;
  couponName?: string;
  periodicity: string;
  products?: IProductSegment[];
}

export const SummaryInformationTotalsShortLink = ({
  values,
  branch,
  templete,
  decorationHidden,
  isTrialPeriod,
  termsAndConditions,
  couponName,
  periodicity,
  products,
}: IPropsSummaryTotals) => {
  const steps = Array(12)
    .fill(1)
    .map((_, index) => index + 1);
  return (
    <CardBody templete={templete}>
      <div className="summary__information__short__link__container">
        <h5 className="summary__information__short__link__title">
          Resumen de la compra
        </h5>

        {products?.map((product, _index) =>
          product.quantity ? (
            <>
              {products[_index - 1] &&
              products[_index - 1].alias_name
                .toLocaleLowerCase()
                .indexOf('carretera') >= 0 &&
              product.alias_name.toLocaleLowerCase().indexOf('carretera') >=
                0 &&
              products[_index - 1].quantity ? (
                ''
              ) : (
                <div className="summary__information__short__link__content title--header">
                  <div className="">{product.alias_name}</div>
                </div>
              )}
              <div className="summary__information__short__link__content__group">
                <div className="summary__information__short__link__content__detail">
                  <div className="product__type">
                    <span>{product.category}</span>
                  </div>
                  <div className="product__price">
                    {' '}
                    <div className="">
                      ${' '}
                      {formatPrice(
                        product.alias_name
                          .toLocaleLowerCase()
                          .indexOf('carretera') > 0
                          ? values.priceForRoad
                          : (values.totalsForPlan as { price: number }[])[
                              _index
                            ] &&
                              (values.totalsForPlan as { price: number }[])[
                                _index
                              ].price,
                        branch
                      )}
                      /
                      {periodicity &&
                      periodicity.toLocaleLowerCase() === 'anual'
                        ? 'año'
                        : 'mes'}
                    </div>
                  </div>
                </div>
              </div>
              {product.profit_price && (
                <>
                  <div className="summary__information__short__link__content title--header">
                    Beneficios de pago
                  </div>
                  <div className="summary__information__short__link__content__group">
                    <div className="summary__information__short__link__content__detail">
                      <div className="product__type">
                        <span>{product.profit_title}</span>
                      </div>
                      <div className="product__price">
                        <div className="">
                          +${' '}
                          {formatPrice(
                            (product.profit_price as number) * product.quantity,
                            branch
                          )}
                          /
                          {periodicity &&
                          periodicity.toLocaleLowerCase() === 'anual'
                            ? 'año'
                            : 'mes'}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            ''
          )
        )}
        <div className="summary__information__short__link__content__group">
          {/* {values.discount ? (
            <div className="summary__information__short__link__content">
              <div className="summary__information__short__link__label orange">
                Descuento
              </div>
              <div className="summary__information__short__link__value orange">
                - $ {formatPrice(values.discount, branch)}
              </div>
            </div>
          ) : (
            ''
          )} */}
          {values.couponAmount ? (
            <div className="summary__information__short__link__content color--primary">
              <div className="summary__information__short__link__label">
                {couponName ? couponName : 'Cupón'}
              </div>
              <div className="summary__information__short__link__value">
                - $ {formatPrice(values.couponAmount, branch)}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="summary__information__short__link__divider"></div>
        <div className="summary__information__short__link__content">
          <div className="summary__information__short__link__label bold orange">
            Total
          </div>
          <div className="summary__information__short__link__value bold orange">
            $ {formatPrice(values.total, branch)}
          </div>
        </div>
        {isTrialPeriod && (
          <>
            <div className="summary__information__short__link__content">
              <div className="summary__information__short__link__label">
                Descuento hoy
              </div>
              <div className="summary__information__short__link__value">
                - $ {formatPrice(values.discountForFreeTrial, branch)}
              </div>
            </div>
            <div className="summary__information__short__link__content color--primary">
              <div className="summary__information__short__link__label bold">
                Total a pagar hoy
              </div>
              <div className="summary__information__short__link__value bold ">
                ${' '}
                {formatPrice(
                  values.total -
                    (values.discountForFreeTrial
                      ? values.discountForFreeTrial
                      : 0),
                  branch
                )}
              </div>
            </div>
          </>
        )}
        {values.couponAmount ? (
          <div
            className="summary__information__short__link__content coupon__terms"
            dangerouslySetInnerHTML={{ __html: termsAndConditions as string }}
          />
        ) : (
          ''
        )}
      </div>
      {!decorationHidden && (
        <div className="summary__information__short__link__decorator__container">
          {steps.map((item: number) => (
            <div
              key={`step-${item}`}
              className="summary__information__short__link__decorator__step"
            ></div>
          ))}
        </div>
      )}
    </CardBody>
  );
};
