import './Service-activation-message.scss';
import { ItemList } from '../Item-list/Item-list';

export const ServiceActivationMessage = () => {
  return (
    <ItemList>
      Podrás utilizar los servicios de tu plan{' '}
      <strong className="activation__bold">
        72 horas a partir del registro de tus propiedades.
      </strong>
    </ItemList>
  );
};
