import { Images } from 'assets/images/images.index';
import { PinConnect } from 'assets/icons/pin-connect';
import { CardBody } from 'components/atoms/Card-body/Card-body';
import { Button } from 'components/atoms/Button/Button';
import './Auth-from-generic.scss';
import { IAuthFromGeneric } from './models/Auth-from-generic.interface';
import { UseAuthFromGeneric } from './hooks/Auth-from-generic-hook';
import { Animations } from 'assets/animations/animations.index';
import { Loader } from 'components/atoms';
export const AuthFromGeneric = ({
  children,
  headerInformation,
  titleFrom,
  labelFromLink,
  labelPrimaryAction,
  listOfInformation,
  labelSecondaryAction,
  secondaryActionPath,
  isLoading,
  handleClick,
  stepper,
}: IAuthFromGeneric) => {
  const { navigateToPage } = UseAuthFromGeneric();
  return (
    <>
      <div className="container__background__screen__auth">
        <div className="container__screen__auth">
          <div className="header__form__auth">
            <PinConnect
              color="primary"
              className="logo__form__auth"
            ></PinConnect>
            {stepper && (
              <div className="step__form__auth">
                Paso {stepper.index} de {stepper.steps}
              </div>
            )}
          </div>
          <h3>{headerInformation.title}</h3>
          <p className="text__from__auth">{headerInformation.text}</p>
          <CardBody
            templete="outline"
            style={{ boxShadow: '5px 0px 4px 0px #f15b2b26' }}
          >
            {titleFrom && <h5>{titleFrom}</h5>}
            <div className="container__form__auth">
              <div>
                {children}
                {labelFromLink && (
                  <button
                    className="link__from__auth"
                    type="button"
                    onClick={() => navigateToPage('/forgot-password')}
                  >
                    {labelFromLink}
                  </button>
                )}
              </div>
              {labelPrimaryAction && (
                <Button
                  color="primary"
                  size="medium"
                  template="primary"
                  typeStyle="rounded"
                  className="from__login__button"
                  type="submit"
                  onClick={handleClick}
                  isDisabled={false}
                >
                  {labelPrimaryAction}
                </Button>
              )}
            </div>
          </CardBody>
          {secondaryActionPath && (
            <button
              className="link__from__auth light"
              onClick={() => navigateToPage(secondaryActionPath)}
              type="button"
            >
              {labelSecondaryAction}
            </button>
          )}
          {listOfInformation ? (
            <div className="container__list__from__auth">
              <div className="divider__from__auth"></div>
              {listOfInformation.map((item, index) => (
                <div key={'ul' + index} className="title__list__from__auth">
                  <h5 className="title__list__from__auth">{item.title}</h5>
                  <ul>
                    {item.items.map((value, index) => (
                      <li key={'li' + index} className="item__list__from__auth">
                        {value}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          ) : (
            ''
          )}
          <img
            className="header__logo"
            src={Images.connetLogoWhite}
            alt="logo connect blanco"
          />
        </div>
        <div className="decoration__form__auth">
          <img
            className="decoration__image decoration__image--right"
            src={Images.persona1}
            alt="loading"
          />
          <img
            className="decoration__image decoration__image--left"
            src={Images.persona2}
            alt="loading"
          />
        </div>
      </div>
      {isLoading && <Loader />}
    </>
  );
};
