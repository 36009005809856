import cms from './cms';

/**
 * Function to get all properties by user id
 * @param id of type number
 * @returns Object
 */
export async function getPropertyTypeById(id: number) {
  const response = await cms.get(`product-types/${id}`);
  return { ...response.data.data.attributes, id: response.data.data.id };
}
