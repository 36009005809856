import './Item-add-property-skeleton.scss';

export const ItemAddPropertySkeleton = () => {
  return (
    <div key={'producto__skeleton'} className="item__add__property__skeleton__product skeleton">
      <div className="icon skeleton"></div>
      <div className="quantity">
        <div className="button less skeleton"></div>
        <div className="border"></div>
        <div className="value skeleton"></div>
        <div className="border"></div>
        <div className="button plus skeleton"></div>
      </div>
    </div>
  );
};
