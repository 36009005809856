import { CadrInfo } from '../../atoms/Car-info/Card-info';
export interface IPropsCustomerSupport {
  onClick: () => void;
}
export const CustomerSupport = (props: IPropsCustomerSupport) => {
  return (
    <CadrInfo
      styleInLine={{ marginBottom: '0' }}
      message="Para soporte, contáctanos"
    ></CadrInfo>
  );
};
