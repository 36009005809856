import { ReactNode } from 'react';
import './Tag-label.scss';

interface IPropsTagLabel {
  children: ReactNode;
  onClick?: () => void;
  typeStyle: 'rounded' | 'square';
  type: 'primary' | 'secondary';
}
export const TagLabel = (props: IPropsTagLabel) => {
  return (
    <div className={`tag__label__container ${props.typeStyle} ${props.type}`}>
      {props.children}
      {props.onClick && (
        <div onClick={props.onClick} className="tag__label__icon">
          <svg
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="18"
              height="18"
              rx="9"
              className="tag__label__icon__rect"
            />
            <path
              d="M6.3865 5.75949L12.2406 11.6136L11.6134 12.2408L5.75928 6.38672L6.3865 5.75949Z"
              className="tag__label__icon__path"
            />
            <path
              d="M5.75928 11.6133L11.6134 5.75919L12.2406 6.38642L6.3865 12.2405L5.75928 11.6133Z"
              className="tag__label__icon__path"
            />
          </svg>
        </div>
      )}
    </div>
  );
};
