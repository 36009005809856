import './Button-close.scss';
interface IPropsButtonClose {
  onClick?: () => void;
  className?: string;
  size?: 'medium';
  template?: 'light' | 'dark';
}
export const ButtonClose = (props: IPropsButtonClose) => {
  return (
    <button
      className={`button__btn__close ${props.className ? props.className : ''}`}
      onClick={props.onClick}
    >
      <svg
        className={`${props.size} ${props.template ? props.template : 'light'}`}
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1L11 11"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 11L11 1"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
};
