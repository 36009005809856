import {
  ICheckoutStartedOrCompleted,
  ICheckoutStepVviewedOrCompleted,
  IClearInput,
  ICouponDenied,
  ICouponEntered,
  ICouponEvent,
  IEventSegment,
  IIdentifyUser,
  IOrderUpdated,
  IProductRemoved,
} from 'models/segment.interface';
import { v4 as uuidv4 } from 'uuid';
const keyIdentifiers = {
  checkout_id: uuidv4(),
  cart_id: uuidv4(),
};

const checkoutStarted = (event: ICheckoutStartedOrCompleted) => {
  invokeAnalytics('Checkout Started', {
    data: {
      ...event,
      affiliation: 'Página Web Regional',
    },
  });
};

const checkoutStepVviewed = (event: ICheckoutStepVviewedOrCompleted) => {
  invokeAnalytics('Checkout Step Viewed', {
    data: {
      ...event,
      checkout_id: keyIdentifiers.checkout_id,
    },
  });
};
const checkoutStepCompleted = (event: ICheckoutStepVviewedOrCompleted) => {
  invokeAnalytics('Checkout Step Completed', {
    data: { ...event, checkout_id: keyIdentifiers.checkout_id },
  });
};

const productRemoved = (event: IProductRemoved) => {
  invokeAnalytics('Product Removed', {
    data: { ...event },
  });
};

const orderUpdated = (event: IOrderUpdated) => {
  invokeAnalytics('Order Updated', {
    data: {
      ...event,
      affiliation: 'Página Web Regional',
    },
  });
};
const paymentInfoEntered = (order: string) => {
  invokeAnalytics('Payment Info Entered', {
    data: {
      checkout_id: keyIdentifiers.checkout_id,
      order_id: order,
    },
  });
};

const orderCompleted = (event: ICheckoutStartedOrCompleted) => {
  invokeAnalytics('Order Completed', {
    data: {
      ...event,
      affiliation: 'Página Web Regional',
      checkout_id: keyIdentifiers.checkout_id,
    },
  });
};

const couponEvent = (type: string, event: ICouponEvent) => {
  invokeAnalytics(type, {
    data: {
      ...event,
      cart_id: keyIdentifiers.cart_id,
    },
  });
};
const couponDenied = (event: ICouponDenied) => {
  invokeAnalytics('Coupon Denied', {
    data: {
      ...event,
      cart_id: keyIdentifiers.cart_id,
    },
  });
};
const couponEntered = (event: ICouponEntered) => {
  invokeAnalytics('Coupon Entered', {
    data: {
      ...event,
      cart_id: keyIdentifiers.cart_id,
    },
  });
};
const clearInput = (event: IClearInput) => {
  invokeAnalytics('Clear Input', {
    data: {
      ...event,
      cart_id: keyIdentifiers.cart_id ? keyIdentifiers.cart_id : undefined,
    },
  });
};

const identifyUser = (dataUser: IIdentifyUser) => {
  try {
    window.analytics.identify(dataUser);
  } catch (error) {
    console.log(`Error evento identify -> ${error}`);
  }
};

const invokeAnalytics = (type: string, data: IEventSegment) => {
  try {
    window.analytics.track(type, data.data);
  } catch (error) {
    console.log(`Error evento ${type} -> ${error}`);
  }
};

export {
  checkoutStarted,
  checkoutStepVviewed,
  productRemoved,
  checkoutStepCompleted,
  identifyUser,
  orderUpdated,
  paymentInfoEntered,
  orderCompleted,
  couponEvent,
  couponDenied,
  couponEntered,
  clearInput,
  keyIdentifiers,
};
