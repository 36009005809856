import { IAppReducer } from 'models/reducer.interface';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';

interface ICreateAccountGuardPros {
  children: JSX.Element;
}

export const CreateAccountGuard = ({ children }: ICreateAccountGuardPros) => {
  const { authenticatedUser } = useSelector(
    (state: IAppReducer) => state.authReducer
  );
  if (!authenticatedUser) {
    return children;
  } else {
    return <Navigate to="/settings" />;
  }
};
