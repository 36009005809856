import { Images } from 'assets/images/images.index';
import { PopupOverlay } from 'components/atoms/Popup-overlay/Popup-overlay';
import { ReactNode } from 'react';
import './Popup-required-validation-generic.scss';
import { Button } from 'components/atoms/Button/Button';

interface IPropsPopupRequiredValidationGeneric {
  title: string;
  labelButton?: string;
  closePopUp: () => void;
  children?: ReactNode;
  isLoading?: boolean;
}
export const PopupRequiredValidationGeneric = (
  props: IPropsPopupRequiredValidationGeneric
) => {
  return (
    <PopupOverlay>
      <div className={`popup__required__validation `}>
        <img
          className="popup__required__validation__icon"
          src={Images.auto}
          alt="Icono tipo modal"
        />
        <h6>{props.title}</h6>
        {props.children}
        <div className="popup__required__validation__btn">
          <Button
            type="button"
            size="small"
            template="primary"
            typeStyle="square"
            isDisabled={false}
            label="validar"
            color="primary"
            isLoading={props.isLoading}
            onClick={props.closePopUp}
          />
        </div>
      </div>
    </PopupOverlay>
  );
};
