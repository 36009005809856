import { Images } from 'assets/images/images.index';
import { useNavigate } from 'react-router';
import { getNameCountry, getPhoneIndicator } from 'utils/branch.utils';
import './User-info.scss';

export interface IPropsUserInfo {
  branch: string;
  name: string;
  lastname: string;
  email: string;
  phone: string;
  photoURL: string;
}

export const UserInfo = (props: IPropsUserInfo) => {
  const navigate = useNavigate();
  const goToEditProfiles = () => {
    navigate('/edit-profile');
  };
  return (
    <div className="user__info__container">
      <div className="user__info__data">
        <h5 className="user__info__data__name">
          {props.name} {props.lastname}
        </h5>
        <span className="user__info__data__country">
          {getNameCountry(props.branch)}
        </span>
        <span className="user__info__data__email">{props.email}</span>
        <span className="user__info__data__phone">
          ({getPhoneIndicator(props.branch)}) {props.phone}
        </span>
      </div>
      <img
        className="user__info__edit__info"
        src={Images.edit_profile}
        alt="Editar Perfil"
        onClick={goToEditProfiles}
      />
    </div>
  );
};
