import { IAppReducer } from 'models/reducer.interface';
import { useSelector } from 'react-redux';
import { getContactInfo } from 'utils/connect-info.utils';
import * as CSS from 'csstype';
import './Card-info.scss';
import { Icons } from 'assets/icons/icons.index';
import { Images } from 'assets/images/images.index';
import { Animations } from 'assets/animations/animations.index';

interface IPropsCardInfo {
  message: string;
  styleInLine?: CSS.Properties<string | number>;
}
export const CadrInfo = (props: IPropsCardInfo) => {
  const branch = useSelector(
    (state: IAppReducer) => state.branchReducer.branch
  );
  return (
    <div className="card__info__container" style={props.styleInLine}>
      <div className="card__info__message">{props.message}</div>
      <div className="card__info__options">
        <div className="card__info__option">
          <img src={Icons.email} alt="" />
          <a className="card__info__link " href={`mailto:soporte@connect.pr`}>
            soporte@connect.pr
          </a>
        </div>
        <div className="card__info__option">
          <img src={Icons.chat} alt="" />
          <a
            className="card__info__link "
            href={`tel:${getContactInfo(branch).phone}`}
          >
            {getContactInfo(branch).phone_mask}
          </a>
        </div>
      </div>
      <div className="card__info__container__image">
        <img className="image" src={Images.cardInfo} alt="" />
      </div>
    </div>
  );
};
