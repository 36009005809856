import './Button-social.scss';

interface IPropsButtonSocial {
  image: string;
  redSocial: 'instagram' | 'facebook';
  url: string;
  isDisabled?: boolean;
  iconClassName?: string;
}

export const ButtonSocial = (props: IPropsButtonSocial) => {
  return (
    <a
      aria-disabled={props.isDisabled}
      className={`button__social ${props.isDisabled ? 'disabled' : ''}`}
      href={props.url}
      target="_blank"
      rel="noreferrer"
    >
      <img
        className={`button__social__icon ${props.iconClassName ?? ''}}`}
        src={props.image}
        alt={`red social ${props.redSocial}`}
      />
    </a>
  );
};
