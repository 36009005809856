import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { config } from "./constants";
import { getRemoteConfig } from "firebase/remote-config";
const firebaseConfig = {
  apiKey: config.firebase.firebaseApiKey,
  authDomain: config.firebase.firebaseAuthDomain,
  projectId: config.firebase.firebaseProjectId,
  storageBucket: config.firebase.firebaseStorageBucket,
  messagingSenderId: config.firebase.firebaseMessagingSenderId,
  appId: config.firebase.firebaseApiKey,
};

const firebaseApp = initializeApp(firebaseConfig);
const defaultFirebaseAuth = getAuth(firebaseApp);
const defaultFirestore = getFirestore(firebaseApp);
const remoteConfig = getRemoteConfig(firebaseApp);

const defaultGoogleprovider = new GoogleAuthProvider();

export { defaultFirebaseAuth, defaultFirestore, defaultGoogleprovider };